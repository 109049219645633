export default {
  click: 'click',
  error: 'error',
  play: 'play',
  read: 'read',
  open: 'open',
  ended: 'ended',
  link: 'link',
  navigate: 'navigate',
  register: 'register',
  login: 'login',
  subscribe: 'subscribe',
  submit: 'submit',
  order: 'order',
  pay: 'pay',
  cancel: 'cancel',
  save: 'save',
  toggle: 'toggle',
  start: 'start',
  display: 'display',
  confirm: 'confirm',
  schedule: 'schedule',
  reschedule: 'reschedule',

  readBio: 'readBio',
  changeGoal: 'changeGoal',
  changeTimeslot: 'changeTimeslot',
  changeTimezone: 'changeTimezone',
  selectTime: 'selectTime',
  selectDay: 'selectDay',
  selectMonth: 'selectMonth',
  selectAppointment: 'selectAppointment',

  timeUpdate: 'timeUpdate',
  resendEmail: 'resendEmail',
  learnMore: 'learnMore',
  saveProfile: 'saveProfile',
  saveGoal: 'saveGoal',
  selectUploadFile: 'selectUploadFile',
  selectSubscribe: 'selectSubscribe',

  assetPlay: 'play',
  assetTimeUpdate: 'timeUpdate',
  assetEnded: 'ended',
  assetRead: 'read',
  assetDocumentDownload: 'documentDownload',

  authRegisterSurccess: 'register-success',
  authRegisterError: 'register-error',
  authLoginSuccess: 'login-success',
  authRecoverError: 'recoverLogin-error',
  authRecoverSuccess: 'recoverLogin-success',
  
  purchasePlaceOrder: 'placeOrder',
  purchasePlaceOrderError: 'placeOrder-error',
  purchasePlaceOrderComplete: 'placeOrder-complete',
  purchaseUpdatePayment: 'updatePayment',
  purchaseUpdatePaymentError: 'updatePayment-error',
  purchaseUpdatePaymentComplete: 'updatePayment-complete',

  assessmentReportDownload: 'reportDownload',
}