import routeConstants from "../constants/route.constants";


export const pageTitles = {
  '/': 'Home.PageTitle',
  '/coaching': 'Pages.Coaching.Title',
  '/chat': 'Pages.Chat.Title',
  [routeConstants.assessments]: 'Pages.Assessment.Title',
  '/resume': 'Pages.Resume.Title',
  '/insights': 'Insights.Home.TitlePost',
  '/interview': 'Pages.Interview.Title',
  '/job': 'GC.JobSearchTitle',
  '/profile': 'Pages.Profile.Title',
  '/preferences': 'Pages.Preferences.Title1',
  '/about': 'Pages.About.Title',
  '/faq': 'Pages.Faq.Title',
  '/login': 'Pages.Login.Title',
  '/register': 'Pages.Register.Title',
  [routeConstants.eLearning]: 'Pages.ELearning.Title'
}