/* eslint-disable */
import axios from 'axios'
import humps from 'humps'
import moment from 'moment-timezone'
//@todo import langauge specific file
import { levels } from '../../constants/level/en.json'
//@todo import langauge specific file
import { functions } from '../../constants/functions/en.json'
//@todo import langauge specific file
import { sectors } from '../../constants/sectors/en.json'
import apiErrorCodes from '../../constants/api-error-codes'
import { serialize, updateObject, findInArray, getHost,api , getContent } from '../../shared/utility'
import { actions as actionToaster } from './toaster'
import { actions as authActions } from './auth'
import { actions as attributesActions } from './attributes'
import { actions as questionsActions } from './questions'
import { actions as panelActions } from './panels'

const formatResponse = response => {
  return new Promise(function(resolve) {
    response.createdAt = moment(response.created).format('MM-DD-YY')
    //console.log(response ? response.data : null)
    if (response && response.data) {
      if (response.data.industries) {
        //not sure why old app keeps evaluating sectors if its a constant
        //const userIndustries = [...response.data.industries]
        // const sectors = []
        // for (const industry of industries) {
        //   for (const sector of industry.Sectors) {
        //     sectors.push(merge(pick(['c', 'd'], sector), { i: industry.c }))
        //   }
        // }

        const arr = [...response.data.industries]
        response.data.industries = arr.map(item => {
          const found = findInArray('c', item.cd, sectors)
          if (found) item.label = found['d']
          return item
        })
      }
      if (response.data.levels) {
        const arr = [...response.data.levels]
        response.data.levels = arr.map(item => {
          const found = findInArray('C', item.cd, levels)
          if (found) item.label = found['D']
          return item
        })
      }

      if (response.data.functions) {
        const arr = [...response.data.functions]
        response.data.functions = arr.map(item => {
          const found = findInArray('C', item.cd, functions)
          if (found) item.label = found['F']

          return item
        })
      }
    }

    resolve(response)
  })
}

// ActionTypes

export const actionTypes = {
  GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
  GET_USER_PROFILE_START: 'GET_USER_PROFILE_START',
  GET_USER_PROFILE_FAIL: 'GET_USER_PROFILE_FAIL',
  SAVE_USER_PROFILE_START: 'SAVE_USER_PROFILE_START',
  SAVE_USER_PROFILE_SUCCESS: 'SAVE_USER_PROFILE_SUCCESS',
  SAVE_USER_PROFILE_FAIL: 'SAVE_USER_PROFILE_FAIL',
  //UPLOAD_ACTIONPLAN_SUCCESS: 'UPLOAD_ACTIONPLAN_SUCCESS',
  POST_SURVEY_OPEN: 'POST_SURVEY_OPEN',
  POST_SURVEY_CLOSE: 'POST_SURVEY_CLOSE',
}

// Action Creators

export const actions = {
  openPostSurvey: () => ({ type: actionTypes.POST_SURVEY_OPEN }),
  closePostSurvey: () => ({ type: actionTypes.POST_SURVEY_CLOSE }),
  getUserProfileSuccess: data => ({ type: actionTypes.GET_USER_PROFILE_SUCCESS, data }),
  getUserProfileFail: error => ({ type: actionTypes.GET_USER_PROFILE_FAIL, error: error }),
  getUserProfileStart: () => ({ type: actionTypes.GET_USER_PROFILE_START }),
  saveUserProfileSuccess: data => ({ type: actionTypes.SAVE_USER_PROFILE_SUCCESS, data }),
  saveUserProfileFail: error => ({ type: actionTypes.SAVE_USER_PROFILE_FAIL, error: error }),
  saveUserProfileStart: () => ({ type: actionTypes.SAVE_USER_PROFILE_START }),
  //uploadActionPlanSuccess: data => ({ type: actionTypes.UPLOAD_ACTIONPLAN_SUCCESS, data }),

  saveUserProfile: (userKey, postData, options = { 
    disableStartDispatch: false,
  }) => {
    return (dispatch, getState) => {
      dispatch(actions.saveUserProfileStart())
      let path = `${getHost().apiBaseUrl}/user`

      return axios(path, {
        method: 'post',
        data: postData,
        withCredentials: true,
      })
        .then(response => {
          return humps.camelizeKeys(response.data)
        })

        .then(response => {
          const { content } = getState()
          !options.disableStartDispatch && dispatch(actions.saveUserProfileSuccess(response))
          dispatch(actions.getUserProfile(userKey, null, { disableStartDispatch: options.disableStartDispatch}))
          dispatch(attributesActions.getUserAttributes(true))
          dispatch(questionsActions.getUserQuestions())
        })
        .catch(error => {
          console.error(error)
          dispatch(actions.saveUserProfileFail(error.response))
          const errorCode = error?.response?.data?.Code;
          if (errorCode === apiErrorCodes.CODE_FIRST_NAME_ERROR) {
            dispatch(actionToaster.showError(getContent('Pages.Profile.FirstName.Error')))
          }
          else if (errorCode === apiErrorCodes.CODE_LAST_NAME_ERROR) {
            dispatch(actionToaster.showError(getContent('Pages.Profile.LastName.Error')))
          }
        })
    }
  },
  saveUserProfile2: (userKey, postData, entityKey, route) => {
    return (dispatch, getState) => {
      const { content, settings, auth } = getState()
      const firstTimeSave = auth && !auth.purchState.profile.isComplete
      dispatch(actions.saveUserProfileStart())

      let path = `${getHost().apiBaseUrl}/user`

      return axios(path, {
        method: 'post',
        data: postData,
        withCredentials: true,
      })
        .then(response => {      
          return humps.camelizeKeys(response.data)
        })

        .then(async response => {          
          dispatch(actions.saveUserProfileSuccess(response))
          dispatch(actions.getUserProfile(userKey))
          dispatch(attributesActions.getUserAttributes(true))
          dispatch(questionsActions.getUserQuestions())
          dispatch(panelActions.getUserPanels(userKey, entityKey, route))
          if(settings.showPostSurveyMsg && firstTimeSave) {
            dispatch(actions.openPostSurvey())
          }
        })
        .catch(error => {
          console.error(error)
          dispatch(actions.saveUserProfileFail(error.response))
          const errorCode = error?.response?.data?.Code;
          if (errorCode === apiErrorCodes.CODE_FIRST_NAME_ERROR) {
            dispatch(actionToaster.showError(getContent('Pages.Profile.FirstName.Error')))
          }
          else if (errorCode === apiErrorCodes.CODE_LAST_NAME_ERROR) {
            dispatch(actionToaster.showError(getContent('Pages.Profile.LastName.Error')))
          }
        })
    }
  },
  getUserProfile: (userKey, queryParams, options = { disableStartDispatch: false }) => {
    return (dispatch, getState) => {
      !options.disableStartDispatch && dispatch(actions.getUserProfileStart())
      let path = `/user/` + (queryParams ? serialize(queryParams) : '')

      let prom = api.get(path,true)
        .then(response => {
          const { auth, settings } = getState()
          const theUser = { ...response.data, EntityKey : auth.user.EntityKey  }
          if ( auth && auth.user  ) {
            //To make sure both userProfile and auth.user get populated.
            //TODO: combine userProfile and auth.user objects
          
          dispatch(authActions.checkConsentOnUserFetchSuccess(theUser, settings))
          }          
          dispatch(authActions.fetchUserSuccess(theUser))
          //return humps.camelizeKeys(response.data)
          return response.data
        })
        //.then(formatResponse)
        .then(response => {
          
          dispatch(actions.getUserProfileSuccess(response))
        })
        .catch(error => {
          console.error(error)
          dispatch(actions.getUserProfileFail(error.response))
        })

        return prom
    }
  },
  uploadImage: (userKey, selectedFile) => {
    return (dispatch, getState) => {
      //dispatch(actions.saveUserProfileStart())
      let path = `${getHost().apiBaseUrl}/image/upload/${userKey}` + `/undefined`
      return axios(path, {
        method: 'PUT',
        headers: {
          'content-type': 'image/jpeg',
        },
        data: selectedFile,
        withCredentials: true,
      })
        .then((response) => {
          const { content } = getState()
          //console.log("uploadImage reponse", response)
          //dispatch(actions.saveUserProfileSuccess(response))
          dispatch(actions.getUserProfile(userKey))
          //dispatch(actionToaster.showSuccess('Global.Message.ImageUpload'))
        })
        .catch(error => {
          console.error(error)
          dispatch(actions.saveUserProfileFail(error.response))
        })
    }
  },
}

// Reducer
const defaultState = {
  loaded:false,
  loading: false,
  saving: false,
  //data: null,
  error: null,
  isPostSurveyOpen: false,
  loadingProfile: false,
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.POST_SURVEY_OPEN:
    return updateObject(state, {
      isPostSurveyOpen: true
    })
    case actionTypes.POST_SURVEY_CLOSE:
    return updateObject(state, {
      isPostSurveyOpen: false
    })
    case actionTypes.GET_USER_PROFILE_START:
      return updateObject(state, {
        loading: true,
        //data:null
      })
    case actionTypes.GET_USER_PROFILE_SUCCESS:
      return updateObject(state, {
        loading: false,
        //data: action.data,
      })
    case actionTypes.GET_USER_PROFILE_FAIL:
      return updateObject(state, {
        loading: false,
        error: action.error,
      })
    case actionTypes.SAVE_USER_PROFILE_START:
      return updateObject(state, {
        saving: true,
      })
    case actionTypes.SAVE_USER_PROFILE_SUCCESS:
      return updateObject(state, {
        saving: false,        
        error: null,
        //error: 'Fake',
      })
    case actionTypes.SAVE_USER_PROFILE_FAIL:
      return updateObject(state, {
        saving: false,
        error: action.error ? action.error : 'Failed',
      })
    // case actionTypes.UPLOAD_ACTIONPLAN_SUCCESS:
    //   return updateObject(state, {
    //     loading: false,
    //     data: {...state.data, reports:state.data.reports.map(r => r.category === 'ActionPlan' ? {...r, url:action.data} : r )}
    //   })

    default:
      return state
  }
}
