import React from 'react';
import './style.css';
import {aria} from '../../wcag';
import {isMobile} from '../../shared/utility';

// IE11 javascript does not support Array.prototype.includes
if (!Array.prototype.includes) {
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(Array.prototype, "includes", {
    enumerable: false,
    value: function(obj) {
        var newArr = this.filter(function(el) {
          return el === obj;
        });
        return newArr.length > 0;
      }
  });
}

const exceptions = [
  '/login',
  '/register',
  '/gift',
  '/reset',
  '/recover',
  '/intake',
  '/ssologin',
]

export default (props) => {
  if (isMobile()) return null;

  return exceptions.includes(window.location.pathname) ? null : (
    <div id="skipNavigation">
      <a href={`#${aria.ids.mainContent}`}>skip navigation</a>
    </div>
  )
}
