import { combineReducers } from 'redux'
import locationReducer from '../../redux/modules/location.js'

//import users from '../../redux/modules/users'
import users from '../../redux/modules/user-search'
import auth from '../../redux/modules/auth'
import mqtt from '../../redux/modules/mqtt'
import userProfile from '../../redux/modules/user-profile'
import userDocs from '../../redux/modules/user-docs'
import userNotes from '../../redux/modules/user-notes'
import userProduct from '../../redux/modules/user-product'
import userActions from '../../redux/modules/user-actions'
import userResume from '../../redux/modules/user-resume'
import coachDetails from '../../redux/modules/coach-details'
import userAppointments from '../../redux/modules/user-appointments'
import coachSchedule from '../../redux/modules/coach-schedule'
import reports from '../../redux/modules/reports'
import invite from '../../redux/modules/invite'
import coaches from '../../redux/modules/coaches'
import toaster from '../../redux/modules/toaster'
import purchase from '../../redux/modules/purchase'
import insights from '../../redux/modules/insights'
import constants from '../../redux/modules/constants'
import content from '../../redux/modules/content'
import settings from '../../redux/modules/settings'
import chat from '../../redux/modules/chat'
import frontend from '../../redux/modules/frontend'
import survey from '../../redux/modules/survey'
import video from '../../redux/modules/video'
import goals from '../../redux/modules/goals'
import attributes from '../../redux/modules/attributes'
import questions from '../../redux/modules/questions'
import panels from '../../redux/modules/panels'
import access from '../../redux/modules/access'
import maps from '../../redux/modules/maps'
import gift from '../../redux/modules/gift'

export const makeRootReducer = asyncReducers => {
  return combineReducers({
    location: locationReducer,
    ...asyncReducers,
    users,
    userDocs,
    userNotes,
    userProfile,
    userProduct,
    userActions,
    userResume,
    userAppointments,
    reports,
    auth,
    mqtt,
    coachSchedule,
    invite,
    coaches,
    toaster,
    purchase,
    coachDetails,
    insights,
    constants,
    content,
    settings,
    chat,
    frontend,
    survey,
    video,
    goals,
    attributes,
    questions,
    panels,
    access,
    maps,
    gift,
  })
}

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}
