import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { querystring } from '../../shared/utility'

const NullRoute = ({ component: C, props: cProps, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const {location, match, history } = props;
        if (!window.kfadvance) window.kfadvance = {location, match, history};
        
        if (cProps.content.loaded || !cProps.content.loading){
          return <C {...props} {...cProps} />
        }

        return null;
      }}
    />
  )
}

export default NullRoute;