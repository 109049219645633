import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import shortid from 'shortid'

import UnauthenticatedRoute from './components/Routing/UnauthenticatedRoute'
import AuthenticatedRoute from './components/Routing/AuthenticatedRoute'
import NullRoute from './components/Routing/NullRoute'
import asyncComponent from './hoc/asyncComponent'
import TermsOfUse from './assets/pdfs/KF-Advance-Terms-of-Use.pdf'
import routeConstants from './constants/route.constants';

const AsyncProfile = asyncComponent(() => import('./containers/Profile'))
const AsyncHome = asyncComponent(() => import('./containers/Home'))
const AsyncAccount = asyncComponent(() => import('./containers/Account/Account'))
const AsyncAccountPreferences = asyncComponent(() => import('./containers/AccountPreferences/AccountPreferences'))
const AsyncServices = asyncComponent(() => import('./containers/Services/Services'))
const AsyncAbout = asyncComponent(() => import('./containers/About'))
const AsyncAboutKfm = asyncComponent(() => import('./containers/About/AboutKfm'))
const AsyncFAQ = asyncComponent(() => import('./containers/FAQ'))
const AsyncMentoring = asyncComponent(() => import('./containers/Mentoring/Mentoring'))

//const AsyncClientSearch = asyncComponent(() => import('./containers/Users/Search'))
//import AsyncClientSearch from './containers/Users/Search'
//const AsyncProfile = asyncComponent(() => import('./containers/UserProfile/UserProfile'))
//import AsyncProfile from './containers/UserProfile/UserProfile'
//const AsyncAppointments = asyncComponent(() => import('./containers/Appointments/Appointments'))
//import AsyncAppointments from './containers/Appointments/Appointments'

const AsyncLogin = asyncComponent(() => import('./containers/Login'))
const AsyncLogout = asyncComponent(() => import('./containers/Logout/Logout'))
const AsyncRegister = asyncComponent(() => import('./containers/Register'))
const AsyncRecoverPassword = asyncComponent(() => import('./containers/RecoverPassword'))
const AsyncResetPassword = asyncComponent(() => import('./containers/ResetPassword'))
const AsyncIntake = asyncComponent(() => import('./containers/Intake/Intake'))

const AsyncSsoLogin = asyncComponent(() => import('./containers/SsoLogin'))
const AsyncSsoEmailLogin = asyncComponent(() => import('./containers/SsoEmailLogin'))
//const AsyncNotFound = asyncComponent(() => import('./components/NotFound/NotFound'))
//const AsyncScheduler = asyncComponent(() => import('./containers/Scheduler/Scheduler'))
//const AsyncAccountProfile = asyncComponent(() => import('./containers/Account/Profile'))
//const AsyncReports = asyncComponent(() => import('./containers/Reports/Reports'))
//const AsyncInvite = asyncComponent(() => import('./containers/Invite/Invite'))
//const AsyncHome = asyncComponent(() => import('./containers/Home/Home'))

const AsyncInsights = asyncComponent(() => import('./containers/Insights/InsightsSlider'))
const AsyncResume = asyncComponent(() => import('./containers/Resume/Resume'))
const AsyncAssessment = asyncComponent(() => import('./containers/Assessment'))
const AsyncInterviewPrep = asyncComponent(() => import('./containers/InterviewPrep'))
const AsyncInterviewBestPractices = asyncComponent(() => import('./containers/InterviewPrep/BestPractices'))
const AsyncInterviewTips = asyncComponent(() => import('./containers/InterviewPrep/Tips'))
const AsyncInterviewTrickyQuestions = asyncComponent(() => import('./containers/InterviewPrep/TrickyQuestions'))
const AsyncCareerCoach = asyncComponent(() => import('./containers/CareerCoach'))
const AsyncPaycheck = asyncComponent(() => import('./containers/Paycheck/Paycheck'))
const AsyncJobs = asyncComponent(() => import('./containers/Jobs'))
const AsyncPurchase = asyncComponent(() => import('./containers/Purchase/Purchase'))
const AsyncThankYou = asyncComponent(() => import('./containers/ThankYou/ThankYou'))
const AsyncPhilosophy = asyncComponent(() => import('./containers/Resume/Popups/Philosophy'))
const AsyncTips = asyncComponent(() => import('./containers/Resume/Popups/Tips'))
const AsyncDos = asyncComponent(() => import('./containers/Resume/Popups/Dos'))
const AsyncArticles = asyncComponent(() => import('./containers/Articles/Articles'))
const AsyncChat = asyncComponent(() => import('./containers/Chat/Chat'))
const AsyncPayment = asyncComponent(() => import('./containers/Payment/Payment'))

const AsyncVideoPlayer = asyncComponent(() => import('./containers/VideoPlayer'))
const AsyncDebug = asyncComponent(() => import('./containers/Debug'))
const AsyncLearning = asyncComponent(() => import('./containers/Learning'))
const AsyncGift = asyncComponent(() => import('./containers/Gift'))
const Journeys = asyncComponent(() => import('./containers/Journeys'))
const Programs = asyncComponent(() => import('./containers/Programs'))
const AsyncInsightDetail = asyncComponent(() => import('./containers/Insights/AssetDetail'))
//routes still use jsx as recommended but following is done so that in future nav links can derive from it

export const routes = [
  { path: '/', exact: true, isAuth: true, component: AsyncHome, key: shortid.generate() },
  { path: '/chat', exact: true, isAuth: true, component: AsyncChat, key: shortid.generate() },
  { path: '/account', exact: true, isAuth: true, component: AsyncAccount, key: shortid.generate() },
  { path: '/services', exact: false, isAuth: true, component: AsyncServices, key: shortid.generate() },
  { path: '/about', exact: false, isAuth: true, component: AsyncAbout, key: shortid.generate() },
  { path: '/aboutkfm', exact: false, isAuth: true, component: AsyncAboutKfm, key: shortid.generate() },
  { path: '/faq', exact: false, isAuth: true, component: AsyncFAQ, key: shortid.generate() },
  { path: '/profile', exact: true, isAuth: true, component: AsyncProfile, key: shortid.generate() },
  { path: '/home', exact: true, isAuth: true, component: AsyncHome, key: shortid.generate() },
  { path: '/article/:id', exact: true, isAuth: true, component: AsyncArticles, key: shortid.generate() },
  { path: '/resume', exact: true, isAuth: true, component: AsyncResume, key: shortid.generate() },
  { path: routeConstants.assessments, exact: true, isAuth: true, component: AsyncAssessment, key: shortid.generate() },
  { path: '/intake', exact: true, isAuth: null, component: AsyncIntake, key: shortid.generate() },
  { path: '/interview', exact: true, isAuth: true, component: AsyncInterviewPrep, key: shortid.generate() },
  { path: '/interview/bestpractices', exact: true, isAuth: true, component: AsyncInterviewBestPractices, key: shortid.generate() },
  { path: '/interview/tips', exact: true, isAuth: true, component: AsyncInterviewTips, key: shortid.generate() },
  { path: '/interview/trickyquestions', exact: true, isAuth: true, component: AsyncInterviewTrickyQuestions, key: shortid.generate() },
  { path: '/coaching', exact: true, isAuth: true, component: AsyncCareerCoach, key: shortid.generate() },
  { path: '/coaching/:itemKey', exact: true, isAuth: true, component: AsyncCareerCoach, key: shortid.generate() },
  { path: '/mentoring', exact: true, isAuth: true, component: AsyncMentoring, key: shortid.generate() },
  { path: '/mentoring/:itemKey', exact: true, isAuth: true, component: AsyncMentoring, key: shortid.generate() },
  { path: '/paycheck', exact: true, isAuth: true, component: AsyncPaycheck, key: shortid.generate() },
  { path: '/job', exact: true, isAuth: true, component: AsyncJobs, key: shortid.generate() },
  { path: '/login', exact: true, isAuth: false, component: AsyncLogin, key: shortid.generate() },
  { path: '/account/logout', exact: true, isAuth: true, component: AsyncLogout, key: shortid.generate() },
  { path: '/kf-terms', exact: true, isAuth: true, component: TermsOfUse, key: shortid.generate() },
  { path: '/purchase/:productKey', exact: true, isAuth: true, component: AsyncPurchase, key: shortid.generate() },
  { path: '/thankyou', exact: true, isAuth: true, component: AsyncThankYou, key: shortid.generate() },
  { path: '/resume/philosophy', exact: true, isAuth: true, component: AsyncPhilosophy, key: shortid.generate() },
  { path: '/resume/tips', exact: true, isAuth: true, component: AsyncTips, key: shortid.generate() },
  { path: '/resume/dosdonts', exact: true, isAuth: true, component: AsyncDos, key: shortid.generate() },
  { path: '/insights', exact: true, isAuth: true, component: AsyncInsights, key: shortid.generate() },
  { path: '/register', exact: true, isAuth: false, component: AsyncRegister, key: shortid.generate() },
  { path: '/recover', exact: true, isAuth: false, component: AsyncRecoverPassword, key: shortid.generate() },
  { path: '/reset', exact: true, isAuth: false, component: AsyncResetPassword, key: shortid.generate() },
  { path: '/preferences', exact: true, isAuth: true, component: AsyncAccountPreferences, key: shortid.generate() },
  { path: '/payment', exact: true, isAuth: true, component: AsyncPayment, key: shortid.generate() },
  { path: '/video', exact: true, isAuth: true, component: AsyncVideoPlayer, key: shortid.generate() },
  { path: '/debug', exact: true, isAuth: true, component: AsyncDebug, key: shortid.generate() },
  { path: routeConstants.eLearning, exact: true, isAuth: true, component: AsyncLearning, key: 'AsyncLearning' },
  { path: '/gift', exact: true, isAuth: null, component: AsyncGift, key: shortid.generate() },
  { path: '/journeys/:workflowKey/:workflowInstanceKey?', exact: false, isAuth: true, component: Journeys, key: shortid.generate() },
  { path: '/programs/:workflowKey/:workflowInstanceKey', exact: false, isAuth: true, component: Programs, key: shortid.generate() },
  { path: '/insights/:assetType/:assetPath', exact: false, isAuth: true, component: AsyncInsightDetail, key: shortid.generate() },
  // { path: '/journeys/:workflowKey/:workflowInstanceKey/:workflowStepKey', exact: true,  loaded:true, component: Journeys, key: shortid.generate() },
  { path: '/ssologin', exact: true, isAuth: false, component: AsyncSsoLogin, key: shortid.generate() },
  { path: '/ssoemaillogin', exact: true, isAuth: false, component: AsyncSsoEmailLogin, key: shortid.generate() },
]

export const noProductRoutes = [
  { path: '/', exact: true, isAuth: true, component: AsyncHome, key: 'home' },
  { path: '/account/logout', exact: true, isAuth: true, component: AsyncLogout, key: shortid.generate() },
]


const PageNotFound = () => <Redirect to='/' />

const Routes = ({ childProps }) => {
  const { purchState } = childProps;
  //console.log('IN ROUTES WITH PURCHSTATE purchState', purchState.hasNoProduct)

  const validRoutes = purchState.hasNoProduct ? noProductRoutes : routes;

  return (
    <Switch>
      {validRoutes && validRoutes.map(item => {
        if (item.isAuth === null)
          return <NullRoute key={item.key} path={item.path} exact component={item.component} props={childProps} />

        return item.isAuth ?
          <AuthenticatedRoute key={item.key} path={item.path} exact={item.exact} component={item.component} props={childProps} sub={item.sub} />
          : <UnauthenticatedRoute key={item.key} path={item.path} exact component={item.component} props={childProps} />
      })}

      <Route component={PageNotFound} />
    </Switch>
  )
}

export default Routes;
