import React from 'react'


export default class extends React.Component {
    render() {
     const { Color } = this.props
     const color = Color ? Color : "#c8102e";

      return (
        <svg width="26px" height="24px" viewBox="0 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" >
        <title>Apps</title>
        <g id="201217_NewVersion" stroke={color} strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Client_Portal" transform="translate(-1050.000000, -23.000000)" fill={color}>
                <g id="Group" transform="translate(1050.000000, 16.000000)">
                    <g id="Apps" transform="translate(0.000000, 7.000000)">
                        <g id="navigation-menu-vertical" transform="translate(20.000000, 0.000000)">
                            <circle id="Oval" cx="2.987" cy="20.628" r="2.625"></circle>
                            <circle id="Oval" cx="2.987" cy="3.378" r="2.625"></circle>
                            <circle id="Oval" cx="2.987" cy="12.003" r="2.625"></circle>
                        </g>
                        <g id="navigation-menu-vertical" transform="translate(10.000000, 0.000000)">
                            <circle id="Oval" cx="2.987" cy="20.628" r="2.625"></circle>
                            <circle id="Oval" cx="2.987" cy="3.378" r="2.625"></circle>
                            <circle id="Oval" cx="2.987" cy="12.003" r="2.625"></circle>
                        </g>
                        <g id="navigation-menu-vertical">
                            <circle id="Oval" cx="2.987" cy="20.628" r="2.625"></circle>
                            <circle id="Oval" cx="2.987" cy="3.378" r="2.625"></circle>
                            <circle id="Oval" cx="2.987" cy="12.003" r="2.625"></circle>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
      )
    }
  }