import humps from 'humps'
import axios from 'axios'
import { api, updateObject, getHost } from '../../shared/utility'

const R = require('ramda')

export const actionTypes = {
  GET_COACH_BIO_SUCCESS: 'GET_COACH_BIO_SUCCESS',
  GET_COACH_BIO_START: 'GET_COACH_BIO_START',
  GET_COACH_BIO_FAIL: 'GET_COACH_BIO_FAIL',
  GET_COACHING_GOALS_SUCCESS: 'GET_COACHING_GOALS_SUCCESS',
  GET_COACHING_GOALS_FAIL: 'GET_COACHING_GOALS_FAIL',
  OPEN_COACH_PROFILE: 'OPEN_COACH_PROFILE',
  CLOSE_COACH_PROFILE: 'CLOSE_COACH_PROFILE',
}

// Action Creators

export const actions = {
  openCoachProfile: () => ({
    type: actionTypes.OPEN_COACH_PROFILE,
  }),

  closeCoachProfile: () => ({
    type: actionTypes.CLOSE_COACH_PROFILE,
  }),

  getCoachBioSuccess: (userKey, data) => ({
    type: actionTypes.GET_COACH_BIO_SUCCESS,
    userKey,
    data,
  }),

  getCoachBioFail: error => ({
    type: actionTypes.GET_COACH_BIO_FAIL,
    error,
  }),

  getCoachBioStart: () => ({
    type: actionTypes.GET_COACH_BIO_START,
  }),

  getCoachingGoals: () => dispatch => {
    dispatch(actions.getCoachBioStart())
    let path = `${getHost().apiBaseUrl}/partner/goals?items=GoalOptions`
    return axios(path, {
      method: 'GET',
      withCredentials: true,
    })
      .then(response => {
        dispatch(actions.getCoachingGoalsSuccess(response.data))
      })
      .catch(error => {
        dispatch(actions.getCoachingGoalsFail(error))
      })
  },

  getCoachingGoalsSuccess: payload => ({
    type: actionTypes.GET_COACHING_GOALS_SUCCESS,
    payload,
  }),
  getCoachingGoalsFail: error => ({
    type: actionTypes.GET_COACHING_GOALS_FAIL,
    error,
  }),
  getCoachBio: coachKey => dispatch => {
    dispatch(actions.getCoachBioStart())
    let path = `${getHost().apiBaseUrl}/coach/${coachKey}`
    return axios(path, {
      method: 'GET',
      withCredentials: true,
    })
      .then(response => {
        dispatch(actions.getCoachBioSuccess(coachKey, response.data))
      })
      .catch(error => {
        dispatch(actions.getCoachBioFail(error))
      })

    // let path = `${getHost().apiBaseUrl}/coach/${coachKey}`
    //
    // return api
    //   .get(path, true)
    //   .then(response => {
    //     return humps.camelizeKeys(response.data)
    //   })
    //   .then(response => {
    //     dispatch(actions.getCoachBioSuccess(coachKey, response))
    //   })
    //   .catch(error => {
    //     dispatch(actions.getCoachBioFail(error))
    //   })
  },
}

// Reducer

const defaultState = {
  loading: false,
  loaded: false,
  userKey: null,
  data: null,
  isOpenCoachProfile: false,
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.OPEN_COACH_PROFILE:
      return updateObject(state, { isOpenCoachProfile: true })

    case actionTypes.CLOSE_COACH_PROFILE:
      return updateObject(state, { isOpenCoachProfile: false })

    case actionTypes.GET_COACH_BIO_START:
      return updateObject(state, { loading: true })
    case actionTypes.GET_COACH_BIO_SUCCESS:
      return updateObject(state, {
        loading: false,
        loaded: true,
        data: action.data,
        userKey: action.userKey,
      })
    case actionTypes.GET_COACH_BIO_FAIL:
      return updateObject(state, {
        loading: false,
        data: undefined,
        userKey: undefined,
      })
    case actionTypes.GET_COACHING_GOALS_SUCCESS:
      const theGoals = R.map(item => {
        return {
          text: item.GoalOptions,
          value: item.ID,
          key: item.ID,
        }
      }, action.payload.GoalOptions)
      return updateObject(state, {
        goals: theGoals,
      })
    case actionTypes.GET_COACHING_GOALS_FAIL:
      return updateObject(state, {
        goals: [],
        error: action.error,
      })
    default:
      return state
  }
}
