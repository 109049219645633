import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MuiDialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Spinner from "../Spinner/Spinner";
import { ButtonMainAction } from "../../styles/common2"

const Dialog = styled(MuiDialog)`
  && {
    h2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 10px;
      b {
        font-weight: bold;
      }
    }
  }
`
const AccountStatusMessage = styled.div`
  && {
    padding: 30px 30px 50px;
    font-size: 18px;
    line-height: 1.4;
  }
`;

class AccountStatusMessageModal extends React.Component {

  render() {
    const {
      fullScreen,
      loading,
      isOpen,
      c,
      isRevoked,
      isSuspended,
      onClose
    } = this.props;

    const error = isRevoked ? 'Revoked' : isSuspended ? 'Suspended' : 'None'
    const title = c("Modal.AccountStatus.Title")
    
    return (
      <Dialog fullScreen={fullScreen} open={isOpen} aria-labelledby={title}>
        
        <AccountStatusMessage dangerouslySetInnerHTML={{ __html: c("Modal.AccountStatus." + error) }} />
        
        <DialogActions>
          <ButtonMainAction onClick={onClose}>
            {c("Modal.AccountStatus.Close")}
          </ButtonMainAction>
        </DialogActions>
        {loading && <Spinner />}
      </Dialog>
    );
  }
}

AccountStatusMessageModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(AccountStatusMessageModal);
