import React from "react"
import styled from 'styled-components'
import { mobile } from '../../styles/media-queries'


const StyledImg = styled.img`
  margin: 10px 0 0 0;
  width: 200px;
  max-height: 120px;

  &.footer {
    margin: 0;
    width: 93px
  }
  &.header {
    margin: 0;
    width: 43px
  }

  @media  ${mobile} {
    //height: 60px;
 
    &.footer {
      margin: 0 !important;
      // height: 40px !important;
      width: auto !important;
    }
    &.header {
      margin: 0;
      // height: 40px
    }
}`


export default ({type = "", dark = false}) => (
  <StyledImg  className={type}
    src={`/static/images/logos/${dark ? 'unilever-logo-dark' : 'unilever-logo-light'}.svg`}
  />
)