import React from 'react'
import styled from 'styled-components'
import Mutton from '@material-ui/core/Button'
import { Checkbox } from "@material-ui/core";
import { mobile, desktop, tablet, md } from '../styles/media-queries'
import { BaseMargin, BaseFontTitle, BaseFontTitleH2 } from '../styles/global'

export const widths = {
  mobile: '100%',
  tablet: '90%',
  desktop: '70%',
  desktopSmall: '85%',
}



export const H1 = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.35417em;
    word-break: break-word;
    span {
      display: inline-block;
      font-size: inherit;
      font-family: inherit;
      font-weight: inherit;
      color: inherit;
    }
  }
`

export const StrongH1 = styled.strong`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.35417em;
  }
`

export const BoldH1 = styled.span`
  && {
    font-family: ${props => props.theme.fontTwo};
    font-size: 26px;
    line-height: 1.35417em;
    font-weight: 700;
  }
`

export const H2 = styled.h2`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.2rem;
    @media ${tablet} {
      font-size: 1.4rem;
    }
    @media ${desktop} {
      font-size: 1.5rem;
    }
    //font-weight: 700;
    line-height: 1.35417em;
  }
`
export const H3 = styled.h3`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.3rem;
    font-weight: 100;
    line-height: 1.35417em;
  }
`

export const PH3 = styled.p`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.3rem;
    font-weight: 100;
    line-height: 1.35417em;
    @media ${md} {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
`
export const PH3Home = styled.p`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.3rem;
    font-weight: 100;
    line-height: 1.35417em;
    a {
      color: white !important;
      text-decoration: none;
    }
    @media ${md} {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
`

export const H4 = styled.h3`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.2rem;
    font-weight: 100;
    line-height: 1.35417em;
  }
`

export const ALink = styled.a`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: ${ props => props.fontSize || '1.3rem'};
    font-weight: 100;
    line-height: ${ props => props.lineHeight || '1.35417em'};
    color: ${ props => props.fontColor || props.theme.whiteColor};
    padding-left: ${ props => props.paddingLeft || '1rem'};
    padding-bottom: 20px;
    @media ${tablet}, ${mobile} {
      font-size: ${ props => props.mobileFontSize || '1rem'};
    }
  }
`

export const Text = styled.h3`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 18px;
    font-weight: 100;
    color: ${props => props.theme.blackColor};
  }
`
export const TextM = styled.h3`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 16px;
    font-weight: 100;
    color: ${props => props.theme.blackColor};
  }
`
export const TextMP = styled.p`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 16px;
    font-weight: 100;
    color: ${props => props.theme.blackColor};
  }
`
export const TextS = styled.h3`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 14px;
    font-weight: 100;
    color: ${props => props.theme.blackColor};
  }
`
export const TextBold = styled.h3`
  && {
    margin: 0;
    font-family: ${props => props.theme.fontOne};
    font-size: 18px;
    font-weight: 400;
    color: ${props => props.theme.blackColor};
  }
`
export const TextMBold = styled.h3`
  && {
    margin: 0;
    font-family: ${props => props.theme.fontOne};
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.blackColor};
  }
`

export const TextSBold = styled.h3`
  && {
    font-family: ${props => props.theme.fontOne};
    font-weight: 400;
    color: ${props => props.theme.blackColor};
    font-size: 14px;
    @media ${mobile}, ${tablet}{
      font-size: 13px;
    }
  }
`
export const TextMain = styled.h3`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 18px;
    font-weight: 100;
    color: ${props => props.theme.lightColor};
  }
`
export const TextMainM = styled.h3`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 16px;
    font-weight: 100;
    color: ${props => props.theme.lightColor};
  }
`
export const TextMainS = styled.h3`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 14px;
    font-weight: 100;
    @media ${mobile} {
      font-size: 13px;
      word-wrap: break-word;
    }
    color: ${props => props.theme.lightColor};
  }
`
export const TextMainBold = styled.h3`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 18px;
    font-weight: 400;
    color: ${props => props.theme.lightColor};
  }
`
export const TextMainMBold = styled.h3`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.lightColor};
  }
`

export const TextMainMBoldH2 = styled.h2`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.theme.lightColor};
  }
`

export const TextMainSBold = styled.div`
  && {
    font-family: ${props => props.theme.fontTwo};
    font-size: 14px;
    @media ${mobile} {
      font-size: 13px;
    }
    font-weight: 400;
    color: black;
    line-height: 14px;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
  }
`

export const TitleMain = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.35417em;
    color: ${props => props.theme.blackColor};
  }
`

export const TitleMainH2 = styled.h2`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.35417em;
    color: ${props => props.theme.blackColor};
  }
`

// fontOne does not accept weight, needs to be fontTwo :) 
export const TitleMainStrong = styled.strong`
  && {
    font-family: ${props => props.theme.fontOne};
    //font-family: ${props => props.theme.fontTwo};
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.35417em;
    color: ${props => props.theme.blackColor};
  }
`

export const TitleMainM = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    ${BaseMargin}
    ${BaseFontTitle}
    color: ${props => props.theme.blackColor};
  }
`

export const TitleMainMH2 = styled.h2`
  && {
    font-family: ${props => props.theme.fontOne};
    ${BaseMargin}
    ${BaseFontTitle}
    color: ${props => props.theme.blackColor};
  }
`

export const TitleMainMH3 = styled.h3`
  && {
    font-family: ${props => props.theme.fontOne};
    ${BaseMargin}
    ${BaseFontTitle}
    color: ${props => props.theme.blackColor};
  }
`

export const TitleMainS = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.3rem;
    font-weight: 100;
    line-height: 1.35417em;
    color: ${props => props.theme.lightColor};
  }
`
export const TitleMainXS = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.2rem;
    font-weight: 100;
    line-height: 1.35417em;
    color: ${props => props.theme.lightColor};
  }
`
export const TitleMainMBold = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.35417em;
    color: ${props => props.theme.lightColor};
  }
`
export const TitleMainSBold = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.35417em;
    color: ${props => props.theme.lightColor};
  }
`
export const TitleMainXSBold = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.35417em;
    color: ${props => props.theme.lightColor};
  }
`
export const TitleSecondary = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.35417em;
    color: ${props => props.theme.secondary};
    @media ${md} {
      font-size: 2rem;
    }
  }
`

export const TitleSecondaryH2 = styled.h2`
  && {
    margin-top: 0;    
    font-family: ${props => props.theme.fontOne};
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.35417em;
    color: ${props => props.theme.secondary};
    @media ${md} {
      font-size: 1.75rem;
    }
  }
`

export const QuestionTitle = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: ${props => props.isCollapsibleProfile ? '18px' : '1.2rem'};;
    font-weight: 700;
    text-align: left;
    margin: 16px 0 10px;
    margin-bottom: ${props => props.isCollapsibleProfile ? 0 : null};
    @media only screen and (min-width: 960px){
      padding-right: ${props => props.isCollapsibleProfile ? '60px' : '30px'};
      padding-left: ${props => props.isCollapsibleProfile ? '60px' : '30px'};
      text-align: ${props => props.isCollapsibleProfile ? 'left' : 'right'};
      margin-top: ${props => props.isCollapsibleProfile ? '8px' : null};
    }
    color: ${props => props.isCollapsibleProfile ? 'black' : props.theme.secondary};
  }
`
export const QuestionTitleLeft = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.2rem;
    font-weight: 700;
    @media only screen and (min-width: 960px){
      padding-right: 100px;
      padding-left: ${props => props.isCollapsibleProfile ? '80px' : '100px'};;
      text-align: left;
    }
    color: ${props => props.isCollapsibleProfile ? 'black' : props.theme.secondary};
  }
`
export const QuestionSubText = styled.div`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 0.9rem;
    text-align: left;
    padding: 0;
    margin-bottom: 16px;
    @media only screen and (min-width: 960px){
      padding-right: 30px;
      padding-left: ${props => props.isCollapsibleProfile ? '60px' : '100px'};
      text-align: ${props => props.isCollapsibleProfile ? 'left' : 'right'};
      margin-bottom: 0;
      font-size: ${props => props.isCollapsibleProfile ? '18px' : '0.9rem'};;
      line-height: ${props => props.isCollapsibleProfile ? '22px' : null};;
    }
    color: ${props => props.isCollapsibleProfile ? 'black' : props.theme.secondary};
  }
`
export const QuestionFootnote = styled.div`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 0.9rem;
    padding-top: 20px;
    @media ${md} {
      padding-right: 150px;
      padding-left: 150px;
      text-align: left;
    }
    color: ${props => props.theme.secondary};
  }
`
export const TitleSecondaryM = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    margin: 0;
    ${BaseFontTitleH2}
    color: ${props => props.theme.secondary};
  }
`

export const TitleSecondaryMH2 = styled.h2`
  && {
    margin: 0;
    font-family: ${props => props.theme.fontOne};
    font-size: 1.2rem;
    @media ${tablet} {
      font-size: 1.4rem;
    }
    @media ${desktop} {
      font-size: 1.5rem;
    }
    font-weight: 700;
    line-height: 1.35417em;
    color: ${props => props.theme.secondary};
  }
`

export const TitleSecondaryS = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.3rem;
    font-weight: 100;
    line-height: 1.35417em;
    color: ${props => props.theme.secondary};
  }
`

export const TitleSecondaryXS = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.2rem;
    font-weight: 100;
    line-height: 1.35417em;
    color: ${props => props.theme.secondary};
  }
`

export const TitleSecondaryXSH3 = styled.h3`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.2rem;
    font-weight: 100;
    line-height: 1.35417em;
    color: ${props => props.theme.secondary};
  }
`

export const TitleSecondarySBold = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.35417em;
    color: ${props => props.theme.secondary};
  }
`
export const TitleSecondaryXSBold = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.35417em;
    color: ${props => props.theme.secondary};
  }
`
export const TitleWhite = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.35417em;
    color: ${props => props.theme.whiteColor};
  }
`
export const TitleWhiteM = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.2rem;
    @media ${tablet} {
      font-size: 1.4rem;
    }
    @media ${desktop} {
      font-size: 1.5rem;
    }
    //font-weight: 700;
    line-height: 1.35417em;
    color: ${props => props.theme.whiteColor};
  }
`
export const Title = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.35417em;
    color: ${props => props.theme.blackColor};
  }
`
export const TitleM = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.2rem;
    @media ${tablet} {
      font-size: 1.4rem;
    }
    @media ${desktop} {
      font-size: 1.5rem;
    }
    //font-weight: 700;
    line-height: 1.35417em;
    color: ${props => props.theme.blackColor};
  }
`

export const TitleS = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.3rem;
    font-weight: 100;
    line-height: 1.35417em;
    color: ${props => props.theme.blackColor};
  }
`
export const TitleXS = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.2rem;
    font-weight: 100;
    line-height: 1.35417em;
    color: ${props => props.theme.blackColor};
  }
`
export const TitleSBold = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.35417em;
    color: ${props => props.theme.blackColor};
  }
`
export const TitleXSBold = styled.h1`
  && {
    font-family: ${props => props.theme.fontOne};
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.35417em;
    color: ${props => props.theme.blackColor};
  }
`
export const ErrorRequired = styled.h3`
  && {
    margin-top: 5px;
    font-family: ${props => props.theme.fontOne};
    font-size: 14px;
    font-weight: 400;
    color: ${props => props.theme.errorRequired};
  }
`

export const ErrorRequiredDiv = styled.div`
  && {
    padding-top: 5px;
    font-family: ${props => props.theme.fontOne};
    font-size: 14px;
    font-weight: 400;
    color: ${props => props.theme.errorRequired};
  }
`

export const StyledCheckBox = styled(Checkbox)`
    &.MuiCheckbox-colorSecondary {
        &.Mui-checked {
            /* color: #1287FF; */
            color: ${props => props.theme.lightColor};
            &:hover {
                background-color: transparent;
            }
        }
    }
    &.MuiIconButton-colorSecondary:hover {
        background-color: transparent;
    }
`
export const CheckBoxText = styled.span`
  && {
    font-size: 14px;
    display: flex;
    align-items: center;
    @media only screen and (min-width: 960px) {
      font-size: 16px;
    }
  }
`
// export const ButtonMain = styled(Mutton)`
//   && {
//     font-family: ${props => props.theme.fontOne};
//     text-transform: capitalize;
//     background-image: ${props => `url(${props.theme.buttonBack})`};
//     background-repeat: no-repeat;
//     background-size: cover;
//     color: white;
//     padding: 10px 15px;
//     text-align: center;
//     text-decoration: none;
//     display: inline-block;
//     font-size: 1.2rem;
//     margin: 4px 2px;
//     -webkit-transition-duration: 0.4s; /* Safari */
//     transition-duration: 0.4s;
//     cursor: pointer;
//   }
// `
export const DynamicButton = props => {
  switch (props.type) {
    case 'link':
      return <ButtonLink {...props} />
    default:
      return <ButtonMainS {...props} />
  }
}

export const ButtonLink = styled(Mutton)`
  && {
    text-transform: capitalize;
    color: ${props => props.theme.lightColor};
    font-family: ${props => props.theme.fontOne};
    padding: 0.5rem 0;
  }
`

export const ButtonMainAction = styled(Mutton)`
  && {
    font-family: ${props => props.theme.fontOne};
    //text-transform: capitalize;
    border-radius: 4px;
    border: 2px solid ${props => props.theme.buttonBorderColor};
    text-transform: uppercase;
    color: ${props => props.theme.buttonTextColor};
    background:  ${props => props.theme.buttonBackColor};
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    font-weight: 900;
    padding: 10px 24px;
    transition-duration: 0.4s;
    cursor: pointer;
    letter-spacing: 2px;
    @media ${mobile} {
      border-width: 1px;
      font-size: .9rem;
      font-weight: 800;
      padding: 8px 20px 8px 20px;
      margin-top: 3px;
      margin-bottom: 3px;
    }
    &:hover {
      background: ${props => props.theme.buttonBackColor};      
    }  
    &.MuiButton-root:hover {
      .MuiTouchRipple-root {
        background: #000;
        opacity: .2;
        transition-duration: 0.4s;
      } 
    }
    &:disabled {
      border-color: #d4d4d4;
      color: #d4d4d4;
      background: #e7e7e7;
      font-weight: 500;
    }
    &:focus {       
    //  box-shadow: 0 0 6px rgba(35, 173, 255, 1);    
     outline: -webkit-focus-ring-color auto 5px;
    }  
    .MuiButton-label {
      position: relative;
      z-index: 3;
    }    
    .MuiTouchRipple-rippleVisible {
      opacity: 0.2;
    }
    .MuiTouchRipple-child {
      background: #000;
    }
  }
  &&.fixed {
    min-width:150px;
  }
`

export const ButtonMainActionLong = styled(Mutton)`
&& {
    font-family: ${props => props.theme.fontOne};
    background: ${props => props.theme.buttonBackColor};  
    color: ${props => props.theme.whiteColor};    
    text-transform: uppercase;
    border-radius: 4px;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 900;
    padding: 4.5px 80px;
    transition-duration: 0.4s;
    letter-spacing: 2px;
    cursor: pointer;
    &.disabled {
      background:  #bcbcbc;
      color: #9b9b9b;
    }
    @media ${mobile} {
      border-width: 1px;
      font-size: .9rem;
      font-weight: 800;
      padding: 8px 20px 8px 20px;
      margin-top: 3px;
      margin-bottom: 3px;
    }
    &:hover {
      background: ${props => props.theme.buttonBackColor};  
      color: ${props => props.theme.whiteColor}    
    }  
    &.MuiButton-root:hover {
      .MuiTouchRipple-root {
        background: #000;
        opacity: .2;
        transition-duration: 0.4s;
      } 
    }
    &:disabled {
      border-color: #d4d4d4;
      color: #d4d4d4;
      background: #e7e7e7;
      font-weight: 500;
    }
    &:focus {       
    //  box-shadow: 0 0 6px rgba(35, 173, 255, 1);    
     outline: -webkit-focus-ring-color auto 5px;
    }  
    .MuiButton-label {
      position: relative;
      z-index: 3;
    }    
    .MuiTouchRipple-rippleVisible {
      opacity: 0.2;
    }
    .MuiTouchRipple-child {
      background: #000;
    }
  }
  &&.fixed {
    min-width:150px;
  }
`

export const ButtonSecondaryAction = styled(ButtonMainAction)`
   && {
    background: transparent;
    color: ${props => props.theme.lightColor};
    &:hover {
      background: transparent;
    }
  }
`

export const ButtonMainS = styled(Mutton)`
  && {
    font-family: ${props => props.theme.fontOne};
    //text-transform: capitalize;
    border-radius: 4px;
    border: 2px solid ${props => props.theme.buttonBorderColor};
    text-transform: uppercase;
    color: ${props => props.theme.buttonTextColor};
    background:  ${props => props.theme.buttonBackColor};
    display: inline-block;
    font-size: 1rem;
    font-weight: 900;
    padding: 10px 24px;
    transition-duration: 0.4s;    
    @media ${mobile} {
      border-width: 1px;
      font-size: .9rem;
      font-weight: 800;
      padding: 8px 20px;
      margin-top: 3px;
      margin-bottom: 3px;
    }
    &.MuiButton-root:hover {
      background: ${props => props.theme.buttonBackColor}; 
      .MuiTouchRipple-root {
        background: #000;
        opacity: .2;
        transition-duration: 0.4s;
      } 
    }
    &:disabled {
      border-color: #d4d4d4;
      color: #aaa;
      background: #e7e7e7;
      font-weight: 500;
    }
    &:focus {       
     // box-shadow: 0 0 6px rgba(35, 173, 255, 1);    
      outline: -webkit-focus-ring-color auto 5px;
    }  
    .MuiButton-label {
      position: relative;
      z-index: 3;
    }    
    .MuiTouchRipple-rippleVisible {
      opacity: 0.2;
    }
    .MuiTouchRipple-child {
      background: #000;
    }
  }
  &&.fixed {
    min-width:150px;
  }
`

// export const ButtonMainSOLD = styled(Mutton)`
//   && {
//     font-family: ${props => props.theme.fontOne};
//     text-transform: capitalize;
//     background: url(${props => props.theme.buttonBack});
//     border-radius: 0;
//     background-repeat: no-repeat;
//     background-size: cover;
//     color: white;
//     padding: 8px 18px;
//     text-align: center;
//     text-decoration: none;
//     display: inline-block;
//     font-size: 1rem;
//     margin: 4px 2px;
//     -webkit-transition-duration: 0.4s; /* Safari */
//     transition-duration: 0.4s;
//     cursor: pointer;
//     clip-path: polygon(3% 0, 100% 0, 97% 100%, 0 100%);
//
//
//   }
//   &&.fixed {
//     min-width:150px;
//   }
// `
// export const ButtonMainSContrast = styled(Mutton)`
//   && {
//     font-family: ${props => props.theme.fontOne};
//     text-transform: capitalize;
//     background: url(${props => props.theme.buttonBackContrast});
//     border-radius: 0;
//     background-repeat: no-repeat;
//     background-size: cover;
//     color: white;
//     padding: 8px 18px;
//     text-align: center;
//     text-decoration: none;
//     display: inline-block;
//     font-size: 1rem;
//     margin: 4px 2px;
//     -webkit-transition-duration: 0.4s; /* Safari */
//     transition-duration: 0.4s;
//     cursor: pointer;
//     clip-path: polygon(3% 0, 100% 0, 97% 100%, 0 100%);
//   }
// `
// export const ButtonMainWhite = styled(Mutton)`
//   && {
//     font-family: ${props => props.theme.fontOne};
//     text-transform: capitalize;
//     background: url(${props => props.theme.buttonBackWhite});
//     border-radius: 0;
//     background-repeat: no-repeat;
//     background-size: cover;
//     color: ${props => props.theme.lightColor};
//     padding: 8px 24px;
//     text-align: center;
//     text-decoration: none;
//     display: inline-block;
//     font-size: 1.2rem;
//     margin: 4px 2px;
//     -webkit-transition-duration: 0.4s; /* Safari */
//     transition-duration: 0.4s;
//     cursor: pointer;
//     clip-path: polygon(3% 0, 100% 0, 97% 100%, 0 100%);
//     font-weight: bold;
//   }
// `
export const ButtonMainSWhite = styled(Mutton)`
  && {
    font-family: ${props => props.theme.fontOne};
    text-transform: capitalize;
    background: ${props => props.theme.whiteColor};
    border-radius: 10px;
    color: ${props => props.theme.lightColor};
    padding: 8px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
    margin: 4px 2px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    cursor: pointer;
    &:hover {
      background: ${props => props.theme.whiteColor};
    }
  }
`
// export const ButtonMainSWhiteOld = styled(Mutton)`
//   && {
//     font-family: ${props => props.theme.fontOne};
//     text-transform: capitalize;
//     background: url(${props => props.theme.buttonBackWhite});
//     border-radius: 0;
//     background-repeat: no-repeat;
//     background-size: cover;
//     color: ${props => props.theme.lightColor};
//     padding: 8px 18px;
//     text-align: center;
//     text-decoration: none;
//     display: inline-block;
//     font-size: 1rem;
//     margin: 4px 2px;
//     -webkit-transition-duration: 0.4s; /* Safari */
//     transition-duration: 0.4s;
//     cursor: pointer;
//     clip-path: polygon(3% 0, 100% 0, 97% 100%, 0 100%);
//   }
// `
export const ButtonRoundPrimary = styled(Mutton)`
&& {
  border: solid 1px #00a3b2;
  color: #00a3b2;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;

  padding: 10px 30px;
  text-transform: none;
}
&& a {
  text-decoration: none;
}
`
export const ButtonRoundSecondary = styled(ButtonRoundPrimary)`
&& {
  border: solid 1px #e99000;
  color: #e99000;
}
`

// export const ButtonDone = styled(Mutton)`
//   && {
//     font-family: ${props => props.theme.fontOne};
//     text-transform: capitalize;
//     background-image: ${props => `url(${props.theme.buttonBack})`};
//     background-repeat: no-repeat;
//     background-size: cover;
//     color: ${props => `url(${props.theme.blackColor})`};
//     padding: 10px 18px;
//     text-align: center;
//     text-decoration: none;
//     display: inline-block;
//     font-size: 1.2rem;
//     margin: 4px 2px;
//     -webkit-transition-duration: 0.4s; /* Safari */
//     transition-duration: 0.4s;
//     cursor: default;
//   }
// `
export const Label = styled.label`
  && {
    display: inline;
    /* margin-bottom: 1.8rem; */
    font-size: 12pt;

    input[type='text'] {
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(128, 128, 128, 1);
      display: block;
      font-family: sans-serif;
      margin: 1rem 0;
      width: 100%;
      height:40px;
      box-sizing: border-box;
      border-radius:0;
      @media ${md} {
        height: 32px;
      }

    }

    > em {
      color: rgba(208, 2, 27, 1);
    }

    .error input {
      border-color: rgba(208, 2, 27, 1);
    }

    .error-msg {
      color: rgba(208, 2, 27, 1);
      font-size: 0.875rem;
      display: inline;
    }

    .select.country {
      display: block;
      line-height: 32px;
      margin: 1rem 0;
      height:40px;
      box-sizing: border-box;
      border-radius:0;
      @media ${md} {
        height: 32px;
      }
    }
  }
`

export const HR = styled.hr`
    height: 5px;
    background-color: ${props => props.theme.lightColor};
    border:none;
    margin: 25px 0;
    @media only screen and (min-width: 960px) {
      margin: 50px 0;
    }
`
export const HR1 = styled.hr`
    height: 1px;
    background-color: ${props => props.theme.blackColor};
    border:none;
    margin: 25px 0;    
    @media only screen and (min-width: 960px) {
      margin-top: 40px;
      margin-bottom: 30px;
      margin-left: 30px;
      margin-right: 30px;  
      }
`
      
export const HR2 = styled.hr`
    height: 1px;
    background-color: ${props => props.theme.lightColor};
    border: none;
    margin: 30px 0;
`
export const HR3 = styled.hr`
  height: 1px;
  background-color: ${props => props.theme.lightColor};
  border: none;
  margin: 10px 0;
`
      
export const HR4 = styled.hr`
    height: 2px;
    background-color: ${props => props.theme.lightColor};
    border:none;
    margin: 40px 0 0;    
`

export const HR5 = styled.hr`
    height: 2px;
    background-color: rgba(0, 0, 0, .125);
    border:none;
    margin: 40px 0 0;    
`

export const ZipInput = styled.input`
  width: 8.8rem !important;
`

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FlexLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const FlexRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const StyledHeaderBlurb = styled.div`
  && {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 1px;
    color: ${props => props.theme.lightColor};
  }
`

export const Section = styled.div`
  width: 100%;

  width: ${widths.mobile};

  @media ${tablet} {
    margin: 0 auto;
    max-width: ${widths.tablet};
  }

  @media ${desktop} {
    margin: 0 auto;
    max-width: ${widths.desktop};
  }
`

export const PanelTitle = styled.div`
&& {
  h1, h2, h3 {
    color: ${props => props.theme.lightColor};
    ${BaseFontTitle}
    ${BaseMargin}
    font-weight: 700;
    letter-spacing: 2px;
    text-align: center;
  }  
}
`

export const PanelTitleH2 = styled.h2`
&& {
  color: ${props => props.theme.lightColor};
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 2px;
  text-align: center;
  margin: 2rem 0 2rem 0;
}
`

/*
export const ButtonMain = styled(Mutton)`
  && {
    font-family: ${props => props.theme.fontOne};
    text-transform: capitalize;
    background: ${props => props.theme.lightColor};
    border: solid 1px ${props => props.theme.lightColor};
    border-radius: 4px;
    color: white;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.2rem;
    margin: 4px 2px;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    cursor: pointer;
    &:hover {
      background: ${props => props.theme.lightColor};
    }
    clip-path: polygon(3% 0, 100% 0, 97% 100%, 0 100%);
  }
`
export const ButtonMainS = styled(Mutton)`
  && {
    font-family: ${props => props.theme.fontOne};
    text-transform: capitalize;
    background: ${props => props.theme.lightColor};
    border: solid 1px ${props => props.theme.lightColor};
    border-radius: 4px;
    color: white;
    padding: 8px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 4px 2px;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    cursor: pointer;
    &:hover {
      background: ${props => props.theme.lightColor};
    }
    clip-path: polygon(3% 0, 100% 0, 97% 100%, 0 100%);
  }
`
export const ButtonWhite = styled(Mutton)`
  && {
    font-family: ${props => props.theme.fontOne};
    text-transform: capitalize;
    background: white;
    border: solid 1px ${props => props.theme.lightColor};
    border-radius: 4px;
    color: ${props => props.theme.lightColor};
    padding: 10px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.2rem;
    margin: 4px 2px;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    cursor: pointer;
    &:hover {
      background: white;
    }
    clip-path: polygon(3% 0, 100% 0, 97% 100%, 0 100%);
  }
`
export const ButtonDone = styled(Mutton)`
  && {
    font-family: ${props => props.theme.fontOne};
    text-transform: capitalize;
    background: ${props => props.theme.done};
    border: solid 1px ${props => props.theme.done};
    border-radius: 4px;
    color: white;
    padding: 10px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.2rem;
    margin: 4px 2px;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    cursor: pointer;
    &:hover {
      background: ${props => props.theme.done};
    }
    clip-path: polygon(3% 0, 100% 0, 97% 100%, 0 100%);
  }
`
*/
