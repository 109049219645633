export const onKeyEnter = cb => event => {
  if (event.keyCode === 13 || event.key === 'Enter') {
    event.preventDefault();
    cb(event);
  } 
}

export const onKeyTab = cb => event => {
  if (event.keyCode === 9) {
    event.preventDefault();
    cb(event);
  } 
}

export const onKeyArrowLeft = cb => event => {
  if (event.keyCode === 37) {
    event.preventDefault();
    cb(event);
  } 
}

export const onKeyArrowRight = cb => event => {
  if (event.keyCode === 39) {
    event.preventDefault();
    cb(event);
  } 
}

export const onKeyArrowUp = cb => event => {
  if (event.keyCode === 38) {
    event.preventDefault();
    cb(event);
  } 
}

export const onKeyArrowDown = cb => event => {
  if (event.keyCode === 40) {
    event.preventDefault();
    cb(event);
  } 
}
