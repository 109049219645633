import { applyMiddleware, compose, createStore as createReduxStore } from 'redux'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'

import { makeRootReducer } from '../redux/reducers'
import { updateLocation } from '../redux/modules/location'
import { actions as authActions } from '../redux/modules/auth'
import { isDev } from '../shared/utility';

const history = createBrowserHistory()

let store = null

export const authInterceptor = ({ dispatch }) => next => action => {
  //api should send 401

  if (action.error) {
    const response = action.error

    const is401 = response && response.status && response.status === 400 && response.data && response.data.toString().indexOf('kf cookie not found') > -1

    //console.log('is401', is401, response.status, response.data)

    if (is401) return dispatch(authActions.sessionExpired())
  }

  return next(action)
}

const createStore = (initialState = {}) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [thunk, authInterceptor]

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = []
  let composeEnhancers = compose

  if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      name: `KF Advance - Client Portal ${isDev && '(DEV)'}`
    })
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  store = createReduxStore(makeRootReducer(), initialState, composeEnhancers(applyMiddleware(...middleware), ...enhancers))
  store.asyncReducers = {}

  // To unsubscribe, invoke `store.unsubscribeHistory()` anytime
  store.unsubscribeHistory = history.listen(updateLocation(store))

  if (module.hot) {
    module.hot.accept('../redux/reducers', () => {
      const reducers = require('../redux/reducers').default
      store.replaceReducer(reducers(store.asyncReducers))
    })
  }

  return store
}

export const getStore = () => store

export default createStore
