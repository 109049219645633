import React, { Component } from 'react'
import Loadable from 'react-loadable'
import { connect } from "react-redux";

import Spinner from '../components/Spinner/Spinner'
import {actions as toasterActions} from '../redux/modules/toaster'

const mapDispatchToProps = dispatch => ({
  showError: (msg) => dispatch(toasterActions.showError(msg))
})

const loadingHandler = (outerProps) => (props) => {
  if (props.error) {
    console.error(props.error.message || JSON.stringify(props.error))
    outerProps.showError('Application version is out of sync with server. Please refresh.')
  }
  else if (props.timedOut) {
    outerProps.showError('Server did not respond in time. Please try again.')
  }
  
  return <Spinner />
}

export default componentToImport => {
  const InnerComponent = class extends Component {
    LoadableAnotherComponent = Loadable({ 
      loader: componentToImport, 
      loading: loadingHandler(this.props),
      timeout: 25000 
    })

    render() {
      const C = this.LoadableAnotherComponent
      return C ? <C {...this.props} /> : null
    }
  }

  const ConnectedAsyncComponent = connect(null, mapDispatchToProps)(InnerComponent)

  return ConnectedAsyncComponent;
};
