import { systemSupportedRoles, roles } from '../constants/roles/role.constants'

export { roles };

let loggedInUserRoles = [];

export let checkRole = {
  isAdmin: false,
  isAssessmentCoach: false,
  isCare: false,
  isCoach: false,
  isContentCreator: false,
  isDeveloper: false,
  isDevPlanner: false,
  isExec: false,
  isInterviewPrepCoach: false,
  isPartnerCoach: false,
  isReportAccess: false,
  isResumeCoach: false,
  isResumeRewriter: false,
  isSalaryCoach: false,
  isSalaryReviewer: false,
  isSgcoach: false,
  isSysAdmin: false,
  isSystem: false,
  isTalentAcquisitionCoach: false,
  canAccessClientPortal: false,
}

const initializeCheckRole = () => {
  checkRole = {
    isAdmin: isInRole(roles.admin),
    isAssessmentCoach: isInRole(roles.assessmentCoach),
    isCare: isInRole(roles.care),
    isCoach: isInRole(roles.coach),
    isContentCreator: isInRole(roles.contentCreator),
    isDeveloper: isInRole(roles.developer),
    isDevPlanner: isInRole(roles.devPlanner),
    isExec: isInRole(roles.exec),
    isInterviewPrepCoach: isInRole(roles.interviewPrepCoach),
    isPartnerCoach: isInRole(roles.partnerCoach),
    isReportAccess: isInRole(roles.reportAccess),
    isResumeCoach: isInRole(roles.resumeCoach),
    isResumeRewriter: isInRole(roles.resumeRewriter),
    isSalaryCoach: isInRole(roles.salaryCoach),
    isSalaryReviewer: isInRole(roles.salaryReviewer),
    isSgcoach: isInRole(roles.sgcoach),
    isSysAdmin: isInRole(roles.sysAdmin),
    isSystem: isInRole(roles.system),
    isTalentAcquisitionCoach: isInRole(roles.talentAcquisitionCoach),
    canAccessClientPortal: isInRole(roles.exec) ||  isInRole(roles.mentor) ,
  }
}

export const initializeLoggedInUserRoles = rolesData => {
  loggedInUserRoles = rolesData.map(item => item.RoleCode);

  initializeCheckRole();
}

export const isInRole = role => {
  return loggedInUserRoles.includes(role);
}

export const isInAnyRole = roleQueryList => {
  for (const roleQuery of roleQueryList) {
    if (isInRole(roleQuery)) {
      return true;
    }
  }
}

export const isInSystemSupportedRoles = () => isInAnyRole(systemSupportedRoles);

/**
 * only here for backwards compatibility for getting a singular "role" of a logged in user
 * DO NOT USE!!!
 */
export const getRole_DEPRECATED = () => {
  for (const role of loggedInUserRoles) {
    if (role.toLowerCase().indexOf('coach') !== -1){
      return roles.coach;
    }

    return loggedInUserRoles[0];
  }
}