import { connect } from 'react-redux'
import { actions } from '../../redux/modules/auth'
import AccountStatusMessageModal from '../../components/Modals/AccountStatusMessageModal'
import { getContent } from '../../shared/utility'

const mapStateToProps = ({ auth, content }) => {
    const { isAccountStatusModalOpen, loading, user } = auth
    return {
      loading,
      isOpen: isAccountStatusModalOpen,
      c: key => getContent(key, content),
      isRevoked: user && user.isRevoked ? user.isRevoked : false,
      isSuspended: user && user.isSuspended ? user.isSuspended : false,
    }
  }

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(actions.logout())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountStatusMessageModal)