import humps from 'humps'
import { updateObject, getHost, api, getContent } from '../../shared/utility'
import { actions as actionToaster } from './toaster'
import { actions as actionAuth } from './auth'
import { logEvent, addTransaction } from '../../shared/analytics'
import { actions as accessActions} from './access'
import analyticsTypeConstants from '../../constants/analytics/analytics-type.constants';
import analyticsActionConstants from '../../constants/analytics/analytics-action.constants';
import analyticsRoutes from '../../constants/analytics/analytics-routes.constants';


export const actionTypes = {
  GET_PAYMENT_IFRAME_URL_SUCCESS: 'GET_PAYMENT_IFRAME_URL_SUCCESS',
  GET_PAYMENT_IFRAME_URL_START: 'GET_PAYMENT_IFRAME_URL_START',
  GET_PAYMENT_IFRAME_URL_ERROR: 'GET_PAYMENT_IFRAME_URL_ERROR',
  RESET__PAYMENT_IFRAME_URL: 'RESET__PAYMENT_IFRAME_URL',

  GET_PRODUCT_PRICE_BY_ZIP_SUCCESS: 'GET_PRODUCT_PRICE_BY_ZIP_SUCCESS',
  GET_PRODUCT_PRICE_BY_ZIP_START: 'GET_PRODUCT_PRICE_BY_ZIP_START',
  GET_PRODUCT_PRICE_BY_ZIP_ERROR: 'GET_PRODUCT_PRICE_BY_ZIP_ERROR',

  SET_PRODUCT_PRICE_ERROR: 'SET_PRODUCT_PRICE_ERROR',

  PLACE_ORDER_START: 'PLACE_ORDER_START',
  PLACE_ORDER_SUCCESS: 'PLACE_ORDER_SUCCESS',
  //PLACE_ORDER_ERROR: 'PLACE_ORDER_ERROR',

  UPDATE_PAYMENT_START: 'UPDATE_PAYMENT_START',
  UPDATE_PAYMENT_SUCCESS: 'UPDATE_PAYMENT_SUCCESS',
  PAYMENT_ERROR: 'PAYMENT_ERROR',

  GET_SECURE_PAYLOAD_SUCCESS: 'GET_SECURE_PAYLOAD_SUCCESS',
  GET_SECURE_PAYLOAD_START: 'GET_SECURE_PAYLOAD_START',
  GET_SECURE_PAYLOAD_ERROR: 'GET_SECURE_PAYLOAD_ERROR',


}

// Action Creators

export const actions = {
  getSecurePayloadStart: () => ({ type: actionTypes.GET_SECURE_PAYLOAD_START }),

  getSecurePayloadSuccess: data => ({
    type: actionTypes.GET_SECURE_PAYLOAD_SUCCESS,
    data,
  }),

  getSecurePayloadFail: error => ({ type: actionTypes.GET_SECURE_PAYLOAD_ERROR, error: error }),

  getSecurePayload: payload => dispatch => {
    dispatch(actions.getSecurePayloadStart())

    let path = `${getHost().apiBaseUrl}/payment/payload`

    return api
      .post(path, payload)
      .then(response => {
        dispatch(actions.getSecurePayloadSuccess(response.data))
      })
      .catch(error => {
        dispatch(actions.getSecurePayloadFail(error))
      })
  },

  getPaymentIframeUrlStart: () => ({ type: actionTypes.GET_PAYMENT_IFRAME_URL_START }),

  getPaymentIframeUrlSuccess: data => ({
    type: actionTypes.GET_PAYMENT_IFRAME_URL_SUCCESS,
    data,
  }),

  getPaymentIframeUrlFail: error => ({ type: actionTypes.GET_PAYMENT_IFRAME_URL_ERROR, error: error }),

  getPaymentIframeUrl: () => dispatch => {
    dispatch(actions.getPaymentIframeUrlStart())

    let path = `${getHost().apiBaseUrl}/payment/token`

    return api
      .get(path)
      .then(response => {
        return humps.camelizeKeys(response.data)
      })
      .then(response => {
        dispatch(actions.getPaymentIframeUrlSuccess(response))
      })
      .catch(error => {
        dispatch(actions.getPaymentIframeUrlFail(error))
      })
  },

  getProductPriceByZipStart: () => ({ type: actionTypes.GET_PRODUCT_PRICE_BY_ZIP_START }),

  getProductPriceByZipSuccess: data => ({
    type: actionTypes.GET_PRODUCT_PRICE_BY_ZIP_SUCCESS,
    data,
  }),

  getProductPriceByZipFail: error => ({ type: actionTypes.GET_PRODUCT_PRICE_BY_ZIP_ERROR, error: error }),

  showProductPriceError: show => ({ type: actionTypes.SET_PRODUCT_PRICE_ERROR, show }),

  getProductPriceByZip: data => dispatch => {
    dispatch(actions.getProductPriceByZipStart())

    let path = `${getHost().apiBaseUrl}/payment/productprices`

    return api
      .post(path, data)
      .then(response => {
        console.log('getProductPriceByZip RESRPONe', response)
        dispatch(actions.getProductPriceByZipSuccess({ ...response.data, ...data }))
      })
      .catch(error => {
        dispatch(actions.getProductPriceByZipFail(error))
        dispatch(actionToaster.showError('Invalid Zip/Postal Code!'))
      })
  },

  placeOrderStart: () => ({ type: actionTypes.PLACE_ORDER_START }),

  paymentError: error => ({ type: actionTypes.PAYMENT_ERROR, error }),

  placeOrderSuccess: data => ({
    type: actionTypes.PLACE_ORDER_SUCCESS,
    data,
  }),

  placeOrderWebhook: (event, onSuccess) => (dispatch, getState) => {
    dispatch(actions.placeOrderStart())
    logEvent('event', analyticsTypeConstants.purchase, analyticsActionConstants.purchasePlaceOrder, '', analyticsRoutes.intercom)
    let path = `${getHost().apiBaseUrl}/payment/placeorder`

    const { auth, content } = getState()

    return api
      .post(path, event)
      .then(response => {
        if (response.data.ErrorCode) {
          console.log('placeOrder error', response.data)
          dispatch(actions.paymentError(response.data))
          dispatch(actionToaster.showError(getContent('Global.Message.Error', content)))
          logEvent('event', analyticsTypeConstants.purchase, analyticsActionConstants.purchasePlaceOrderError, '', analyticsRoutes.intercom)
        } else {
          dispatch(actions.placeOrderSuccess(response.data))
          if (!auth.isTest) {
            logEvent('event', analyticsTypeConstants.purchase, analyticsActionConstants.purchasePlaceOrderComplete, '', analyticsRoutes.intercom)
            addTransaction(auth.userKey, event.total, event.tags.productKey, event.tags.productKey)
          } 
          dispatch(actionAuth.refreshProducts())
          if (onSuccess) {
            setTimeout(dispatch(accessActions.getAccess()), 1000)
            onSuccess()
          } else {
            window.location = '/thankyou'
          }
        }
      })
      .catch(error => {
        console.log('placeOrder error', error)
        error.response 
              ? dispatch(actions.paymentError(error.response.data))
              : dispatch(actions.paymentError(error.mesg));
        dispatch(actionToaster.showError(getContent('Global.Message.Error', content)))
        logEvent('event', analyticsTypeConstants.purchase, analyticsActionConstants.purchasePlaceOrderError, '', analyticsRoutes.intercom)
      })
  },

  placeOrder: (order, signature, token, onSuccess) => (dispatch, getState) => {
    dispatch(actions.placeOrderStart())
    logEvent('event', analyticsTypeConstants.purchase, analyticsActionConstants.purchasePlaceOrder, '', analyticsRoutes.intercom)
    let path = `${getHost().apiBaseUrl}/payment/transaction?signature=${signature}&accesstoken=${token}`

    const { auth, content } = getState()

    return api
      .post(path, order)
      .then(response => {
        if (response.data.ErrorCode) {
          console.log('placeOrder error', response.data)
          dispatch(actions.paymentError(response.data))
          dispatch(actionToaster.showError(getContent('Global.Message.Error', content)))
          logEvent('event', analyticsTypeConstants.purchase, analyticsActionConstants.purchasePlaceOrderError, '', analyticsRoutes.intercom)
        } else {
          dispatch(actions.placeOrderSuccess(response.data))
          logEvent('event', analyticsTypeConstants.purchase, analyticsActionConstants.purchasePlaceOrderComplete, '', analyticsRoutes.intercom)

          const price = order.Prices && order.Prices.length > 0 ? order.Prices[0] : {BasePrice: -1}
          if (!auth.isTest && price) {
            addTransaction(auth.userKey, price.BasePrice, price.ProductKey, price.ProductKey)
          } 
          dispatch(actionAuth.refreshProducts())
          if (onSuccess) {
            setTimeout(dispatch(accessActions.getAccess()), 1000)
            onSuccess()
          } else {
            window.location = '/thankyou'
          }
        }
      })
      .catch(error => {
        console.log('placeOrder error', error)
        dispatch(actions.paymentError(error.response.data))
        dispatch(actionToaster.showError(getContent('Global.Message.Error', content)))
        logEvent('event', analyticsTypeConstants.purchase, analyticsActionConstants.purchasePlaceOrderError, '', analyticsRoutes.intercom)
      })
  },

  resetPaymentUrl: () => ({ type: actionTypes.RESET__PAYMENT_IFRAME_URL }),

  updatePaymentStart: () => ({ type: actionTypes.UPDATE_PAYMENT_START }),

  updatePaymentSuccess: data => ({
    type: actionTypes.UPDATE_PAYMENT_SUCCESS,
    data,
     }),


  updatePayment: (paymentInfo, signature, token, onSuccess) => (dispatch, getState) => {
      dispatch(actions.updatePaymentStart())
      logEvent('event', analyticsTypeConstants.purchase, analyticsActionConstants.purchaseUpdatePayment)
      let path = `${getHost().apiBaseUrl}/payment/update?signature=${signature}&accesstoken=${token}`
      const { content } = getState()

      return api
        .post(path, paymentInfo)
        .then(response => {
          if (response.data.ErrorCode) {
            console.log('updatePayment error 1', response.data)
            dispatch(actions.paymentError(response.data))
            dispatch(actionToaster.showError(getContent('Global.Message.Error', content)))
            logEvent('event', analyticsTypeConstants.purchase, analyticsActionConstants.purchaseUpdatePaymentError)
          } else {
            dispatch(actions.updatePaymentSuccess(response.data))
            logEvent('event', analyticsTypeConstants.purchase, analyticsActionConstants.purchaseUpdatePaymentComplete)
            //TODO: react router redirect
            if (onSuccess) {
              onSuccess()
            }
          }
        })
        .catch(error => {
          console.log('updatePayment error 2', error)
          dispatch(actions.paymentError(error.response.data))
          dispatch(actionToaster.showError(getContent('Global.Message.Error', content)))
          logEvent('event', analyticsTypeConstants.purchase, analyticsActionConstants.purchaseUpdatePaymentError)
        })
  },
}

// Reducer
const defaultState = {
  loading: false,
  iframeObj: null,
  productPriceLoading: false,
  productPrice: null,
  productPriceError: null,
  showingProductPriceError: false,

  placeOrderComplete: false,
  paymentError: null,

  securePayloadLoading: false,
  securePayload : null,
  securePayloadError: null,

}

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_SECURE_PAYLOAD_START:
      return updateObject(state, { securePayloadLoading: true, securePayload: null, securePayloadError: null })
    case actionTypes.GET_SECURE_PAYLOAD_SUCCESS:
      return updateObject(state, {
        securePayloadLoading: false,
        securePayload: action.data,
      })
    case actionTypes.GET_SECURE_PAYLOAD_ERROR:
      return updateObject(state, { securePayloadLoading: false, securePayload: null, securePayloadError: action.error })

    case actionTypes.RESET__PAYMENT_IFRAME_URL:
      return updateObject(state, { iframeObj: null })
    case actionTypes.GET_PAYMENT_IFRAME_URL_START:
      return updateObject(state, { loading: true, iframeObj: null })
    case actionTypes.GET_PAYMENT_IFRAME_URL_SUCCESS:
      return updateObject(state, {
        loading: false,
        iframeObj: action.data,
      })
    case actionTypes.GET_PAYMENT_IFRAME_URL_ERROR:
      return updateObject(state, { loading: false, iframeObj: null, paymentError: action.error })

    case actionTypes.GET_PRODUCT_PRICE_BY_ZIP_START:
      return updateObject(state, { productPriceLoading: true, productPrice: null, productPriceError: null })
    case actionTypes.GET_PRODUCT_PRICE_BY_ZIP_SUCCESS:
      return updateObject(state, {
        productPriceLoading: false,
        productPrice: action.data,
        productPriceError: null,
      })
    case actionTypes.GET_PRODUCT_PRICE_BY_ZIP_ERROR:
      return updateObject(state, { productPriceLoading: false, productPrice: null, productPriceError: action.error })

    case actionTypes.SET_PRODUCT_PRICE_ERROR:
      return updateObject(state, { showingProductPriceError: action.show })

    case actionTypes.PLACE_ORDER_START:
      return updateObject(state, { loading: true, placeOrderComplete: false, paymentError: null })
    case actionTypes.PLACE_ORDER_SUCCESS:
      return updateObject(state, {
        loading: false,
        placeOrderComplete: true,
        paymentError: null,
      })
    case actionTypes.PAYMENT_ERROR:
      return updateObject(state, { loading: false, paymentError: action.error, placeOrderComplete: false })

    case actionTypes.UPDATE_PAYMENT_START:
      return updateObject(state, { loading: true, paymentError: null })
    case actionTypes.UPDATE_PAYMENT_SUCCESS:
      return updateObject(state, {
        loading: false,
        paymentError: null,
      })
    default:
      return state
  }
}
