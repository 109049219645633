/** @jsxRuntime classic */
// https://github.com/facebook/create-react-app/issues/9906 also 10272
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable' //needed for WeakSet
import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { Helmet } from 'react-helmet'
import 'normalize.css'
import 'typeface-roboto'
import './App.css'

//App Components
import App from './App'

import { unregister } from './registerServiceWorker';
// import './i18n'

// Store Initialization
import createStore from './store/create-store'
import { GlobalProvider } from './contexts/global.context'

const store = createStore(window.__INITIAL_STATE__)
//force ssl
if (window.location.hostname.indexOf('local') < 0 && window.location.protocol !== 'https:') window.location.protocol = 'https:'

const root = document.getElementById('root')

const render = Component => {
  ReactDOM.render(
    <Fragment>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Provider store={store}>
        <GlobalProvider>
          <BrowserRouter>
            <Component />
          </BrowserRouter>
        </GlobalProvider>
      </Provider>
    </Fragment>,
    root
  )
}

render(App)
unregister() 