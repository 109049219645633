export const actionTypes = {
  GET_CONSTANTS_SUCCESS: 'GET_CONSTANTS_SUCCESS',
}

// Action Creators

export const actions = {
  getConstantsSuccess: data => ({
    type: actionTypes.GET_CONSTANTS_SUCCESS,
    data,
  }),
}

// Reducer

const defaultState = {
  loading: false,
  coaches: {},
  error: null,
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_CONSTANTS_SUCCESS:
      const theData = action.data
      return { ...theData }
    default:
      return state
  }
}
