import axios from 'axios'
import { filter } from 'ramda'
import { updateObject, getHost } from '../../shared/utility'
import Moment from 'moment-timezone'
import { extendMoment } from 'moment-range'
import {actions as toasterActions} from './toaster'

const moment = extendMoment(Moment)
const R = require('ramda')
// ActionTypes


export const actionTypes = {
  GET_COACH_DIALOG_AVAILABILITY_SUCCESS: 'GET_COACH_DIALOG_AVAILABILITY_SUCCESS',
  GET_COACH_AVAILABILITY_SUCCESS: 'GET_COACH_AVAILABILITY_SUCCESS',
  GET_COACH_AVAILABILITY_START: 'GET_COACH_AVAILABILITY_START',
  GET_COACH_AVAILABILITY_FAIL: 'GET_COACH_AVAILABILITY_FAIL',
  SCHEDULE_COACH_AVAILABILITY_SUCCESS: 'SCHEDULE_COACH_AVAILABILITY_SUCCESS',
  SCHEDULE_COACH_AVAILABILITY_START: 'SCHEDULE_COACH_AVAILABILITY_START',
  SCHEDULE_COACH_AVAILABILITY_FAIL: 'SCHEDULE_COACH_AVAILABILITY_FAIL',
  DELETE_COACH_AVAILABILITY_SUCCESS: 'DELETE_COACH_AVAILABILITY_SUCCESS',
  DELETE_COACH_AVAILABILITY_START: 'DELETE_COACH_AVAILABILITY_START',
  DELETE_COACH_AVAILABILITY_FAIL: 'DELETE_COACH_AVAILABILITY_FAIL',
  SET_SELECTED_EVENT: 'SET_SELECTED_EVENT',
  CLEAR_CALENDAR: 'CLEAR_CALENDAR',
  GET_AUTO_AVAILABILITY_SUCCESS: 'GET_AUTO_AVAILABILITY_SUCCESS',
  GET_DAILY_AVAILABILITY_START: 'GET_DAILY_AVAILABILITY_START',
  GOT_DAILY_AVAILABILITY: 'GOT_DAILY_AVAILABILITY',
  GOT_AUTO_DAILY_AVAILABILITY: 'GOT_AUTO_DAILY_AVAILABILITY',
  SELECTED_THE_APPOINTMENT: 'SELECTED_THE_APPOINTMENT',
  SELECTED_THE_GOAL: 'SELECTED_THE_GOAL',
  SCHEDULE_COACHING_APPOINTMENT_START: 'SCHEDULE_COACHING_APPOINTMENT_START',
  SCHEDULE_COACHING_APPOINTMENT_FINISH: 'SCHEDULE_COACHING_APPOINTMENT_FINISH',
  SCHEDULE_COACHING_APPOINTMENT_FAIL: 'SCHEDULE_COACHING_APPOINTMENT_FAIL',
  GET_AUTO_AVAILABILITY_START: 'GET_AUTO_AVAILABILITY_START',
  RESET_GOALS: 'RESET_GOALS',
}

const defaultState = {
  loading: false,
  userKey: '',
  start: '',
  end: '',
  events: [],
  devents: [],
  forDialog: false,
  timezone: '',
}

// Action Creators
//("GET", "scheduler/coaches/availability?type=DateRange&startday=" + theStart + "&endday=" + theEnd  + "&duration=" + theDuration );

const getAutoAvailabilityRange = (userKey, start, end, timbeBlock, roleCode, scheduleRange, timezone, dispatch, options = {}) => {
  dispatch(actions.getAutoAvailabilityStart())
  const {countryCode, spkLanguageCode} = options
  let path =
    `${getHost().apiBaseUrl}/scheduling/coach-availability/auto?type=DateRange&startday=` +
    start +
    '&endday=' +
    end +
    '&duration=' +
    timbeBlock +
    '&roleCode=' +
    roleCode;

    if(countryCode)
    path += '&countryCode=' +   countryCode;

    if(timezone)
    path += '&timezone=' +   timezone;

    if (spkLanguageCode)
      path += `&spkLanguageCode=${spkLanguageCode}`

  return axios(path, {
    method: 'GET',
    withCredentials: true,
  })
    .then(response => {
      //console.log('GOT AUTO AVAILABILITY AND ITS ', response)
      return dispatch(actions.getAutoAvailabilitySuccess({response, scheduleRange, timezone}))
    })
    .catch(error => {
      dispatch(actions.getCoachAvailabilityFail(error))
    })
}

const getCoachAvailability = (userKey, start, end, forDialog, timezone, timeBLock, scheduleRange, roleCode, dispatch) => {
  //console.log('GETTING REGULAR COACH AVAILABLILITY!')
  const theGetData = {
    userKey,
    start,
    end,
    timezone,
    timeBLock,
  }
  dispatch(actions.getCoachAvailabilityStart(theGetData))
  var query = '?startDate=' + start + '&endDate=' + end + '&roleCode=' + roleCode + '&interval=' + timeBLock
  let path = `${getHost().apiBaseUrl}/scheduling/coach-availability/${userKey}` + (query ? query : '')
  return axios(path, {
    method: 'GET',
    withCredentials: true,
  })
    .then(response => {
      console.log('RETURNING RESPONSE AND SCHED RANGE ', response, scheduleRange)
      return dispatch(actions.getCoachAvailabilitySuccess({response,scheduleRange}))
    })
    .catch(error => {
      dispatch(actions.getCoachAvailabilityFail(error))
    })
}

export const actions = {

  getOutlookAppointments: (postData, scheduleRange, timezone) => dispatch => {
    //console.log('GETTING OUTLOOK AVAILABILITY AND RANGE TIMEXZSONE', scheduleRange, timezone)
    const theGetData = {

      timezone,

    }
    dispatch(actions.getCoachAvailabilityStart(theGetData))
    const path = `${getHost().apiBaseUrl}/scheduling/calendar-availability`
    
    return axios(path, {
      method: 'post',
      data: postData,
      withCredentials: true,
    })
      .then(response => {
        const theSched = response.data.map(t => t.TimeSlot)
        const theResponse = {
          response: {
            data: {
              Schedule: theSched
            }
          },
          scheduleRange
        }
        return dispatch(actions.getCoachAvailabilitySuccess(theResponse))
      })
      .catch(error => {
        const errorObj = error?.response?.data
        dispatch(toasterActions.showError(errorObj?.friendlyMessage || errorObj?.message));
        dispatch(actions.getCoachAvailabilityFail(error))
      })
  },
  clearCalendar: () => ({ type: actionTypes.CLEAR_CALENDAR }),
  resetGoals: () => ({type: actionTypes.RESET_GOALS}),
  setSelectedEvent: data => ({
    type: actionTypes.SET_SELECTED_EVENT,
    data,
  }),
  getCoachDialogAvailabilitySuccess: data => ({
    type: actionTypes.GET_COACH_DIALOG_AVAILABILITY_SUCCESS,
    data,
  }),
  getCoachAvailabilitySuccess: data => ({
    type: actionTypes.GET_COACH_AVAILABILITY_SUCCESS,
    data,
  }),
  getDailyAvailabilityStart: data => ({
    type: actionTypes.GET_DAILY_AVAILABILITY_START,
    data,
  }),
  scheduleCoachingAppointmentStart: data => ({
    type: actionTypes.SCHEDULE_COACHING_APPOINTMENT_START,
    data,
  }),
  scheduleCoachingAppointmentFinish: data => ({
    type: actionTypes.SCHEDULE_COACHING_APPOINTMENT_FINISH,
    data,
  }),
  scheduleCoachingAppointmentFail: errMessage => dispatch => {
    dispatch(toasterActions.showError(errMessage));
    dispatch({
      type: actionTypes.SCHEDULE_COACHING_APPOINTMENT_FAIL
    })
  },
  getAutoAvailabilityStart: () => ({type: actionTypes.GET_AUTO_AVAILABILITY_START}),
  getAutoAvailabilitySuccess: (data, scheduleRange, timezone) => ({
    type: actionTypes.GET_AUTO_AVAILABILITY_SUCCESS,
    data,
    scheduleRange,
    timezone,
  }),
  getCoachAvailabilityFail: error => ({ type: actionTypes.GET_COACH_AVAILABILITY_FAIL, error: error }),

  getCoachAvailabilityStart: data => ({
    type: actionTypes.GET_COACH_AVAILABILITY_START,
    data,
  }),
  gotDailyAvailability: data => ({
    type: actionTypes.GOT_DAILY_AVAILABILITY,
    data,
  }),
  gotAutoDailyAvailability: data => ({
    type: actionTypes.GOT_AUTO_DAILY_AVAILABILITY,
    data,
  }),
  getCoachAvailability: (userKey, start, end, forDialog, timezone, timeBLock, scheduleRange, roleCode) => dispatch => {
    getCoachAvailability(userKey, start, end, forDialog, timezone, timeBLock, scheduleRange, roleCode, dispatch)
  },
  getAutoAvailabilityRange: (coachId, start, end, timeBlock, roleCode, scheduleRange, timezone, options) => dispatch => {
    console.log('IN ACTIONS AND RANGE AND TIEZONE ', scheduleRange, timezone, options)
    getAutoAvailabilityRange(coachId, start, end, timeBlock, roleCode, scheduleRange, timezone, dispatch, options)
  },
  getAutoAvailabilityDay: (theDay, timezone, roleCode, timeBlock) => dispatch => {

    //console.log('AUTO TIMEZONE IS ', timezone)
    //console.log('GETTING AUTO AVAILABLE FOR THE DAY OF ', theDay)
    dispatch(actions.getDailyAvailabilityStart(theDay))
    const mDay = moment(theDay).tz(timezone)
    const sendDay = moment.utc(mDay)
    //console.log('AND THE SEND DAY UTC IS ', sendDay.format())
    const path = `${getHost().apiBaseUrl}/scheduling/coach-availability/auto?type=SingleDay&calendarday=` + sendDay.format() + '&roleCode=' + roleCode + '&duration=' + timeBlock
    return axios(path, {
      method: 'GET',
      withCredentials: true,
    })
      .then(response => {
        let theResponse = { ...response, timezone }
        return dispatch(actions.gotDailyAvailability(theResponse))
      })
      .catch(error => {
        dispatch(actions.getCoachAvailabilityFail(error))
      })
  },
  selectedTheAppointment: data => ({
    type: actionTypes.SELECTED_THE_APPOINTMENT,
    data,
  }),
  selectedTheGoal: data => ({
    type: actionTypes.SELECTED_THE_GOAL,
    data,
  }),
  scheduleCoachAvailability: (userKey, postData, calendarRange, forDialog, timezone) => {
    //console.log('IN SCHEDUL COACH AVAIL WITH CAL RANGE OF ', calendarRange)
    return dispatch => {
      dispatch(actions.scheduleCoachAvailabilityStart())
      let path = `${getHost().apiBaseUrl}/scheduler/availability/${userKey}`

      return axios(path, {
        method: 'post',
        data: [postData],
        withCredentials: true,
      })
        .then(response => {
          getCoachAvailability(calendarRange.userKey, calendarRange.start, calendarRange.end, forDialog, timezone, 60, dispatch)
        })
        .catch(error => {
          console.error(error)
          dispatch(actions.scheduleCoachAvailabilityFail(error.response))
        })
    }
  },
  scheduleCoachAvailabilitySuccess: data => ({
    type: actionTypes.SCHEDULE_COACH_AVAILABILITY_SUCCESS,
    data,
  }),
  scheduleCoachAvailabilityFail: error => ({ type: actionTypes.SCHEDULE_COACH_AVAILABILITY_FAIL, error: error }),

  scheduleCoachAvailabilityStart: () => ({ type: actionTypes.SCHEDULE_COACH_AVAILABILITY_START }),

  deleteCoachAvailability: (id, postData, calendarRange, forDialog, timezone) => {
    return dispatch => {
      dispatch(actions.deleteCoachAvailabilityStart())
      let path = `${getHost().apiBaseUrl}/scheduler/availability/${id}`

      return axios(path, {
        method: 'delete',
        data: [postData],
        withCredentials: true,
      })
        .then(response => {
          getCoachAvailability(calendarRange.userKey, calendarRange.start, calendarRange.end, forDialog, timezone, 60, dispatch)
        })
        .catch(error => {
          console.error(error)
          dispatch(actions.scheduleCoachAvailabilityFail(error.response))
        })
    }
  },
  deleteCoachAvailabilitySuccess: data => ({
    type: actionTypes.SCHEDULE_COACH_AVAILABILITY_SUCCESS,
    data,
  }),
  deleteCoachAvailabilityFail: error => ({ type: actionTypes.SCHEDULE_COACH_AVAILABILITY_FAIL, error: error }),

  deleteCoachAvailabilityStart: () => ({ type: actionTypes.SCHEDULE_COACH_AVAILABILITY_START }),
}

// Reducer
// Reducer
const randNum = () =>
  Math.random()
    .toString(36)
    .substring(2, 15)

const isReallyWithinTimeRange = t => {
  var begin = moment.tz(new Date(), t.timezone).add(t.scheduleRange, 'hours')
  return moment.tz(t.start, t.timezone).isAfter(begin)
}
const uuid = () => randNum() + randNum() //.startOf('day')

const toEvent = x => {
  return {
    id: uuid(),
    start: moment.tz(x.start, x.timezone), //moment(x.start)

      //.toISOString(),
    type: 'available',
    editable: false,
    allDay: true,
    rendering: 'background',
    timezone: x.timezone,
    scheduleRange: x.scheduleRange,
    theDay: moment.tz(x.start, x.timezone).format("YYYY/MM/DD"),
  }
}

const toAutoEvent = (x, timezone) => {
  //console.log('TO AUTO EVENT AND THE RAW DATE IS ', x.TimeSlot, moment(x.TimeSlot).format("YYYY/MM/DD"))
  //console.log('TO AUTO EVENT AND THE RAW DATE IS ', x.TimeSlot, moment.tz(x.TimeSlot, x.UserTimeZone)
  //  .toISOString(), moment.tz(x.TimeSlot, x.UserTimeZone).format("YYYY/MM/DD"))
return {
    id: uuid(),
    start: moment.tz(x.TimeSlot, timezone),
    type: 'available',
    editable: false,
    allDay: true,
    rendering: 'background',
    theDay: moment.tz(x.TimeSlot, timezone).format("YYYY/MM/DD"),
    timezone: timezone,
    scheduleRange: x.scheduleRange,
  }
}

const getAppointments = (data, timezone, scheduleRange) => {
  const newData = data.map(x => {
    return {
      start: x,
      timezone,
      scheduleRange,
    }
  })
  var eventDays = R.compose(
    R.uniqBy(R.prop('theDay')),
    R.filter(
      isReallyWithinTimeRange
    ),
    R.map(toEvent)
  )(newData)

  const theEvents = data.map(function(x) {
    var localStart = timezone ? moment.utc(x).tz(timezone) : moment.utc(x)
    var localEnd = localStart.clone().add(x.duration, 'minutes')
    return {
      id: '333',
      title: new Date(localStart.format('YYYY/MM/DD HH:mm')),
      start: x, //new Date(x.startDate),
      scheduleRange,
      timezone,
    }
  })
  return { eventDays, theEvents }
}



const getAutoAppointments = (data, timezone, scheduleRange) => {
  console.log('AND AUTO APPOINTMENT DATA IS ', data)
  console.log('THE TIMEZONE IS ', timezone)
  console.log('GET AUTO APPOINTMENTS TIMEZONE RANGE ',data,timezone, scheduleRange)
  const newData = data.map(x => {
    return {
      ...x,
      scheduleRange,
    }
  })
  var eventDays = R.compose(
    R.uniqBy(R.prop('theDay')),
    R.filter(
      isReallyWithinTimeRange,
    ),
    R.map(x => toAutoEvent(x, timezone))
  )(newData)
  const theEvents = data.map(function(x) {
    var localStart = timezone ? moment.tz(x.TimeSlot,timezone) : moment(x.TimeSlot)
    return {
      id: '333',
      title: new Date(localStart.format('YYYY/MM/DD HH:mm')),
      start: x.TimeSlot, //new Date(x.startDate),
      scheduleRange,
      availability: x,
    }
  })
  
  return { eventDays, theEvents }
}

const getAvailabilityObject = (AutoDayReturn, timezone) => {
  const availability = R.map(item => {
    const appointment = item.TimeSlot ? item.TimeSlot : item.startDate ? item.startDate : undefined
    return {
      id: uuid(),
      event: item,
      label: getSlotLabel(timezone, appointment),

      startDate: appointment,
      localDate: moment.tz(appointment, timezone),
      CoachKey: item.CoachKey,
    }
  }, AutoDayReturn)
  return availability
}

const getSlotLabel = (timezone, x) => {
  return moment.tz(x, timezone).format('ddd, MMM Do [at] h:mm A z') //dd, MMM Do YYYY [at] h:mm A z");
}



export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.CLEAR_CALENDAR:
      return {
        ...state,
        devents: [],
        events: [],
      }
    case actionTypes.RESET_GOALS:
      return {
        ...state,
        goalSelected: false,
        selectedGoalKey: undefined,
        selectedGoalText: undefined,
      }
    case actionTypes.SET_SELECTED_EVENT:
    //  console.log('SETTING SELECTED EVENT WITH ACTION ', action)
      var origEvents = filter(x => {
        return x.type !== 'selected'
      }, state.events)
      //console.log('AND ORIGINAL EVENtS IS ',origEvents);
      const newEvent = action.data
      origEvents.push(newEvent)
      return {
        ...state,
        events: origEvents,
      }
    case actionTypes.GET_COACH_AVAILABILITY_START:
      const payload = action.data
      return {
        ...state,
        userKey: payload ? payload.userKey : '',
        start: payload ? payload.start : '',
        end: payload ? payload.end : '',
        loading: true,
        timezone: payload ? payload.timezone : '',
        appointments: [],
      }
    case actionTypes.GET_COACH_AVAILABILITY_SUCCESS:
      const theReturn = getAppointments(action.data.response.data.Schedule, state.timezone, action.data.scheduleRange)
      return {
        ...state,
        loading: false,
        devents: theReturn.eventDays,
        events: theReturn.theEvents,
        appointments: [],
        scheduleButtonEnabled: false,
        selectedDay: undefined,
        availableAppointments: {
          tag: 'SelectDay',
          value: undefined
        },
        selectedAppointment: undefined,
      }
    case actionTypes.SCHEDULE_COACHING_APPOINTMENT_START:
      return {
        ...state,
        spinner: true,
        scheduleButtonEnabled: false,
      }
    case actionTypes.SCHEDULE_COACHING_APPOINTMENT_FINISH:
      return {
        ...state,
        spinner: false,
        scheduleButtonEnabled: false,
      }
    case actionTypes.GET_DAILY_AVAILABILITY_START:
      return {
        ...state,
        spinner: true,
      }
    case actionTypes.GOT_AUTO_DAILY_AVAILABILITY:
      //console.log('GOT AUTO AUTO DAILY AVAILABILITY AND SETTING APPOINTMENTS', action.data)
      const theAppointments = action.data.data ? action.data.data : action.data
      //console.log('AND THE TIMEZONE IS ', timezone)
      const theDay = theAppointments ? moment(theAppointments[0].TimeSlot).format('ddd MMM DD') : undefined
      const timezone = action.data.timezone
      const appointments = getAppointments(theAppointments, timezone)
      return {
        ...state,
        spinner: false,
        appointments,
        scheduleButtonEnabled: false,
        selectedDay: ' | ' + theDay,
        availableAppointments: {
          tag: 'TimeSlots', //' Available Time Slots (select below)'
          value: appointments.length,
        },
        selectedAppointment: undefined,
      }
    case actionTypes.GOT_DAILY_AVAILABILITY:
     //console.log('GOT COACHES DAILY AVAILABILITY AND SETTING APPOINTMENTS', action)
      // const aTest = getAutoAppointments(action.data.data, action.data.timezone)
      // console.log('AND NOW ATEST IS ', aTest )
      const theAppointments2 = action.data.data ? action.data.data : action.data
      const theDate = theAppointments2[0].TimeSlot ? theAppointments2[0].TimeSlot : theAppointments2[0].startDate
      const theDay2 = theAppointments2 ? moment.tz(theDate, action.data.timezone).format('ddd MMM DD') : undefined
      const fullDate = theAppointments2 ? moment.tz(theDate, action.data.timezone).format('MMMM DD, YYYY') : undefined
      const timezone2 = action.data.timezone
      const appointments2 = getAvailabilityObject(theAppointments2, timezone2)
      //console.log('AND THE APPOINTMENTS IS ', appointments2)
      return {
        ...state,
        spinner: false,
        appointments: appointments2,
        scheduleButtonEnabled: false,
        selectedDay: ' | ' + theDay2,
        fullDate,
        availableAppointments: {
          tag: 'TimeSlots', //' Available Time Slots (select below)'
          value: appointments2.length
        },
        selectedAppointment: undefined,
      }
    case actionTypes.SELECTED_THE_APPOINTMENT:
      let anySelected = false
      const theAppnts = state.appointments.map(apt => {
        apt.selected = action.data.startDate === apt.startDate;
        anySelected = apt.selected ? true : anySelected
        return apt
      })
      return {
        ...state,
        scheduleButtonEnabled: anySelected,
        selectedAppointment: action.data,
        appointments: theAppnts,
      }
    case actionTypes.SELECTED_THE_GOAL:
      //console.log('IN SELECTED THE GOAL AND ACTION DATAIS', action.data)
      return {
        ...state,
        goalSelected: true,
        selectedGoalKey: action.data.key,
        selectedGoalText: action.data.text,
      }
    case actionTypes.GET_AUTO_AVAILABILITY_START:
      return {
        ...state,
        loading:true,
        appointments: [],
      }
    case actionTypes.GET_AUTO_AVAILABILITY_SUCCESS:

      const _timezone = action.data.timezone ? action.data.timezone  : action.data.timezone;
      const theTHing = getAutoAppointments(action.data.response.data, _timezone, action.data.scheduleRange)
      // const isWithinTimeRange = action.data.scheduleRange === 2 ? isWithinTimeRange2Hours : isWithinTimeRange24Hours
      // //console.log('NOW THE THING IS ', theTHing)
      // const theTimezone = state.timezone
      // //console.log('AND THE STATE IS ', state)
      // const availbleDays = action.data.response.data //R.filter(item => {return item.AvailableAppointments === 'TRUE' ? true : false}, action.data.data)
      // const eventDays = R.filter(item => {
      //   return isWithinTimeRange(item.AvailableAppointments)
      // }, availbleDays)
      // const theDays = R.map(item => {
      //   console.log
      //   const theDate = theTimezone ? moment.utc(item.AvailableAppointments).tz(theTimezone) : moment.utc(item.AvailableAppointments)
      //   const localStart = new Date(theDate.format('YYYY/MM/DD HH:mm'))
      //   return { start: localStart }
      // }, eventDays)
      return {
        ...state,
        spinner: false,
        loading: false,
        devents: theTHing.eventDays,
        events: theTHing.theEvents,
        appointments: [],
        scheduleButtonEnabled: false,
        selectedDay: undefined,
        availableAppointments: {
          tag: 'SelectDay',
          value: undefined
        } ,
        selectedAppointment: undefined,
      }
      // return {
      //   ...state,
      //   spinner: false,
      //   loading: false,
      //   devents: theDays,
      //   appointments: [],
      //   events: [],
      //   scheduleButtonEnabled: false,
      //   selectedDay: undefined,
      //   availableAppointments: {
      //     tag: 'SelectDay',
      //     value: undefined
      //   } ,
      //   selectedAppointment: undefined,
      // }
    case actionTypes.GET_COACH_DIALOG_AVAILABILITY_SUCCESS:
      const devents = getAppointments(action.data.data, state.timezone)
      const availableEvents = filter(x => {
        return x.type === 'Available'
      }, devents)
      const theappointments = filter(x => {
        return x.type !== 'Available'
      }, devents)
      //console.log('GOT DIALOG AVAILABILITY SUCCESS ', appointments);
      return {
        ...state,
        loading: false,
        events: theappointments,
        devents: availableEvents,
      }
    case actionTypes.GET_COACH_AVAILABILITY_FAIL:
      return updateObject(state, {
        loading: false,
        spinner: false,
        appointments: [],
        events: [],
        devents: [],
        scheduleButtonEnabled: false,
        selectedDay: undefined,
        availableAppointments: {
          tag: 'SelectDay',
          value: undefined
        } ,
        selectedAppointment: undefined,
       })
    case actionTypes.SCHEDULE_COACH_AVAILABILITY_SUCCESS:
      return updateObject(state, {
        ...state,
        loading: false,
      })
    case actionTypes.SCHEDULE_COACHING_APPOINTMENT_FAIL:
      return {
        ...state,
        loading: false,
        spinner: false
      }
    default:
      return state
  }
}