import React from "react"
import styled from 'styled-components'
import { mobile } from '../../styles/media-queries'


const StyledImg = styled.img`
  height: 80px;
  margin: 0px;

  &.footer {
    margin: 0;
    height: 70px
  }
  &.header {
    margin: 0;
    height: 70px
  }

  @media  ${mobile} {
    height: 60px;

    &.footer {
      margin: 0;
      height: 50px
    }
    &.header {
      margin: 0;
      height: 50px
    }
}`

export default ({type = "", dark = false}) => (
  <StyledImg className={type}
    src={`/static/images/logos/${dark ? 'acme-outplacement-black' : 'acme-outplacement-white'}.png`}
  />
)