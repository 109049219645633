import React from "react"
import styled from 'styled-components'
import { mobile } from '../../styles/media-queries'

const StyledImg = styled.img`
  height: 56px;
  margin-top: 10px;

  &.footer {
    margin: 0;
    height: 56px
  }
  &.header {
    margin: 0;
    height: 56px
  }

  @media  ${mobile} {
    height: 60px;

    &.footer {
      margin: 0;
      height: 50px
    }
    &.header {
      margin: 0;
      height: 50px
    }
}`


export default ({ type = "",dark = false}) => (
    <StyledImg className={type}
      src={`/static/images/logos/${
        dark ? 'NFLA-Logo-dark' : 'NFLA-Logo-light'
      }.svg`}
      alt="NFLA Logo"
    />
)
