import humps from 'humps'
import { getInitials, createActionTypes, getHost, formatDateChat, createActions, api, handleError } from '../../shared/utility'
import { actions as iotActions } from './mqtt'
import { actions as actionInsights } from './insights'
import {formatChatsList} from '../../shared/chat-helper'
import getContent from '../../shared/content.helper';

const formatMessage = message => {
  const fullName = message.fullName || (message.firstName || '') + ' ' + (message.lastName || '')
  return {
    ...message,
    sentFormatted: formatDateChat(message.sentDT),
    fullName: fullName,
    initials: getInitials(fullName),
    avatar: message.avatar ? message.avatar : message.isPhoto ? getHost().imageBaseUrl + message.senderUserKey : getHost().avatarPlaceholder,
  }
}
const formatUnread = unread => {
  //parseFloat returns a string .. wth?
  return parseInt(parseFloat(unread).toFixed(0))
}
const formatThread = (chat, noMessages) => {
  return {
    ...chat,
    messages: noMessages ? [] : chat.messages.map(message => formatMessage(message)),

    subject: {
      ...chat.subject,
      fullName: chat.subject.firstName + ' ' + chat.subject.lastName,
      avatar: chat.subject.isPhoto ? getHost().imageBaseUrl + chat.subject.userKey : getHost().avatarPlaceholder,
    },
    participants: chat.participants.map(participant => {
      return {
        ...participant,
        fullName: participant.firstName + ' ' + participant.lastName,
        avatar: participant.isPhoto ? getHost().imageBaseUrl + participant.userKey : null, //getHost().avatarPlaceholder,
      }
    }),
  }
}
const updateChatMessages = async (chat, noMessages) => {

  console.log('updateChatMessages',chat)
  return {
    ...chat,
    messages: noMessages ? [] : await Promise.all(chat.messages.map(async message => {
            if (message.message.type === "Interview") {
              message.message.interview.uRLS.signedthumb = await actions.getThumbnailSignedUrl(message)
              return message
            } else {
              return message
            }
    })
    )
  }
}
const newInterviewMessages = async mainMessage => {
  return {
    ...mainMessage,
    message: mainMessage.message.type === "Interview" ? 
    mainMessage.message.interview.uRLS.signedthumb = await actions.getThumbnailSignedUrl(mainMessage) : mainMessage.message
  }
}

const THROTTLE_REMOVE_TYPER = 8 * 1000

const foundTyper = (typers, currentTyper) => typers.findIndex(typer => typer.userKey === currentTyper.userKey) > -1
const foundTyperIndex = (typers, currentTyper) => typers.findIndex(typer => typer.userKey === currentTyper.userKey)

// Action Types
export const actionTypes = {
  ...createActionTypes('GET_TOPIC_USER_CHAT'),
  ...createActionTypes('POST_CHAT_MESSAGE'),
  ...createActionTypes('GET_CHAT_PRESENCE'),
  ADD_SINGLE_MESSAGE: 'ADD_SINGLE_MESSAGE',
  INCREAMENT_UNREAD: 'INCREAMENT_UNREAD',
  RESET_UNREAD: 'RESET_UNREAD',
  SINGLE_TYPER: {
    ADD: 'SINGLE_TYPER.ADD',
    REMOVE: 'SINGLE_TYPER.REMOVE',
    UPDATE: 'SINGLE_TYPER.RESET',
  },
  GET_SINGLE_CHATTER_SUCCESS: 'GET_SINGLE_CHATTER_SUCCESS',
  VIEW_ASSET: 'VIEW_ASSET',
  CLOSE_ASSET: 'CLOSE_ASSET',
  GOT_ASSET: 'GOT_ASSET',
  GOT_INTERVIEW: 'GOT_INTERVIEW',
  GOT_THUMBSIGNEDURL: 'GOT_THUMBSIGNEDURL',
  GOT_THUMBNAILSIGNEDURL: 'GOT_THUMBNAILSIGNEDURL',
  GOT_COVERSIGNEDURL: 'GOT_COVERSIGNEDURL'

}

// Action Creators

export const actions = {
  ...createActions('GET_TOPIC_USER_CHAT', actionTypes),
  ...createActions('POST_CHAT_MESSAGE', actionTypes),
  ...createActions('GET_CHAT_PRESENCE', actionTypes),
  viewAsset: message => ({ type: actionTypes.VIEW_ASSET, message }),
  closeAsset: () => ({ type: actionTypes.CLOSE_ASSET }),
  incrementUnread: threadKey => ({ type: actionTypes.INCREAMENT_UNREAD, threadKey }),
  resetUnread: threadKey => ({ type: actionTypes.RESET_UNREAD, threadKey }),
  singleTyperAdd: data => ({ type: actionTypes.SINGLE_TYPER.ADD, data }),
  singleTyperRemove: data => ({ type: actionTypes.SINGLE_TYPER.REMOVE, data }),
  addSingleTyper: data => dispatch => {
    dispatch(actions.singleTyperAdd(data))
    setTimeout(() => {
      dispatch(actions.singleTyperRemove(data))
    }, THROTTLE_REMOVE_TYPER)
  },
  getAsset: message => dispatch => {
    let path = `/insights/asset/${message.message.asset.assetKey}?coachshared=1`
    let unalteredResponse = {}
    api.get(path, true).then(response => {
      unalteredResponse = response.data
      return humps.camelizeKeys(response.data);
    }).then(data => {
      console.log('GOT ASSET WITH DATA ', data)
      switch (message.message.asset.type) {
        case 'Document':
          window.open(data.uRLS.signedUrl.fulfillmentValue, '_blank')
          break
        case 'Article':
          window.open(data.vanityPath + '?coachshared=1&key=' + data.assetKey, '_blank')
          //dispatch(actionInsights.getAssetSuccess(unalteredResponse))
          break;
        case 'Video':
          window.open(data.vanityPath + '?coachshared=1&key=' + data.assetKey, '_blank')
          break;
        default:
          break
      }

    }).catch(error => {

    })
  },
  getInterview: message => dispatch => {
    let path = `/interview/video/${message.message.interview.answerKey}/take.mov`
    api.get(path, true).then(response => {
      return humps.camelizeKeys(response.data);
    }).then(data => {
      dispatch(actions.gotInterview(data.url, message))
    }).catch(error => {

    })
  },
  getThumbSignedUrl: message => dispatch => {

    let path = `/interview/video/${message.message.interview.answerKey}/take.mov`
    let thumbUrl = path.replace('take.mov', 'take.jpg')
    api.get(thumbUrl, true).then(response => {
      return humps.camelizeKeys(response.data);
    }).then(data => {

      dispatch(actions.gotThumbSignedURl(data.url, message))

    }).catch(error => {

    })

  },
  getThumbnailSignedUrl: async (message) => {

    let path = `/interview/video/${message.message.interview.answerKey}/take.mov`
    let thumbUrl = path.replace('take.mov', 'take.jpg')
    return api.get(thumbUrl, true).then(response => {
      return humps.camelizeKeys(response.data);
    }).then(data => {
      return data.url
    }).catch(error => {

    })
  },
  getCoverSignedUrl: message => dispatch => {

    let path = `/interview/video/${message.message.interview.answerKey}/take.mov`
    api.get(path, true).then(response => {
      return humps.camelizeKeys(response.data);
    }).then(data => {

      dispatch(actions.gotCoverSignedURl(data.url, message))

    }).catch(error => {

    })
  },

  gotCoverSignedURl: (url, message) => ({ type: actionTypes.GOT_COVERSIGNEDURL, url, message }),
  gotThumbnailSignedURl: (url, message) => ({ type: actionTypes.GOT_THUMBNAILSIGNEDURL, url, message }),
  gotThumbSignedURl: (url, message) => ({ type: actionTypes.GOT_THUMBSIGNEDURL, url, message }),
  gotInterview: (url, message) => ({ type: actionTypes.GOT_INTERVIEW, url, message }),
  gotAsset: (signedURL, message) => ({ type: actionTypes.GOT_ASSET, signedURL, message }),
  postChatMessage: (threadKey, postData) => dispatch => {
    if (!threadKey) {
      console.error('"threadKey" is required to send a message')
      return false
    }
    dispatch(actions.postChatMessageStart())

    let path = `/chat/${threadKey}`

    const req = api.post(path, postData, true)

    return req
      .then(response => {
        return humps.camelizeKeys(response.data)
      })
      .then(data => {
        return data
      }).then(data =>{
        return newInterviewMessages(data)
      })
      .then(data => {
        //thom says no call
        //dispatch(actions.getChatMessages(threadKey))
        console.log('POST CHAT MESSAGE SUCCESS')
        dispatch(actions.postChatMessageSuccess({ threadKey, messages: data }))
      })
      .catch(error => {
        handleError(dispatch, actions.postChatMessageFail, error)
      })
  },
  getChatStatus: status => dispatch => {
    dispatch(actions.getChatPresenceStart())
    //GET CALL

    let path = '/chat/status'

    const req = api.get(path, true)

    return req
      .then(response => {
        return humps.camelizeKeys(response.data)
      })

      .then(data => {
        //dispatch(iotActions.mqttConnect2(data.iot, data.subscribe))

        //data.subscribe.forEach(sub => {dispatch(iotActions.mqttSubscribe(sub))})
        dispatch(actions.getChatPresenceSuccess(data))
        if(status){
          status(data)
        }
      })
      .catch(error => {
        handleError(dispatch, actions.getChatPresenceFail, error)
      })
  },
  userTyping: threadKey => dispatch => {
    if (!threadKey) {
      console.log('"threadKey is required to notify typing"')
      return
    }

    return api
      .get(`/chat/${threadKey}/typing`, true)
      .then(response => {
        //we dont care about response here
        //return humps.camelizeKeys(response.data)
      })
      .catch(error => {
        console.log('ERROR NOTIFYING API THAT USER IS TYPING')
      })
  },

  addMessage: message => ({ type: actionTypes.ADD_SINGLE_MESSAGE, message }),

  readMessage: threadKey => dispatch => {
    if (!threadKey) {
      console.log('"threadKey is required to mark message read"')
      return
    }

    return api.get(`/chat/${threadKey}/read`, true).then(response => {
     // console.log('SET THREADKEY TO READ ', threadKey)
      return humps.camelizeKeys(response.data)
    })
  },
  getSingleChatter: userKey => dispatch => {
    let path = '/chat/byuser/' + userKey
    const req = api.get(path, true)
    return req
      .then(response => {
        return humps.camelizeKeys(response.data)
      })
      .then(data => {
        const newChats = formatChatsList(data.users)
        if (newChats && newChats.length > 0) {
          //console.log('OK RETURNING SINGLE USER ', newChats[0])
          const newChatUser = newChats[0]
          return newChatUser
        } else {
          return {}
        }
      })
      .then(data => {
        //console.log('AND HERE IS THE SINGLE CHATTER RETURN ', data)
        if (data.threads && data.threads.length > 0) {
          //console.log('OK THEN HERE IS THE RETURNN FROM SINGLE CHATTER ', data)
          dispatch(actions.getSingleChatterSuccess(data))
        } else {
          //console.log('OK WE ARE INITING NEW USER CHAT IN SINGLE CHATTER')
          //dispatch(chatBoxActions.initUserChat(userKey))
        }
        //dispatch(actions.setSelectedUser(data))
      })
      .catch(error => {
        //dispatch(actions.getActiveChattersFail(error))
      })
  },
  getSingleChatterSuccess: payload => ({ type: actionTypes.GET_SINGLE_CHATTER_SUCCESS, payload }),
  getTopicUserChat: (userKey, topicKey = 'general') => dispatch => {
    if (!userKey) {
      console.error('"userKey" is required')
      return false
    }
    dispatch(actions.getTopicUserChatStart())

    let path = `/chat/messages/${userKey}/${topicKey}`

    const fakeResponse = false

    const req = !fakeResponse ? api.get(path, true) : api.get(path, false)

    //console.log(req)

    return req
      .then(response => {
        return humps.camelizeKeys(response.data)
      })
      .then(data => {

        // console.log('GET MESSAGES WITH SIGNED URLS', data)

        return updateChatMessages(data)

      }).then(data => {
        
        return formatThread(data)
          
        }).then(data => {
        //console.log('GET_TOPIC_USER_CHAT RESPONSE', data)
        dispatch(actions.getTopicUserChatSuccess(data))
      })
      .catch(error => {
        console.error(error)
        handleError(dispatch, actions.getTopicUserChatFail, error)
      })
  },
  getCareTeam: () => dispatch => {

  }
}

// Reducer
const defaultState = {
  saving: false,
  saved: false,
  loading: false,
  loaded: false,
  error: null,
  data: null,
  unread: 0,
  isOpen: false,
  isOnline: false,
  isBackOffice: false,
  activeChats: [],
  subs: [],
  activeThreadsCount: 0,
  iot: null,
  statusLoaded: false,
  typers: [],
  typeString: '',
  playClick1: false,
}

export default function (state = defaultState, action) {
  let newMessages, newMessage
  let currentTyper, newTypers
  switch (action.type) {
    case actionTypes.GOT_THUMBSIGNEDURL:
      console.log('FIRING GOT_THUMBSIGNEDURL AND ACTION IS ', action.url)
      return {
        ...state,
        assetViewerIsOpen: true,
        thumbSignedUrl: action.url,
        assetViewMsg: action.message
      }
    case actionTypes.GOT_THUMBNAILSIGNEDURL:
      console.log('FIRING GOT_THUMBNAILSIGNEDURL AND ACTION IS ', action.url, action.message)
      return {
        ...state.chat.messages,
        assetViewerIsOpen: false,
        thumbnailSignedUrl: action.url,
        assetViewMsg: action.message
      }
    case actionTypes.GOT_COVERSIGNEDURL:
      console.log('FIRING GOT_COVERSIGNEDURL AND ACTION IS ', action.url)

      return {
        ...state,
        assetViewerIsOpen: true,
        coverSignedUrl: action.url,
        assetViewMsg: action.message,
      }
    case actionTypes.GOT_INTERVIEW:
      console.log('FIRING GOT INTERVIEW AND ACTION IS ', action)

      return {
        ...state,
        assetViewerIsOpen: true,
        videoURL: action.url,
        vidCover: action.message.message.interview.uRLS.cover,
        vidTitle: action.message.message.interview.question,
        assetViewMsg: action.message,
      }
    case actionTypes.GOT_ASSET:
      console.log('FIRING GOT ASSET WITH ACTION OF ', action)
      //signedURL, message
      return {
        ...state,
        documentURL: action.signedURL,
        assetViewerIsOpen: true,
        assetViewMsg: action.message,
      }
    case actionTypes.VIEW_ASSET:
      return {
        ...state,
        assetViewerIsOpen: true,
        assetViewMsg: action.message,

      }
    case actionTypes.CLOSE_ASSET:
      return {
        ...state,
        assetViewerIsOpen: false,
        assetViewMsg: undefined,
      }
    case actionTypes.RESET_UNREAD:
      return { ...state, unread: 0 }
    case actionTypes.INCREAMENT_UNREAD:
      return { ...state, unread: (state.unread = formatUnread(state.unread) + 1) }

    case actionTypes.SINGLE_TYPER.REMOVE:
      //console.log('5 seconds over')
      var toRemove = foundTyperIndex(state.typers, action.data)
      //console.log('found typer', toRemove)
      if (toRemove > -1) {
        //console.log('typer removed from array')
        state.typers.splice(toRemove, 1)
      }
      return { ...state, typers: state.typers }
    case actionTypes.SINGLE_TYPER.ADD:
      currentTyper = action.data
      newTypers = state.typers
      if (foundTyper(state.typers, currentTyper)) {
        //console.log('TYPER ALREADY IN LIST', foundTyper(state.typers, currentTyper))
      } else {
        if (state.data && (state.data.threadKey === currentTyper.threadKey)) {
          newTypers.unshift(currentTyper)
        }
      }
      //console.log('AND NEW TYPERS IS ', newTypers)
      let typeString = ''
      switch (true) {
        case newTypers.length === 1:
            typeString = newTypers[0].isCareTeam ? 'Care Team ' + getContent('Global.Message.IsTyping') : newTypers[0].firstName + ' ' + getContent('Global.Message.IsTyping')
            break
        case newTypers.length === 2:
          const typer1 = newTypers[0].isCareTeam ? 'Care Team' : newTypers[0].firstName //+ ' ' + newTypers[0].lastName
          const typer2 = newTypers[1].isCareTeam ? 'Care Team' : newTypers[1].firstName //+ ' ' + newTypers[1].lastName
           typeString = typer1 + ' and ' + typer2 + ' ' + getContent('Global.Message.AreTyping')
          break
        case newTypers.length > 2:
          newTypers.forEach(item => {
            typeString += item.isCareTeam ? 'Care Team, ' : item.firstName + ', '
          })
          typeString = typeString.substring(1, typeString.length - 1)
          typeString += ' ' + getContent('Global.Message.AreTyping')
          break
        default:
          typeString = ''
      }
      return { ...state, typers: newTypers, typeString: typeString }

    case actionTypes.POST_CHAT_MESSAGE.START:
      return {
        ...state,
        playClick1: true,
      }
    case actionTypes.POST_CHAT_MESSAGE.SUCCESS:
    return {
      ...state,
      playClick1: false,
    }
    case actionTypes.GET_CHAT_PRESENCE.SUCCESS:
      // console.log('I SET UNREAD', formatUnread(action.data.unread))

      return {
        ...state,
        statusLoaded: true,
        unread: formatUnread(action.data.unread),
        isOnline: action.data.isOnline,
        iot: action.data.iot,
        isBackOffice: action.data.isBackOffice,
        activeThreadsCount: action.data.activeChats,
        subs: action.data.subscribe,
      }
    case actionTypes.ADD_SINGLE_MESSAGE:
      const theData = state.data && state.data.messages ? state.data.messages : undefined
      if (state.data.threadKey === action.message.threadKey) {
        newInterviewMessages(action.message)
        // console.log('MESSAGE WAS ADDED TO MESSAGES', action.message)
        const newMessage = formatMessage(action.message)
        //  console.log('THE FORMATTED MESSAGE IS ', newMessage)
        theData.unshift(newMessage)
        //   console.log('AND NOW MESSAGES ARE ', theData)
        //state.data.messages.unshift(formatMessage(action.message))
      }
      return {
        ...state,
        data: {
            ...state.data,
            messages: theData,
          }
      }

    case actionTypes.GET_TOPIC_USER_CHAT.SUCCESS:
      //console.log('GET_TOPIC_USER_CHAT.SUCCESS action.data', action.data)
      //what is action.data
      /* {
          messages:[]
          more:0
          next:null,
          participants:[],
          since: ""
          subject:{}
          //something more add later
       }*/
      return { ...state, error: null, data: action.data }
    case actionTypes.GET_SINGLE_CHATTER_SUCCESS:
      return {
        ...state,
        clientChat: action.payload
      }
    case actionTypes.GET_TOPIC_USER_CHAT.FAIL:
      return { ...state, error: action.error }

    default:
      return state
  }
}
