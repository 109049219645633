import {getHost, checkForInterviewApp} from "./utility";
import { init as initAnalyticsLib } from "@kornferry/kfadvance-ui-analytics"

export const initAnalytics = () => {
  const gtmCode = getHost().gtmCode;
  const isInsideInterviewApp = checkForInterviewApp();
  initAnalyticsLib(gtmCode, isInsideInterviewApp)
  //init(gtmCode, isInsideInterviewApp, "0516a71c-85a5-11ea-bc55-0242ac130003")

}


// const init = (gtmCode, isInsideInterviewApp = false, nonce=null) => {
//   if (!gtmCode) {
//     throw new Error('gtmCode is a required parameter')
//   }

//   ; (function (w, d, s, l, i) {
//     w[l] = w[l] || []
//     w[l].push({
//       'gtm.start': new Date().getTime(),
//       event: 'gtm.js',
//     })
//     var f = d.getElementsByTagName(s)[0],
//       j = d.createElement(s),
//       dl = l != 'dataLayer' ? '&l=' + l : ''
//     j.async = true
//     j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
//     if(nonce)
//     j.setAttribute("nonce","0516a71c-85a5-11ea-bc55-0242ac130003" )

//        console.log("nonce", j)
//    // f.parentNode.insertBefore(j, f)
//   })(window, document, 'script', 'dataLayer', gtmCode)
// }

export const setUser2 = (email, userKey, name, created, partnerKey, subscriptionUser, hasFreemium, martketingConsent, trackUserAnalytics, products) => {
  if (trackUserAnalytics) {
    let showIntercomChat =  !checkForInterviewApp(); //!config.isInsideInterviewApp;

    if (showIntercomChat) {
      showIntercomChat = !subscriptionUser && hasFreemium;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'user',
      'loggedIn': true,
      'email': email,
      'userKey': userKey,
      'created': Math.round(Date.parse(created) / 1000),
      //Unix timestamp
      'name': name,
      'partnerKey': partnerKey,
      'subscriptionUser': subscriptionUser,
      'freemiumUser': !subscriptionUser && hasFreemium,
      'martketingConsent': martketingConsent === 1,
      'trackUserAnalytics': trackUserAnalytics,
      'showIntercomChat': showIntercomChat,
      'products': products
    });
  }
};


export * from "@kornferry/kfadvance-ui-analytics";