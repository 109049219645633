export const products = [
  {
    productId: 'CareerCoachingGift-3M',
    months: 3,
    price: 147,
    discount: 0,
    selected: false,
    topLine: '',
    payment: {
      productKey: "CareerCoachingGift-3M",
      prices: {US: {price: 147, currency: 'usd'}},
      availableForCountry: "us",
      availableForUserCountry: true,
      fastSpringProductId: "3-month-gift-subscription",
      data: {
        securePayload: "A5bXu1gwE50tecH+9BgeB/mqUUFG/xrDjvMaD0QJ7Ndb4sCKlgH+RN9FcqzdooUArXxKjFrYoDRmlPEABrz3Dt+TdQPUXioW2TQdRWuhuiwkx0dpOAvF75Bkgsg76yEHiDdtYHxgCIS0IuDwsiHJeRNwdcW71AdkDQiK0zCZ3fRaAM6y+0YqmiPk8n7IpicmjA21S7Ycnze5iOUrR7mCXfAEQ/qr07WVeUv/ndu8GhtobuTbxjt8jIO4DLiYCfQ9LWvnbf8c7I+Y96ljQ2wncyUhhQybzIufvPkQGcUcH06BsR22dTJyXIn/TCrRvw3j8D9cqKaGrI9aWKm9y3ZCeX41OK9DZxZ47MQdwPfH4l0ZRf37ods0rk3m8WBI9OzmPULM2YFnT6n1b0iinF8v/rbsvY+E2o1jM6ia38Uj58jqbkTbbl/V4/TdeR9qZh9U3yGSqsffFhsmkQygATskjYWfTa8ZmHn4fcJiSlLIs58Ie5qscth5unRkTHu0nwWFLXgkgLnZfhrSUhYunACx5p6pOFtbVo6VhX3GLguaZco=",
        secureKey: "URXU+pIEWYKTkUs18cBh0DvOjjmnVyXh5yytkZXbI8HXH5WDvaUhIZkGpzUtmnV84DDgb0miCTF0cZlIoUk1IyGBFZFvAGkP1zBngS69lHtH3s3aHgWuxXs1C6TZAFWPQ1R6BfPsHA8F8k2xeGToj59DWXG0qD52EMQNA1qPTLGq6t9g5nkS/mQaUMo8iyZ8Qt9UuaHuOKj5H7vvOB9yLF9NKCvwr9V4y5bCJ9sSw/RMVitX8Uj1hHJguZ09nAMrLnzKxEuAG0bgk9HqcwDqt5fqy7marc7yrs6fc4km5xhfQ+HoLT111guTpv0Y1vFuLCePygUnvkZ1vUrGBrGFzw=="
      },
      dataAccessKey: "OHWNL3AJTA6_Z_SHGO2IFQ",
      storeFront: "kfadvance.test.onfastspring.com",
      partnerName: 'KF',
    }
  },
  {
    productId: 'CareerCoachingGift-6M',
    months: 6,
    price: 245,
    discount: 17,
    selected: true,
    topLine: 'Most Popular',
    payment: {
      productKey: "CareerCoachingGift-6M",
      prices: {US: {price: 245, currency: 'usd'}},
      availableForCountry: "us",
      availableForUserCountry: true,
      fastSpringProductId: "6-month-gift-subscription",
      data: {
        securePayload: "U2T8vglPR45TPX4SZtnjFwpxIvR9RjorPLxz6CM+BN4j/dC7QGJ8ismqa3L5OcRD2X9DF3TrR4jItWn3Z7IeIOD0Kuh2E1paQPp28o17kJtuC6vwncY3c0kQnediHxEPHHrZ43GzMvmbqG4xmb+UZZuUsepQZ+UIMe4VE8yUxKgl45uc+pcQcSznGe+QbRTJKI5KpoXqzu5H6kVz92BH8gHRhvoAIxitc7PBsq+SQ36FTg2S+y6gTJYEYe8U1h4q/6qSt50zJQdlQFWdj+hfroJukD6BVCIEonx8zpg8MDqPs1OwH10I7XzIfbmTqCByiuZ9wPivysDMNKFLtnyIBxs/1oPsDmhbgqG6CxfdpTzzfu+HApl1XkHyzvbTq3DShxsc+TzGcw0W3KJmKcenH3CfL52LxsRbgxcx20lTW1FQbVaIXzOIeSUKITg+6NjGJdc8idhybK5ssdtHL/+O315Qz1PpT5iM2DmqgAYneDzHp/Xq7CCvL029LpzNqGRtEffC5px2tEmMdROY30MW/JFVT/Ox3YNAdr9B+1d3kEE=",
        secureKey: "tkyM7aZfwPjt11i17tOPI/HJpskbeIKenw5q4jhfjGtj80MvBgmuU5uiDUnw1lIAym5xcXnu4y0RfgvVvCa/O+gGTQ0c8/QpudC/sepPTd0zHiZEvQmc1iIxu8WSRntzq+PawR2VYuO2JqzDV6DUTm96/0/wYJYLnUo5GVqL8/pnZC5mPaUBVeEx81GBv0hY6DDLXrfTD0fvsgeA6yyNbVKmND5w8OroJoiEI3ozdjImcFdE4h/cvGvrs3kqnFLIF9gXh3I+vIopfdIp9Qfsx2erFa/9//+LJ4vCtYjPhJiR1R54T4uzJkSi4srl5vMG2rAQzRV0/pqYUi7qO8kM/A=="
      },
      dataAccessKey: "OHWNL3AJTA6_Z_SHGO2IFQ",
      storeFront: "kfadvance.test.onfastspring.com",
      partnerName: 'KF',      

    }
  },
  {
    productId: 'CareerCoachingGift-12M',
    months: 12,
    price: 441,
    discount: 25,
    selected: false,
    topLine: '',
    payment: {
      productKey: "CareerCoachingGift-12M",
      prices: {US: {price: 411, currency: 'usd'}},
      availableForCountry: "us",
      availableForUserCountry: true,
      fastSpringProductId: "12-month-gift-subscription",
      data: {
        securePayload: "uHnCtF/sWKYWSNEcuwPWpcqT3PduJR3VyA45gp1GVUyy/Hq7HyUOh4ArXoH58JtJk5/ntMl9hZfXHeJF3Z4ANa8xcm3NbPbht0EzF/MlyCf/2NsomA39+Wd0NOp0zfdQBHv0pco+dg8TdLlD8NxxabcxpW/VSkhx3/98GnnIXKRMiY6yVK5jGyEpYqyaF+mNyYcs0shP5vGqwzKSeXy6BPflaXTcTY6DR5M3YWOJzRW/QTXmD/wmopqgX3P3W6W69u3OReJ0O5xNAEFjkIsUin3WDreiPrcVUj5AD49g7hwp8A3LNJc9TLlMhrldogTYLIXT044gEOC9SOXqd6qazt5+ze20CYnXMI7UAXb5oNaIGasuYHXsgzQPdxTSx4lJWK2+KsAPI9LlfQoyH5NYfGopZR2E+n1F+qY8VqyUANNCWLprOHAfWkhMqF5Tppl91rJNGM5ZuaaNYGY+8hVY11xL3wfLNJaXDSMvHzcKdDkZoJo3fRYd8xYe5Lyhl43V7dXB+XHDK1rcIJpVwEdgmqZsagdknhoieSIoXKB68sY=",
        secureKey: "SgaXVm47d1FRidNPfODWzl1mya5UTNAgWQiOrZQcZ8MfFR1xCTxG+Zwj4jMSX8GxEnjY8ydo5ZPZ7e6yo9SQ1jeIk7VRB4QPFyNhmaY3eypSJWLZrspNK0rt9H7ctVmCCj/lY+NTdcTa37WiCd7/6ovI1478pKOg4o7M1DP9eVtsHE7ddw2DfKl01bXg6TPIWwSgh4i2bpWN3VxipG7Juu/a9pVMUw0ZQBoJ04x7z6QcC4jmgxA/lv5dnLOSPWuN4GOQK3hdiN7JE/LPyZ0GKLzKh1OfjeWELzxfV2kjIwjRGfnvl9LED0gP1986LRaq3rf2mqbTLPXxfWFG3kZ5LQ=="
      },
      dataAccessKey: "OHWNL3AJTA6_Z_SHGO2IFQ",
      storeFront: "kfadvance.test.onfastspring.com",
      partnerName: 'KF',
    }
  },
]


export const data = {
  partnerName: 'KF',
  partnerKey: 'SVOoQJaeX0eLboUOU0wSoQtt',
  entityKey: '3bd810a50bb711ea866e12242d62e6a0', //KF cohort
  dataAccessKey: "OHWNL3AJTA6_Z_SHGO2IFQ",
  storeFront: "kfadvance.test.onfastspring.com",
  products,
}