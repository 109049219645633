// @flow
import { connect } from 'react-redux'
import { actions } from '../../redux/modules/auth'
import SelectPartnerModal from '../../components/Modals/SelectPartnerModal'

const mapStateToProps = ({ auth }) => {
    const { isSelectPartnerOpen, partnerKeys, content, user, role, partnerKey } = auth
    const allPartners = content && content.Partners ? content.Partners : []
    const userRoles = user && user.roles ? user.roles : []


    let arr = [];

    if( partnerKeys) {
        partnerKeys.split(',').forEach(i => {
                let p = allPartners.find(f => f.PartnerKey === i);
                if (p) {

                    let uf = userRoles.filter(f => f.isVisibleOnMenu === 1 && f.PartnerKey === i && f.isCoach !== 1);

                    if (uf && uf.length > 0) {
                        uf.forEach(j => {
                            if(!(p.PartnerKey === partnerKey && j.RoleCode === role) ) {
                                p.RoleName = j.RoleName;
                                p.RoleCode = j.RoleCode;
                                arr.push(p)
                            }
                        })
                    }
                }
            })
        }


    arr.sort((a, b) => {
        if (a.SearchPartnerName < b.SearchPartnerName) {
            return -1;
        }
        if (a.SearchPartnerName > b.SearchPartnerName) {
            return 1;
        }
        return 0;
    })

    return {
        isOpen: isSelectPartnerOpen,
        partners: arr
    }
}

const mapDispatchToProps = dispatch => ({
  onClose: () => () => {},
  roleChange: (role, partnerKey) => dispatch(actions.roleChange(role, partnerKey)),
  logout: () => dispatch(actions.logout())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectPartnerModal)
