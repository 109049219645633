import {data} from '../../components/Gift/config/data';
import { querystring, getHost, api } from "../../shared/utility";

const {products, partnerName, partnerKey, dataAccessKey, storeFront} = data;
const initCards = products.map((obj, i) => ({...obj, index: i}));
export const initErrors = {
  purchaserFirstName: '',
  purchaserLastName: '',
  purchaserEmail: '',
  recipientFirstName: '',
  recipientLastName: '',
  recipientEmail: '',
  notifyDate: '',
  giftNote: '',
};
const initFormData = {
  purchaserFirstName: '',
  purchaserLastName: '',
  purchaserEmail: '',
  recipientFirstName: '',
  recipientLastName: '',
  recipientEmail: '',
  notifyDate: new Date(),
  giftNote: '',
}

export const getSelected = state => {
  if (!state || !state.cards) return null;

  const arr = state.cards.filter(obj => obj.selected === true);
  return arr.length > 0 ? arr[0] : null;
}

export const defaultState = {
  cards: initCards,
  errorText: '',
  statusText: '',
  errors: initErrors,
  data: initFormData,
  secure: {
    securePayload: '',
    secureKey: '',
  },
  partnerName: 'KF',
  partnerKey: 'SVOoQJaeX0eLboUOU0wSoQtt',
  entityKey: '3bd810a50bb711ea866e12242d62e6a0',
  dataAccessKey: '', 
  storeFront: '', //"kfadvance.test.onfastspring.com",
  view: 1, // set default current viewing slide to be 1
}

export const actions = {
  setItem: (key, value) => ({type: 'Gift_setItem', payload: {key, value}}),
  setState: newState => ({type: 'Gift_setState', payload: {newState}}),

  getSecurePayload: (payload) => dispatch => {
    let path = `${getHost().apiBaseUrl}/payment/payload`
    return api
      .post(path, payload)
      .then(response => {
        const secureData = response.data.payload[0];
        const dataAccessKey = response.data.dataAccessKey;
        const storeFront = response.data.storeFront;
        return dispatch({type: 'Gift_setSecurePayload', payload: {secureData, dataAccessKey, storeFront}});
      })
      .catch(error => {
       console.log('>>> GET SECURE PAYLOAD ERROR', error);
      })
  },

  getUserByRedemptionCode: (redemptionCode) => dispatch => {
    let path = `${getHost().apiBaseUrl}/gift/userbyredemption/${redemptionCode}`

    return api.get(path)
      .then(response => {
        return response.data[0];
      })
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'Gift_setItem':
      const {key, value} = action.payload;
      return {...state, [key]: value};

    case 'Gift_setState': 
      const {newState} = action.payload;
      return {...newState};

    case 'Gift_setSecurePayload':
      const {
        secureData: {
          productKey,
          data
        },
        dataAccessKey,
        storeFront
      } = action.payload;
      return {...state, productKey, secure: data, dataAccessKey, storeFront}

    default:
      return state;
  }
}
