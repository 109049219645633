import styled from 'styled-components';
import { md, sm, desktop } from '../../styles/media-queries';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu'
import cyan from '@material-ui/core/colors/cyan';
import blue from '@material-ui/core/colors/blue';
import Toolbar from '@material-ui/core/Toolbar';
import { createMuiTheme } from '@material-ui/core/styles';


export const StyledToolbar = styled(Toolbar)`
  && {
    background: ${props => props.theme.bgColorMenuAppBar || '#000'};
    color: ${props => props.theme.colorHeaderText || '#fff'};
  }
`

export const StyledAppBar = styled(AppBar)`
  && {
    position: fixed;
    z-index: 5;
    padding: 0;
    width: auto;
    left: 0;
    right: 0;
    background-color: ${props => props.theme.bgColorMenuAppBar || props.theme.darkColor};;
    height: 65px;
    @media only screen and (min-width: 600px) {
      height: 75px;
    }
    .mobileMenu {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @media ${desktop} {
        display: none;
      }
    }
    .mainMenu {
      display: none;
      width: 100%;
      @media ${desktop} {
        display: block;
      }
    }
    .topnav {
      display: flex;
      align-items: center;
      justify-content: center;
      @media ${md} {
        justify-content: space-between;
      }
      a {
        text-decoration: none;
      }

      .logo {
        max-height: 75px;
        cursor: pointer;
      }
    }

    .drawer {
      //cant find a drawer here but keeping styles
      position: relative;
      background-color: ${props => props.theme.darkColor};
      width: 250px;
    }
  }
`

export const StyledMenuIcon = styled(MenuIcon)`
  && {
    color: ${props => props.theme.colorAppBarMenuIconMobile}
  }
`

export const HeaderAltTextContainer = styled.div`
  && {
    color: ${props => props.theme.colorHeaderText || '#fff'};
    font-family: ${props => props.theme.fontThree};
    font-size: 18px;
`

export const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    backgroundColor: '#ff6e40',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    marginTop: 4,
    color: '#fff',
    flex: 'none',
    alignSelf: 'flex-start',
    justifySelf: 'center',
  },

  topnav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: '100%',
    width: '100%',
    alignSelf: 'center',
    boxSizing: 'border-box',
  },
  menuItem: {
    fontSize: '14px',
    color: '#fff',
  },
  logo: {
    maxHeight: '50px',
    cursor: 'pointer',
    alignSelf: 'center',
    justifySelf: 'center',
  },
  mobileLogo: {
    maxHeight: '45px',
    cursor: 'pointer',
    alignSelf: 'center',
    justifySelf: 'center',
  },
  appBar: {
    //zIndex: theme.zIndex.drawer + 1,
  },
  drawerPaper: {
    position: 'relative',
    backgroundColor: cyan[400],
    color: '#fff',
  },
  nested: {
    //paddingLeft: theme.spacing.unit * 5,
    paddingLeft: theme.spacing(5),
  },
  icon: {
    color: '#fff',
  },
  text: {
    color: '#fff',
  },

  barContainer: {
    display: 'grid',
    gridTemplateColumns: '50px auto 50px',
    width: '100%',
    height: '75px',
    alignItems: 'center',
  },

  space : {
    width: "48px"
  },

  barButton: {
    gridColumn: '1 / span 1',
    opacity: '.7',
  },

  barLogo: {
    gridColumn: '2 / span 1',
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'center',
    marginTop: '0px',
  },

  barContainerFull: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    width: '100%',
    height: '75px',
    alignItems: 'center',
  },
  barLeftLogo: {
    gridColumn: '1 / span 1',
    //width: '200px',
    display: 'flex',
    alignItems: 'center',
  },
  barAvatar: {
    gridColumn: '2 /span 1',
    justifySelf: 'end',
    alignSelft: 'center',
  },
  userName: {
    marginLeft: '5px',
    color: 'white',
  },
});

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: cyan[350],
      main: '#000',
      dark: cyan[900],
      contrastText: '#fff',
    },
    secondary: {
      light: blue[350],
      main: blue[600],
      dark: blue[750],
      contrastText: '#000',
    },
  }
})

export const siteDrawerStyles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    backgroundColor: '#ff6e40',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    marginTop: 4,
    color: '#fff',
    flex: 'none',
    alignSelf: 'flex-start',
    justifySelf: 'center',
  },
  topnav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: '100%',
  },
  menuItem: {
    fontSize: '14px',
    color: '#fff'
  },
  logo: {
    maxHeight: '50px',
    cursor: 'pointer',
    alignSelf: 'center',
    justifySelf: 'center',
  },
  mobileLogo: {
    maxHeight: '45px',
    cursor: 'pointer',
    alignSelf: 'center',
    justifySelf: 'center',
  },
  appBar: {
    //zIndex: theme.zIndex.drawer + 1,
  },
  drawerPaper: {
    position: 'relative',
    backgroundColor: cyan[400],
    color: '#fff',
  },
  nested: {
    //paddingLeft: theme.spacing.unit * 5,
    paddingLeft: theme.spacing(5),
  },
  icon: {
    color: '#fff'
  },
  text: {
    color: '#fff'
  },
  barContainer: {
    display: 'grid',
    gridTemplateColumns: '50px auto 50px',
    width: '100%'
  },
  barButton: {
    gridColumn: '1 / span 1',
  },
  barLogo: {
    gridColumn: '2 / span 1',
    maxHeight: '50px',
    cursor: 'pointer',
    justifyItems: 'center',
    display: 'flex',
    justifySelf: 'center',
  },
  barContainerFull: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    width: '100%',
  },
  barAvatar: {
    gridColumn: '3 / span 1'
  },
  barLeftLogo: {
    gridColumn: '1 / span 1',
  },
  userName: {
    marginLeft: '5px',
    color: 'white',
  },
});
