import humps from 'humps'
import { api, updateObject, getHost, getContent } from '../../shared/utility'

export const actionTypes = {
  GOT_USER_QUESTIONS: 'GOT_USER_QUESTIONS',
  GOT_PARTNER_QUESTIONS: 'GOT_PARTNER_QUESTIONS',
}

export const actions = {
  getUserQuestions: route => dispatch => {
    if (!route) route = '/profile';
    //let path = `/user/questions` + '?route=' + route
    let path = '/user/questions?route=' + route + '&includeAttribute=0'
    api
      .get(path, true)
      .then(response => {
        return humps.camelizeKeys(response.data)
      })
      .then(data => {
        dispatch(actions.gotUserQuestions(data))
      })
      .catch(error => {})
  },


  getPartnerQuestionsOnly: (entityKey, route) => dispatch => {
    if (!route) route = '/intake';
    let path = `/content/questions/` + entityKey + '?route=' + route
    api
      .get(path, true)
      .then(response => {
        return humps.camelizeKeys(response.data)
      })
      .then(data => {
        dispatch(actions.gotPartnerQuestions(data))
      })
      .catch(error => {})
  },


  getPartnerQuestions: (entityKey, route) => dispatch => {
  if (!route) route = '/profile';
    let path = `/content/questions/` + entityKey + '?route=' + route
    api
      .get(path, true)
      .then(response => {
        return humps.camelizeKeys(response.data)
      })
      .then(data => {
        //console.log('GOT partner ATTRIBUTES AND THE RESPONSE IS ', data)
        dispatch(actions.gotPartnerQuestions(data))
        dispatch(actions.getUserQuestions(route)) //todo add these values into the /user GET call for perf
      })
      .catch(error => {})
  },
  gotUserQuestions: payload => ({ type: actionTypes.GOT_USER_QUESTIONS, payload }),
  gotPartnerQuestions: payload => ({ type: actionTypes.GOT_PARTNER_QUESTIONS, payload }),
}

// Reducer

const defaultState = {
  partnerQuestions: {},
  userQuestions: {},
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GOT_PARTNER_QUESTIONS:
      return {
        ...state,
        partnerQuestions: action.payload,
      }
    case actionTypes.GOT_USER_QUESTIONS:
      return {
        ...state,
        userQuestions: action.payload,
      }
    default:
      return state
  }
}
