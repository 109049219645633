
import React from "react"
import styled from 'styled-components'
import { mobile } from '../../styles/media-queries'

  const StyledImg = styled.img`
    width: 350px;
    margin: 20px;

    &.footer {
      margin: 0px;
      width: 200px;
    }

    &.header {
      margin: 0px;
      height: 60px;
    }

  @media  ${mobile} {
    width: 250px;
  }`

export default ({type = "", dark = false}) => (
      <StyledImg className={type}
      alt="Korn Ferry Logo"
        src={`/static/images/logos/${dark ? 'ntuc-logo-dark' : 'ntuc-logo'}.png`}
      />
  )
  