//import R from 'ramda'

const R = require('ramda')

export const actionTypes = {
  LOAD: 'LOAD_WORK',
  LOAD_SUCCESS: 'FETCH_WORK_SUCCESS',
  UPDATE: 'FETCH_UPDATE_SUCCESS',
  PUSH_STEP: 'PUSH_STEP_START',
  PUSH_STEP_SUCCESS: 'PUSH_STEP_SUCCESS',
  PUSH_STEP_ERROR: 'PUSH_STEP_ERROR',
  OPEN_FEATURE: 'OPEN_FEATURE',
}

export const actions = {
  fetch: payload => ({ type: actionTypes.FETCH, payload }),
  load: payload => ({ type: actionTypes.LOAD, payload }),
  loadSuccess: payload => ({ type: actionTypes.LOAD_SUCCESS, payload }),
  update: payload => ({ type: actionTypes.UPDATE, payload }),
  pushStep: payload => ({ type: actionTypes.PUSH_STEP, payload }),
  pushStepSuccess: payload => ({
    type: actionTypes.PUSH_STEP_SUCCESS,
    payload,
  }),
  pushStepError: payload => ({ type: actionTypes.PUSH_STEP_ERROR, payload }),
  openFeature: payload => ({ type: actionTypes.OPEN_FEATURE, payload }),
}

const isNotNil = R.complement(R.isNil)
const isNotEmpty = R.complement(R.isEmpty)
const findCoaching = R.find(R.propEq('FeatureType', 'coaching'))
const sortByDate = R.sortWith([R.descend(R.prop('PurchaseDate'))])

export const selectors = {
  getPropertyArray: object => {
    let arr = []
    for (var property in object) {
      if (object.hasOwnProperty(property)) {
        arr.push(object[property])
      }
    }
    return arr
  },
  getLatestGoal: products => {
    let goalId = ''
    if (products) {
      R.forEach(x => {
        if (x && x.details && x.details.Steps) {
          R.forEach(y => {
            if (y.PartnerGoalID) {
              goalId = y.PartnerGoalID
            }
          }, x.details.Steps)
        }
      }, sortByDate(products))
    }
    return goalId
  },

  setGoalName: (products, goalList) => {
    R.forEach(x => {
      if (x && x.details && x.details.Steps) {
        R.forEach(y => {
          if (y.PartnerGoalID) {
            let f = R.find(R.propEq('ID', y.PartnerGoalID))(goalList)
            if (f && f.GoalOptions) {
              y.PartnerGoalName = f.GoalOptions
            }
          }
        }, x.details.Steps)
      }
    }, products)
  },

  allCoachingAreCompleted: products => {
    let arr = []
    let arrC = []

    if (products) {
      R.forEach(x => {
        if (x && x.details && x.details.Steps) {
          arr.push(findCoaching(x.details.Steps))
        }
      }, products)
    } else {
      return false
    }
    R.forEach(t => {
      if (t.CurrentStep === 'complete' || t.CurrentStep === 'nomore') {
        arrC.push(t)
      }
    }, arr)

    return arrC.length === arr.length
  },

  previousFeature: (type, work) => {
    const index = work ? R.findIndex(R.propEq('ProductFeatureCode', type), work.details.Steps) : undefined
    //const previousWork = R.gt(index, 0) ? work.details.Steps[index - 1]: work.details.Steps[0];
    const previousWork = R.gt(index, 0) ? work.details.Steps[index - 1] : undefined
    return previousWork
  },
  previousFeatureByID: (PurchasedItemKey, work) => {
    const index = work ? R.findIndex(R.propEq('PurchasedItemKey', PurchasedItemKey), work.details.Steps) : undefined
    //const previousWork = R.gt(index, 0) ? work.details.Steps[index - 1]: work.details.Steps[0];
    const previousWork = R.gt(index, 0) ? work.details.Steps[index - 1] : undefined
    return previousWork
  },
  feature: (type, work) => {
    const curStep = work ? R.findLast(R.propEq('ProductFeatureCode', type), work.details.Steps) : undefined
    return curStep
  },
  featureByType: (type, work) => {
    const curStep = work ? R.findLast(R.propEq('FeatureType', type), work.details.Steps) : undefined
    return curStep
  },
  featureByID: (PurchasedItemKey, work) => {
    const curStep = work ? R.find(R.propEq('PurchasedItemKey', PurchasedItemKey), work.details.Steps) : undefined
    return curStep
  },
  prodByFeature: (feature, products) => {
    let prods = R.values(products)
    for (var prod of prods) {
      const theFeature = R.find(R.propEq('ProductFeatureCode', feature), prod.details.Steps)
      //console.log("GOING THROUGH STEPS FOR FEATURE ", theFeature);
      if (theFeature) {
        return prod
      }
    }
  },

  coachId: R.compose(
    R.path(['AssignedCoaches', 0, 'roleUserKey']),
    R.find(
      R.where({
        Name: R.equals('assignCoach'),
        AssignedCoaches: R.both(isNotNil, isNotEmpty),
      })
    ),
    R.prop('Steps')
  ),
  resumeCoachId: R.compose(
    R.path(['AssignedCoaches', 0, 'roleUserKey']),
    R.find(
      R.where({
        Name: R.equals('assignResumeCoach'),
        AssignedCoaches: R.both(isNotNil, isNotEmpty),
      })
    ),
    R.prop('Steps')
  ),
  interviewCoachId: R.compose(
    R.path(['AssignedCoaches', 0, 'roleUserKey']),
    R.find(
      R.where({
        Name: R.equals('assignInterviewPrepCoach'),
        AssignedCoaches: R.both(isNotNil, isNotEmpty),
      })
    ),
    R.prop('Steps')
  ),
  salaryCoachId: R.compose(
    R.path(['AssignedCoaches', 0, 'roleUserKey']),
    R.find(
      R.where({
        Name: R.equals('assignSalaryCoach'),
        AssignedCoaches: R.both(isNotNil, isNotEmpty),
      })
    ),
    R.prop('Steps')
  ),
  sofiAutoCoachID: R.compose(
    R.path(['AssignedCoaches', 0, 'roleUserKey']),
    R.find(
      R.where({
        Name: R.equals('coachingCall'),
        AssignedCoaches: R.both(isNotNil, isNotEmpty),
      })
    ),
    R.prop('Steps')
  ),
  sofiRepeatCoachID: R.compose(
    R.path(['AssignedCoaches', 0, 'roleUserKey']),
    R.find(
      R.where({
        Name: R.equals('scheduleCoachingCall'),
        AssignedCoaches: R.both(isNotNil, isNotEmpty),
      })
    ),
    R.prop('Steps')
  ),
  AutoPlacementCoachID: R.compose(
    R.path(['AssignedCoaches', 0, 'roleUserKey']),
    R.find(
      R.where({
        Name: R.equals('placementcoachingCall' || 'scheduleCoachingCall1'),
        AssignedCoaches: R.both(isNotNil, isNotEmpty),
      })
    ),
    R.prop('Steps')
  ),
  AutoRepeatCoachID: R.compose(
    R.path(['AssignedCoaches', 0, 'roleUserKey']),
    R.find(
      R.where({
        Name: R.equals('scheduleCoachingCall1'),
        AssignedCoaches: R.both(isNotNil, isNotEmpty),
      })
    ),
    R.prop('Steps')
  ),
  isFeatureComplete: R.propEq('CurrentStep', 'complete'),

  nextFeature: (feature, work) => {
    const index = work && feature ? R.findLastIndex(R.eqProps('ProductFeatureCode', feature), work.details.Steps) : undefined
    return work ? work.details.Steps[index + 1] : undefined
  },
  getNavByID: (StepID, navs) => {
    let theNav = []
    R.forEach(function(x) {
      if (x.activated) {
        if (x.subs.length > 0) {
          R.forEach(function(y) {
            theNav.push(y)
            return
          }, x.subs)
        } else {
          theNav.push(x)
        }
      }
      return
    }, navs)
    //console.log('GONNA LOOK FOR ' + StepID + ' IN the Navs ', theNav);
    return R.find(R.propEq('PurchasedItemKey', StepID), theNav)
  },
  getNavTool: (feature, navs) => {
    switch (true) {
      case navs.home.ProductFeatureCode === feature:
        return navs.home
      case navs.onboarding.ProductFeatureCode === feature:
        return navs.onboarding
      case navs.resume.ProductFeatureCode === feature:
        return navs.resume
      case navs.assessment.ProductFeatureCode === feature:
        return navs.assessment
      case navs.interviewprep.ProductFeatureCode === feature:
        return navs.interviewprep
      case navs.jobs.ProductFeatureCode === feature:
        return navs.jobs
      case navs.paycheck.ProductFeatureCode === feature:
        return navs.paycheck
      case navs.careerquestionnaire.ProductFeatureCode === feature && navs.careerquestionnaire.activated:
        return navs.careerquestionnaire
      case navs.jobsearchquestionnaire.ProductFeatureCode === feature && navs.jobsearchquestionnaire.activated:
        return navs.jobsearchquestionnaire
      case navs.jobsearchquestionnaire2.ProductFeatureCode === feature && navs.jobsearchquestionnaire2.activated:
        return navs.jobsearchquestionnaire2
      case navs.actionplan.ProductFeatureCode === feature && navs.actionplan.activated:
        return navs.actionplan
      default:
        for (let sub of navs.careercoach.subs) {
          if (sub.ProductFeatureCode === feature) {
            return sub
          }
        }
        for (let sub of navs.resumecoach.subs) {
          if (sub.ProductFeatureCode === feature) {
            return sub
          }
        }
        for (let sub of navs.interviewcoach.subs) {
          if (sub.ProductFeatureCode === feature) {
            return sub
          }
        }
        for (let sub of navs.careercoach2.subs) {
          if (sub.ProductFeatureCode === feature) {
            return sub
          }
        }
        //console.log("GONNA RETURN UNDEFINED");
        return undefined
    }
  },
}

export const initialState = {}

export default function reducer(state = initialState, action) {
  let data = {}
  switch (action.type) {
    case actionTypes.LOAD:
      return {
        ...initialState,
      }
    case actionTypes.LOAD_SUCCESS: {
      data = R.unless(R.isArrayLike, R.of, action.payload.products)
      data = R.reduce(
        (acc, x) => {
          acc[x.PurchaseBundleCode] = x
          return acc
        },
        { ...state },
        data
      )
      console.log('OK HERE IN THE WORK REDUCER AND HERES THE DATA ', data)
      // const curProd =
      //   action.payload.products.length > 0
      //     ? action.payload.products[0]
      //     : undefined;
      // const currentStep = curProd
      //   ? R.find(
      //       R.propEq("ProductFeatureCode", "onboarding"),
      //       curProd.details.Steps
      //     )
      //   : undefined;
      return {
        //curProduct: curProd,
        //curStep: currentStep,
        products: data, //action.payload.products,

        //nav: action.payload.nav
      }
    }

    case actionTypes.UPDATE: {
      data = R.unless(R.isArrayLike, R.of, action.payload.products)
      data = R.reduce(
        (acc, x) => {
          acc[x.PurchaseBundleCode] = x
          return acc
        },
        { ...state },
        data
      )
      const curProd = action.payload.products.length > 0 ? action.payload.products[0] : undefined
      const currentStep = curProd ? R.find(R.propEq('ProductFeatureCode', 'onboarding'), curProd.details.Steps) : undefined
      return {
        //curProduct: curProd,
        curStep: currentStep,
        products: data, //action.payload.products,
        nav: action.payload.nav,
      }
    }
    // var data = R.unless(R.isArrayLike, R.of, action.payload);
    // return R.reduce(
    //   (acc, x) => {
    //     acc[x.UserKey] = x;
    //     return acc;
    //   },
    //   { ...state },
    //   data
    // );
    case actionTypes.PUSH_STEP_SUCCESS: {
      //var curProduct = state.products[action.payload.PurchaseBundleCode];
      //console.log('PUSH STEP SUCCESS INIT Product ', curProduct)
      const updated = action.payload
      //console.log("PUSH STEP SUCCESS WITH ", updated);
      //console.log('CurProduct is ', curProduct);

      return {
        ...state,
        //curProduct: updated.curProduct,
        products: updated.products,
      }
    }
    // var data = action.payload; //R.unless(R.isArrayLike, R.of, action.payload);
    //
    // console.log('PUSH STEP SUCCESS PAYLOAD ', data);
    // console.log('PUSH STEP SUCCESS INIT STATE ', state);
    //
    //
    // const newState = R.reduce(
    //   (acc, x) => {
    //     acc[x.UserKey] = x;
    //     return acc;
    //   },
    //   {
    //     ...state
    //    }
    // );
    // console.log('AND HERE IS THE NEW STATE',newState);
    // return newState;
    case actionTypes.PUSH_STEP:
      return {
        ...state,
      }
    case actionTypes.OPEN_FEATURE: {
      const curProduct = state.products[action.payload.PurchaseBundleCode]
      //console.log("AND THE CURRENT PRODUCT IS ", curProduct);
      const curStep = R.find(R.propEq('PurchasedItemKey', action.payload.PurchasedItemKey), curProduct.details.Steps)
      //console.log("AND THE CURSTEP IS ", curStep);
      return {
        ...state,
        curProduct,
        curStep,
      }
    }
    case actionTypes.PUSH_STEP_ERROR:
    default:
      return state
  }
}
