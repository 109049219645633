import axios from 'axios'
import humps from 'humps'
//import moment from 'moment-timezone'
//import { map } from 'ramda'
import { serialize, updateObject, getHost } from '../../shared/utility'
import { actions as actionAuth } from './auth'
// var formatResponse = record => {
//   return record
// }

// Constants

export const actionTypes = {
  GET_USER_RESUME_SUCCESS: 'GET_USER_RESUME_SUCCESS',
  GET_USER_RESUME_START: 'GET_USER_RESUME_START',
  GET_USER_RESUME_FAIL: 'GET_USER_RESUME_FAIL',
}

// Action Creators

export const actions = {
  getUserResumeSuccess: (userKey, data) => ({
    type: actionTypes.GET_USER_RESUME_SUCCESS,
    userKey,
    data,
  }),

  getUserResumeFail: error => ({
    type: actionTypes.GET_USER_RESUME_FAIL,
    error,
  }),

  getUserResumeStart: () => ({
    type: actionTypes.GET_USER_RESUME_START,
  }),

  getUserResume: (userKey, postData, queryParams) => dispatch => {
    dispatch(actions.getUserResumeStart())
    let path = `${getHost().apiBaseUrl}/resume/analysis` + (queryParams ? serialize(queryParams) : '')
    return axios(path, {
      method: 'post',
      data: postData,
      withCredentials: true,
    })
      .then(response => {
        return humps.camelizeKeys(response.data)
      })
      .then(response => {
        const theUrl = response ? response.data.pDFURL : undefined
        //response.clients = map(formatResponse, response.recent)
        if (theUrl) {
          dispatch(actionAuth.updatePurchaseStateResume('resumeUrl', theUrl))
        }
        dispatch(actions.getUserResumeSuccess(userKey, response))
      })
      .catch(error => {
        dispatch(actions.getUserResumeFail(error))
      })
  },
}

// Reducer

const defaultState = {
  loading: false,
  loaded: false,
  userKey: null,
  data: null,
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_USER_RESUME_START:
      return updateObject(state, { loading: true })
    case actionTypes.GET_USER_RESUME_SUCCESS:
      return updateObject(state, {
        loading: false,
        loaded: true,

        data: action.data,
        userKey: action.userKey,
      })
    case actionTypes.GET_USER_RESUME_FAIL:
      return updateObject(state, { loading: false })
    default:
      return state
  }
}
