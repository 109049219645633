import axios from 'axios'

import { getHost } from './utility'
import { csrfRequestInterceptor } from '../helpers/csrf.helpers';



axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response && (error.response.status === 401 || error.response.status === 404)) {
    window.location = '/'
  }

  console.error(error.response.data)
  return Promise.reject(error);
});


const _request = axios.create({
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
})

axios.interceptors.request.use(csrfRequestInterceptor)
_request.interceptors.request.use(csrfRequestInterceptor);

export const api = {
  get(url, appendHost) {
    if (appendHost) url = getHost().apiBaseUrl + url
    return _request.get(url)
  },
  post(url, postData, appendHost) {
    if (appendHost) url = getHost().apiBaseUrl + url
    return _request.post(url, postData)
  },
  put(url, postData, appendHost) {
    if (appendHost) url = getHost().apiBaseUrl + url
    return _request.put(url, postData)
  },
  delete(url, appendHost) {
    if (appendHost) url = getHost().apiBaseUrl + url
    return _request.delete(url)
  },
}
