export const languages = {
  code: {
    'en-US': 'English',
    'es-ES': 'Español',
    'pt-PT': 'Português',
    'pt-BR': 'Português',
    'de-DE': 'Deutsche',
    'fr-FR': 'Française',
    'en-GB': 'English-GB',
    'it-IT': 'Italian',
    'zh-CN': 'Chinese',
    'ja-JP': 'Japanese',
    'ko-KR': 'Korean',
    'nl-NL': 'Dutch',
    
    'en': 'English',
    'es': 'Español',
    'pt': 'Português',
    'de': 'Deutsche',
    'fr': 'Française',
    'it': 'Italian',
    'zh': 'Chinese',
    'ja': 'Japanese',
    'ko': 'Korean',
    'nl': 'Dutch'
  }
}

/* Note that when 'en' is sent by itself, there is no way to 
distinguish between 'en-US' and 'en-GB' */

