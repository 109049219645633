export const actionTypes = {
  GET_CONTENT_SUCCESS: 'GET_CONTENT_SUCCESS',
}

// Action Creators

export const actions = {
  getContentSuccess: (data, language) => {
    const result = {...data, language}

    return ({
      type: actionTypes.GET_CONTENT_SUCCESS,
      result,
    });
  },
}

// Reducer

const defaultState = {
  loading: false,
  coaches: {},
  error: null,
  loaded: false,
}

export let contentState = {};

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_CONTENT_SUCCESS:
      const theData = action.result;
      contentState = theData;
      
      return { ...theData, loading: false, loaded: true }
    default:
      return state
  }
}
