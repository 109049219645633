// Action Types

import humps from 'humps'
import { serialize, api, updateObject, getHost, formatDateLong, formatCurrentDateTime, getContent } from '../../shared/utility'
import { actions as actionUserProducts } from './user-product'
import { actions as actionUserProfile } from './user-profile'
import { actions as actionUserResume } from './user-resume'
import { actions as actionAuth } from './auth'
import { logEvent } from '../../shared/analytics'
import { analyticsTypes, analyticsRoutes} from '../../constants/analytics';
import { actions as actionToaster } from './toaster'

const R = require('ramda')

//@todo:extract elsewhere
export const helpers = {
  documentUploader(fileName, file, contentType, fileTypeCode, fileCategory, userKey) {
    let theURL = getHost().upload.documents + userKey

    theURL += `?fileName=${fileName}&fileTypeCode=${fileTypeCode}&fileCategory=${fileCategory}&contentType=${contentType}`

    return api.put(theURL, file)
  },
}
export const actionTypes = {
  GET_USER_DOCS_SUCCESS: 'GET_USER_DOCS_SUCCESS',
  GET_USER_DOCS_START: 'GET_USER_DOCS_START',
  GET_USER_DOCS_FAIL: 'GET_USER_DOCS_FAIL',
  UPLOAD_USER_DOCS_START: 'UPLOAD_USER_DOCS_START',
  UPLOAD_USER_DOCS_FAIL: 'UPLOAD_USER_DOCS_FAIL',
  UPLOAD_USER_DOCS_SUCCESS: 'UPLOAD_USER_DOCS_SUCCESS',

}

// Action Creators

export const actions = {
  getUserDocsSuccess: data => ({ type: actionTypes.GET_USER_DOCS_SUCCESS, data }),
  getUserDocsFail: error => ({ type: actionTypes.GET_USER_DOCS_FAIL, error: error }),
  getUserDocsStart: () => ({ type: actionTypes.GET_USER_DOCS_START }),

  uploadUserDocsSuccess: data => ({ type: actionTypes.UPLOAD_USER_DOCS_SUCCESS, data }),
  uploadUserDocsFail: error => ({ type: actionTypes.UPLOAD_USER_DOCS_FAIL, error: error }),
  uploadUserDocsStart: (files, fileTypeDetails) => ({ type: actionTypes.UPLOAD_USER_DOCS_START, files, fileTypeDetails }),

  uploadUserDocs: (userKey, postData) => {
    return (dispatch, getState) => {
      //console.log('uploadUserDocs FROM ACTION', userKey, postData)
      const { content } = getState()
      const file = postData.files[0]
      let fileName = file.name
      const arrayName = file.name.split('.')
      if (arrayName && arrayName.length > 1) {
        fileName = arrayName[0] + '-' + formatCurrentDateTime() + '.' + arrayName[1]
      }
      //console.log('1111', fileName)
      const contentType = file.type
      const { fileTypeCode, fileCategory } = postData.fileTypeDetails
      const { userProduct } = postData
      //console.log(fileName, contentType, fileTypeCode, fileCategory, userKey)
      logEvent('event', analyticsTypes.upload, 'upload-start-' + fileCategory, '', analyticsRoutes.intercom )

      dispatch(actions.uploadUserDocsStart(postData.files, postData.fileTypeDetails))
      dispatch(actionAuth.uploadStartStop(true))
      helpers
        .documentUploader(fileName, file, contentType, fileTypeCode, fileCategory, userKey)
        .then(response => {
          return humps.camelizeKeys(response.data)
        })
        .then(async data => {
          console.log('UPLOAD RESPONSE', data)
          await dispatch(actions.getUserDocs(userKey))
          dispatch(actions.uploadUserDocsSuccess(data, postData))
          logEvent('event', analyticsTypes.upload, 'upload-done-' + fileCategory, '', analyticsRoutes.intercom)
          switch (fileCategory) {
            case 'ActionPlan': //need to advance a step
              const firstProduct = userProduct && userProduct.data && userProduct.data.length > 0 ? userProduct.data[0] : undefined
              if (firstProduct && firstProduct.steps) {
                let actionPlanStep = R.findLast(R.propEq('productFeatureCode', 'singaporecareercoaching'), firstProduct.steps)
                actionPlanStep = actionPlanStep ? actionPlanStep : R.findLast(R.propEq('productFeatureCode', 'singaporecareersearchcoaching'), firstProduct.steps)
                actionPlanStep = actionPlanStep
                  ? actionPlanStep
                  : R.findLast(R.propEq('productFeatureCode', 'singaporecareeracceleratecoaching2'), firstProduct.steps)
                if (actionPlanStep) {
                  let theData = {
                    PurchaseBundleCode: firstProduct.purchaseBundleCode,
                    PurchasedItemKey: actionPlanStep.purchasedItemKey,
                    Submit: 'actionplan',
                    CurrentStep: actionPlanStep.currentStep,
                    Action: actionPlanStep.action ? actionPlanStep.action : actionPlanStep.productFeatureCode,
                    delegate: true,
                    UserKey: actionPlanStep.stepResponsible,
                  }
                  dispatch(actionUserProducts.pushStep(theData))
                  //dispatch(actionUserProfile.uploadActionPlanSuccess(data))
                  dispatch(actionUserProfile.getUserProfile(userKey))
                }
              }
              break
            case 'general':
              dispatch(actionUserProfile.getUserProfile(userKey))

              const purchState = postData.purchState
              const resume = purchState && purchState.resume ? purchState.resume : undefined
              const resumeReturn = {
                pDFURL: data.pDFURL,
                data: {
                  analysis: data.analysis,
                  resume: data.resume,
                },
              }
              dispatch(actionUserResume.getUserResumeSuccess(resume.userKey, resumeReturn))
              resume.pushSteps.forEach(payload => {
                dispatch(actionUserProducts.pushStep(payload, resumeReturn.pDFURL))
              })
              dispatch(actionAuth.updatePurchaseStateResume('resumeUrl', resumeReturn.pDFURL))
              dispatch(actionAuth.updatePurchaseStateResume('uploadDate', new Date()))
              dispatch(actionAuth.uploadStartStop(false))
              break
            default:
              break
          }
        })
        .catch(error => {
          console.error(error)
          dispatch(actions.uploadUserDocsFail(error.response))
          dispatch(actionToaster.showError(getContent('Global.Message.ErrorUploadingResume', content)))
          dispatch(actionAuth.uploadStartStop(false))
        })
    }
  },
  getUserDocs: (userKey, queryParams) => {
    queryParams = queryParams || { ctx: 'docs' }
    return dispatch => {
      dispatch(actions.getUserDocsStart())
      let path = `/user/${userKey}${queryParams ? '?' + serialize(queryParams) : ''}`

      return api
        .get(path, true)
        .then(response => {
          return humps.camelizeKeys(response.data)
        })
        .then(data => {
          return [...data.resumes, ...data.reports].map(item => {
            item.formattedCreated = formatDateLong(item.created)
            //try to get file Name
            if (item.url) {
              try {
                let path = item.url.split('/')
                item.fileName = '...' + path[path.length - 1].split('?')[0].substr(-20)
              } catch (e) {
                //all resumes will show this
                //@todo P4: show resume file name
                item.fileName = 'Preview'
              }
            }

            return item
          })
        })
        .then(data => {
          dispatch(actions.getUserDocsSuccess(data))
        })
        .catch(error => {
          console.error(error)
          dispatch(actions.getUserDocsFail(error.response))
        })
    }
  },

}

// Reducer
const defaultState = {
  loading: false,
  loaded: false,
  uploading: false,
  data: null,
  error: null,
  fileTypeDetails: null,
  files: [],
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_USER_DOCS_START:
      return updateObject(state, {
        loading: true,
      })
    case actionTypes.GET_USER_DOCS_SUCCESS:
      return updateObject(state, {
        loading: false,
        loaded: true,
        data: action.data,
      })
    case actionTypes.GET_USER_DOCS_FAIL:
      return updateObject(state, {
        loading: false,
        error: action.error,
      })

    case actionTypes.UPLOAD_USER_DOCS_START:
      return updateObject(state, {
        uploading: true,
        files: action.files,
        fileTypeDetails: action.fileTypeDetails,
      })
    case actionTypes.UPLOAD_USER_DOCS_SUCCESS:
      return updateObject(state, {
        uploading: false,
        files: [],
        fileTypeDetails: null,
      })
    case actionTypes.UPLOAD_USER_DOCS_FAIL:
      return updateObject(state, {
        uploading: false,
        error: action.error,
      })
    default:
      return state
  }
}
