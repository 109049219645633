import { getHost } from "./utility";
import axios from "axios";

export const getCombined = async (routes = []) => {
  const queryString = encodeURIComponent(JSON.stringify(routes));
  const basePath = `${getHost().apiBaseUrl}/combine`
  const combinedGetApiUrl = `${basePath}?routes=${queryString}`;
  
  return (await axios.get(combinedGetApiUrl, {
    withCredentials: true
  })).data;
}