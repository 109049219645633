import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { withTheme } from 'styled-components';
import Avatar from 'react-avatar'
import Toolbar from '@material-ui/core/Toolbar'
import { getHost } from '../../shared/utility'
import TopMenu from '../TopMenu';
import SiteDrawer from './SiteDrawer'
import IconButton from '@material-ui/core/IconButton'
import { actions as chatActions } from '../../redux/modules/chat'
import { actions as iotActions } from '../../redux/modules/mqtt'
import { styles, StyledAppBar, StyledMenuIcon, HeaderAltTextContainer } from './styles';
import { logEvent } from '../../shared/analytics';
import { analyticsTypes, analyticsActions } from '../../constants/analytics';
import ProductGrid from '../../assets/icons/ProductGrid'


class MenuAppBar extends React.Component {
  state = {}
  static getDerivedStateFromProps(props, state) {
    return null
  }

  isAllowed = (path) => {
    if (!path) return true;
    const {access, loaded} = this.props;
    if (loaded && access[path] === 'allowed') return true;
    return false;
  }

  componentDidMount() {
    this._listen()
    //userKey ? mqttSubscribe(userKey) : undefined
  }

  _listen(){
    const self = this
    const {user, MqttClient, getSingleChatter} = self.props
    const userKey = user && user.UserKey ? user.UserKey : undefined
    getSingleChatter(userKey)
    self._loadChatStatus((status)=>{
      MqttClient.connect(()=>{
        //console.log("MqttClient CONNECTED", status)
        self.props.updateMqttConnectionStatus(true);
        if( userKey){
          MqttClient.subscribe(userKey)
        }
      }, (topic, message)=>{
        //console.log("->", topic)
        self.props.iotReceived(message)
      },()=>{},()=>{
        MqttClient.end()
        self._listen()
        self.props.updateMqttConnectionStatus(false);
      })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.props.statusLoaded && !prevProps.statusLoaded) {
    //   //after status call comples
    //   //console.log('LOADING MESSAGES')
    //   this._loadMessages(this.props.user.UserKey)
    // }
  }

  _loadChatStatus = (status) => {
    this.props.getChatStatus(status)
  }
  // _loadMessages = userKey => {
  //   if (userKey) this.props.getTopicUserChat(userKey)
  // }

  render() {
    const { assets, isAuthenticated, accountNavLinks, user, classes, toolbarNavLinks, history, c, purchState, content, resourceGroups, theme} = this.props
    if (!isAuthenticated) return null
   // const userKey = user && user.UserKey ? user.UserKey : null
    const userName = user ? user.FirstName + ' ' + user.LastName : '????'
    const avatarPath = user ? user.avatarPath : ''
    let path = `${getHost().imageBaseUrl + avatarPath}`
    const LogoComponent = assets.LogoComponent;
    const rolesMenu = resourceGroups?.rolesMenu || [];
    
    return (

      <StyledAppBar id="main-header" position="static" className="topnav-container">
        <Toolbar className={classes.topnav} id="appbar-toolbar">
          <div className="mainMenu">
            <div className={classes.barContainerFull}>
              <Link to="/" 
                    style={{textDecoration: 'none'}}
                    onClick={e => logEvent('event', analyticsTypes.icon, analyticsActions.navigate, {source: 'Home Logo'})}
                    className={classes.barLeftLogo} >
                {assets.headerShowAlternateText ? 
                  <HeaderAltTextContainer>{c('Global.Header.AlternateText')}</HeaderAltTextContainer> :       
                  <LogoComponent type="header" />
                }
              </Link>
              <TopMenu styles={{ padding: 0, marginTop: 0, minWidth: 177 }}
                  selected={{ label: <Avatar name={userName} src={path} size={40} alt="user avatar"/>, name: userName }}
                  content={content}
                  assets={assets}
                  c={c}
                  history={history} />
            </div>
          </div>

          <div className="mobileMenu" id="mobile-menu">
            
              <IconButton onClick={e => {
                        this.setState({ isOpen: !this.state.isOpen, anchorEl: e.currentTarget });
                        logEvent('event', analyticsTypes.icon, analyticsActions.toggle, {source: 'Left Menu'})
                    }}
                    className={classes.barButton}  color="inherit" aria-label="Menu">
                <StyledMenuIcon />
              </IconButton>

              <Link to="/" 
                    onClick={e => logEvent('event', analyticsTypes.icon, analyticsActions.navigate, {source: 'Home Logo'})}
                    className={classes.barLogo}
                    style={{textDecoration: 'none'}} >
                {assets.headerShowAlternateText ? 
                  <HeaderAltTextContainer>{c('Global.Header.AlternateText')}</HeaderAltTextContainer> :       
                  <LogoComponent type="header" />
                }

              </Link>

              {rolesMenu?.length ?
               <IconButton onClick={e => {
                        this.setState({ isRoleOpen: !this.state.isRoleOpen, anchorEl: e.currentTarget });
                        logEvent('event', analyticsTypes.icon, analyticsActions.toggle, {source: 'Right Menu'})
                    }}
                    className={classes.barButton}  color="inherit" aria-label="Menu">
               <ProductGrid Color={theme.menuTextColor}/>
              </IconButton>
              : 
              <div className={classes.space}></div>
              }
 

            
        

              <SiteDrawer
                purchState={purchState}
                history={history}
                assets={assets}
                classes={classes}
                isOpen={this.state.isOpen}
                toolbarNavLinks={toolbarNavLinks.filter(obj => this.isAllowed(obj.resource))}
                accountNavLinks={accountNavLinks.filter(obj => this.isAllowed(obj.resource))}
                close={() => this.setState({ isOpen: !this.state.isOpen })}
                content={content}
                type="menu"
                c={c}
              />

               <SiteDrawer
                purchState={purchState}
                history={history}
                assets={assets}
                classes={classes}
                isOpen={this.state.isRoleOpen}
                toolbarNavLinks={toolbarNavLinks.filter(obj => this.isAllowed(obj.resource))}
                accountNavLinks={accountNavLinks.filter(obj => this.isAllowed(obj.resource))}
                close={() => this.setState({ isRoleOpen: !this.state.isRoleOpen })}
                content={content}
                rolesMenu={rolesMenu}
                type="role-menu"
                c={c}
              />
          </div>
        </Toolbar>
      </StyledAppBar>

    )
  }
}

const mapStateToProps = state => ({
    iot: state.chat && state.chat.iot,
    iotClient: state.mqtt && state.mqtt.client,
    unread: state.chat && state.chat.unread,
    isOnline: state.chat && state.chat.isOnline,
    statusLoaded: state.chat && state.chat.statusLoaded,
    subs: state.chat && state.chat.subs,
    access: state.access.access,
    loaded: state.access.loaded,
    resourceGroups:  state.access?.resourceGroups
})

const mapDispatchToProps = dispatch => ({
    // mqttConnect2: mqttEndPont => dispatch(iotActions.mqttConnect2(mqttEndPont)),
   // mqttSubscribe: channel => dispatch(iotActions.mqttSubscribe(channel)),
    getChatStatus: (status) => dispatch(chatActions.getChatStatus(status)),
    getSingleChatter: userKey => dispatch(chatActions.getSingleChatter(userKey)),
    getTopicUserChat: userKey => dispatch(chatActions.getTopicUserChat(userKey)),
    iotReceived: (message) => dispatch(iotActions.iotReceived( message)),
    updateMqttConnectionStatus : message => dispatch(iotActions.updateMqttConnectionStatus(message))
})

// prettier-ignore
export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MenuAppBar)));
