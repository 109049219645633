import actionTypes from './actionTypes';

export const defaultState = {
  loading: false,
  error: null,
  loaded: false,
  access: null,
  resources: [],
  resourceGroups: [],
  formattedResources: {},
  formattedResourceGroups: {},
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case actionTypes.Access_setAccess: {
      const {access, resources, resourceGroups, formattedResources, formattedResourceGroups} = action.payload;
      return {...state, access, resources, resourceGroups, formattedResources, formattedResourceGroups, loading: false, loaded: true, error: null};
    }

    case actionTypes.Access_apiStart: 
      return {...state, loading: true, loaded: false, error: null};

    case actionTypes.Access_apiSuccess: 
      return {...state, loading: false, loaded: true, error: null};

    case actionTypes.Access_apiFail: 
      return {...state, loading: false, loaded: false, error: action.payload.error};
    
    default:
      return state;
  }
}