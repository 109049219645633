import styled from 'styled-components';
import * as FontAwesome from 'react-icons/lib/fa'
import { md, sm } from '../../styles/media-queries';

export const StyledChevron = styled(FontAwesome.FaChevronDown)`
  font-size: 1rem;
  margin-top: -4px;
  display: inline-block;
  margin-left: 0.7rem;
  opacity: 0.9;
  color: ${props => props.theme.toolbarTextColor};
`
export const StyledContainer = styled.div`

  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media ${md} {
    min-width: 20rem;
    text-align: right;
  }
  .userName {
    letter-spacing: 1px;
    color: ${props => props.theme.toolbarTextColor};
    padding: 0 20px 0 0;
    display: inline;
  }

  .productName {
    letter-spacing: 1px;
    color: ${props => props.theme.toolbarTextColor};
    font-size: 0.9rem;
  }

  .line {
    height: 1px;
    background: #979797;
  }
  .dropdown {
    @media ${md} {
      position: relative;
    }
    display: flex;
    padding: 20px;
    font-size: 16px;
    a {
      cursor: pointer;
      color: ${props => props.theme.menuTextColor};
      font-size: 18px;
    }

    .dropdown-content {
      list-style: none;
      margin: 0;
      padding: 0 0.25rem;
      display: block;
      position: absolute;
      background: ${props => props.theme.lightColor};
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 2;
      height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;
      top: 69px;
      left: 0;
      right: 0;
      @media ${sm} {
        //iphone landscape
        height: auto;
        max-height: 80vh;
        right: initial;
      }
      @media ${md} {
        height: auto;

        top: 55px;
        right: 0;
        left: initial;
        background: ${props => props.theme.darkColor};
      }

      li.action {

         padding-right: 40px;
      }


      li.selected {
       // background: ${props => props.theme.lightColorDisabled};

        opacity: 0.6;
        a { cursor: not-allowed;
          &:hover {
            color: white;
          }
        }
      }

      li.divider {
        background: ${props => props.theme.lightColor};
        display: block;

              a { cursor: default;
              &:hover {
                color: white;
              }
            }
         
      }

      li {
        padding: 0.5rem 0;
        border-top: solid 1px #c4c4c4;
        @media ${md} {
          padding: 0 1rem;
        }

      


        a {
          color: ${props => props.theme.menuTextColor};
          font-size: 20px;
          width: 100%;
          text-decoration: none;
          display: block;
          padding: 0.8rem 1.5rem;

          @media ${md} {
            font-size: 18px;
            padding: 10px;
          }
          & .count {
            font-size: 0.8em;
            display: inline-block;
            margin-left: 10px;
            opacity: 0.5;
          }

          &:hover {
            color: ${props => props.theme.menuHoverColor};
          }
        }

        
      }
    }
  }
`