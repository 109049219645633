import React from 'react'
import styled from 'styled-components'
import { mobile } from '../../styles/media-queries'


const StyledContainer = styled.div`
  background-color: #000000;
  margin: 15px;

  img {
    height: 55px;
    padding: 10px;
    margin: 0 20px;
  }

  &.footer {
    margin: 0;
  }
  &.header {
    margin: 0;
  }

  img.footer {
    margin: 0 0;
    height: 43px;
    padding:0 ;
  }
  img.header {
    margin: 0 0;
    height: 50px;
    padding:0;
  }


  @media  ${mobile} {
    margin: 20px auto;
    img {
      width: 50px;
      padding: 10px;
      margin: 0 0;

    }

    &.footer {
      margin: 0;
    }
    &.header {
      margin: 0;
    }

    img.footer {
      margin: 0;
      height: 50px;
      padding:0;
    }
    img.header {
      margin: 0;
      height: 50px;
      padding:0;
    }
  }`

export default ({ type = "", dark = false }) => (
  <StyledContainer className={type}>
    <img className={type}
      src={`/static/images/logos/${dark ? 'uom_logo_dark' : 'uom_logo'}.png`}
      alt="UM Logo"
    />
  </StyledContainer>
)
