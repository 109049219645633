// @flow
import { connect } from 'react-redux'
import { actions } from '../../redux/modules/auth'
import ConsentModal from '../../components/Modals/ConsentModal'
import { getContent } from '../../shared/utility'

const mapStateToProps = ({ auth, content, settings }) => {
    const { isConsentOpen, loading, user, entityContext } = auth
    return {
      loading,
      isOpen: isConsentOpen,
      c: key => getContent(key, content),
      partnerKey: user && user.PartnerKey ? user.PartnerKey : '',
      entityContext,
      showMarketingConsent: settings ? settings.showMarketingConsent : false,
      showPartnerConsent: settings ? settings.showPartnerConsent : false,
      consentForPrivacy: user && user.ConsentForPrivacy ? user.ConsentForPrivacy : false,
      consentForMarketing: user && user.ConsentForMarketing ? user.ConsentForMarketing : false,
      consentForPartner: user && user.ConsentForPartner ? user.ConsentForPartner : false,
    }
  }

const mapDispatchToProps = dispatch => ({
  onAccept: (data) => dispatch(actions.updateConsent(data)),
  onDecline: () => dispatch(actions.logout()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsentModal)
