import axios from 'axios';

import { updateObject, api, getHost } from '../../shared/utility'
import {fromJS} from 'immutable';
import { actions as actionToaster } from './toaster'
import { find, propEq, prop, sortWith, descend, ascend } from 'ramda'

// Constants

export const actionTypes = {
  GET_DATASET_DEF_SUCCESS: 'GET_DATASET_DEF_SUCCESS',
  GET_LOCATION_SUCCESS: 'GET_LOCATION_SUCCESS',
  CLEAR_LOCATIONS: 'CLEAR_LOCATIONS',
  GET_JOBS_SUCCESS: 'GET_JOBS_SUCCESS',
  LOCATION_SELECTED: 'LOCATION_SELECTED',
  FEATURE_CLICKED: 'FEATURE_CLICKED',
  CLOSE_POPUP: 'CLOSE_POPUP',
  GET_STYLE_SUCCESS: 'GET_STYLE_SUCCESS',
  REMOVE_LAYER_SUBCAT: 'REMOVE_LAYER_SUBCAT',
  SELECT_SCENARIO: 'SELECT_SCENARIO',
  GET_SCENARIOS_SUCCESS: 'GET_SCENARIOS_SUCCESS',
  SAVE_SCENARIO_SUCCESS: 'SAVE_SCENARIO_SUCCESS',
  GET_JOBS_FROM_LOCATION_START: 'GET_JOBS_FROM_LOCATION_START',
  GET_JOBS_FROM_LOCATION_SUCCESS: 'GET_JOBS_FROM_LOCATION_SUCCESS',
  GET_JOB_SUCCESS: 'GET_JOB_SUCCESS',
  BACK_TO_JOBS_LIST: 'BACK_TO_JOBS_LIST',
  SEARCH_JOBS_SUCCESS: 'SEARCH_JOBS_SUCCESS',
  UPDATE_LAYER_FILTERS: 'UPDATE_LAYER_FILTERS',
  SET_CP_VIEW: 'SET_CP_VIEW',
  UPDATE_JOB_RATING: 'UPDATE_JOB_RATING',
  ADD_CLUSTER_PATHWAYS_SUCCESS: 'ADD_CLUSTER_PATHWAYS_SUCCESS',
  ADD_CLUSTER_PATHWAYS_START: 'ADD_CLUSTER_PATHWAYS_START',
  SET_SCENARIO_FOR_DELETE: 'SET_SCENARIO_FOR_DELETE',
  RESET_SCENARIO_DELETES: 'RESET_SCENARIO_DELETES',
  DELETE_SCENARIO_SUCCESS: 'DELETE_SCENARIO_SUCCESS',
  ADD_NEW_SCENARIO: 'ADD_NEW_SCENARIO',
  REMOVE_CATEGORY_FROM_FILTER: 'REMOVE_CATEGORY_FROM_FILTER',
  ADVANCE_WIZARD: 'ADVANCE_WIZARD',
  ADD_LAYER_SUBCAT: 'ADD_LAYER_SUBCAT',
  GOT_COMMUTE_LAYER: 'GOT_COMMUTE_LAYER',
  TEST_RENDER_SUCCESS: 'TEST_RENDER_SUCCESS',
  REMOVE_LOCAL_LAYERS: 'REMOVE_LOCAL_LAYERS',
  SEARCH_JOBS_LIST_SUCCESS: 'SEARCH_JOBS_LIST_SUCCESS',
}

// Action Creators

const getCategoryString = scenario => {
  let cats = ''
  scenario.settings?.categories?.forEach(cat => {
    if (cat.subCats?.length > 0) {
      cat.subCats.forEach(sub => {
        if (sub.selected) {
          cats += sub.subcategorycode + ','
        }
      })
      cats = cats.substring(0, cats.length - 1)
    }
  })
  if (cats !== '') {
    cats = '&subcategory=' + cats
  }
  return cats
}
export const actions = {
  testRender: (param) => dispatch => {
    let path = `https://jobsapi.kfadvance-dev.com/api/test/${param}`
    axios.get(path)
      .then(response => {
        console.log('GOT TEST ', response)
        dispatch(actions.testRenderSuccess(response.data))
      })
      .catch(error => {
        console.log('>>> GET TEST RENDER API FAILED:', error)
      })
  },
  testRenderSuccess: data => ({
    type: actionTypes.TEST_RENDER_SUCCESS,
    data,
  }),
  getDataSetDef: () => dispatch => {
    let path = 'https://jobsapi.kfadvance-dev.com/api/dataset'
    axios.get(path)
      .then(response => {
        console.log('GOT DATASET ', response)
        dispatch(actions.getDatasetCategories(response.data))
      })
      .catch(error => {
        console.log('>>> GET DATA DEF API FAILED:', error)
      })
  },
  getDatasetCategories: (datasets) => dispatch => {
    datasets.forEach(dataset => {
      let path = `https://jobsapi.kfadvance-dev.com/api/dataset/${dataset.datasetkey}/categories`
      axios.get(path)
        .then(response => {
          console.log('GOT DATASET CATEGORIES', response)
          const dataCats = {
            ...dataset,
            categories: response.data.map(cat => {
              return {
                ...cat,
                datasetkey: dataset.datasetkey,
              }
            }),
          }
          dispatch(actions.getDatasetDefSuccess(dataCats))
          dispatch(actions.getMainStyle(styleDefs[0]))
        })
        .catch(error => {
          console.log('>>> GET DATA CATS API FAILED:', error)
        })
    })

  },
  addClusterPathways: ( cluster) => dispatch => {

    let path = `https://jobsapi.kfadvance-dev.com/api/dataset/${cluster.datasetkey}/categories/${cluster.categorycode}`
    axios.get(path)
      .then(response => {
        console.log('GOT SUPER CAGTEGORIES ', response)
        const theResponse = {
          categorycode: cluster.categorycode,
          subCats: response.data
        }
        console.log('SUPER CAT RESPONSE ', theResponse)
        dispatch(actions.addClusterPathwaysSuccess(theResponse))
      })
      .catch(error => {
        console.log('>>> CLUSTER PATHWAYS API FAILED:', error)
      })
  },
  addClusterPathwaysStart: data => ({
    type: actionTypes.ADD_CLUSTER_PATHWAYS_START,
    data,
  }),
  addClusterPathwaysSuccess: data => ({
    type: actionTypes.ADD_CLUSTER_PATHWAYS_SUCCESS,
    data,
  }),
  removeCategoryFromFilter: data => ({
    type: actionTypes.REMOVE_CATEGORY_FROM_FILTER,
    data,
  }),
  getDatasetDefSuccess: payload => ({
    type: actionTypes.GET_DATASET_DEF_SUCCESS,
    payload,
  }),
  getLocationSuccess: (response, homeType) => ({
    type: actionTypes.GET_LOCATION_SUCCESS,
    response,
    homeType,
  }),
  setCpView: payload => ({
    type: actionTypes.SET_CP_VIEW,
    payload,
  }),
  locationSelected: (long, lat, location, homeType) => ({
    type: actionTypes.LOCATION_SELECTED,
    long,
    lat,
    location,
    homeType,
  }),
  featureClicked: payload => ({
    type: actionTypes.FEATURE_CLICKED,
    payload,
  }),
  updateJobRating: payload => ({
    type: actionTypes.UPDATE_JOB_RATING,
    payload,
  }),
  closePopup: payload => ({
    type: actionTypes.CLOSE_POPUP,
    payload,
  }),
  getJobs: (data) => dispatch => {
    dispatch(actions.getJobsSuccess(data))
  },
  backToJobsList: () => ({
    type: actionTypes.BACK_TO_JOBS_LIST,
  }),
  getJobsSuccess: data => ({
    type: actionTypes.GET_JOBS_SUCCESS,
    data,
  }),
  clearLocations: () => ({
    type: actionTypes.CLEAR_LOCATIONS,
  }),
  removeLayerSubcat: (data) => ({
    type: actionTypes.REMOVE_LAYER_SUBCAT,
    data,
  }),
  addLayerSubcat: (subcategory, category) => ({
    type: actionTypes.ADD_LAYER_SUBCAT,
    subcategory,
    category,
  }),
  updateLayerFilters: () => ({
    type: actionTypes.UPDATE_LAYER_FILTERS,
  }),
  getMainStyle: (styleDef) => dispatch => {
    ///styles/v1/{username}/{style_id}
    let path = `https://api.mapbox.com/styles/v1/steinhot/${styleDef.id}?access_token=${getHost().mapBox}`
    axios.get(path)
      .then(response => {
        const theReturn = {
          ...response,
          currentStyleDef: styleDef,
        }
        dispatch(actions.getStyleSuccess(theReturn))
      })
      .catch(error => {
        console.log('>>> MAPS MAIN STYPE API FAILED:', error)
      })
  },
  getStyleSuccess: payload => ({
    type: actionTypes.GET_STYLE_SUCCESS,
    payload,
  }),

  getLocation: (searchString, type) => dispatch => {
    let path = `https://api.mapbox.com/geocoding/v5/mapbox.places/` +
    JSON.stringify(searchString)
    + `.json?types=${type}&country=us&access_token=`
    + `pk.eyJ1Ijoic3RlaW5ob3QiLCJhIjoiY2p2NnRqdmNjMDh5YjQ0czF1czUwd24xMiJ9.NZHLDlR4AQZpdx9ApcvuNg`
    axios.get(path)
      .then(response => {
        const theReturn = {
          ...response.data,
          features: response.data.features.map(feat => (
            {...feat,
              homeType: type,
            }
          ))
        }
        dispatch(actions.getLocationSuccess(theReturn, type))
      })
      .catch(error => {
        console.log('>>> GET LOCATION API FAILED:', error)
      })
  },
  getReverseLocation: (long, lat) => dispatch => {
    let path = `https://api.mapbox.com/geocoding/v5/mapbox.places/` + long + `,` + lat + `.json` +
    `?access_token=`
    + `pk.eyJ1Ijoic3RlaW5ob3QiLCJhIjoiY2p2NnRqdmNjMDh5YjQ0czF1czUwd24xMiJ9.NZHLDlR4AQZpdx9ApcvuNg`
    axios.get(path)
      .then(response => {
        console.log('>>> AND THE RESPONSE FOR reverse MAPBOX IS ', response)
        //dispatch(actions.getLocationSuccess(response.data))
      })
      .catch(error => {
        console.log('>>> GET REVERSE LOCATION API FAILED:', error)
      })
  },
  getComuteDistance: (long, lat, scenariokey) => dispatch => {

    // let path = `https://api.mapbox.com/isochrone/v1/mapbox/driving/${long},${lat}?`
    // + `contours_minutes=45&contours_colors=4286f4&polygons=true`
    // + `&access_token=${getHost().mapBox}`

    let path = `https://jobsapi.kfadvance-dev.com/api/scenario/${scenariokey}/isochrone?minutes=45`
    axios.get(path)
      .then(response => {
        console.log('>>> AND THE COMPUTE DISTANCE MAPBOX IS ', response)
        dispatch(actions.gotCommuteLayer(response.data))
      })
      .catch(error => {
        console.log('>>> GET REVERSE LOCATION API FAILED:', error)
      })
  },
  gotCommuteLayer: data => ({
    type: actionTypes.GOT_COMMUTE_LAYER,
    data,
  }),
  getScenarios: (userKey) => dispatch => {
    let path = `https://jobsapi.kfadvance-dev.com/api/scenario`
    axios.get(path)
      .then(response => {
        console.log('>>> AND THE RESPONSE FOR getScenario IS ', response)
        dispatch(actions.getScenariosSuccess(response.data))
        //response.data.length > 0 && dispatch(actions.selectScenario())
        if (response.data.length > 0) {
          dispatch(actions.getSelectedScenario(response.data[0]))
        }

      })
      .catch(error => {
        console.log('>>> GET SCENARIOS API FAILED:', error)
      })
  },
  getSelectedScenario: (scenario, forJobs) => dispatch => {
    console.log('GET SELECTED SCENARIO WITH ', scenario)
    const itzJobs = true ? '/jobs' : ''
    let path = `https://jobsapi.kfadvance-dev.com/api/scenario/${scenario.scenariokey}${itzJobs}`
    console.log('GETTING SELECTED SCEDNAARIO WIATH ', path)
    axios.get(path)
      .then(response => {
        console.log('>>> AND THE RESPONSE FOR SELEACTAED SCENARIO IS ', response)
        let newScenario = {
          ...scenario,
          jobs: response.data.jobs,
        }
        if (!forJobs) {

          dispatch(actions.selectScenario(newScenario))
          //dispatch(actions.updateLayerFilters())
        }

      })
      .catch(error => {
        console.log('>>> GET SELECTED SCENARIO API FAILED:', error)
      })
  },
  selectScenario: payload => ({
    type: actionTypes.SELECT_SCENARIO,
    payload,
  }),
  addNewScenario: () => ({
    type: actionTypes.ADD_NEW_SCENARIO,
  }),
  saveScenario: () => (dispatch, getState) => {
      let path = `https://jobsapi.kfadvance-dev.com/api/scenario`
      const {maps} = getState()
      let postData = {
        scenariokey: maps.selectedScenario.scenariokey,
        longitude: maps.selectedScenario.longitude,
        latitude: maps.selectedScenario.latitude,
        address: maps.selectedScenario.address,
        hometype: maps.selectedScenario.homeType,
        settings: {
          ...maps.selectedScenario.settings,
          commuteTime: maps.selectedScenario.settings.commuteTime,
        }
      }
      console.log('SAVING SCENARIO WITH PAYLOAD ', postData)
      return axios(path, {
        method: 'post',
        data: postData,
      })
        .then(response => {
          console.log('AND  SAVE SCENARIO SCUUSSESS ', response)
          dispatch(actions.saveScenarioSuccess(response.data))
        })
        .catch(error => {
          console.error(error)
          //dispatch(actions.saveUserProfileFail(error.response))
        })

  },
  setScenarioForDelete: scenario => ({
    type: actionTypes.SET_SCENARIO_FOR_DELETE,
    scenario,
  }),
  resetScenarioDeletes: () => ({
    type: actionTypes.RESET_SCENARIO_DELETES
  }),
  deleteScenario: scenariokey => dispatch => {
    let path = `https://jobsapi.kfadvance-dev.com/api/scenario/${scenariokey}`
    return axios(path, {
      method: 'delete',
    })
      .then(response => {
        console.log('AND  DELETE SCENARIO SCUUSSESS ', response)
        dispatch(actions.deleteScenarioSuccess(scenariokey))
      })
      .catch(error => {
        console.error(error)
        //dispatch(actions.saveUserProfileFail(error.response))
      })
  },
  deleteScenarioSuccess: scenariokey => ({
    type: actionTypes.DELETE_SCENARIO_SUCCESS,
    scenariokey,
  }),
  saveScenarioSuccess: payload => ({
    type: actionTypes.SAVE_SCENARIO_SUCCESS,
    payload,
  }),
  getScenariosSuccess: payload => ({
    type: actionTypes.GET_SCENARIOS_SUCCESS,
    payload,
  }),
  removeLocalLayers: () => ({
    type: actionTypes.REMOVE_LOCAL_LAYERS,
  }),
//   {
//     "scenariokey": "cjx2nft0i0000w6tb8af7gn10",
//     "kfaserver": "na",
//     "hometype": "exact",
//     "longitude": -122.32526,
//     "latitude": 47.60997,
//     "rank": 1,
//     "status": "active",
//     "address": "1105 Spring St",
//     "city": "Seatle",
//     "state": "Wa",
//     "zip": "98104",
//     "country": "US ",
//     "distance": 10,
      // "settings": {
      //         "test": true,
      //         "number": 34,
      //         "strnig": "This is a test",
      //         "data": {
      //             "more": "this is more data"
      //         }
      //     }
// }

  getJob: (scenariokey, jobid) => dispatch => {
    //let path = `https://jobsapi.kfadvance-dev.com/api/jobs/${scenarioKey}/${jobId}`
    let path = `https://jobsapi.kfadvance-dev.com/api/jobs/${scenariokey}/${jobid}`
    axios.get(path)
      .then(response => {

        console.log('>>> AND THE RESPONSE FOR getJob IS ',response)
        dispatch(actions.getJobSuccess(response.data))
      })
      .catch(error => {
        console.log('>>> MAPS API FAILED:', error)
      })
  },
  attachJob: (scenario, jobKey, rating)=> dispatch => {
    let path = `https://jobsapi.kfadvance-dev.com/api/scenario/${scenario.scenariokey}/jobs?jobid=${jobKey}&userrating=${rating}`
    let payload = {
      scenariokey: scenario.scenariokey,
      jobid: jobKey,
      status: 'new',
      userrating: rating,
    }
    console.log('ATTACH JOB WITH ', payload)
    return axios(path, {
      method: 'post',
      data: payload,
    })
      .then(response => {
        console.log('ATTACHED JOB ', response)
        dispatch(actions.getSelectedScenario(scenario))
        dispatch(actions.updateJobRating(rating))
      })
      .catch(error => {
        console.error(error)
      })
  },
  getJobSuccess: payload => ({
    type: actionTypes.GET_JOB_SUCCESS,
    payload,
  }),
  updateSearchFilters: (scenario, payload) => dispatch => {
    let cats = getCategoryString(scenario)
    let path = `https://jobsapi.kfadvance-dev.com/api/jobs/search?${cats}&scenariokey=${scenario.scenariokey}&longitude=${scenario.longitude}&latitude=${scenario.latitude}&limit=2000&distance=50mi$&restrict=isochrone&sort=distance`
    axios(path, {
      method: 'post',
      data: payload,
    })
      .then(response => {
        console.log('UPDARED SEARCH FILTEER', response)
        dispatch(actions.searchJobs(scenario,true))
        dispatch(actions.searchJobsListSuccess(response.data))
      })
      .catch(error => {
        console.error(error)
      })
  },
  searchJobs: (scenario, forMaps) => dispatch => {
    let cats = getCategoryString(scenario)
    const theType = forMaps ? '&format=geojson' : ''
    console.log('SETTINGS AMND CATS STRING', scenario.settings, cats)
    let path = `https://jobsapi.kfadvance-dev.com/api/jobs/search?${cats}&scenariokey=${scenario.scenariokey}&longitude=${scenario.longitude}&latitude=${scenario.latitude}&limit=2000&distance=50mi${theType}&restrict=isochrone&sort=distance`
    //let path = `https://jobsapi.kfadvance-dev.com/api/jobs/search?longitude=${payload.long}&latitude=${payload.lat}${SupCat}`
    axios.get(path)
      .then(response => {
        console.log('>>> AND THE RESPONSE FOR Search IS ', response)
        if (forMaps) {
          dispatch(actions.searchJobsSuccess(response.data))
        } else {
          dispatch(actions.searchJobsListSuccess(response.data))
        }

      })
      .catch(error => {
        console.log('>>> MAPS API FAILED:', error)
      })
  },
  searchJobsListSuccess: data => ({
    type: actionTypes.SEARCH_JOBS_LIST_SUCCESS,
    data,
  }),
  searchJobsSuccess: data => ({
    type: actionTypes.SEARCH_JOBS_SUCCESS,
    data,
  }),
  getJobsFromLocation: (payload) => dispatch => {
    // payload = {
    //   key: '',
    //   long: '',
    //   lat:''
    // }
    dispatch(actions.getJobsFromLocationStart())
    let SupCat = ""
    if (payload.superCategory.length > 1) {
      SupCat = "&subcategory="
      payload.superCategory.forEach(cat => {
        if (cat.subcategorycode !== 'n') {
          SupCat += cat.subcategorycode + ','
        }
      })
      SupCat = SupCat.substring(0, SupCat.length - 1)
      console.log('AND THE SUBSTRING IS ', SupCat)
    }
    let path = `https://jobsapi.kfadvance-dev.com/api/jobs/${payload.key}?longitude=${payload.long}&latitude=${payload.lat}${SupCat}`
    //let path = `https://jobsapi.kfadvance-dev.com/api/jobs/search?longitude=${payload.long}&latitude=${payload.lat}${SupCat}`
    axios.get(path)
      .then(response => {
        console.log('>>> AND THE RESPONSE FOR getJobLocation IS ', response)
        // var companySort = sortWith([
        // ascend(prop('company')),
        // ascend(prop('title')),
        // ])
        const theResponse = {
          ...response,
          data: {
            ...response.data,
            jobs: response.data.jobs
          }
        }
        dispatch(actions.getJobsFromLocationSuccess(theResponse.data))
      })
      .catch(error => {
        console.log('>>> MAPS API FAILED:', error)
      })
  },
  getJobsFromLocationStart: () => ({
    type: actionTypes.GET_JOBS_FROM_LOCATION_START,
  }),
  getJobsFromLocationSuccess: payload => ({
    type: actionTypes.GET_JOBS_FROM_LOCATION_SUCCESS,
    payload,
  }),
  advanceWizard: payload => ({
    type: actionTypes.ADVANCE_WIZARD,
    payload,
  })
}

// Reducer   NAV MAP cjxdxtlrn05wc1cqwjf3ps522   MONO MAP cjx6toivq072r1cqxyw81rm8u
const styleDefs = [
  {
    id: 'cjxdxtlrn05wc1cqwjf3ps522',
    layers: [
      {
        id: 'TheJobsLabels',
      },
      {
        id: 'TheJobsDots',
      },
      {
        id: 'MultipleJobsLabel',
      },
    ]
  }
]
const getLayerStack = (filters) => {
  let labelFilter = []
  let multiFilter = []
  let dotsFilter = []
  let theFilters = filters && filters.filters ? filters.filters : [{subcategorycode: 'n'}]
  if (theFilters.length === 1) {
    labelFilter =  ["match", ["get", theFilters[0].subcategorycode], [1], true, false]
    multiFilter = [">", ["get", theFilters[0].subcategorycode], 1]
    dotsFilter = [">", ["get", theFilters[0].subcategorycode], 0]
  } else {
    dotsFilter = ["any"]
    labelFilter = ["any"]
    multiFilter=["any"]
    theFilters.forEach(x => {
      dotsFilter.push([">", ["get", x.subcategorycode], 0])
      // labelFilter.push([">", ["get", x.subcategorycode], 1])
      // multiFilter.push([">", ["get", x.subcategorycode], 0])
    })
  }

  let dotLayer = {
      "id": "TheJobsDots" + filters.dataSource,
      "type": "circle",
      "source": "composite",
      "source-layer": filters.dataSource,
      "maxzoom": 8.5,
      "layout": {},
      "paint": {
          "circle-color": '#2F2CC8',
          "circle-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0,
              1,
              3.5,
              2,
              10.68,
              5,
              22,
              7
          ]
      }
  }


  // "circle-radius": {
  // 				"stops": [
  // 					[
  // 						2,
  // 						2
  // 					],
  // 					[
  // 						11,
  // 						5
  // 					],
  // 					[
  // 						16,
  // 						10
  // 					]
  // 				]
  //
  //
  // if (theFilter !== "count") {
     dotLayer.filter = dotsFilter
  // }
  let layers = [dotLayer]
//   {
//       "id": "TheJobsLabels" + filters.dataSource,
//       "type": "symbol",
//       "source": "composite",
//       "source-layer": filters.dataSource,
//       "minzoom": 8.5,
//       "filter": labelFilter,
//       "maxzoom": 9,
//       "layout": {
//           "text-field": ["to-string", ["get", "c"]],
//           "text-anchor": "bottom",
//           "text-offset": [0, -0.5]
//       },
//       "paint": {
//           "text-halo-color": "hsl(239, 18%, 84%)",
//           "text-halo-width": 1,
//           "text-halo-blur": 1
//       }
//   },
//   {
//       "id": "MultipleJobsLabel" + filters.dataSource,
//       "type": "symbol",
//       "source": "composite",
//       "source-layer": filters.dataSource,
//       "minzoom": 8.5,
//       "filter": multiFilter,
//       "maxzoom": 9,
//       "layout": {
//           "text-field": [
//               "concat",
//               ["to-string", ["get", "c"]],
//               " +"
//
//           ],
//           "text-anchor": "bottom",
//           "text-offset": [0, -0.5]
//       },
//       "paint": {
//           "text-halo-color": "hsl(239, 18%, 84%)",
//           "text-halo-width": 1,
//           "text-halo-blur": 1
//       }
//   }
// ]
  return layers
}
const defaultState = {
  loading: false,
  locations: [],
  location: '',
  showMap: false,
  currentStyleDef: styleDefs[0],
  currentStyle: {},
  filterPathways: [],
  jobsLoading: false,
  currentJob: {},
  viewJob: false,
  mJobs: [],
  cpView: 1,
  datasets: [],
  loadingFilters: false,
  wizardState: 'categories',
  searchJobs: [],
}

const removeDynamicLayers = (theStyleDef, layers) => {
  let theLayers = layers.filter(layer => {
    let theReturn = true
    theStyleDef.layers.forEach(def => {
      if (layer.id.indexOf(def.id) !== -1) {
        theReturn = false
      }
    })
    return theReturn
  })
  return theLayers
}
const getLayers = (theStyleDef, layers, filters) => {
  let theLayers = removeDynamicLayers(theStyleDef, layers)
  console.log('GETTING LAYERS WITH FILTERS', filters)
  var dataSort = sortWith([
    ascend(prop('tilesetkey'))])
  const tLayers = dataSort(filters)
  let curDataSource = ''
  let tempData = []
  let renderData = []
  tLayers.forEach(filter => {
    if (curDataSource !== filter.tilesetkey) {
      if (tempData.length > 0) renderData.push({filters: tempData, dataSource: curDataSource})
      curDataSource = filter.tilesetkey
      tempData = []
      tempData.push(filter)
    } else {
      tempData.push(filter)
    }
  })
  if (tempData.length > 0) renderData.push({filters: tempData, dataSource: curDataSource})
  console.log('AND RENDER DATA IS ', renderData)
  renderData.forEach(filter => {
    let newLayers = getLayerStack(filter)
    theLayers.splice(130,0,newLayers[0])
    // theLayers.push(newLayers[1])
    // theLayers.push(newLayers[2])
  })
  return theLayers
}

const showAllJobs = (theStyleDef, layers, tileSets) => {
  let theLayers = removeDynamicLayers(theStyleDef, layers)
  console.log('REMOVED DYNAMIC LAYERS')
  tileSets.forEach(tSet => {
    const theFilter = {subcategorycode: 'n', dataSource: tSet.tilesetkey}
    let newLayers = getLayerStack(theFilter)
    theLayers.splice(130,0,newLayers[0])
  })
  theLayers = theLayers.filter( layer => (layer.id !== 'commutetime' && layer.is !== 'query-results'))
  return theLayers
}


export let contentState = {};

const anySelected = cats => {
  let theSelected = cats.filter(cat => cat.selected)
  return theSelected.length > 0
}
export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SAVE_SCENARIO_SUCCESS:
      console.log('SAVING SCENARIO ACTION', action)
      const isItNewScenario = state.selectedScenario.scenarioKey ? false : true
      let theNewScenarios = state.scenarios
      const theCurrentScenario = {
        ...state.selectedScenario,
        scenariokey: action.payload.scenariokey
      }
      if (isItNewScenario) { theNewScenarios.push(theCurrentScenario)}
      return {
        ...state,
        selectedScenario: theCurrentScenario,
        scenarios: theNewScenarios
      }
    case actionTypes.SEARCH_JOBS_LIST_SUCCESS:
      console.log('GOT SEArCH JOBS LISt SUCCESS ', action)
      return {
        ...state,
        scenarioJobs: action.data.hits,
        scenarioJobCount: action.data.total,
        filters: action.data.filters,
      }
    case actionTypes.SEARCH_JOBS_SUCCESS:
    case actionTypes.TEST_RENDER_SUCCESS:
      const itzAlreadyThere = state.currentStyle.layers.filter(layer => layer.id === 'query-results')
      console.log('ANF ITS ALREADY ATHERE IS ', itzAlreadyThere)
      // var cstyle = {
      //     ...state.currentStyle
      //   }


      console.log('GOT JOBS SUCCESS WITH ACTION', action)
      // let theSources = state.currentStyle.sources
      //   action.data.sources.forEach(function(source){
      //     theSources[source.id] = source
      //     delete source.id
      //   })
      // const theSource2 = action.data.sources && action.data.sources.length > 0 && action.data.sources[0]
      // delete theSource2.id
      console.log("OLD SOURCES", state.currentStyle.sources)
      const sourceList = {...state.currentStyle.sources}
      action.data.sources.forEach(function(source){
        sourceList[source.id] = source
        delete source.id
      })
      console.log("NEW SOURCES", sourceList)
      let someNewLayers = state.currentStyle.layers.filter(function(layer){
          var hit = false
          action.data.layers.forEach(function(newLayer){
            if(layer.id === newLayer.id){
              hit = true
            }
          })
          return !hit
        })

          action.data.layers.forEach(function(layer){
            someNewLayers.splice(layer.splice,0,layer)
            delete layer.splice
          })
        // console.log('AND THE NEW SOURCE DATA IS ', theSource2)
        return {
          ...state,
          currentStyle: {
            ...state.currentStyle,
            sources: sourceList,
            layers: someNewLayers,
          }
        }
    case actionTypes.REMOVE_LOCAL_LAYERS:
      let stripedLayers = state.currentStyle.layers.filter(layer =>
        (layer.id.indexOf('commutetime') === -1))
      console.log('AND THE STRIPEDLAYERS ARE ', stripedLayers)
      return {
        ...state,
        currentStyle: {
          ...state.currentStyle,
          layers: stripedLayers
        }
      }
    case actionTypes.GOT_COMMUTE_LAYER:
      console.log('GOT COMMUTE !', action.data)
      let returnLayers = action.data.layers
      returnLayers.push({id: "commutetimeline",
          layout: {},
          paint: {'line-color': "#9f4646", 'line-opacity': 0.7, 'line-width': 2},
          source: "commutetime",
          type: "line"})
      let newLayers2 = state.currentStyle.layers
      const alreadyThere = state.currentStyle.layers.filter(layer => layer.id === 'commutetimeline')

        newLayers2 = state.currentStyle.layers.filter(function(layer){
          var hit = false
          action.data.layers.forEach(function(newLayer){
            if(layer.id === newLayer.id){
              hit = true
            }
          })
          return !hit
        })

      // var newSources = {
      //   ...state.currentStyle.sources
      // }
      // action.data.sources.forEach(function(source){
      //   newSources[source.id] = {
      //     'type': 'geojson',
      //     'data': source
      //   }
      // })

      const theSource = action.data.sources && action.data.sources.length > 0 && action.data.sources[0]


        returnLayers.forEach(function(layer){
          newLayers2.splice(120,0,layer)
        })
      return {
        ...state,
        currentStyle: {
          ...state.currentStyle,
          sources: {
            ...state.currentStyle.sources,
            'commutetime': {
                'type': 'geojson',
                'data': theSource,
            }
          },
          layers: newLayers2
        },
      }
    case actionTypes.ADVANCE_WIZARD:
      return {
        ...state,
        wizardState: action.payload,
      }
    case actionTypes.ADD_NEW_SCENARIO:
      const theMapDataSources = state.datasets.filter(set => set.datasetkey === 'general')
      const tileSets = theMapDataSources && theMapDataSources.length > 0 && theMapDataSources[0].tilesets
      const newStyle = JSON.parse(JSON.stringify(state.defaultStyle))
      return {
        ...state,
        selectedScenario: {
          scenariokey: "",
          kfaserver: null,
          longitude: -77.090922,
          latitude: 42.617831,
          address: "",
          city: null,
          state: null,
          zip: null,
          country: null,
          distance: null,
          hometype: null,
          rank: null,
          status: "active",
          settings: {
            categories: theMapDataSources && theMapDataSources.length > 0 &&
            theMapDataSources[0].categories.map(cat => {
              return {
                ...cat,
                subCats: [],
                selected: false,
              }
            }),
            commuteTime: 30,
          },
          jobs: [],

        },
        currentStyle: {
          ...newStyle,
          layers: showAllJobs(state.currentStyleDef, newStyle.layers, tileSets)
        },
        filterPathways: [],
        wizardState: 'categories',
      }

    case actionTypes.GET_DATASET_DEF_SUCCESS:

      let dSets = state.datasets
      dSets.push(action.payload)
      return {
        ...state,
        datasets: dSets,
      }
    case actionTypes.UPDATE_JOB_RATING:
      return {
        ...state,
        currentJob: {
          ...state.currentJob,
          userrating: action.payload,
        }
      }
    case actionTypes.SET_CP_VIEW:
      return {
        ...state,
        cpView: action.payload,
      }
    case actionTypes.SELECT_SCENARIO:
      let filterPathways = []
      let selectedScenario = action.payload
      console.log('AND THE SELECTED SCENARIO IS ', selectedScenario)
      if (!selectedScenario && state.scenarios.length > 0) {
        console.log('GOING FOR THE DEFAULT 0 SCENARIO ', state.scenarios[0])
        selectedScenario = state.scenarios[0]
      }

      if (selectedScenario && selectedScenario.settings && selectedScenario.settings.categories) {
        filterPathways = []
        selectedScenario.settings.categories.forEach(cat => {
          if (cat.selected) {
            cat.subCats.forEach(sub => {
              if (sub.selected) {
                filterPathways.push(sub)
              }
            })
          }
        })
      }
      let scenarios = state.scenarios.map(x => {
           return {
             ...x,
             selected: x.scenariokey === selectedScenario.scenariokey
           }
      })
      console.log('AND FILTERPATHWAYS IS ', filterPathways)
      return {
        ...state,
        scenarios,
        selectedScenario,
        filterPathways,
        currentStyle: {
          ...state.currentStyle,
          layers: getLayers(state.currentStyleDef, state.currentStyle.layers, filterPathways)
        },
      }

    case actionTypes.BACK_TO_JOBS_LIST:
      return {
        ...state,
        viewJob: false,
      }
    case actionTypes.GET_JOBS_FROM_LOCATION_START:
      return {
        ...state,
        searchJobs: [],
        jobsLoading: true,
        viewJob: false,
      }
    case actionTypes.GET_JOB_SUCCESS:
      const theJobs = state.searchJobs ? state.searchJobs : []
      const selectedJobs = theJobs.map(job => {
        if (job.id === action.payload.id) {
          job.selected = true
        } else {
          job.selected = false
        }
        return job
      })
      return {
        ...state,
        currentJob: action.payload,
        searchJobs: selectedJobs,
        viewJob: true,
        cpView: 2,
      }
    case actionTypes.GET_JOBS_FROM_LOCATION_SUCCESS:
      return {
        ...state,
        searchJobs: action.payload.jobs,
        jobsLoading: false,
        viewJob: false,
        cpView: 2,
      }
    case actionTypes.GET_SCENARIOS_SUCCESS:
      const currentScenario = action.payload && action.payload.length > 0  ? action.payload[0]: {}
      return {
        ...state,
        scenarios: action.payload,

      }
    case actionTypes.UPDATE_LAYER_FILTERS:
      let filterCats3 = state.filterPathways
      //filterCats3[0].hidden = true
      return {
        ...state,
        currentStyle: {
          ...state.currentStyle,
          layers: getLayers(state.currentStyleDef, state.currentStyle.layers, filterCats3)
        }
      }
    case actionTypes.REMOVE_CATEGORY_FROM_FILTER:
      let newFilters = state.filterPathways.filter(path => {
        let keepIt = true
        action.data.subCats.forEach(cat => {
          if (cat.subcategorycode === path.subcategorycode) {
            keepIt = false
          }
        })
        return keepIt
      })
      let newLayers = []
      if (newFilters.length === 0) {
        const theMapDataSources = state.datasets.filter(set => set.datasetkey === 'general')
        const tileSets = theMapDataSources && theMapDataSources.length > 0 && theMapDataSources[0].tilesets
        newLayers = showAllJobs(state.currentStyleDef, state.currentStyle.layers, tileSets)
      } else {
        newLayers = getLayers(state.currentStyleDef, state.currentStyle.layers, newFilters)
      }
      const updatedCats = state.selectedScenario.settings.categories.map(cat => {
        if (cat.categorycode === action.data.categorycode) {
          cat.selected = false
        }
        return cat
      })
      return {
        ...state,
        filterPathways: newFilters,
        currentStyle: {
          ...state.currentStyle,
          layers: newLayers,
        },
        selectedScenario: {
          ...state.selectedScenario,
          settings: {
            ...state.selectedScenario.settings,
            categories: updatedCats,
          }
        },
        categorySelected: anySelected(updatedCats),
      }
    case actionTypes.ADD_CLUSTER_PATHWAYS_START:
      return {
        ...state,
        loadingFilters: true,
      }
    case actionTypes.ADD_CLUSTER_PATHWAYS_SUCCESS:
      console.log('AAND ADD_CLUSTER_PATHWAYS_SUCCESS IS ', action)
      let newCats2 = state.selectedScenario.settings.categories.map(cat => {
          if (cat.categorycode === action.data.categorycode) {
                cat.selected = true
                cat.subCats = action.data.subCats.map(sub => {
                  sub.selected = false
                  return sub
                })
              }
              return cat
      })
      let pathwys = state.filterPathways ? state.filterPathways : []
      let newPaths = action.data.subCats
      newPaths.forEach(path => {
        let addIt = true
        pathwys.forEach(thepath => {
          if (path.subcategorycode === thepath.subcategorycode) {
            addIt = false
          }
        })
        if (addIt) pathwys.push(path)
      })
      console.log('AND FILTER PATHS ARE ', pathwys)
      return {
        ...state,
        filterPathways: pathwys,
        loadingFilters: false,
        //datasets: theDataSets,
        selectedScenario: {
          ...state.selectedScenario,
          settings: {
            ...state.selectedScenario.settings,
            categories: newCats2,
          }
        },
        currentStyle: {
          ...state.currentStyle,
          layers: getLayers(state.currentStyleDef, state.currentStyle.layers, pathwys)
        },
        categorySelected: anySelected(newCats2),
      }
    case actionTypes.ADD_LAYER_SUBCAT:
      let filterCats4 = []
      let cats5 = state.selectedScenario.settings.categories.map(cat => {
        if (cat.selected) {
          cat.subCats = cat.subCats.map(sub => {
            if (sub.subcategorycode === action.subcategory.subcategorycode) {
              sub.selected = true
            }
            if (sub.selected) {
              filterCats4.push(sub)
            }
            return sub
          })
        }
        return cat
      })
      return {
        ...state,
        filterPathways: filterCats4,
        currentStyle: {
          ...state.currentStyle,
          layers: getLayers(state.currentStyleDef, state.currentStyle.layers, filterCats4)
        },
        selectedScenario: {
          ...state.selectedScenario,
          settings: {
            ...state.selectedScenario.settings,
            categories: cats5,
          }
        },
      }
    case actionTypes.REMOVE_LAYER_SUBCAT:
      let filterCats2 = state.filterPathways.filter(cat => cat.subcategorycode !== action.data.subcategorycode)
      let cats4 = state.selectedScenario.settings.categories.map(cat => {
        if (cat.subCats) {
          let anewCat
          cat.subCats = cat.subCats.map(sub => {
            if (sub.subcategorycode === action.data.subcategorycode) {
              sub.selected = false
            }
            return sub
          })
        }
        return cat
      })
      return {
        ...state,
        filterPathways: filterCats2,
        currentStyle: {
          ...state.currentStyle,
          layers: getLayers(state.currentStyleDef, state.currentStyle.layers, filterCats2)
        },
        selectedScenario: {
          ...state.selectedScenario,
          settings: {
            ...state.selectedScenario.settings,
            categories: cats4,
          }
        }
      }
    case actionTypes.SET_SCENARIO_FOR_DELETE:
      return {
        ...state,
        scenarios: state.scenarios.map(scenario => {
          if (scenario.scenariokey === action.scenario.scenariokey) {
            scenario.toDelete = true
          } else {
            scenario.toDelete = false
          }
          return scenario
        }),
      }
    case actionTypes.RESET_SCENARIO_DELETES:
      return {
        ...state,
        scenarios: state.scenarios.map(scenario => {
          scenario.toDelete = false
          return scenario
        })
      }
    case actionTypes.DELETE_SCENARIO_SUCCESS:
      return {
        ...state,
        scenarios: state.scenarios.filter(scenario => scenario.scenariokey !== action.scenariokey)
      }
    case actionTypes.FEATURE_CLICKED:
      return {
        ...state,
        popUp: {
          long: action.payload.long,
          lat: action.payload.lat,
          category: action.payload.feature.properties.category,
          id: action.payload.feature.properties.id,
          company: action.payload.feature.properties.company,
          title: action.payload.feature.properties.title,
          opened: true,
        }
      }
    case actionTypes.GET_STYLE_SUCCESS:
      const jobSource = {
        'type': 'geojson',
        'cluster': false,
        'clusterMaxZoom': 14,
        'clusterRadius': 50,
        'data': {
          'type': 'FeatureCollection',
          'features': []
        }
      }
      const theStyle = {
        ...action.payload.data,
        sources: {
          ...action.payload.data.sources,
          'query-result': jobSource,
        },
        layers: getLayers(action.payload.currentStyleDef, action.payload.data.layers, state.filterPathways)
      }
      return {...state,
        currentStyle: theStyle,
        defaultStyle: JSON.parse(JSON.stringify(theStyle)),
        showMap: true,
        currentStyleDef: action.payload.currentStyleDef
      }
    case actionTypes.CLOSE_POPUP:
      return {...state,
              popUp: {
                ...state.popUp,
                opened: false
              }}
    case actionTypes.LOCATION_SELECTED:
    console.log('LOCATION SELECTED ACTION IS ', action)
      const theLocation = (action.location.indexOf("United States") !== -1) ? action.location.substring(0, action.location.length - 15) : action.location
      return {...state,
        origLong: action.long,
        origLat: action.lat,
        location: action.location,
        selectedScenario: {
          ...state.selectedScenario,
          longitude: action.long,
          latitude: action.lat,
          address: theLocation,
          hometype: action.homeType,
        }}
    case actionTypes.CLEAR_LOCATIONS:
      return updateObject(state, { locations: [] })
    case actionTypes.GET_LOCATION_SUCCESS:
      return { ...state,  locations: action.response.features }
    case actionTypes.GET_JOBS_SUCCESS:
      console.log('Get Job Success ', action.data)
      return {...state, currentJob: action.data.jobs}
    default:
      return state
  }
}
