import React from "react"
import styled from 'styled-components'
import { mobile } from '../../styles/media-queries'


const StyledImg = styled.img`
    width: 350px;
    margin: 20px;

    &.footer {
      margin: 0;
      height: 50px
    }
    &.header {
      margin: 0;
      height: 50px
    }

  @media  ${mobile} {
    width: 250px;

    &.footer {
      margin: 0;
      height: 50px
    }
    &.header {
      margin: 0;
      height: 50px
    }
  }`

export default ({type = "", dark = false}) => (
    <StyledImg className={type}
      src={`/static/images/logos/${
        dark ? 'AAAE-cobranding-dark' : 'AAAE-cobranding-light'
      }.svg`}
    />
  )