import humps from 'humps'
import { api, updateObject, getHost, getContent } from '../../shared/utility'
import { actions as actionToaster } from './toaster'

export const actionTypes = {
  GET_INSIGHT_START: 'GET_INSIGHT_START',
  GET_INSIGHT_SUCCESS: 'GET_INSIGHT_SUCCESS',
  GET_INSIGHT_ERROR: 'GET_INSIGHT_ERROR',
  VIDEO_OPEN: 'VIDEO_OPEN',
  VIDEO_CLOSE: 'VIDEO_CLOSE',
  ARTICLE_OPEN: 'ARTICLE_OPEN',
  ARTICLE_CLOSE: 'ARTICLE_CLOSE',
  GET_ASSET_SUCCESS: 'GET_ASSET_SUCCESS',
  GET_ASSET_CATEGORY_SUCCESS: 'GET_ASSET_CATEGORY_SUCCESS',
  GET_INSIGHT_VIEW_SUCCESS: 'GET_INSIGHT_VIEW_SUCCESS',
  GET_INSIGHT_VIEW_ERROR: 'GET_INSIGHT_VIEW_ERROR',
}

// Action Creators



export const actions = {
  getInsightData: (modifier = 'insightvanitypath') => (dispatch, getState) => {
    dispatch(actions.getInsightDataStart())
    const { settings } = getState();
    const vanityPath = modifier ? settings[modifier] : settings.insightvanitypath;
    //let path = `${getHost().apiBaseUrl}/insights/asset?vanitypath=${settings.insightvanitypath}`
    let path = `${getHost().apiBaseUrl}/insights/asset?vanitypath=${vanityPath}`
    return api
      .get(path)
      .then(response => {
        dispatch(actions.getInsightDataSuccess(response.data))
      })
      .catch(error => {
        const { content } = getState()
        dispatch(actions.getInsightDataError({message: error.message, status: error.response?.status}))
        
        if (error.response?.status !== 404) {
          dispatch(actionToaster.showError(getContent('Global.Message.Error', content)))
        }
      })
  },
  getInsightView: (modifier,parsed=null) => (dispatch, getState) => {
    dispatch(actions.getInsightDataStart())
    const { settings } = getState()
    let path = (parsed && (parsed.coachshared==='1') && parsed.key) ? `${getHost().apiBaseUrl}/insights/asset/${parsed.key}?coachshared=1` : `${getHost().apiBaseUrl}/insights/asset?vanitypath=${modifier}`
    return api
      .get(path)
      .then(response => {
        dispatch(actions.getInsightViewSuccess(response.data))
        if(response.data && response.data.parentAssetKey && response.data.parentAssetKey !=null && parsed.coachshared !=='1' ){ // Removed related from shared assets
        dispatch(actions.getAssetCategory(response.data.parentAssetKey))
        }
      })
      .catch(error => {
        const { content } = getState()
        dispatch(actions.getInsightViewDataError({message: error.message, status: error.response.status}))
        
        if (error.response.status !== 404) {
          dispatch(actionToaster.showError(getContent('Global.Message.Error', content)))
        }
      })
  },
  getInsightViewSuccess: data => {
    return { type: actionTypes.GET_INSIGHT_VIEW_SUCCESS, data }
  },
  getAssetCategory: (assetKey) => dispatch => {
    let path = `/insights/asset/${assetKey}`
    api.get(path, true).then(response => {
        return response.data //humps.camelizeKeys(response.data);
    }).then(data => {
      dispatch(actions.getAssetCategorySuccess(data))
    }).catch(error => {

    })
  },
  getAssetCategorySuccess: data => {
    return { type: actionTypes.GET_ASSET_CATEGORY_SUCCESS, data }
  },
  getAsset: (assetKey) => dispatch => {
      let path = `/insights/asset/${assetKey}`
      api.get(path, true).then(response => {
          return humps.camelizeKeys(response.data);
      }).then(data => {
        dispatch(actions.getAssetSuccess(data))
      }).catch(error => {

      })
    },
  getAssetSuccess: data => {
    return { type: actionTypes.GET_ASSET_SUCCESS, data }
  },
  getInsightDataStart: () => {
    return { type: actionTypes.GET_INSIGHT_START }
  },
  getInsightDataSuccess: data => {
    return { type: actionTypes.GET_INSIGHT_SUCCESS, data }
  },
  getInsightDataError: (error) => {
    return { type: actionTypes.GET_INSIGHT_ERROR, error }
  },
  getInsightViewDataError: (error) => {
    return { type: actionTypes.GET_INSIGHT_VIEW_ERROR, error }
  },

  videoOpen: ({ videoImage, videoUrl, assetKey, vttUrl, title, description, transcript, languageCode, URLS }) => ({
    type: actionTypes.VIDEO_OPEN,
    videoImage,
    videoUrl,
    assetKey,
    vttUrl,
    title,
    description,
    transcript,
    languageCode,
    URLS
  }),

  videoClose: () => {
    return {
      type: actionTypes.VIDEO_CLOSE,
    }
  },

  articleOpen: ({ selectedArticle }) => {
    return {
      type: actionTypes.ARTICLE_OPEN,
      selectedArticle,
    }
  },
  articleClose: () => {
    return {
      type: actionTypes.ARTICLE_CLOSE,
    }
  },
}

// Reducer

const defaultState = {
  loading: false,
  insightData: null,
  videoImage: '',
  videoUrl: '',
  isVideoOpen: false,
  isArticleOpen: false,
  selectedArticle: null,
  vttUrl: '',
  title: '',
  description: '',
  transcript: '',
  languageCode: ''
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_ASSET_CATEGORY_SUCCESS:
      return {...state, categoryAssets: action.data}

    case actionTypes.GET_INSIGHT_VIEW_SUCCESS:
      return {...state, loading: false, insightViewErrorMsg: null, insightViewErrorStatus: null, currentView: action.data}
    
    case actionTypes.GET_ASSET_SUCCESS:
      return updateObject(state, { isArticleOpen: true, selectedArticle: action.data })
    
    case actionTypes.GET_INSIGHT_START:
      return updateObject(state, { loading: true })

    case actionTypes.GET_INSIGHT_SUCCESS:
      return updateObject(state, { loading: false, insightErrorMsg: null, insightErrorStatus: null, insightData: action.data })

    case actionTypes.GET_INSIGHT_ERROR:
      return updateObject(state, { loading: false, insightErrorMsg:action.error.message, insightErrorStatus: action.error.status, insightData: [] })
    
    case actionTypes.GET_INSIGHT_VIEW_ERROR:
      return updateObject(state, { loading: false, insightViewErrorMsg:action.error.message, insightViewErrorStatus: action.error.status, insightData: [] })

    case actionTypes.VIDEO_OPEN:
      return updateObject(state, {
        isVideoOpen: true,
        videoUrl: action.videoUrl ? action.videoUrl : state.videoUrl,
        videoImage: action.videoImage ? action.videoImage : state.videoImage,
        assetKey: action.assetKey ? action.assetKey : state.assetKey,
        vttUrl: action.vttUrl,
        title: action.title,
        description: action.description,
        transcript: action.transcript,
        languageCode: action.languageCode,
        URLS: action.URLS
      })

    case actionTypes.VIDEO_CLOSE:
      return updateObject(state, { isVideoOpen: false, videoUrl: undefined, videoImage: undefined, assetKey: undefined, vttUrl: undefined })

    case actionTypes.ARTICLE_OPEN:
      return updateObject(state, { isArticleOpen: true, selectedArticle: action.selectedArticle })
    case actionTypes.ARTICLE_CLOSE:
      return updateObject(state, { isArticleOpen: false })
    default:
      return state
  }
}
