import React from "react"

export default ({ type = "",dark = false }) => {
  const color = dark ? "black" : "#FFFFFF"

  let width = "316px";
  let height = "114px"
  switch(type) {
    case "header":
      width = "140px";
      height = "50px";
      break;
    case "footer":
      width = "260px";
      height = "70px";
      break;
    default:
      break;
  }

  return (
    <svg
      version="1.1"
      baseProfile="basic"
      id="Layer_1"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 316 114"
    >
      <g>
        <defs>
          <rect
            id="SVGID_1_"
            x="-62"
            y="-143.208"
            width="442.208"
            height="442.208"
          />
        </defs>

        <path
          fill={color}
          d="M27.022,40.896l10.63-10.848h-3.61L23.559,40.942l11.045,13.926h3.515L27.022,40.896z
		 M19.788,30.048h-2.802v24.82h2.802V30.048z M83.755,32.646h7.657c4.006,0,6.345,1.835,6.345,4.889v0.066
		c0,3.039-2.407,4.913-5.853,5.091h-8.149V32.646z M98.045,54.868h3.437l-7.58-10.083v-0.072c3.877-0.714,6.692-3.119,6.692-7.213
		v-0.07c0-1.956-0.715-3.695-1.914-4.9c-1.561-1.551-4.01-2.482-7.054-2.482H80.953v24.82h2.802v-9.623h7.105L98.045,54.868z
		 M252.792,32.642h7.655c4.011,0,6.344,1.835,6.344,4.888v0.069c0,3.036-2.409,4.913-5.849,5.09h-8.15V32.642z M267.081,54.862h3.44
		l-7.582-10.083v-0.07c3.879-0.715,6.688-3.12,6.688-7.214v-0.069c0-1.957-0.708-3.695-1.91-4.901
		c-1.559-1.552-4.007-2.483-7.054-2.483h-10.676v24.82h2.804V45.24h7.106L267.081,54.862z M221.014,32.642h7.657
		c4.005,0,6.344,1.835,6.344,4.888v0.069c0,3.036-2.409,4.913-5.854,5.09h-8.146V32.642z M235.297,54.862h3.441l-7.579-10.083v-0.07
		c3.873-0.715,6.692-3.12,6.692-7.214v-0.069c0-1.957-0.711-3.695-1.912-4.901c-1.564-1.552-4.009-2.483-7.057-2.483H218.21v24.82
		h2.804V45.24h7.107L235.297,54.862z M287.788,54.862h2.847v-9.857l10.31-14.963h-3.261l-8.432,12.415l-8.364-12.415h-3.411
		l10.312,14.998V54.862z M188.67,54.862h18.124v-2.547h-15.325v-8.688h13.555V41.07h-13.555v-8.475h15.155v-2.553H188.67V54.862z
		 M160.879,54.862h2.802V44.058h13.438v-2.553h-13.438v-8.863h15.036v-2.6h-17.838V54.862z M112.734,54.862h2.727V34.514
		l16.022,20.348h2.234v-24.82h-2.733v19.89l-15.63-19.89h-2.62V54.862z M66.732,42.525v-0.066c0-5.633-4.113-10.242-9.79-10.242
		c-5.664,0-9.706,4.536-9.706,10.178v0.064c0,5.64,4.112,10.253,9.781,10.253C62.688,52.712,66.732,48.168,66.732,42.525
		 M44.329,42.525v-0.066c0-6.837,5.135-12.835,12.688-12.835s12.621,5.929,12.621,12.771v0.064c0,6.844-5.138,12.833-12.696,12.833
		C49.395,55.292,44.329,49.382,44.329,42.525"
        />
      </g>
      <path
        fill={color}
        d="M98.153,88.472l2.899,6.708h-5.777L98.153,88.472z M96.835,84.95l-7.381,16.894h3.02l1.725-4.049h7.932l1.703,4.049h3.115
	L99.568,84.95H96.835z"
      />
      <g>
        <defs>
          <rect
            id="SVGID_3_"
            x="-62"
            y="-143.208"
            width="442.208"
            height="442.208"
          />
        </defs>
        <path
          fill={color}
          d="M118.461,87.754h-3.306v11.408h3.306c3.524,0,5.824-2.373,5.824-5.681
		C124.285,90.177,121.985,87.754,118.461,87.754 M118.461,101.844h-6.253V85.072h6.253c5.273,0,8.915,3.618,8.915,8.361
		C127.376,98.179,123.734,101.844,118.461,101.844"
        />
      </g>
      <polygon
        fill={color}
        points="144.159,85.071 139.129,97.963 134.071,85.071 130.813,85.071 137.786,101.965 140.374,101.965 147.347,85.071 "
      />
      <path
        fill={color}
        d="M157.753,88.472l2.9,6.708h-5.774L157.753,88.472z M156.436,84.95l-7.38,16.894h3.019l1.725-4.049h7.933l1.699,4.049h3.119
	l-7.383-16.894H156.436z"
      />
      <polygon
        fill={color}
        points="183.242,85.071 183.242,96.668 174.26,85.071 171.525,85.071 171.525,101.844 174.426,101.844 174.426,89.915
	183.676,101.844 186.143,101.844 186.143,85.071 "
      />
      <g>
        <defs>
          <rect
            id="SVGID_5_"
            x="-62"
            y="-143.208"
            width="442.208"
            height="442.208"
          />
        </defs>
        <path
          fill={color}
          d="M200.628,102.132c-4.886,0-8.527-3.81-8.527-8.65c0-4.791,3.569-8.699,8.647-8.699
		c3.092,0,4.961,1.082,6.566,2.615l-1.892,2.178c-1.344-1.246-2.778-2.086-4.698-2.086c-3.21,0-5.536,2.637-5.536,5.944
		c0,3.309,2.326,5.991,5.536,5.991c2.061,0,3.378-0.838,4.818-2.182l1.893,1.919C205.686,100.983,203.77,102.132,200.628,102.132"
        />
      </g>
      <polygon
        fill={color}
        points="212.912,85.071 212.912,101.844 225.467,101.844 225.467,99.209 215.859,99.209 215.859,94.704 224.268,94.704
	224.268,92.067 215.859,92.067 215.859,87.71 225.349,87.71 225.349,85.071 "
      />
      <g>
        <defs>
          <rect
            id="SVGID_7_"
            x="-62"
            y="-143.208"
            width="442.208"
            height="442.208"
          />
        </defs>
        <path
          fill={color}
          d="M158.966,70.612c54.777,0,104.372,3.112,140.291,8.147
		c-25.087-7.331-78.479-12.39-140.291-12.39c-61.813,0-115.203,5.059-140.29,12.39C54.595,73.724,104.189,70.612,158.966,70.612"
        />
      </g>
    </svg>

  )
}
