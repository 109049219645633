import React from 'react'
import humps from 'humps'
import { toast } from 'react-toastify'
import { lastUrlSegment, getContent } from '../../shared/utility'
import { actions as actionAuth } from './auth'
import { actions as actionToaster } from './toaster'
import { selectors as workSelectors } from './work'
import { actions as actionUserProduct } from './user-product'
import { actions as chatActions } from './chat'
import ToastRedirect from '../../components/ToastRedirect/ToastRedirect'
import { paymentCompleted as actionToasterPayment } from './toaster'
import { refreshSignals, liveSignal } from "@kornferry/kfadvance_journeys/client";
// Constants
import {
  userImageUploaded as actionToasterUserImageUploaded,
  profileUpdated as actionToasterProfileUpdated,
} from './toaster';
import { actions as userActions1 } from './user-actions'


export const actionTypes = {
  MQTT_CONNECTION_SUCCESS: 'MQTT_CONNECTION_SUCCESS',
  MQTT_CONNECTION_FAIL: 'MQTT_CONNECTION_FAIL',
  MQTT_REPORTS_RECEIVED: 'MQTT_REPORTS_RECEIVED',
  MQTT_ASSESSMENT_PROGRESS_CHANGED: 'MQTT_ASSESSMENT_PROGRESS_CHANGED',
  MQTT_HIDE_ASSESSMENT_REPORT_CREATED_TOASTER: 'MQTT_HIDE_ASSESSMENT_REPORT_CREATED_TOASTER',
  MQTT_CONNECTION_STATUS:'MQTT_CONNECTION_STATUS'
}

// Action Creators
var service = {
  alreadyVerified: false,
}

export const actions = {
  iotReceived: message => (dispatch, getState) => {
    const { content, auth, settings, attributes, userActions, mqtt } = getState()
    const authedUserKey = auth.userKey
    const hideCoachingToaster = settings?.hideCoachingToaster || false;
    const hideProfileUpdateToaster = userActions?.hideProfileUpdateToaster || false;
    const hideAssessmentReportCreatedToaster = mqtt?.hideAssessmentReportCreatedToaster || false;

    const json = settings.Assessments?.TalentHub && JSON.parse(settings.Assessments.TalentHub);
    const assessmentReportIsVisibleLater = json && Array.isArray(json.clientReports) && json.clientReports.length > 0
                              ? json.clientReports[0].relativeHours < 0 
                              : false;
    
    const messageReceived = humps.camelizeKeys(message)
     console.log('RECEIVED MSG ', messageReceived)

    // console.log('AND THE RAW MSG ', message)
    const messageT = message?.t;
    switch (messageT) {
      case 'message':
        console.log('CHAT MESSAGE RECEIVED', messageReceived)
        // if (messageReceived.senderUserKey !== authedUserKey) {
          // please tell me a better way to do this
          liveSignal('iotMessage', messageReceived)
          if (lastUrlSegment() !== 'chat') {
            toast(<ToastRedirect url="/chat" msg={`${messageReceived.firstName} ${messageReceived.lastName}: ${messageReceived.message.text}`} />)
            dispatch(chatActions.incrementUnread())
          } else {
            //console.log('lastUrlSegment()', lastUrlSegment())
            dispatch(chatActions.addMessage(messageReceived))
            //console.log("trying to mark read", messageReceived)
            dispatch(chatActions.readMessage(messageReceived.threadKey))
          }
          //console.log('GONNA ADD MESSAGE ')
          //dispatch(chatActions.addMessage(messageReceived))
        // } else {
        //   console.log('SELF CHAT IOT MESSAGE RECEIVED NOT ADDING')
        // }
        break
      case 'read':
        //console.log('READ EVENT RECEIVED', messageReceived)
        //dispatch(chatActions.addMessage(messageReceived))
        break
      case 'typing':
        //console.log('TYPING EVENT RECEIVED', messageReceived)
        if (messageReceived.userKey !== authedUserKey) {
          //console.log('GONNA DISPATCH ADD SINGLE TYPER')
          dispatch(chatActions.addSingleTyper(messageReceived))
        }
        break
      // case 'connect':
      //   console.log('YOU GOT A CONNECTION!!!!! ', message)
      //   break
      case ('emailConfirmed', 'emailverified'):
        console.log('AND ALREADY VERIFIED IS ', service.alreadyVerified)
        if (!service.alreadyVerified) {
          service.alreadyVerified = true
          dispatch(
            actionAuth.emailVerificationSuccess({
              isVerified: true,
            }, settings, attributes )
          )
          dispatch(actionToaster.showSuccess(getContent('Global.Message.VerifyEmail', content)))
        }
        break
      case 'connect':
        console.log('IN MQTT AND GOT A CONNECTION!')
        const coachName = messageReceived.firstName + ' ' + messageReceived.lastName
        const coaching4 = getState() && getState().auth && getState().auth.purchState ? getState().auth.purchState.coaching : undefined
        dispatch(actionToaster.showSuccess(getContent('Global.Message.CoachAssigned', content).replace('{coachName}', coachName)))
        dispatch(actionAuth.refreshProducts(coaching4))
        break
      case 'orderCompleted':
          dispatch(actionToaster.showSuccess(actionToasterPayment.PAYMENT_COMPLETED))
          dispatch(actionAuth.refreshProducts());
          break;
      case 'interviewPrepcoachingCall':
      case 'interviewPrepcoachingCall2':
      case 'salaryCall':
      case 'coachingCall':
      case 'resumeCoachingCall':
      case 'resumeCoachingCall2':
      case 'coachingCall2':
      case 'placementcoachingCall':
      case 'coachingCall1':
      case 'coachingCall3':
      case 'placementcoachingCall1':
      case 'placementcoachingCall2':
      case 'placementcoachingCall3':
      case 'uomCoachcoachingCall':
      case 'partnerCoachcoachingCall':
        const coaching = getState() && getState().auth && getState().auth.purchState ? getState().auth.purchState.coaching : undefined
        // !hideCoachingToaster && dispatch(actionToaster.showSuccess(getContent('Global.Message.CoachSessionBegun', content)))
        setTimeout(dispatch(actionAuth.refreshProducts(coaching)), 10000)
        break
      case 'interviewPrepcoachingCallComplete':
      case 'interviewPrepcoaching2Complete':
      case 'interviewprepsalaryCallComplete':
      case 'coachingCallComplete':
      case 'coachingCall2Complete':
      case 'resumeCoachingCallComplete':
      case 'resumeCoachingCall2Complete':
      case 'salaryCallComplete':
      case 'placementcoachingCallComplete':
      case 'coachingCallComplete1':
      case 'coachingCallComplete2':
      case 'coachingCallComplete3':
      case 'placementcoachingCallComplete1':
      case 'placementcoachingCallComplete2':
      case 'placementcoachingCallComplete3':
      case 'uomCoachCallComplete':
      case 'partnerCoachCallComplete':
      case 'westpointCoachingCall1Complete':

        const coaching1 = getState() && getState().auth && getState().auth.purchState ? getState().auth.purchState.coaching : undefined
        //console.log('GOT IOT AND COACHING IS ', coaching1)
        setTimeout(() => {
          !hideCoachingToaster && dispatch(actionToaster.showSuccess(getContent('Global.Message.CoachSessionDone', content)))
          dispatch(actionAuth.refreshProducts(coaching1))
        }, 3000)
        break
      case 'assessmentReportCreated':
        refreshSignals('documents')
        dispatch({type: actionTypes.MQTT_REPORTS_RECEIVED})
        !assessmentReportIsVisibleLater && !hideAssessmentReportCreatedToaster && dispatch(actionToaster.showSuccess(getContent('Global.Message.ExecutiveReportCreated', content)))

        dispatch({type: actionTypes.MQTT_HIDE_ASSESSMENT_REPORT_CREATED_TOASTER})

        const featureType = 'traitsassessment'
        const products = getState() && getState().auth && getState().auth.products ? getState().auth.products : undefined

        if (products && products.length > 0) {
          products.forEach(product => {
            let work = product
            let curStep = workSelectors.feature(featureType, product)

            if (!work || !curStep) {
              return;
            }

            var theData = {
              PurchaseBundleCode: work.PurchaseBundleCode,
              PurchasedItemKey: curStep.PurchasedItemKey,
              Submit: 'assessment',
              CurrentStep: curStep.CurrentStep,
              Action: curStep.Action ? curStep.Action : curStep.ProductFeatureCode,
            }

            dispatch(actionUserProduct.pushStep(theData))
          })
        }
        break
        case 'assessmentProgressChange':
          const message = messageReceived.message;
          liveSignal('assessmentProgress', message)
          dispatch({type: actionTypes.MQTT_ASSESSMENT_PROGRESS_CHANGED, message})
        break
      case 'userImageUploaded':
        dispatch(actionToaster.showSuccess(actionToasterUserImageUploaded.USER_IMAGE_UPLOADED))
        break;
      case 'profileUpdated':
        !hideProfileUpdateToaster && dispatch(actionToaster.showSuccess(getContent('Global.Message.ProfileUpdated', content)))
        break;
      case 'scheduleCoachingAppointment':
        dispatch(userActions1.hideProfileUpdateToaster(false))
        !hideCoachingToaster && dispatch(actionToaster.showSuccess(getContent('Global.Message.AppointmentScheduled', content)));
        break;
      case 'rescheduleCoachingAppointment':
        // !hideCoachingToaster && dispatch(actionToaster.showSuccess(getContent('Global.Message.AppointmentRescheduled', content)));
        break;
      case 'cancelCoachingAppointment':
        !hideCoachingToaster && dispatch(actionToaster.showSuccess(getContent('Global.Message.AppointmentCancelled', content)));
        break;
     default:
      //console.log('YOU GOT A MESSAGE!!!!! ', message)
    }
  },
  iotDisconnect: message => (dispatch, getState) => {
    dispatch(actionToaster.showError(message))
  },
  updateMqttConnectionStatus: status => (dispatch,getState) => {
      dispatch({type:actionTypes.MQTT_CONNECTION_STATUS,message:status})
  },
  // mqttConnectionSuccess: client => ({
  //   type: actionTypes.MQTT_CONNECTION_SUCCESS,
  //   client,
  // }),
  // mqttConnectionFail: error => ({
  //   type: actionTypes.MQTT_CONNECTION_FAIL,
  //   error,
  // }),
  // mqttSubscribe: channel => (dispatch, getState) => {
  //   //console.log('****** mqttSubscribe', getState())
  //   if (channel) {
  //     if (!service.subscriptions[channel]) {
  //       try {
  //         if (channel) {
  //           console.log('SUBSCRIBING TO', channel)
  //           service.client.subscribe(channel)
  //           service.subscriptions[channel] = true
  //         }
  //       } catch (err) {
  //         if (channel && channel !== null && service & service.client) {
  //           service.client.unsubscribe(channel)
  //           delete service.subscriptions[channel]
  //         }
  //       }
  //     }
  //   }
  // },
  // mqttConnect2: (endpoint, channels) => (dispatch, getState) => {
  //   let client = mqtt.connect(endpoint)
  //   client.on('connect', function() {
  //     console.log('MQTT CONNECTED2', endpoint)
  //     console.log('MQTT CONNECTED', client.client)
  //     if (channels && channels.length) {
  //       channels.forEach(channel => {
  //         try {
  //           console.log('SUBSCRIBING2 TO', channel)
  //           client.subscribe(channel)
  //           console.log('SUBSCRIBED2 TO', channel)
  //         } catch (e) {
  //           console.log('ERROR SUBSCRIBING TO', channel)
  //         }
  //       })
  //     }
  //   })
  //   client.on('message', function(topic, msg) {
  //     var message = JSON.parse(msg)
  //     //console.log('YOU GOT A MESSAGE!!!!! ', message)
  //     switch (message.t) {
  //       default:
  //         return dispatch(actions.iotReceived(message))
  //     }
  //   })
  // },


  // mqttConnect: () => (dispatch, getState) => {
  //   let path = `${getHost().apiBaseUrl}/iot/subscribe`
  //   console.log('HITTING MQTTCONNECt')
  //   api
  //     .get(path)
  //     .then(response => {
  //       const endpoint = response.data.url
  //       service.client = mqtt.connect(endpoint)
  //       service.client.on('connect', function() {
  //         console.log('MQTT CONNECTED', service.client)
  //         //client.subscribe('2576738167f44b8bf6811136622d729f')
  //         dispatch(actions.mqttConnectionSuccess(service.client))
  //       })
  //       //client.on('message', messageReceived)
  //       service.client.on('message', function(topic, msg) {
  //         var message = JSON.parse(msg)
  //         const messageReceived = humps.camelizeKeys(message)
  //         const authedUserKey = getState().auth.userKey
  //         //console.log('YOU GOT A MESSAGE!!!!! ', message)
  //         //console.log('mttq - getState()', getState())
  //         switch (message.t) {
  //           case 'message':

  //             console.log('CHAT MESSAGE RECEIVED', messageReceived)
  //             if (messageReceived.senderUserKey !== authedUserKey) {
  //               // please tell me a better way to do this
  //               if (lastUrlSegment() !== 'chat') {
  //                 toast(<ToastRedirect url="/chat" msg={`${messageReceived.firstName}: ${messageReceived.message.text}`} />)
  //                 dispatch(chatActions.incrementUnread())
  //               } else {
  //                 //console.log('lastUrlSegment()', lastUrlSegment())
  //               }
  //               dispatch(chatActions.addMessage(messageReceived))
  //             } else {
  //               console.log('SELF CHAT IOT MESSAGE RECEIVED NOT ADDING')
  //             }
  //             break
  //           case 'read':
  //             console.log('READ EVENT RECEIVED', messageReceived)
  //             //dispatch(chatActions.addMessage(messageReceived))
  //             break
  //           case 'typing':
  //             console.log('TYPING EVENT RECEIVED', messageReceived)
  //             //dispatch(chatActions.addMessage(messageReceived))
  //             break
  //           case ('emailConfirmed', 'emailverified'):
  //             if (!service.alreadyVerified) {
  //               service.alreadyVerified = true
  //               dispatch(
  //                 actionAuth.emailVerificationSuccess({
  //                   isVerified: true,
  //                 })
  //               )
  //               dispatch(actionToaster.showSuccess('Thank you for verifying your email!'))
  //             }
  //             break
  //           case 'connect':
  //             console.log('IN MQTT AND GOT A CONNECTION!')
  //             const coachName = message.FirstName + ' ' + message.LastName
  //             const coaching4 = getState() && getState().auth && getState().auth.purchState ? getState().auth.purchState.coaching : undefined
  //             dispatch(actionToaster.showSuccess('Your coach ' + coachName + ' has been assigned, you can now schedule an appointment!'))
  //             dispatch(actionAuth.refreshProducts(coaching4))
  //             break
  //           case 'interviewPrepcoachingCall':
  //           case 'interviewPrepcoachingCall2':
  //           case 'salaryCall':
  //           case 'coachingCall':
  //           case 'resumeCoachingCall':
  //           case 'resumeCoachingCall2':
  //           case 'coachingCall2':
  //           case 'placementcoachingCall':
  //           case 'coachingCall1':
  //           case 'coachingCall3':
  //           case 'placementcoachingCall1':
  //           case 'placementcoachingCall2':
  //           case 'placementcoachingCall3':
  //           case 'westpointcoachingCall':
  //             const coaching = getState() && getState().auth && getState().auth.purchState ? getState().auth.purchState.coaching : undefined
  //             dispatch(actionToaster.showSuccess('Your coaching session has begun.'))
  //             setTimeout(dispatch(actionAuth.refreshProducts(coaching)), 10000)
  //             break
  //           case 'interviewPrepcoachingCallComplete':
  //           case 'interviewPrepcoaching2Complete':
  //           case 'interviewprepsalaryCallComplete':
  //           case 'coachingCallComplete':
  //           case 'coachingCall2Complete':
  //           case 'resumeCoachingCallComplete':
  //           case 'resumeCoachingCall2Complete':
  //           case 'salaryCallComplete':
  //           case 'placementcoachingCallComplete':
  //           case 'coachingCallComplete1':
  //           case 'coachingCallComplete2':
  //           case 'coachingCallComplete3':
  //           case 'placementcoachingCallComplete1':
  //           case 'placementcoachingCallComplete2':
  //           case 'placementcoachingCallComplete3':
  //           case 'westpointcoachingCallComplete':
  //             const coaching1 = getState() && getState().auth && getState().auth.purchState ? getState().auth.purchState.coaching : undefined
  //             //console.log('GOT IOT AND COACHING IS ', coaching1)
  //             setTimeout(() => {
  //               dispatch(actionToaster.showSuccess('Your coaching session has been completed.'))
  //               dispatch(actionAuth.refreshProducts(coaching1))
  //             }, 3000)
  //             break
  //           case ('Candidate Assessment', 'Executive Movie', 'Career Growth'):
  //             const featureType = message.t === 'Career Growth' ? 'traitsassessment' : 'enterpriseassessment'
  //             const products = getState() && getState().auth && getState().auth.products ? getState().auth.products : undefined
  //             if (products && products.length > 0) {
  //               products.forEach(product => {
  //                 let work = undefined
  //                 let curStep = undefined
  //                 work = product
  //                 curStep = workSelectors.feature(featureType, product)
  //                 //console.log('llll work', work)
  //                 //console.log('2222 curStep', curStep)
  //                 if (work && curStep) {
  //                   var theData = {
  //                     PurchaseBundleCode: work.PurchaseBundleCode,
  //                     PurchasedItemKey: curStep.PurchasedItemKey,
  //                     Submit: 'assessment',
  //                     CurrentStep: curStep.CurrentStep,
  //                     Action: curStep.Action ? curStep.Action : curStep.ProductFeatureCode,
  //                   }
  //                   //console.log('3333 theData', theData)
  //                   if (message.Reports) {
  //                     if (message.Reports.length > 0) {
  //                       message.Reports.map(function(rep) {
  //                         if (rep.Category === 'ExecutiveReport') {
  //                           //console.log('4444 rep.Url', rep.Url)
  //                           dispatch(
  //                             actionAuth.completedAssessment({
  //                               //UserKey: state.global.UserKey,
  //                               PDFUrl: rep.Url,
  //                             })
  //                           )
  //                           dispatch(actionUserProduct.pushStep(theData))
  //                           dispatch(actionToaster.showSuccess('Your executive report has been created'))
  //                         } else if (rep.Category === 'ExecutiveMovie') {
  //                           const ExecMovie = [
  //                             {
  //                               src: rep.Url,
  //                               type: 'video/mp4',
  //                             },
  //                           ]
  //                           //console.log('5555 ExecMovie', ExecMovie)
  //                           dispatch(
  //                             actionAuth.completedMovie({
  //                               //UserKey: state.global.UserKey,
  //                               MovieURL: ExecMovie,
  //                             })
  //                           )
  //                           dispatch(actionToaster.showSuccess('Your executive Movie has been created'))
  //                         }
  //                       })
  //                     }
  //                   }
  //                 }
  //               })
  //             }
  //             break
  //           default:
  //             break
  //         }
  //       })
  //     })
  //     .catch(error => {
  //       //console.log('lai mqtt error', error)
  //       dispatch(actions.mqttConnectionFail(error))
  //     })
  // },
}

// Reducer
const defaultState = {
  client: null,
  err: null,
  assessmentStatus: null,
  hideAssessmentReportCreatedToaster: false,
  isMqttConnected: null
}

// const messageReceived = (topic, msg) => {
//   var message = JSON.parse(msg)
//   // eslint-disable-next-line no-console
//   console.log('YOU GOT A MESSAGE!!!!! ', message)
// }

export default function(state = defaultState, action) {

  switch (action.type) {
    case actionTypes.MQTT_CONNECTION_SUCCESS:
      //console.log('lai action isConnected', action.client.isConnected())
      return {
        ...state,
        client: action.client,
      }
    case actionTypes.MQTT_REPORTS_RECEIVED:
      return {
        ...state,
        lastReportsReceivedTime: (new Date()).getTime()
      }
    case actionTypes.MQTT_ASSESSMENT_PROGRESS_CHANGED:
      return {
        ...state,
        assessmentStatus: action.message
      }
    case actionTypes.MQTT_CONNECTION_FAIL:
      return {
        ...state,
        err: action.error,
      }
    case actionTypes.MQTT_HIDE_ASSESSMENT_REPORT_CREATED_TOASTER:
      return {
        ...state,
        hideAssessmentReportCreatedToaster: true
      }
      case actionTypes.MQTT_CONNECTION_STATUS:
        return{
          ...state,
          isMqttConnected:action.message
        }
    default:
      return state
  }
}