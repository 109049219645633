import { commonMobile } from './Mobile.Common'
import BgImgHome from '../../assets/images/home/KFA_Hero_Home.jpg' //home_hero6.jpg' //190512_New_KFA_Site_Home.jpg' //Hero_Test_01.jpg' //home_hero6.jpg'
import BgImgHomeMobile from '../../assets/images/home/KFA_Hero_Home_Mobile.jpg'
import BgImgAssessment from '../../assets/images/asssessment/KFA_Assessments_Hero.jpg' //assessment_hero5.jpg' //190512_New_KFA_Site_Assessment.jpg'
import BgImgAssessmentMobile from '../../assets/images/asssessment/KFA_Assessments_Hero_Mobile.jpg'
import BgImgResume from '../../assets/images/resume/KFA_Resume_Hero.jpg'//resume_hero2.jpg' //190512_New_KFA_Site_Resume.jpg'
import BgImgResumeMobile from '../../assets/images/resume/KFA_Resume_Hero_Mobile.jpg'
import BgImgChat from '../../assets/images/chat/hero.jpg'
import BgImgFQA from '../../assets/images/faq/KFA_FAQ_Hero.jpg'
import BgImgFQAMobile from '../../assets/images/faq/KFA_FAQ_Hero_Mobile.jpg'
import BgImgAbout from '../../assets/images/about/KFA_About_Hero.jpg'
import BgImgAboutMobile from '../../assets/images/about/KFA_About_Hero_Mobile.jpg'
import ImgAnalysis from '../../assets/images/about/analysis.svg'
import ImgExperts from '../../assets/images/about/experts.svg'
import ImgAboutLaunch from '../../assets/images/about/launch.svg'
import ImgWallet from '../../assets/images/about/wallet.svg'
import BgImgCoaching from '../../assets/images/coaching/KFA_Coaching_Hero.jpg' //coaching_hero5.jpg' //190512_New_KFA_Site_Coaching.jpg'
import BgImgCoachingMobile from '../../assets/images/coaching/KFA_Coaching_Hero_Mobile.jpg'

import imgCoaching from '../../assets/images/prods/sofiCoaching.png'
import BgImgInterview from '../../assets/images/interview/KFA_Interview_Hero.jpg'
import BgImgInterviewMobile from '../../assets/images/interview/KFA_Interview_Hero_Mobile.jpg'
import BgImgBestPractices from '../../assets/images/interview/foundationHero.jpg'
import BgImgProfile from '../../assets/images/profile/KFA_Profile_Hero.jpg'//profile_hero5.jpg' //190512_New_KFA_Site_Profile.jpg'
import BgImgProfileMobile from '../../assets/images/profile/KFA_Profile_Hero_Mobile.jpg'
import BgImgPurchase from '../../assets/images/purchase/KFA_Purchase_Hero.jpg'
import BgImgPurchaseMobile from '../../assets/images/purchase/KFA_Purchase_Hero_Mobile.jpg'
import BgImgJobs from '../../assets/images/jobs/KFA_Jobs_Hero.jpg' //jobs_hero5.jpg' //190512_New_KFA_Site_Jobs.jpg'
import BgImgJobsMobile from '../../assets/images/jobs/KFA_Jobs_Hero_Mobile.jpg'
import BgImgPaycheck from '../../assets/images/paycheck/hero.jpg'
import BgImgServices from '../../assets/images/services/KFA_Hero_Services.jpg'
import BgImgServicesMobile from '../../assets/images/services/KFA_Hero_Services_Mobile.jpg'
import BgImgPhilosophyHero from '../../assets/images/resume/philosophy_hero.jpg'
import BgImgTipsHero from '../../assets/images/resume/tips_hero.jpg'
import BgImgDosHero from '../../assets/images/resume/dos_hero.jpg'
import accountPrefHero from '../../assets/images/account/KFA_AccountPref_Hero.jpg'
import accountPrefHeroMobile from '../../assets/images/account/KFA_AccountPref_Hero_Mobile.jpg'
//import InterviewAppImage from '../../assets/images/interapp/interview_app.jpg'
//import InterviewApp2Image from '../../assets/images/interapp/interview_app2.jpg'
//import InterviewAppImage from '../../assets/images/interapp/InterviewPrep_Ad_blue.png'
import InterviewAppImage from '../../assets/images/interapp/InterviewPrep_Ad.png'
import ButtonAppStore from '../../assets/images/interapp/Button_AppStore_L.png'
import ButtonGooglePlay from '../../assets/images/interapp/Button_GooglePlay_L.png'
import VideoSplashImage from '../../assets/images/video_splash.jpg'
import WhatIsKFA from '../../assets/images/what_is_kfa.jpg'
// import ButtonBack from '../../assets/slant_button.png'
// import ButtonBackContrast from '../../assets/slant_button_contrast.png'
// import ButtonBackWhite from '../../assets/slant_button_white.png'
import { HomeIcon, InsightsIcon, ResumeIcon, AssessmentsIcon } from '../../assets/icons/ToolbarIcons'
import { InterviewIcon, CoachingIcon, PaycheckIcon, JobsIcon, ChatIcon } from '../../assets/icons/ToolbarIcons'
import imgPhilosophy from '../../assets/images/resume/philosophy.jpg'
import imgTips from '../../assets/images/resume/tips.jpg'
import imgDosDonts from '../../assets/images/resume/dosdonts.jpg'
import imgBestPractice from '../../assets/images/interview/best_practices.jpg'
import GirlCoach from '../../assets/images/avatars/sampleCoachWoman.jpg'
import GirlCoach2 from '../../assets/images/avatars/sampleCoachWoman2.jpg'
import GirlCoach3 from '../../assets/images/avatars/sampleCoachWoman3.jpg'
import BoyCoach from '../../assets/images/avatars/sampleCoachGuy.jpg'
import KFALogo from '../../components/Logos/KFALogo'
import mastHeadDesktopImage from '../../assets/images/e-learning/mast-head-desktop.jpg'
import mastHeadMobileImage from '../../assets/images/e-learning/mast-head-mobile.jpg'
import adImage from '../../assets/images/e-learning/ad.png'
import { getDefaultNavbar } from '../../shared/utility'

const lightColor = '#5cc658'  //'#006699' //'#00A3B2' //'#5ca2f6'
const panelColor = lightColor
const lighterColor = '#ebf9ed' //'#7dd179' // '#59c3cc'
const lightColorDisabled = lightColor //'#59c3cc'
const darkColor = '#000' //'#1e3857'
const whiteColor = '#f8f8f8'//'#ffffff'
const blackColor = '#000' //'#333333'
const secondaryColor = '#4f4f4f'
const contrastColor = 'rgb(110, 110, 110)' //'#8bc330'230 247 255
const contrastLightColor = '#f1f7e7' //'#e7f4d2' //
const contrastColor2 = '#bdcf02'
const lightGrayColor = '#f2f2f2' //'#f4f4f4'
const mobile = { ...commonMobile }

export const Assets = history => {
  const theIcons = {
    HomeIcon, InsightsIcon, ResumeIcon, AssessmentsIcon,
    InterviewIcon, CoachingIcon, PaycheckIcon, JobsIcon, ChatIcon
  }
  const toolbarNavLinks = getDefaultNavbar(history, theIcons)

  const theReturn = {
    bookEligible: true,
    mobile,
    toolbar: 'littleBar',
    mastHead: 'large',
    externalJobsBoard: false,
    externalAssessment: false,
    scheduleRange: 48,
    hasGoals: false,
    hasNavigator: false,
    autoAssignScheduling: true,
    toolbarNavLinks: toolbarNavLinks(history),
    iconColor: lightColor,
    iconLightColor: '#b5d9dc',
    iconColorSelected: whiteColor,
    lightColor,
    menuColor: whiteColor,
    menuTextColor: whiteColor,
    lightColorDisabled,
    iconContrastColor2: contrastColor2,
    contrastColor,
    bgHome: BgImgHome,
    bgHomeMobile: BgImgHomeMobile,
    bgAssessment: BgImgAssessment,
    bgImgAssessmentMobile: BgImgAssessmentMobile,
    bgImgCoaching: BgImgCoaching,
    bgImgCoachingMobile: BgImgCoachingMobile,
    bgImgResume: BgImgResume,
    bgImgResumeMobile: BgImgResumeMobile,
    bgImgChat: BgImgChat,
    bgImgFQA: BgImgFQA,
    bgImgFQAMobile: BgImgFQAMobile,
    bgImgInterview: BgImgInterview,
    bgImgInterviewMobile: BgImgInterviewMobile,
    bgImgBestPractices: BgImgBestPractices,
    bgImgProfile: BgImgProfile,
    bgImgProfileMobile: BgImgProfileMobile,
    bgImgPurchase: BgImgPurchase,
    bgImgPurchaseMobile: BgImgPurchaseMobile,
    bgImgJobs: BgImgJobs,
    bgImgJobsMobile: BgImgJobsMobile,
    bgImgPaycheck: BgImgPaycheck,
    bgImgServices: BgImgServices,
    bgImgServicesMobile: BgImgServicesMobile,
    bgImgPhilosophyHero: BgImgPhilosophyHero,
    bgImgTipsHero: BgImgTipsHero,
    bgImgDosHero: BgImgDosHero,
    bgAccountPref: accountPrefHero,
    bgAccountPrefMobile: accountPrefHeroMobile,
    interviewAppImage: InterviewAppImage,
    buttonAppStore: ButtonAppStore,
    buttonGooglePlay: ButtonGooglePlay,
    //interviewApp2Image: InterviewApp2Image,
    videoSplashImage: VideoSplashImage,
    whatIsKFA: WhatIsKFA,
    // buttonBack: ButtonBack,
    // buttonBackContrast: ButtonBackContrast,
    LogoComponent: KFALogo,
    FooterLinks: '© Korn Ferry, All rights reserved ',
    FooterCareerQuestions:
      'Questions? Concerns? Contact us <a href="mailto:Advance@KornFerry.com">Advance@KornFerry.com</a> | <a href="tel:+1.888.291.7818">+1.888.291.7818</a>',
    FooterLoanQuestions: '',
    TermsLink: '',
    WelcomeMovie: 'https://video.kfadvance.com/Welcome/mp4-Welcome-1080.mp4',
    WhatIsKFA: 'https://video.kfadvance.com/Korn+Ferry+Advance/Why+Korn+Ferry+Advance.mp4',
    bgImgAbout: BgImgAbout,
    bgImgAboutMobile: BgImgAboutMobile,
    imgAnalysis: ImgAnalysis,
    imgExperts: ImgExperts,
    imgAboutLaunch: ImgAboutLaunch,
    imgWallet: ImgWallet,
    imgPhilosophy,
    imgTips,
    imgDosDonts,
    imgBestPractice,
    imgCoaching,
    boyCoach: BoyCoach,
    girlCoach: GirlCoach,
    girlCoach2: GirlCoach2,
    girlCoach3: GirlCoach3,
    addOnBlurb: '',
    addOnButton: '',
    sampleCoach1: {
      bg: 'yellow',
      img: BoyCoach,
      name: 'David',
      specialty: 'Career Coach',
      quote:
        '“As a coach, I am committed to each client’s journey. Nothing brings me more satisfaction than seeing my client’s overcome obstacles to land a career and lead a life filled with fulfillment, meaning, purpose, and passion.”',
    },
    sampleCoach2: {
      bg: 'blue',
      img: GirlCoach,
      name: 'Nicole Wade',
      specialty: 'Financial Sector | Career Coach',
      quote:
        '"As a coach, I am committed to each client’s journey. Nothing brings me more satisfaction than seeing my client’s overcome obstacles to land a career and lead a life filled with fulfillment, meaning, purpose, and passion."',
    },
    hideGradientOverlay: true,
    showPromoBanner: false,
    pages: {
      home: {
        showKFAd: false,
        showKFSubscriptionAd: false,
        showKFService: false,
        showSofiServices: false,
        showProductPurchased: false,
        showPrysmianFlow: false,
        showRedundentPrysmian: false,
        showInsights: false,
        newInsights: false,
        mainBlurb:
          'Korn Ferry Advance is about helping you build a successful career. We have the resources and experience to help you manage your career. We just need to know a little bit more about you. It all starts with the basics… a profile, resume review, and an assessment. We’ll take it from there.',
        showPODLearningPlan: false,
        hasJourneys: true,
        suppressGoals: true,
        suppressWins: true,
        showWebinarPanel: false,
        suppressRightHandSideBar: false,


      },
      resume: {
        showKFAd: false,
        showAssessmentBlurb: false,
        newInsights: true,
        showCoachingAd3: true,
      },
      assessment: {
        showAssessmentBlurb: false,
        showResumeParsing: false,
        newInsights: true,
        showCoachingAd3: true,
        showAssessmentContent: false,
        showAssessmentStart: true, showAssessmentBlurb2: false,
      },
      profile: {
        showKFAd: false,
        showKFSubscriptionAd: false,
        showKFService: false,
        showSofiServices: false,
        showResumeParsing: false,
        showRedundentPrysmian: false,
      },
      faq: {
        showKFAd: false,
        showKFService: true,
        showPrysmianFaq: false,
      },
      coaching: {
        showKFAd: false,
        showCoachingAd1: false,
        showCoachingAd2: false,
        coachingGoals: false,
        showKFSubscriptionAd: false,
        newInsights: true,
        showCoachingAd3: true,
      },
      interview: {
        showFAQ: true,
        mobileAppOnly: false,
        newInsights: true,
        style: 'newStyle',
        showCoachingAd3: true,
      },
      eLearning: {
        mastHeadDesktopImage: mastHeadDesktopImage,
        mastHeadMobileImage: mastHeadMobileImage,
        adImage: adImage
      },
    },
    menus: {
      main: 'group:main_menu',
      myAccount: 'group:my_account',
      topTools: 'group:top_tools',
    },
    getInsightsModifier: auth => auth && auth.purchState && auth.purchState.hasSubscription
      ? 'insightvanitypathpostbuy'
      : 'insightvanitypath',
  }
  //console.log('AND THE RETURN FOR DEFAULT IS ', theReturn);
  return theReturn
}

export const Theme = () => {
  const theTheme = {
    mobile,
    error: '#d54300',
    warning: '#daa520',
    good: 'green',
    fontOne: 'ProximaNova-Light,Roboto, Helvetica Neue, Arial, sans-serif',
    fontTwo: 'ProximaNova-Bold, Roboto, Helvetica Neue, Arial, sans-serif',
    fontThree: 'ProximaNova-Extrabld, Roboto, Helvetica Neue, Arial, sans-serif',
    darkColor: darkColor,
    lightColor: lightColor,
    headerColor: lightColor,
    panelColor,
    lightColorDisabled,
    lighterColor: lighterColor,
    whiteColor: whiteColor,
    blackColor: blackColor,
    contrastColor: contrastColor,
    contrastLightColor,
    lightGrayColor,


    buttonBackColor: lightColor,
    buttonTextColor: whiteColor,
    buttonBorderColor: lightColor,
    menuHoverColor: lighterColor,
    grayTextColor: '#838383', //'#6a6a77',
    careerColor: lightColor,
    careerColorLight: lighterColor,
    paycheckColor: '#76e6d2',
    paycheckColorLight: '#ccfbf3',
    resumeColor: '#306259',
    resumeColorLight: '#c2ede5', //'#7abcb0',
    interviewColor: '#009396',
    interviewColorLight: '#b7f3f4',
    tbBackground: '#f4f4f4',
    pnlBackground: '#ebeae9',
    kfaBackground: lightGrayColor, //'#f6f8db',
    kfa2Background: '#E0F2F1', //'#009396',
    secondary: secondaryColor, //'#4f4f4f', //'#424242',
    notification: '#ffc602',
    errorRequired: '#e99000',
    done: '#757575',
    toolbarTextColor: whiteColor,
    toolbar: 'littleBar',
    menuTextColor: whiteColor,
    footerTextColor: whiteColor,
    actionPanelBG1: '#f6f8db',
    actionPanelBG2: '#daeff0',
    // buttonBack: ButtonBack,
    // buttonBackContrast: ButtonBackContrast,
    // buttonBackWhite: ButtonBackWhite,
    contrastColor2: contrastColor2,
    bgQuoteCareerCoach: '#e8f9f6',
    bgQuotePaycheckCoach: '#e0f2f1',
    bgQuoteResumeCoach: '#e9efee',
    bgQuoteInterviewCoach: '#ddf0f1',
    colorCareerCoach: '#03c096',
    colorPaycheckCoach: '#61e3ba',
    colorResumeCoach: '#306259',
    colorInterviewCoach: '#009396',
    colorMastText: blackColor,
    colorMastButtonText: '#fff'
  }
  //console.log('AND THE THEME IS ', theTheme)
  return theTheme
}
