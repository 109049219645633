import humps from 'humps'
import { map } from 'ramda'
import { serialize, updateObject, api, formatDateLong, getHost, handleError } from '../../shared/utility'

import Error from '../../classes/Error'

const formatResponse = client => {
  return {
    ...client,
    startDate: formatDateLong(client.startDate),
  }
}

// Constants

export const actionTypes = {
  GET_APPOINTMENTS_SUCCESS: 'GET_APPOINTMENTS_SUCCESS',
  GET_APPOINTMENTS_START: 'GET_APPOINTMENTS_START',
  GET_APPOINTMENTS_FAIL: 'GET_APPOINTMENTS_FAIL',
}

// Action Creators

export const actions = {
  getAppointmentsSuccess: (filters, response) => ({
    type: actionTypes.GET_APPOINTMENTS_SUCCESS,
    filters,
    response,
  }),

  getAppointmentsFail: error => ({
    type: actionTypes.GET_APPOINTMENTS_FAIL,
    error,
  }),

  getAppointmentsStart: () => ({
    type: actionTypes.GET_APPOINTMENTS_START,
  }),

  getAppointments: queryParams => {
    return dispatch => {
      if (!queryParams) throw Error('queryParams are required')
      dispatch(actions.getAppointmentsStart())
      let path = `${getHost().apiBaseUrl}/search/${queryParams.type}/${'?' + serialize(queryParams)}`

      return api
        .get(path)
        .then(response => {
          return humps.camelizeKeys(response.data)
        })
        .then(response => {
          dispatch(
            actions.getAppointmentsSuccess(queryParams, {
              ...response,
              data: map(formatResponse, response.data),
            })
          )
        })
        .catch(error => handleError(dispatch, actions.getAppointmentsFail, error))
    }
  },
}

// Reducer

const defaultState = {
  loading: false,
  filters: null,
  response: null,
  error: null,
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_APPOINTMENTS_START:
      return updateObject(state, { loading: true })
    case actionTypes.GET_APPOINTMENTS_SUCCESS:
      return updateObject(state, {
        loading: false,
        filters: action.filters,
        response: action.response,
      })
    case actionTypes.GET_APPOINTMENTS_FAIL:
      //console.log('action.error:', action.error)
      return updateObject(state, { loading: false, error: action.error, response: null })
    default:
      return state
  }
}
