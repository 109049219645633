import styled, { css } from "styled-components";
import { md, mobile, tablet, desktop, isDesktop, mdDown } from '../styles/media-queries'

let mp = ''
for (let i = 0; i < 10; i++) {
  mp += `.mt-${i}{margin-top:${i}rem;}`
  mp += `.mb-${i}{margin-bottom:${i}rem;}`
  mp += `.ml-${i}{margin-left:${i}rem;}`
  mp += `.mr-${i}{margin-right:${i}rem;}`
  mp += `.m-${i}{margin:${i}rem;}`
  mp += `.pt-${i}{padding-top:${i}rem;}`
  mp += `.pb-${i}{padding-bottom:${i}rem;}`
  mp += `.pl-${i}{padding-left:${i}rem;}`
  mp += `.pr-${i}{padding-right:${i}rem;}`
  mp += `.p-${i}{padding:${i}rem;}`
}

export const BaseMargin = ({ theme, myProp }) => css`
  margin-top: 0;
  margin-bottom: 16px;
  @media ${tablet} {
    margin-bottom: 18px;
  }
  @media ${desktop} {
    margin-bottom: 20px;
  }
`

export const BaseFont = ({ theme, myProp }) => css`
  font-size: 16px;
  @media ${tablet} {
    font-size: 18px;
  }
  @media ${desktop} {
    font-size: 20px;
  }
`
export const BaseFontSmall = ({ theme, myProp }) => css`  
  font-size: 15px;    
  @media ${tablet} {
    font-size: 16px;        
  }
  @media ${desktop} {
    font-size: 18px;
  }
`

export const BaseFontSmaller = ({ theme, myProp }) => css`  
  font-size: 14px;    
  @media ${tablet} {
    font-size: 15px;        
  }
  @media ${desktop} {
    font-size: 16px;
  }
`

export const BaseFontTitle = ({ theme, myProp }) => css`
  font-size: 22px;
  //letter-spacing: 1px;
  font-weight: 400;
  @media ${tablet} {
    font-size: 24px;
    letter-spacing: 2px;
  }
  @media ${desktop} {
    font-size: 28px;
    //letter-spacing: 2px;
  }
`

export const BaseFontLargeTitle = ({ theme, myProp }) => css`
  font-size: 24px;
  letter-spacing: 1px;
  font-weight: 400;
  @media ${tablet} {
    font-size: 28px;
    letter-spacing: 2px;
  }
  @media ${desktop} {
    font-size: 30px;
    letter-spacing: 2px;
  }
`

export const BaseFontH1 = ({ theme, myProp }) => css`
  font-size: 28px;
  @media ${tablet} {
    font-size: 32px;
  }
  @media ${desktop} {
    font-size: 38px;
  }
`

export const BaseFontTitleH2 = ({ theme, myProp }) => css`
  font-size: 20px;
  letter-spacing: 1px;
  @media ${tablet} {
    font-size: 22px;
    letter-spacing: 2px;
  }
  @media ${desktop} {
    font-size: 24px;
    letter-spacing: 2px;
  }
`

export const BorderMargin = ({ theme, myProp }) => css`
  box-sizing: border-box;
  margin: 0 16px 0;
  @media ${tablet} {
    margin: 0 24px 0;
  }
  @media ${desktop} {
    margin: 0 32px 0;
  }
`

export const StyledMain = styled.main`

 //placing Footer at the bottom of the screen irrespective of the content size
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;

  html:not(&) {
    height: 100%;
    //overflow-x: hidden; //This making body scrollable when a  material-ui select is open. 
    @media ${md} {
      //overflow-x: auto; //This making body scrollable when a  material-ui select is open.
    }
  }

  body:not(&) {
    position: relative;
    min-height: 100%;
    color: #222;
    font-size: 14px;
    //overflow-x: hidden;
    overflow: scroll;
    &.modal-open {              
        overflow: hidden;
        @media only screen and (min-width: 1200px) { 
        padding-right: 15px;
        .topnav-container {
          right: 15px;
        }
      }
    }
    &.noscroll {
      overflow: hidden;
      max-height: 100vh;
      position: fixed;
      width: 100%;
    }
    .flex-col {
      display: flex;
      flex-direction: column;
    }
    .flex-center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .md-down-flex-center {
      @media ${mdDown} {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .flex-left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .flex-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    // TEXT
    .first-upper {
      &::first-letter{
        text-transform:uppercase;
      }
    }
    .capitalize {
      text-transform:capitalize;
    }
    .uppercase {
      text-transform:uppercase;
    }
    
    .text-center, .center {text-align: center;}
    .text-left {text-align: left;}
    .md-text-left {@media ${md} {text-align: left;}}
    .text-right {text-align: right;}
    .absolute {
      position: absolute;
    }
    .relative {
      position: relative;
    }
    .maxContent {
      transition: 0.25s;      
      max-width: 100%;
      width: 100%;
      margin: auto;     
      box-sizing: border-box;
      &.padded {
        padding: 0 28px;
        @media ${tablet} {
          padding: 0 48px;
        }
        @media ${desktop} {
          padding: 0 100px;
        }
      }
    }
    .maxColumnContent {
      transition: 0.25s;
      max-width: 542px;     
      width: 100%;
      margin: 0 auto;
      font-family: inherit;
    }
    .maxColumn {
      transition: 0.25s;
      max-width: 440px;     
      width: 100%;
      margin: 0 auto;
      padding: 0 20px;
      font-family: inherit;
      @media ${tablet} {
        padding: 0 48px;
      }
      @media ${desktop} {
        padding: 0 56px;
      }
      box-sizing: border-box;
      * {
        box-sizing: border-box;
      }
    }
    .maxContent.padded .maxColumn,
    .maxColumnContent .maxColumn {
        padding: 0;
    }
    .topBorder {
      border-top: 16px solid #fff;
      margin: 0;
      &:not(.noPadding) {
        padding-top: 16px;
        @media ${tablet}, ${desktop} {
          padding-top: 30px;       
        }
      }
    }
    .bottomBorder {
      border-bottom: 2px solid #dbdbdb;
      margin: 0 24px;
      .maxContent.padded {
        padding: 0 24px;
      }
      @media ${mobile} {
        margin: 0 10px;
        .maxContent.padded {
          padding: 0 18px;
        }
      }
      @media only screen and (min-width: 1280px){
        border-bottom: 16px solid #fff;
        margin: 0;
        .maxContent.padded {
          padding: 0 68px;
        }
      }
    }
    .pageOptions {
      .bottomBorder {
        border-bottom: 16px solid #fff;
        margin: 0;
        @media ${mobile} {
          margin: 0;
          .maxContent.padded {
            padding: 0 28px;
          }
        }
      }
      div:last-child > .bottomBorder {
          border: none;
      }  
      @media only screen and (min-width: 1280px){
        .bottomBorder {
          border-bottom: none;
        }
      }  
    }
    .mobileOnly {
      display: block;
      @media ${tablet}, ${desktop} {
        display: none;
      }
    }
    .hiddenMobile {
      display: none;
      @media ${tablet}, ${desktop} {
        display: block;
      }
    }
    .md-flex-left {
      @media ${md} {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
    .md-flex-right {
      @media ${md} {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
    .md-down-flex-right {
      @media ${mdDown} {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
    .md-hidden{
      @media ${md} {
        display: none;
      }
    }
    .md-visible{
        display: none;
      @media ${md} {
        display: inline;
      }
    }
    
    // bug in material ui seems to have switched line height and height, this fixes it, should be fine
    .MuiInputBase-input {
      height: auto;
      line-height: 1.1875em;
    }
   
    ${mp}
    @media ${md} {
      overflow-x: auto;
    }
  
    .mobile-reset {
      all: ${!isDesktop ? 'unset' : 'set'};
    }
    * {
      font-family: ${props => props.theme.fontOne};
      .material-icons {
        font-family: 'Material Icons';
      }
    }
    
    a {
      cursor:pointer; //a without hrefs dont get a pointer
      color: ${props => props.theme.lightColor};
      font-weight: 900;
      // text-decoration: none;
      outline-offset: 1px;
    }

    b, strong {
     // font-family: ${props => props.theme.fontTwo};
     font-weight: bold;
    }

    #KfaMainContentId { 
      outline: transparent; 
      // * { // this has to be implemented soon, will be asap by Todd during mobile resizing home page, makes life easier for all
      //   box-sizing: border-box;
      // }
    }
    .rc-tooltip, 
    .rc-slider-tooltip { 
      z-index: 1500; 
    }
  }
`
