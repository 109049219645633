import humps from 'humps'
import { api, updateObject, getHost, getContent } from '../../shared/utility'

export const actionTypes = {
  GOT_PANELS: 'GOT_PANELS',
  GET_USER_PANELS_START: 'GET_USER_PANELS_START',
  GET_USER_PANELS_SUCCESS: 'GET_USER_PANELS_SUCCESS',
  GET_USER_PANELS_ERROR: 'GET_USER_PANELS_ERROR',
}

export const actions = {
  getPanels: (entityKey, route) => dispatch => {
    if (!route) route = '/profile'
    let path = `/content/panels/` + entityKey + '?route=' + route
    api
      .get(path, true)
      .then(response => {
        return humps.camelizeKeys(response.data)
      })
      .then(data => {
        //console.log('GOT partner ATTRIBUTES AND THE RESPONSE IS ', data)
        dispatch(actions.gotPanels(data))
      })
      .catch(error => {})
  },
  gotPanels: payload => ({ type: actionTypes.GOT_PANELS, payload }),

  getUserPanels: (userKey, entityKey, route) => dispatch => {
    dispatch(actions.getUserPanelStart())
    let path = `/user/panels?userKey=${userKey}&entityKey=${entityKey}&route=${route}`
    return api
      .get(path, true)
      .then(response => {
        return humps.camelizeKeys(response.data)
      })
      .then(data => {
        dispatch(actions.getUserPanelSuccess(data))
      })
      .catch(error => {
        dispatch(actions.getUserPanelError(error))
      })
  },
  getUserPanelStart: () => ({ type: actionTypes.GET_USER_PANELS_START }),
  getUserPanelSuccess: data => ({
    type: actionTypes.GET_USER_PANELS_SUCCESS,
    data,
  }),
  getUserPanelError: error => ({ type: actionTypes.GET_USER_PANELS_ERROR, error }),
}

// Reducer

const defaultState = {
  panels: {},
  loading: false,
  userPanels: null,
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GOT_PANELS:
      return {
        ...state,
        panels: action.payload,
      }
    case actionTypes.GET_USER_PANELS_START:
      return { ...state, loading: true, userPanels: null }
    case actionTypes.GET_USER_PANELS_ERROR:
      return { ...state, loading: false }
    case actionTypes.GET_USER_PANELS_SUCCESS:
      return { ...state, loading: false, userPanels: action.data }
    default:
      return state
  }
}
