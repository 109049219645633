import React from 'react';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import {pageTitles} from '../../wcag';

class PageSettings extends React.Component {
  render() {
    let attrs = {};
    const {lang, c} = this.props;
    if (lang) attrs['lang'] = lang;
    const key = pageTitles[window.location.pathname] || pageTitles['/'];

    return (
      <Helmet htmlAttributes={attrs}>
        <title>{c(key)}</title>
      </Helmet>
    )
  }
}


const mapStateToProps = state => {
  let lang = state.auth?.user?.LanguageCode || 'en';

  if (lang && lang.length > 2) {
    lang = lang.substring(0,2);
  }

  return {lang}
}



export default connect(mapStateToProps)(PageSettings)
