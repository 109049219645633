import { contentState } from '../redux/modules/content'
import { getHost } from './utility';

const get = (p, o) => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);

/**
 * 
 * @param {*} key 
 * @param {object} options 
 * @param {boolean} options.hasVariables true means content has environment variables, and getContent function should automatically replace those variables 
 */
export const getContent = (key, options) => {
  options = options || {}

  if (!contentState) {
    return "";
  }

  let result = get(key.split("."), contentState) || ""

  if (!options.hasVariables) {
    return result;
  }
  
  const variables = result.match(/{{{(.*?)}}}/)

  if (!variables || variables.length < 1) {
    return result;
  }
  
  for (let i = 1; i < variables.length; i++) {
    const currentVariable = variables[i]
    result = result.replace(new RegExp(`{{{${currentVariable}}}}`, 'g'), getHost()[currentVariable])
  }

  return result;
};

export const getRoleSelectContent = (contentKey, name) => {
  const content = getContent(`RoleSelect.Codes.${contentKey}`);
  if(content)
     return content;
  return name;
}



export const getPageContentFactory = pageNamespace => key => getContent(`${pageNamespace}.${key}`)

export default getContent;
