import React from "react"
import styled from 'styled-components'
import { mobile } from '../../styles/media-queries'


const StyledContainer = styled.div`
  background-color: #000000;
  margin: 20px 50px;


  img {
    width: 200px;
    padding: 10px;
    margin: 0 20px;
  }

  &.footer {
    margin: 0;
  }
  &.header {
    margin: 0;
  }

  img.footer {
    margin: 0;
    width: 200px;
    padding: 0px;
  }

  img.header {
    margin: 0;
    height: 50px;
    padding: 0;
  }



  @media  ${mobile} {
    margin: 20px auto;
    img {
      height: 50px;
      padding: 10px;
      margin: 0;
    }

    &.footer {
      margin: 0;
    }
    &.header {
      margin: 0;
    }

    img.footer {
      margin: 0px;
      padding: 0px;
    }

    img.header {
      margin: 0px;
      padding: 0px;
    }
  }`


export default ({type = "",dark = false}) => (
  <StyledContainer className={type}>
    <img className={type}
      src={`/static/images/logos/${dark ? 'WPAOG' : 'WPAOG'}.png`}
      alt="WPAOG logo"
    />
  </StyledContainer>
  )