import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
//import DialogTitle from '@material-ui/core/DialogTitle'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import styled from "styled-components"
//import { Button } from '../../styles/common'

//import Spinner from '../Spinner/Spinner'


const DialogTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 0 0;
`

class PostSurveyModal extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.isOpen || nextProps.isOpen) {
      return true
    }
    return false
  }

  render() {
    const { fullScreen, isOpen, onClose, c } = this.props

    const title = "";

    return (
      <Dialog fullScreen={fullScreen} open={isOpen} onClose={onClose} aria-labelledby={title}>
        <DialogTitleContainer>
          <div dangerouslySetInnerHTML={{ __html: title }} />{" "}
          <IconButton aria-label="Close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitleContainer>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span
              dangerouslySetInnerHTML={{
                __html: c('Pages.Profile.SurveyBlurb'),
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={onClose}>
            Close
          </Button> */}
        </DialogActions>
      </Dialog>
    )
  }
}

PostSurveyModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
}

export default withMobileDialog()(PostSurveyModal)
