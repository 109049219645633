import { getHost } from './utility'

const betterMessages = msg => {
  console.log('betterMessages', msg)
  if(msg === 'Client cannot initiate connection')
    return 'A chat has not been initiated by care team yet.'
}

const translateAdhoc = (msg, language) => {
  //someday we can translate this message adhoc
  return msg
}

export const handleError = (dispatch, action, error) => {
  const { response, code, config } = error
  let err = new Error()
  let host = getHost()
  let isDev = false //host.env === 'dev'

  if (response && response.status === 401) {
    //err.message = 'Session Expired'
    err.code = 401
  } else if ((code || response) && config.url) {
    //err.url = config.url
    if (isDev) {
      if (response)
        err.message = `API Error in ${config.url.replace(host.apiBaseUrl, '').substring(0, 150)}  ${
          response.data && typeof response.data === 'string' ? ' - ' + response.data.substring(0, 50) + '...' : ''
        }`
      else if (code) {
        err.message = 'Internal Server Error.. Please try refreshing the page or contact us if the problem persists'
        if (code === 'ECONNABORTED') err.message = `API didn't respond in 7 seconds : ${config.url.replace(host.apiBaseUrl, '')}...`
      }
    } else {
      console.log('WE HAVE response', response)
      if (response) {
        let { data } = response
        if (data) {

          try{
          data = data.replace('An error occurred:', '')
          data = betterMessages(data)
          data = translateAdhoc(data)
          err.message = data
          }catch (e) {
            err.message = 'error'
          }
        }
      }
    }
  }

  dispatch(action(err))
}
