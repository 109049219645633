import React from "react"
import styled from 'styled-components'
import { mobile } from '../../styles/media-queries'

const StyledImg = styled.img`
  height: 60px;
  margin: 0px;

  &.footer {
    margin: 0;
    height: 50px
  }
  &.header {
    margin: 0;
    height: 50px
  }

  @media  ${mobile} {
    height: 60px;

    &.footer {
      margin: 0;
      height: 40px
    }
    &.header {
      margin: 0;
      height: 40px
    }
}`


export default ({type = "",dark = false}) => (
    <StyledImg className={type}
      src={`/static/images/logos/${
        dark ? 'sofi-kfa-cobranding-dark' : 'sofi-kfa-cobranding-white'
      }.png`}
      alt="Korn Ferry Logo"
    />
  )