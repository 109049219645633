import { actions as userProductActions } from '../../redux/modules/user-product'
import { selectors as workSelectors } from '../../redux/modules/work'
import { getStore } from '../../store/create-store'


//To break the circular reference with auth and user-product 
export const advanceInterviewPrepStep = (products, isInterviewApp) => {
    if (isInterviewApp && products && products.length > 0) {
        products.forEach(product => {
          const curStep = workSelectors.featureByType('interviewprep', product)
          if (product && curStep && curStep.CurrentStep !== 'complete') {
            const theData = {
              PurchaseBundleCode: product.PurchaseBundleCode,
              PurchasedItemKey: curStep.PurchasedItemKey,
              Submit: 'interviewprep',
              CurrentStep: curStep.CurrentStep,
              Action: 'none',
              donotUseNavToAppointment: true
            }
            getStore().dispatch(userProductActions.pushStep(theData));
          }

          const curStepChat = workSelectors.featureByType('chat', product)
          if (product && curStepChat && curStepChat.CurrentStep !== 'complete') {
            const theData = {
              PurchaseBundleCode: product.PurchaseBundleCode,
              PurchasedItemKey: curStepChat.PurchasedItemKey,
              Submit: 'chat',
              CurrentStep: curStepChat.CurrentStep,
              Action: 'submit',
              donotUseNavToAppointment: true
            }
            getStore().dispatch(userProductActions.pushStep(theData));
          }

        })
      }
}
