/* eslint-disable */
import axios from 'axios'
import humps from 'humps'
import { prop, sortWith, ascend} from 'ramda'
import { getHost,api } from '../../shared/utility'
import { logEvent } from '../../shared/analytics'
import analyticsTypeConstants from '../../constants/analytics/analytics-type.constants';
import analyticsRoutes from '../../constants/analytics/analytics-routes.constants';



// ActionTypes

export const actionTypes = {
  GOAL_ROLE_CLICKED: 'GOAL_ROLE_CLICKED',
  SAVED_GOAL_ROLES: 'SAVED_GOAL_ROLES',
  GOAL_CATEGORIES_LOADED: 'GOAL_CATEGORIES_LOADED',
  CLICKED_ADD_GOAL: 'CLICKED_ADD_GOAL',
  SELECTED_GOAL_CATEGORY: 'SELECTED_GOAL_CATEGORY',
  GOAL_SAVE_SUCCESS: 'GOAL_SAVE_SUCCESS',
  GET_USER_GOALS_SUCCESS: 'GET_USER_GOALS_SUCCESS',
  REORDERED_GOALS: 'REORDERED_GOALS',
  GO_TO: 'GO_TO',
  EDIT_USER_GOAL: 'EDIT_USER_GOAL',
  GOAL_SELECTED: 'GOAL_SELECTED',
  SET_SHOW_GOAL_DESCRIPTION: 'SET_SHOW_GOAL_DESCRIPTION',
  TOGGEL_DRAG_DROP: 'TOGGEL_DRAG_DROP',
  UPDATED_USER_GOAL: 'UPDATED_USER_GOAL',
  GOAL_COMPLETE_SELECTED: 'GOAL_COMPLETE_SELECTED',
  CONFIGURE_NAVIGATOR: 'CONFIGURE_NAVIGATOR',
  GOT_NAVIGATOR_ROLES: 'GOT_NAVIGATOR_ROLES',
  CHANGE_COMPLETED_PAGE: 'CHANGE_COMPLETED_PAGE',
  GOT_USER_NAV_ROLES: 'GOT_USER_NAV_ROLES',
  NAV_GO_TO: 'NAV_GO_TO',
  RESET_SELECTED: 'RESET_SELECTED',
  SAVE_IN_PROGRESS: 'SAVE_IN_PROGRESS',

  NAVIGATOR_HELP_OPEN: "NAVIGATOR_HELP_OPEN",
  NAVIGATOR_HELP_CLOSE: "NAVIGATOR_HELP_CLOSE",
  UPDATE_NAV_STATE: "UPDATE_NAV_STATE",
}
const getBlankGoal = priority => {
  return {
    uGKey: "",
    aVKey: "",
    goalTitle: "",
    goalDescription: "",
    targetDate: "",
    createdDate: "",
    updatedDate: "",
    createdBy: "",
    updatedBy: "",
    priority,
  }
}


export const actions = {
  openNavigatorHelp: () => ({type: actionTypes.NAVIGATOR_HELP_OPEN}),
  closeNavigatorHelp: () => ({type: actionTypes.NAVIGATOR_HELP_CLOSE}),
  resetSelected: () => ({type: actionTypes.RESET_SELECTED}),
  changeCompletedPage: payload => ({type: actionTypes.CHANGE_COMPLETED_PAGE, payload}),
  goalRoleClicked: payload => ({type: actionTypes.GOAL_ROLE_CLICKED, payload}),
  updateNavState: payload => ({type: actionTypes.UPDATE_NAV_STATE, payload}),
  saveGoalPriority: (theGoals) => dispatch => {
    //console.log('SAVING GOAL PRIORITY WITH GOALS ', theGoals)
    let thePriority = 0
    const reorderedGoals = theGoals.map(goal => {
      thePriority += 1
      return {
        ...goal,
        priority: thePriority
      }
    })
    let savePayload = []
    let userKey = ''
    const payload = reorderedGoals.map(goal => {
      userKey = goal.userKey
      if (goal.uGKey) {
        savePayload.push({ugKey: goal.uGKey, priority: goal.priority})
      }
    })
    //To avoid the flicker
    dispatch(actions.reorderedGoals(reorderedGoals))

    let path = `${getHost().apiBaseUrl}/user/goals/priority`
    return axios(path, {
      method: 'post',
      data: savePayload,
      withCredentials: true,
    })
      .then(response => {
        //console.log('MAYBE SAVED A GOAL PRIORITY AND THE RESPONSE IS ', response)
        dispatch(actions.reorderedGoals(reorderedGoals))
      })
      .catch(error => {
        console.error(error)
      })
  },
  reorderedGoals: payload => ({type: actionTypes.REORDERED_GOALS, payload}),
  toggleDragDrop: () => ({type: actionTypes.TOGGEL_DRAG_DROP}),
  goTo: (where) => ({type: actionTypes.GO_TO, where}),
  navGoTo: where => ({type: actionTypes.NAV_GO_TO, where}),
  savedGoalRoles: payload => ({type: actionTypes.SAVED_GOAL_ROLES, payload}),
  goalCategoriesLoaded: payload => ({type: actionTypes.GOAL_CATEGORIES_LOADED,payload}),
  configureNavigator: payload => ({type: actionTypes.CONFIGURE_NAVIGATOR,payload}),
  clickedAddGoal: () => ({type: actionTypes.CLICKED_ADD_GOAL}),
  selectedGoalCategory: payload => ({type: actionTypes.SELECTED_GOAL_CATEGORY, payload}),
  setShowGoalDescription: showGoalDescription => ({type: actionTypes.SET_SHOW_GOAL_DESCRIPTION, showGoalDescription}),
  getUserGoals: (payload, goalScreen) => (dispatch, getState) => {
    //user/goals?userKey=5685dfdf0ed3c61e164760b0fb623f27
    const {auth} = getState()
    //console.log('GOT USER GOALS AND THE AUTH IS ', auth)
    //let query = '?userKey=' + payload
    let path = `/user/goals`// + query
    return api.get(path, true).then(response => {
        return humps.camelizeKeys(response.data)
    }).then(data => {
      dispatch(actions.getUserGoalsSuccess(data, goalScreen))
    }).catch(error => {

    })

  },
  gotNavigatorRoles: payload => dispatch => {
    dispatch(actions.gotNavigatorRolesSuccess(payload))
    dispatch(actions.configureNavigator(true))
  },
  gotNavigatorRolesSuccess: payload => ({type: actionTypes.GOT_NAVIGATOR_ROLES,payload}),

  gotUserNavRoles: (payload, profileComplete) => ({type: actionTypes.GOT_USER_NAV_ROLES, payload, profileComplete}),

  saveNavigatorRoles: (payload) => (dispatch, getState) => {
    //console.log('OK SAVE NAV ROLES WITH ', payload)
    const {auth} = getState()
    const profileComplete = auth.purchState.profile.isComplete
    let thePayload = []
    payload.userNavRoles.map(role => {
      const stillSelected = payload.selectedRoles.filter(theRole => theRole.aVKey === role.aVKey)
      if (stillSelected.length === 0) {
        thePayload.push({
          UAKey: role.uAKey,
          ADefKey: payload.aDefKey,
          AVKey: role.aVKey,
          PartnerKey: payload.partnerKey,
          Value: role.textValue,
          isDeleted: true,
        })
      }
    })
    let rolesToSave = []
    payload.selectedRoles.map(role => {
      const stillSelected = payload.userNavRoles.filter(theRole => theRole.aVKey === role.aVKey)
      if (stillSelected.length === 0) {
        thePayload.push({
          ADefKey: payload.aDefKey,
          AVKey: role.aVKey,
          PartnerKey: payload.partnerKey,
          Value: role.textValue,
        })
      }
    })
    const newPayload = {attributes: thePayload}
    let path = `${getHost().apiBaseUrl}/user/attributes`
    return axios(path, {
      method: 'post',
      data: newPayload,
      withCredentials: true,
    })
      .then(response => {
        return humps.camelizeKeys(response.data)
      })
      .then( data => {
        dispatch(actions.gotUserNavRoles(data.cN.values, profileComplete))
        logEvent('event', analyticsTypeConstants.goals,'navigator-saved', '', analyticsRoutes.intercom)
      })
      .catch(error => {
        console.error(error)
      })
  },

  goalSelected: payload => ({type: actionTypes.GOAL_SELECTED, payload}),
  goalCompleteSelected: payload => ({type: actionTypes.GOAL_COMPLETE_SELECTED, payload}),
  getUserGoalsSuccess: (payload, goalScreen) => ({type: actionTypes.GET_USER_GOALS_SUCCESS, payload, goalScreen}),
  saveInProgress: () => ({type: actionTypes.SAVE_IN_PROGRESS}),
  saveNewGoal: (payload, prevGoalScreen) => dispatch => {
    dispatch(actions.saveInProgress())
    let path = `${getHost().apiBaseUrl}/user/goals`
    return axios(path, {
      method: 'post',
      data: payload,
      withCredentials: true,
    })
      .then(response => {
        //console.log('MAYBE SAVED A GOAL AND THE RESPONSE IS ', response)
        dispatch(actions.getUserGoals(payload.userKey, prevGoalScreen))
        logEvent('event', analyticsTypeConstants.goals, 'goal-saved', '', analyticsRoutes.intercom)
      })
      .catch(error => {
        console.error(error)
      })
  },
  completeGoal: (payload, goalpayload) => dispatch => {
    let path = `${getHost().apiBaseUrl}/user/goals/complete`
    return axios(path, {
      method: 'post',
      data: payload,
      withCredentials: true,
    })
      .then(response => {
        //console.log('MAYBE SAVED A GOAL AND THE RESPONSE IS ', response)
        //dispatch(actions.updateGoal(goalpayload))
        dispatch(actions.getUserGoals(payload.userKey, 'goalsOverview'))
        logEvent('event', analyticsTypeConstants.goals,'goal-completed', '', analyticsRoutes.intercom)
      })
      .catch(error => {
        console.error(error)
      })
  },
  updateGoal: (payload, goalScreen) => dispatch => {
    console.log('UPDATING GOAL WITH PAYLOAD ', payload, goalScreen)
    let path = `${getHost().apiBaseUrl}/user/goals`
    return axios(path, {
      method: 'post',
      data: payload,
      withCredentials: true,
    })
      .then(response => {
        dispatch(actions.getUserGoals(payload.userKey, goalScreen))
      })
      .catch(error => {
        console.error(error)
      })
  },
  updatedUserGoal: payload => ({type: actionTypes.UPDATED_USER_GOAL, payload}),
  editTheUserGoal: payload => ({type: actionTypes.EDIT_USER_GOAL, payload}),
  deleteGoal: payload => dispatch => {
    let path = `${getHost().apiBaseUrl}/user/goals`
    let theData = {
      ugKey: payload.uGKey,
    }
    return axios(path, {
      method: 'delete',
      data: theData,
      withCredentials: true,
    })
      .then(response => {
        dispatch(actions.getUserGoals(payload.userKey, 'goalsOverview'))
      })
      .catch(error => {
        console.error(error)
      })
  }
}
// Reducer
const defaultState = {
  selectedRoles: [],
  desc1Visible: false,
  desc2Visible: false,
  desc3Visible: false,
  currentRole: {},
  goalScreen: 'overview',
  navScreen: 'overview',
  goalCategories: [],
  goalRoles: [],
  selectedGoalCategory: {},
  showCategoryDescriptions: true,
  userGoals:[],
  disableSort: false,
  isNavigatorHelpOpen: false,
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_NAV_STATE:
      return state
    case actionTypes.NAVIGATOR_HELP_OPEN:
    return {
      ...state,
      isNavigatorHelpOpen: true,
    }
    case actionTypes.NAVIGATOR_HELP_CLOSE:
    return {
      ...state,
      isNavigatorHelpOpen: false,
    }
    case actionTypes.SAVE_IN_PROGRESS:
      return {
        ...state,
        saveInProgress: true,
      }
    case actionTypes.RESET_SELECTED:
      let completedGoals3 = state.realCompleted.map(page => {
        return page.map(goal => {
          return {
            ...goal,
            selected: false
          }
        })
      })
      let theUserGoals = state.userGoals.map(goal => {
        return {
          ...goal,
          selected: false,
        }
      })
      return {
        ...state,
        anySelected: false,
        realCompleted: completedGoals3,
        userGoals: theUserGoals,
      }
    case actionTypes.NAV_GO_TO:
      return {
        ...state,
        navScreen: action.where,
      }
    case actionTypes.GOT_USER_NAV_ROLES:
      const navigatorCompleted = action.payload.length > 0
      let theSelectedRoles = []
      const newGoalRoles = state.goalRoles.map(role => {
        const selectedRole = action.payload.filter(selected => selected.aVKey === role.aVKey)
        if (selectedRole.length > 0) {
          const theFullRole = state.navRoles.values.filter(selected => selected.aVKey === role.aVKey)
          if (theFullRole.length > 0) {
            theSelectedRoles.push(theFullRole[0])
          }
        }
        const theSelectedRole = selectedRole.length > 0 ? true : false
        return {
          ...role,
          selected: theSelectedRole
        }
      })
      return {
        ...state,
        goalRoles: newGoalRoles,
        userNavRoles: action.payload,
        selectedRoles: theSelectedRoles,
        navScreen: navigatorCompleted ? 'navigatorCompleted' : action.profileComplete ? 'SelectGoalRoles' : 'overview',
        rolesReadyToSave: false,
      }
    case actionTypes.GOT_NAVIGATOR_ROLES:
    //  console.log('GOT NAVIGATOR ROLES!!!', action)
      return {
        ...state,
        navRoles: action.payload,
      }
    case actionTypes.CHANGE_COMPLETED_PAGE:
      let completedGoals4 = state.realCompleted.map(page => {
        return page.map(goal => {
          return {
            ...goal,
            selected: false
          }
        })
      })
      return {
        ...state,
        currentCompletedPage: action.payload,
        anySelected: false,
        realGoals: completedGoals4,
      }

    case actionTypes.CONFIGURE_NAVIGATOR:
      const zaRoles = state.goalRoles.length > 0 ? state.goalRoles :  state.navRoles ? state.navRoles.values : []
      let colCount = action.payload ? 2 : 3
      let curCell = 0
      let curRow = 1
      let NewRoles = zaRoles.map(role => {
        curCell += 1
        const tRole = {
          ...role,
          descRow: curRow,
          descAfter: curCell === colCount,
        }
        if (curCell === colCount) {
          curCell = 0
          curRow += 1
        }
        return tRole
      })
      return {
        ...state,
        goalRoles: NewRoles
      }
    case actionTypes.TOGGEL_DRAG_DROP:
      return {
        ...state,

      }
    case actionTypes.SET_SHOW_GOAL_DESCRIPTION:
      return {...state,
        showCategoryDescriptions: action.showGoalDescription,
      }
    case actionTypes.GOAL_SELECTED:
      let anySelected = false
      let userGoals = state.userGoals.map(goal => {
        anySelected = action.payload.uGKey === goal.uGKey ? goal.selected ? false : true : anySelected
        return {
          ...goal,
          selected: goal.selected ? false : action.payload.uGKey === goal.uGKey
        }
      })
      let theCompletedGoals = state.completedGoals.map(goal => {
        return {
          ...goal,
          selected: false,
        }
      })

      return {
        ...state,
        userGoals,
        completedGoals: theCompletedGoals,
        anySelected,
      }
    case actionTypes.GOAL_COMPLETE_SELECTED:
      let anySelected2 = false

      let completedGoals2 = state.realCompleted.map(page => {
        return page.map(goal => {
          anySelected2 = action.payload.uGKey === goal.uGKey ? goal.selected ? false : true : anySelected2
          return {
            ...goal,
            selected: goal.selected ? false : action.payload.uGKey === goal.uGKey
          }
        })

      })
      let theUserGoals2 = state.userGoals.map(goal => {
        return {
          ...goal,
          selected: false,
        }
      })

      return {
        ...state,
        userGoals: theUserGoals2,
        realCompleted: completedGoals2,
        anySelected: anySelected2,
      }
    case actionTypes.REORDERED_GOALS:
      return {...state,
        userGoals: action.payload,
      }
    case actionTypes.GOAL_ROLE_CLICKED:
      let selectedRoles = state.selectedRoles
      if (action.payload.selected) {
        selectedRoles = selectedRoles.filter(item => item.titleContentDef != action.payload.titleContentDef)
      } else {
        selectedRoles.push(action.payload)
        if (selectedRoles.length > 2) {
          selectedRoles.shift()
        }
      }
      let theRoles = state.goalRoles.map(role => {
        const selected = selectedRoles.filter(item => role.titleContentDef === item.titleContentDef)
        return {
          ...role,
          selected: selected.length != 0 ? true : false
        }
      })
      const desc1Visible = action.payload.descRow === 1 && !action.payload.selected
      const desc2Visible = action.payload.descRow === 2 && !action.payload.selected
      const desc3Visible = action.payload.descRow === 3 && !action.payload.selected
      const rolesReadyToSave = selectedRoles.length === 2
      return {...state,
        currentRole: action.payload,
        desc1Visible,
        desc2Visible,
        desc3Visible,
        goalRoles: theRoles,
        selectedRoles,
        rolesReadyToSave,
      }
    case actionTypes.SAVED_GOAL_ROLES:

      return state
    case actionTypes.GOAL_CATEGORIES_LOADED:
      return {
        ...state,
        // The goal categories have a sort order, but do not come back in that order so must sort here
        goalCategories: action.payload.sort((a,b) => {
          if(a.sortOrder && b.sortOrder) { return a.sortOrder - b.sortOrder }
          return 0
        }),
      }
    case actionTypes.GO_TO:
      let whichView2 = 'overview'
      switch(state.goalScreen) {
        case 'editGoal':
        case 'addGoal':
          whichView2 = state.hasGoals ? 'goalsOverview' : 'overview'
      }
      if (action.where) {
        whichView2 = action.where
      }
      //console.log('GOALS GO_TO FIRING', action, whichView2)
      //console.log('IN GOTO WITH CHANGING VIEW TO FROM', whichView2, action.where)

      return {
        ...state,
        goalScreen: whichView2,
        showCategoryDescriptions: false,
      }
    case actionTypes.CLICKED_ADD_GOAL:
      //console.log('OK CLICKED ADD GOAL!!!!')
      return {
        ...state,
        prevGoalScreen: state.goalScreen,
        goalScreen: 'addGoal',
        showCategoryDescriptions: true,
        selectedGoalCategory: "",
      }
    case actionTypes.EDIT_USER_GOAL:
      console.log('EDIT USER GOAL!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
      return {
        ...state,
        currentGoal: action.payload,
        goalScreen: 'editGoal',
        showCategoryDescriptions: false,
      }
    case actionTypes.SELECTED_GOAL_CATEGORY:

    console.log('SELECTED_GOAL_CATEGORY',state.showCategoryDescriptions )
      return {
        ...state,
        selectedGoalCategory: action.payload,
        showCategoryDescriptions: false,
      }
    case actionTypes.GET_USER_GOALS_SUCCESS:
    //  console.log('GOT USER GOALS SUCCESS WITH PAYLOAD ', action.payload)
      let whichView = action.payload.length > 0 ? 'goalsOverview' : 'overview'
      whichView = action.goalScreen ? action.goalScreen : whichView
      var chatSort = sortWith([
        ascend(prop('priority'))])
      let newGoals = chatSort(action.payload)
      let completedGoals = chatSort(action.payload)
      let inProgress = newGoals.length
      let priority = 1
      newGoals = newGoals.filter(goal => goal.isCompleted === 0)
      newGoals.map(goal => {
        goal.priority = priority
        priority += 1
        if (goal.isCompleted) {
          inProgress -= 1
        }
      })
      priority = 1
      completedGoals = completedGoals.filter(goal => goal.isCompleted === 1)
      let realCompleted = []
      let completedPage = []
      let goalCount = 0
      completedGoals.map(goal => {
        goalCount += 1
        completedPage.push(goal)
        if (goalCount === 3) {
          realCompleted.push(completedPage)
          goalCount = 0
          completedPage = []
        }
        goal.priority = priority
        priority += 1
        if (goal.isCompleted) {
          inProgress -= 1
        }
      })
      if (goalCount > 0) {
        realCompleted.push(completedPage)
      }
      let currentCompletedPage = 0
      if (realCompleted.length > 0) {
        currentCompletedPage = 1
      }
      const hasGoals = newGoals.length > 0 || completedGoals.length > 0
      whichView = hasGoals ? whichView : 'overview'
      switch (newGoals.length) {
        case 0:
          newGoals.push(getBlankGoal(1))
          newGoals.push(getBlankGoal(2))
          newGoals.push(getBlankGoal(3))
          break
        case 1:
          newGoals.push(getBlankGoal(2))
          newGoals.push(getBlankGoal(3))
          break
        case 2:
          newGoals.push(getBlankGoal(3))
          break
      }
      return {
        ...state,
        userGoals: newGoals,
        goalScreen: whichView,
        anySelected: false,
        inProgress,
        completedGoals,
        hasGoals,
        realCompleted,
        currentCompletedPage,
        saveInProgress: false,
      }
    default:
      return state
  }
}

const getPaging = completedGoals => {

}
