// import React from 'react'
// import styled from 'styled-components'
//
// const StyledContainer = styled.div`
// .spinner {
//   -webkit-animation: rotator 1.4s linear infinite;
//   animation: rotator 1.4s linear infinite;
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   margin-left: -32px;
//   margin-top: -32px;
// }
//
//   @-webkit-keyframes rotator {
//     0% {
//       -webkit-transform: rotate(0deg);
//       transform: rotate(0deg);
//     }
//     100% {
//       -webkit-transform: rotate(270deg);
//       transform: rotate(270deg);
//     }
//   }
//
//   @keyframes rotator {
//     0% {
//       -webkit-transform: rotate(0deg);
//       transform: rotate(0deg);
//     }
//     100% {
//       -webkit-transform: rotate(270deg);
//       transform: rotate(270deg);
//     }
//   }
//   .path {
//     stroke-dasharray: 187;
//     stroke-dashoffset: 0;
//     -webkit-transform-origin: center;
//     transform-origin: center;
//     -webkit-animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
//     animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
//   }
//
//   @-webkit-keyframes colors {
//     0% {
//       stroke: #4285f4;
//     }
//     25% {
//       stroke: #de3e35;
//     }
//     50% {
//       stroke: #f7c223;
//     }
//     75% {
//       stroke: #1b9a59;
//     }
//     100% {
//       stroke: #4285f4;
//     }
//   }
//
//   @keyframes colors {
//     0% {
//       stroke: #4285f4;
//     }
//     25% {
//       stroke: #de3e35;
//     }
//     50% {
//       stroke: #f7c223;
//     }
//     75% {
//       stroke: #1b9a59;
//     }
//     100% {
//       stroke: #4285f4;
//     }
//   }
//   @-webkit-keyframes dash {
//     0% {
//       stroke-dashoffset: 187;
//     }
//     50% {
//       stroke-dashoffset: 46.75;
//       -webkit-transform: rotate(135deg);
//       transform: rotate(135deg);
//     }
//     100% {
//       stroke-dashoffset: 187;
//       -webkit-transform: rotate(450deg);
//       transform: rotate(450deg);
//     }
//   }
//   @keyframes dash {
//     0% {
//       stroke-dashoffset: 187;
//     }
//     50% {
//       stroke-dashoffset: 46.75;
//       -webkit-transform: rotate(135deg);
//       transform: rotate(135deg);
//     }
//     100% {
//       stroke-dashoffset: 187;
//       -webkit-transform: rotate(450deg);
//       transform: rotate(450deg);
//     }
//   }
// `
// const Spinner = () => (
//   <StyledContainer>
//     <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
//       <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
//     </svg>
//   </StyledContainer>
// )
//
// export default Spinner
//
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import {aria} from '../../wcag';
//for some readon styled-components isnt working in this component
//import styled from 'styled-components'

const styles = {
  spinner: {
    WebkitAnimation: 'rotator 1.4s linear infinite',
    animation: 'rotator 1.4s linear infinite',
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginLeft: '-32px',
    marginTop: '-32px',
  },
}

const Spinner = () => {
  return (
    <div style={styles.spinner} aria-hidden='true' role={aria.roles.spinbutton}>
      <CircularProgress className="spinner" size={50} />
    </div>
  )
}

export const SpinnerClean = CircularProgress

export default Spinner
