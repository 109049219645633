import React, { Component } from 'react'
import {connect} from 'react-redux'
import { withTheme } from 'styled-components';
import { disableScroll } from '../../shared/utility'
import {actions} from '../../redux/modules/frontend';
import {StyledContainer} from './styles';
import { logEvent } from '../../shared/analytics';
import { analyticsTypes, analyticsActions } from '../../constants/analytics';
import './style.css';
import { Wrapper, Button, Menu, MenuItem } from 'react-aria-menubutton';
import {aria} from '../../wcag';
import RoleSelect from '../../components/RoleSelect/RoleSelect'
import { getRoleSelectContent } from '../../shared/content.helper'
import ProductGrid from '../../assets/icons/ProductGrid'



class TopMenu extends Component {
  state = {
    isVisible: false,
  }

  closeNav = () => {
    this.setState({
      isVisible: false,
    })

    disableScroll(false)
  }

  onClick = (item) => {
    this.closeNav()
    logEvent('event', analyticsTypes.menu, analyticsActions.navigate, {source: 'topLeftMenu:' + item.resource});
    item.onClick ? item.onClick() : this.props.onClick && this.props.onClick(item);
    this.props.clearSelectedTool();
    this.props.setItem('TopTools', 'currentTool', null);
  }

  onClickOut = e => {
    e.preventDefault()
    this.closeNav()
  }

  toggleMenu = _ => {
    const { isVisible } = this.state
    if (isVisible) this.closeNav()
    else this.setState({ isVisible: true })
  }

  getLabel = item => {
    const {c, content} = this.props;
    if (item.props && item.props.topMenuContentDef) return c(item.props.topMenuContentDef);
    if (content && content.Global) return content['Global']['Menu'][item.name];
    return '-'
  }

  handleSelection = value => {
    this.onClick(value);
  }

  renderDropdown =  (menu) => {
    if (!menu || !menu.children) return null;
    const {history, access, resources} = this.props;

    return menu.children
      .filter(obj =>  obj.type === 'divider' || obj.type === 'action' || access[obj.resource] === 'allowed' )
      .map((obj, i) => {
        let item =obj;

        if ( obj.resource) {
          item = resources[obj.resource];
          item.onClick = ['route', 'link'].includes(item.type) ? () => {
            if (item.type === 'route') history.push(item.resource);
            if (item.type === 'link') window.open(item.resource, '_blank');
            return;
          }: null;
        }

        const label = this.getLabel(item) || item.name || item.text || item.label;

        return (
          <li className="AriaMenuButton-menuItemWrapper" key={i}>
            <MenuItem className="AriaMenuButton-menuItem" value={item} text={label} role={aria.roles.link} >
              {label}
            </MenuItem>
          </li>
        );
    });
  }

  render() {
    const { assets, getMyAccountMenu, selected, styles, resourceGroups, theme } = this.props;
    const rolesMenu = resourceGroups?.rolesMenu || [];
    const hasMultiProducts = !!rolesMenu?.length
    let productName = "";

    if (hasMultiProducts) {
       const role = rolesMenu.find(r => r.selected);
       if(role) {
        productName = getRoleSelectContent(role.contentKey, role.name)
       }
    }
    return (
      <StyledContainer>
         <div className="dropdown" style={styles}>
           {rolesMenu?.length > 0 &&
            <Wrapper className="AriaMenuButton" onSelection={this.handleSelection}>
              <Button tag="button" className="AriaMenuButton-trigger">
                <ProductGrid Color={theme.menuTextColor}/>
              </Button> 
              <Menu>
                <div className="roles-AriaMenuButton-menu">
                  <RoleSelect rolesMenu={rolesMenu} assets={assets}/>
                </div>
            </Menu>
            </Wrapper>
           }
            
          <Wrapper className="AriaMenuButton" onSelection={this.handleSelection}>
            <Button tag="button" className="AriaMenuButton-trigger">
                <div className="userName">
                  {selected ? selected.name : null}
                       {hasMultiProducts &&   <div className="productName" >{productName}</div> }
                </div>
                {selected ? selected.label : null}
            </Button> 
            <Menu>
              <ul className="AriaMenuButton-menu">{this.renderDropdown(getMyAccountMenu(assets))}</ul>
            </Menu>
          </Wrapper>
          </div>
      </StyledContainer>
    );
  }
}

const mapStateToProps = state => ({
  access: state.access.access,
  resourceGroups:  state.access?.resourceGroups,
  resources: state.access.resources,
  getMyAccountMenu: assets => assets.menus && assets.menus.myAccount
          ? state.access.resourceGroups[assets.menus.myAccount]
          : state.access.resourceGroups['group:my_account'],
  roles: state.auth && state.auth.user && state.auth.user.roles  ? state.auth.user.roles : [],
});

const mapDispatchToProps = dispatch => ({
  clearSelectedTool: () => dispatch(actions.TopTools.clearSelected()),
  setItem: (group, key, value) => dispatch(actions.setItem(group, key, value)),
})

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(TopMenu));
