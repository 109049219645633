// @flow
import * as React from 'react'

import { initAnalytics, logPageView } from '../../shared/analytics'

export default class extends React.PureComponent {
  componentDidMount() {
    if (!window.ANALYTICS_INITIALIZED) {
      initAnalytics()
      window.ANALYTICS_INITIALIZED = true;
    }
    logPageView()
  }

  render() {
    return null
  }
}
