export const actionTypes = {
  SET_TOASTER_MESSAGE: 'SET_TOASTER_MESSAGE',
  RESET_TOASTER_MESSAGE: 'RESET_TOASTER_MESSAGE',
}

export const toasterTypes = {
  ERROR: 'TOASTER_ERROR',
  SUCCESS: 'TOASTER_SUCCESS',
  WARNING: 'TOASTER_WARNING',
}

export const paymentCompleted = {
  PAYMENT_COMPLETED: 'Order completed'
}

export const userImageUploaded = {
   USER_IMAGE_UPLOADED: 'User image uploaded successfully!'
}

export const profileUpdated = {
  PROFILE_UPDATED: 'User profile updated successfully!'
}

export const scheduleCoachingAppointment = {
  SCHEDULE_COACHING_APPOINTMENT: 'Coaching session has been scheduled successfully!'
}

export const rescheduleCoachingAppointment = {
  RESCHEDULE_COACHING_APPOINTMENT: 'Coaching session has been rescheduled successfully!'
}

export const cancelCoachingAppointment = {
  CANCEL_COACHING_APPOINTMENT: 'Appointment has been cancelled successfully!'
}

export const actions = {
  show: message => ({
    type: actionTypes.SET_TOASTER_MESSAGE,
    message,
    toasterType: '',
  }),
  showError: message => ({
    type: actionTypes.SET_TOASTER_MESSAGE,
    message,
    toasterType: toasterTypes.ERROR,
  }),

  showSuccess: message => ({
    type: actionTypes.SET_TOASTER_MESSAGE,
    message,
    toasterType: toasterTypes.SUCCESS,
  }),

  showWarning: message => ({
    type: actionTypes.SET_TOASTER_MESSAGE,
    message,
    toasterType: toasterTypes.WARNING,
  }),

  reset: () => ({
    type: actionTypes.RESET_TOASTER_MESSAGE,
  }),
}

const defaultState = {
  message: null,
  showToaster: false,
  toasterType: null,
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SET_TOASTER_MESSAGE:
      return { ...state, showToaster: true, message: action.message, toasterType: action.toasterType }
    case actionTypes.RESET_TOASTER_MESSAGE:
      return { ...state, showToaster: false, message: null, toasterType: null }
    default:
      return state
  }
}
