import React from "react"
import styled from 'styled-components'
import { mobile } from '../../styles/media-queries'

const StyledImg = styled.img`
  height: 80px;
  margin: 0px;

  &.footer {
    margin: 0;
    height: 70px
  }
  &.header {
    margin: 0;
    height: 70px
  }

  @media  ${mobile} {
    height: 60px;

    &.footer {
      margin: 0;
      height: 60px
    }
    &.header {
      margin: 0;
      height: 60px
    }
}`

export default ({ type = "",dark = false, alt="University of Phoenix, Korn Ferry Advance"}) => (
    <StyledImg className={type} alt={alt}
      src={`/static/images/logos/${
        dark ? 'UOP-Logo-dark' : 'UOP-Logo'
      }.png`}
    />
)
