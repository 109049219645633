import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Spinner from "../Spinner/Spinner";
import { getHost } from "../../shared/utility";
import { ButtonSecondaryAction, ButtonMainAction } from "../../styles/common2"

const DialogItemContainer = styled.div`
  padding: 0 10px 0 24px;

  .consent-input {
    margin-bottom: 20px;
  }
  .consent-input input {
    margin-right: 0.5rem;
    position:relative;
    top: 2px;
  }

  .consent-input label {
    cursor: pointer;
  }

  .consent-input span {
    line-height: 1.4;
    b {
      font-family: ${props => props.theme.fontTwo};
    }
  }
`;

const ConsentDataItem = ({ name, value, onChange, text }) => {
  return (
    <div className="consent-input">
      {name ? (
        <label>
          <input
            className="checkbox"
            name={name}
            type="checkbox"
            checked={value}
            onChange={onChange}
          />

          <span
            dangerouslySetInnerHTML={{
              __html: text
            }}
          />
        </label>
      ) : (
        <span
          dangerouslySetInnerHTML={{
            __html: text
          }}
        />
      )}
    </div>
  );
};

class ConsentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValues: {
        privacy: props.consentForPrivacy ? props.consentForPrivacy : false,
        marketing: props.consentForMarketing ? props.consentForMarketing : false,
        partner: props.consentForPartner ? props.consentForPartner : false
      }
    };
  }
  handleUserInput = e => {
    const name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    let { formValues } = this.state;
    formValues[name] = value;
    this.setState({ formValues });
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.isOpen || nextProps.isOpen) {
      return true;
    }
    return false;
  }

  onSubmit = () => {
    const { entityContext, onAccept, showMarketingConsent, showPartnerConsent } = this.props;
    const { privacy, marketing, partner } = this.state.formValues;
    const data = {
      entityKey: entityContext[0],
      privacy,
      marketing: showMarketingConsent ? marketing : null,
      partner: showPartnerConsent ? partner : null,
    };
    onAccept(data);
  };

  render() {
    const {
      fullScreen,
      isOpen,
      onDecline,
      loading,
      c,
      showMarketingConsent,
      showPartnerConsent
    } = this.props;

    const title = c("Modal.Register.TermsOfUseTitle");

    return (
      <Dialog fullScreen={fullScreen} open={isOpen} aria-labelledby={title}>
        <DialogTitle id="Consent-dialog-title">
        {title}
        </DialogTitle>
        <DialogItemContainer>
          <ConsentDataItem
            name="privacy"
            value={this.state.formValues.privacy}
            onChange={this.handleUserInput}
            text={c("Pages.Register.Privacy")}
          />

          {showPartnerConsent && (
            <ConsentDataItem
              name="partner"
              value={this.state.formValues.partner}
              onChange={this.handleUserInput}
              text={c("Pages.Register.PartnerConsent")}
            />
          )}


          {showMarketingConsent && (
            <ConsentDataItem
              name="marketing"
              value={this.state.formValues.marketing}
              onChange={this.handleUserInput}
              text={c("Pages.Register.Marketing").replace(
                "{{UIHost}}",
                getHost().mainHost
              )}
            />
          )}

          <ConsentDataItem
            text={c("Pages.Register.Terms").replace(
              "{{{UIHost}}}",
              getHost().mainHost
            )}
          />
        </DialogItemContainer>

        <DialogActions>
          <ButtonSecondaryAction onClick={onDecline} disabled={loading}>
            {c("Modal.Register.ButtonDeclineExit")}
          </ButtonSecondaryAction>
          <ButtonMainAction
            onClick={this.onSubmit}
            disabled={loading || (!this.state.formValues.privacy || (this.props.showPartnerConsent && !this.state.formValues.partner))}
          >
            {c("Modal.Register.ButtonAcceptContinue")}
          </ButtonMainAction>
        </DialogActions>
        {loading && <Spinner />}
      </Dialog>
    );
  }
}

ConsentModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(ConsentModal);
