import React, { Component } from 'react'
import { ToastContainer, toast, Slide } from 'react-toastify'

import { connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import { actions as userActions } from '../../redux/modules/user-actions'
import { actions as toasterActions, toasterTypes } from '../../redux/modules/toaster'
import getContent from '../../shared/content.helper';

class Toaster extends Component {
  state = {}
  static getDerivedStateFromProps(nextProps) {
    if (nextProps.toaster.showToaster) {
      switch (nextProps.toaster.toasterType) {
        case toasterTypes.ERROR:
          toast.error(nextProps.toaster.message)
          break
        case toasterTypes.SUCCESS:
          toast.success(nextProps.toaster.message)
          break
        case toasterTypes.WARNING:
          toast.warn(nextProps.toaster.message)
          break
        default:
          toast(nextProps.toaster.message)
          break
      }
      nextProps.reset()
    }

    if (nextProps.userActions.isRollbackAppointmentError) {
      toast.error(nextProps.userActions.rollbackAppointmentErrorData)
      nextProps.rollbackAppointmentResetMessages()
    }
    if (nextProps.userActions.showRollbackAppointmentSuccessMessage) {
      toast.success('Appointment rollback success!!')
      nextProps.rollbackAppointmentResetMessages()
    }

    if (nextProps.userActions.isCancelAppointmentError) {
      toast.error(nextProps.userActions.cancelAppointmentErrorData)
      nextProps.cancelAppointmentResetMessages()
    }
    if (nextProps.userActions.showCancelAppointmentSuccessMessage) {
      nextProps.cancelAppointmentResetMessages()
    }
    return null
  }

  render() {
    return (
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnVisibilityChange
        draggable={false}
        transition={Slide}
        pauseOnHover
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    userActions: {
      ...state.userActions,
    },
    toaster: {
      ...state.toaster,
    },
  }
}

const mapDispatchToProps = dispatch => {
  return {
    rollbackAppointmentResetMessages: () => dispatch(userActions.rollbackAppointmentResetMessages()),
    cancelAppointmentResetMessages: () => dispatch(userActions.cancelAppointmentResetMessages()),
    reset: () => dispatch(toasterActions.reset()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Toaster)
