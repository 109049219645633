import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import withMobileDialog from '@material-ui/core/withMobileDialog'
import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from '@material-ui/core/DialogContentText'
// import CloseIcon from "@material-ui/icons/Close"
// import IconButton from "@material-ui/core/IconButton"
// import styled from "styled-components"
import { Button } from '../../styles/common'

//import Spinner from '../Spinner/Spinner'


class SelectPartnerModal extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.isOpen || nextProps.isOpen) {
      return true
    }
    return false
  }

  render() {
    const { fullScreen, isOpen, onClose , partners, roleChange , logout } = this.props

    const title = "";

    return (
      <Dialog fullScreen={fullScreen} open={isOpen} onClose={onClose} aria-labelledby={title}>

        <DialogTitle id="rollback-dialog-title">
        Select a Partner/Role
        </DialogTitle>
        <DialogContent>
          <MenuList>
            {partners.map(partner => {
                                  return (
                                    <MenuItem key={partner.PartnerKey + partner.RoleName} value={partner.PartnerKey + partner.RoleName} onClick={() => roleChange(partner.RoleCode, partner.EntityKey )} >
                                      {partner.SearchPartnerName} / {partner.RoleName}
                                    </MenuItem>
                                  )
                                })}
          </MenuList>
        </DialogContent>
        <DialogActions>
           <Button onClick={logout}>
            Logout
          </Button> 
        </DialogActions>
      </Dialog>
    )
  }
}

SelectPartnerModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
}

export default withMobileDialog()(SelectPartnerModal)
