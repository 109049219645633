import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Spinner from '../Spinner/Spinner';
import WithAccess from '../../hoc/WithAccess';


const CustomRedirect = props => {
  let redirectUrl = `/login${props.location.pathname &&
    (props.location.pathname !== '/' || props.location.search.includes('context=app')) ?
    '?redirect=' + encodeURIComponent(props.location.pathname + props.location.search) : ''}`
  console.log('CustomRedirect: ', props)
  console.log('CustomRedirect => redirectUrl: ', redirectUrl)
  if (props.loading)
    return <Spinner />;

  return <Redirect to={redirectUrl} />
}


export default ({ component: C, props: cProps, sub, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const { location, match, history } = props;
        if (!window.kfadvance) window.kfadvance = { location, match, history };

        if (cProps.isAuthenticated) {
          if (!sub) return <WithAccess component={C} props={{ ...props, ...cProps }} />;
          return (
            <Switch>
              <Route {...rest} exact={true}>
                <WithAccess component={C} props={{ ...props, ...cProps }} />
              </Route>

              {sub.map(obj => {
                return (
                  <Route path={obj.path} exact={obj.exact}>
                    <WithAccess component={obj.component} props={{ ...props, ...cProps, match: { ...props.match, path: obj.path } }} />)}
                  </Route>
                )
              })}
            </Switch>
          )
        }
        return <CustomRedirect {...props} loading={cProps.loading} />
      }}
    />
  )
}


// export default ({ component: C, props: cProps, ...rest }) => {
//   return (
//     <Route
//       {...rest}
//       render = {props => {
//         const {location, match, history} = props;
//         if (!window.kfadvance) window.kfadvance = {location, match, history};

//         return cProps.isAuthenticated 
//             ? <WithAccess component={C} props={{...props, ...cProps}} />
//             : <CustomRedirect {...props} loading={cProps.loading} />
//       }}
//     />
//   )
// }
