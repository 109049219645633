import { Theme as theTheme, Assets as theAssets } from './Default.js'
import SGLogo from "../../components/Logos/SGLogo"



export const Assets = history => {

  return { ...theAssets(history), LogoComponent: SGLogo }
}
export const Theme = () => {
  return theTheme()
}
