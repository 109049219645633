import React, { Fragment } from 'react';
import {connect} from 'react-redux';
import MenuAppBar from '../../components/MenuAppBar/MenuAppBar'
import { getHost } from '../../shared/utility'
import TopTools from '../../components/TopTools'
import MqttContext from '../../mqttClient-context'
import {actions} from '../../redux/modules/frontend';

const accountNavLinks = (history, userKey, purchState, content) => {
  var nav_links = []
  const hasServices = true //purchState ? purchState.services.partnerAccess : false
  const hasFAQ = true //purchState ? purchState.faq.partnerAccess : false
  const topMenu = content && content.Global ? content.Global.TopMenuDrop : undefined
  nav_links.push({
    text: topMenu ? topMenu.MyProfile : '',
    onClick: () => history.push('/profile'),
    resource: '/profile',
  })
  nav_links.push({
    text: topMenu ? topMenu.AccountPreferences : 'Account Preferences',
    onClick: () => history.push('/preferences'),
    resource: '/preferences',
  })
  if (hasServices) {
    nav_links.push({
      text: topMenu ? topMenu.Services : '',
      onClick: () => history.push('/services'),
      resource: '/services',
    })
    nav_links.push({
      text: topMenu ? topMenu.AboutKF : '',
      onClick: () => history.push('/about'),
      resource: '/about',
    })
  }

  if (hasFAQ) {
    nav_links.push({
      text: topMenu ? topMenu.Faq : '',
      onClick: () => history.push('/faq'),
      resource: '/faq',
    })
  }

  nav_links.push({
    text: topMenu ? topMenu.Logout : '',
    onClick: () => history.push('/account/logout'),
    resource: '/account/logout',
  })
  return nav_links
}

const redirect = (url, history) => {
  if (history.location && history.location.pathname && history.location.pathname === url) {
    return false
  }
  history.push(url)
}

const drawerLinks = (history, userKey, data) => {
  return [
    {
      icon: 'report',
      isVisible: true,
      text: 'Home',
      onClick: () => redirect('/home', history),
    },
    {
      icon: 'report',
      isVisible: true,
      text: 'Articles',
      //onClick: () => window.location.assign(getHost().insightsUrl),
      onClick: () => redirect('/insights', history),
    },
    {
      icon: 'report',
      isVisible: true,
      text: 'Resume',
      onClick: () => redirect('/resume', history),
    },
    {
      icon: 'report',
      isVisible: true,
      text: 'Assessment',
      onClick: () => redirect('/assessment', history),
    },
    {
      icon: 'report',
      isVisible: true,
      text: 'Interview Prep',
      onClick: () => redirect('/interviewprep', history),
    },
    {
      icon: 'report',
      isVisible: true,
      text: 'Career Coach',
      onClick: () => redirect('/careercoach', history),
    },
    {
      icon: 'report',
      isVisible: true,
      text: 'Paycheck',
      onClick: () => redirect('/paycheck', history),
    },
    {
      icon: 'report',
      isVisible: true,
      text: 'Jobcast',
      onClick: () => redirect('/jobs', history),
    },
  ]
}

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  render() {
    const { hideHeader, assets, purchState, content, history, theme, user, userKey, isAuthenticated, data, c } = this.props;
    if (hideHeader) return null;
    //console.log('HEADER RENDER WITH PROPS ', this.props)
    const toolbarNavLinks = assets && assets.toolbarNavLinks ? assets.toolbarNavLinks : []
    const iconColor = assets && assets.iconColor ? assets.iconColor : '#dddddd'
    const iconColorSelected = assets && assets.iconColorSelected ? assets.iconColorSelected : '#ffffff'
    if (!isAuthenticated) return null
    return (
      <Fragment>
        <MqttContext.Consumer>
          {MqttClient =>
              <MenuAppBar
                {...this.props}
                img={props => theme.homeBut}
                drawerSide="top"
                drawerWidth={250}
                accountNavLinks={accountNavLinks(history, userKey, purchState, content)}
                navHome={() => history.push('/')}
                isAuthenticated={isAuthenticated}
                user={user}
                drawerLinks={drawerLinks(history, userKey, data)}
                assets={assets}
                history={history}
                toolbarNavLinks={toolbarNavLinks}
                c={c}
                MqttClient={MqttClient}
                content={content}
              />}
        </MqttContext.Consumer>

        <TopTools
          accountNavLinks={accountNavLinks(history, userKey, purchState)}
          toolbarNavLinks={toolbarNavLinks}
          iconColor={iconColor}
          iconColorSelected={iconColorSelected}
          content={content}
          purchState={purchState}
          assets={assets}
          history={history}
          c={c}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  hideHeader: state.frontend.Header.hideHeader,
});

const mapDispatchToProps = dispatch =>({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
