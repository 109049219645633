import humps from 'humps'
import { updateObject, api, getHost } from '../../shared/utility'

import { actions as actionToaster } from './toaster'

// Constants

export const actionTypes = {
  GET_COACHES_SUCCESS: 'GET_COACHES_SUCCESS',
  GET_COACHES_START: 'GET_COACHES_START',
  GET_COACHES_FAIL: 'GET_COACHES_FAIL',
}

// Action Creators

export const actions = {
  getCoachesSuccess: (response, coachType, partnerKey) => ({
    type: actionTypes.GET_COACHES_SUCCESS,
    response,
    coachType,
    partnerKey,
  }),

  getCoachesFail: error => ({
    type: actionTypes.GET_COACHES_FAIL,
    error,
  }),

  getCoachesStart: () => ({
    type: actionTypes.GET_COACHES_START,
  }),

  getCoaches: (coachType, purchaseBundleCode) => dispatch => {
    dispatch(actions.getCoachesStart())

    const path = `${getHost().apiBaseUrl}/product/getcoaches/${coachType}/${purchaseBundleCode}`

    return api
      .get(path)
      .then(response => {
        return humps.camelizeKeys(response.data)
      })
      .then(response => {
        dispatch(actions.getCoachesSuccess(response, coachType, purchaseBundleCode))
      })
      .catch(error => {
        dispatch(actions.getCoachesFail(error))
        dispatch(actionToaster.showError(error))
      })
  },
}

// Reducer

const defaultState = {
  loading: false,
  coaches: {},
  error: null,
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_COACHES_START:
      return updateObject(state, { loading: true })
    case actionTypes.GET_COACHES_SUCCESS:
      const c = state.coaches
      if (!c[action.coachType]) {
        c[action.coachType] = {}
      }
      c[action.coachType][action.partnerKey] = action.response
      return { ...state, loading: false, coaches: c }
    case actionTypes.GET_COACHES_FAIL:
      console.log(action.error)
      return updateObject(state, { loading: false, error: action.error })
    default:
      return state
  }
}
