import React, {Fragment} from 'react'

export const SquaresIcon = props => (
  <svg width={props.size || 50} height={props.size || 50} viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs />
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Dash-Menu-Squares" fill={props.BgColor || '#00A3B2'} fillRule="nonzero">
        <g id="grid-copy-2">
          <g id="Combined-Shape">
            <path
              d="M1.40625,1.5 L1.40625,5.25 L4.921875,5.25 L4.921875,1.5 L1.40625,1.5 Z M0.703125,0 L5.625,0 C6.01332521,0 6.328125,0.335786438 6.328125,0.75 L6.328125,6 C6.328125,6.41421356 6.01332521,6.75 5.625,6.75 L0.703125,6.75 C0.314799785,6.75 0,6.41421356 0,6 L0,0.75 C0,0.335786438 0.314799785,0 0.703125,0 Z M8.4375,0 L13.359375,0 C13.7477002,0 14.0625,0.335786438 14.0625,0.75 L14.0625,6 C14.0625,6.41421356 13.7477002,6.75 13.359375,6.75 L8.4375,6.75 C8.04917479,6.75 7.734375,6.41421356 7.734375,6 L7.734375,0.75 C7.734375,0.335786438 8.04917479,0 8.4375,0 Z M9.140625,5.25 L12.65625,5.25 L12.65625,1.5 L9.140625,1.5 L9.140625,5.25 Z M8.4375,8.25 L13.359375,8.25 C13.7477002,8.25 14.0625,8.58578644 14.0625,9 L14.0625,14.25 C14.0625,14.6642136 13.7477002,15 13.359375,15 L8.4375,15 C8.04917479,15 7.734375,14.6642136 7.734375,14.25 L7.734375,9 C7.734375,8.58578644 8.04917479,8.25 8.4375,8.25 Z M9.140625,13.5 L12.65625,13.5 L12.65625,9.75 L9.140625,9.75 L9.140625,13.5 Z M0.703125,8.25 L5.625,8.25 C6.01332521,8.25 6.328125,8.58578644 6.328125,9 L6.328125,14.25 C6.328125,14.6642136 6.01332521,15 5.625,15 L0.703125,15 C0.314799785,15 0,14.6642136 0,14.25 L0,9 C0,8.58578644 0.314799785,8.25 0.703125,8.25 Z M1.40625,13.5 L4.921875,13.5 L4.921875,9.75 L1.40625,9.75 L1.40625,13.5 Z"
              id="path-1"
            />
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export class HomeIcon extends React.Component {
  render() {
    const { BgColor } = this.props
    return (
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="60" height="60" id="homeIcon">
        <path
          id="canvas1-bezier2"
          fill={BgColor}
          d="M 31.5,11.5 L 29.5,11.5 C 29.5,11.5 28.24,12.45 26.42,13.81 25.57,14.44 24.61,15.17 23.59,15.93 22.24,16.95 20.8,18.02 19.45,19.03 16.24,21.44 13.5,23.5 13.5,23.5 L 13.5,45.5 14.5,47.5 22.5,47.5 C 22.5,47.5 22.5,46.91 22.5,46.53 22.5,46.88 22.5,27.5 22.5,27.5 L 23.5,26.5 36.5,26.5 37.5,27.5 C 37.5,27.5 37.5,31.98 37.5,36.69 37.5,41.95 37.5,47.5 37.5,47.5 L 44.5,47.5 46.5,45.5 46.5,23.5 31.5,11.5 Z M 34.5,30.5 L 25.5,30.5 25.5,47.5 34.5,47.5 C 34.5,47.5 34.5,38.69 34.5,33.76 34.5,31.84 34.5,30.5 34.5,30.5 L 34.5,30.5 Z M 31.5,6.5 L 49.5,21.5 49.5,47.5 46.5,50.5 13.5,50.5 10.5,47.5 10.5,21.5 C 10.5,21.5 13.45,19.17 17.03,16.34 22.53,12 29.5,6.5 29.5,6.5 L 31.5,6.5 31.5,6.5 Z M 31.5,6.5"
        />
      </svg>
    )
  }
}

function LightenDarkenColor(col, amt) {

    var usePound = false;

    if (col[0] === "#") {
        col = col.slice(1);
        usePound = true;
    }

    var num = parseInt(col,16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if  (r < 0) r = 0;

    var b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) b = 255;
    else if  (b < 0) b = 0;

    var g = (num & 0x0000FF) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);

}

export class AscmIcon extends React.Component {
  render() {
    const { BgColor } = this.props
    const BgColor2 = LightenDarkenColor(BgColor, 70)
    const transform = 'scale(.7, .7)'
    return (
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="80" height="80" id="ascmIcon">
          <path id="ascmIcon-bezier3" transform={transform} stroke="none" fill={BgColor} d="M 24.82,65.77 L 12.87,65.77 12.87,68.32 24.82,68.32 24.82,65.77 24.82,65.77 Z M 31.4,50.61 C 31.4,50.6 31.4,74.43 31.4,74.43 L 6.87,74.43 6.87,59.47 24.95,59.47 24.95,56.14 6.87,56.14 6.87,50.6 31.4,50.6 31.4,50.61 Z M 31.4,50.61" />
          <path id="ascmIcon-bezier" transform={transform} stroke="none" fill={BgColor} d="M 35.37,50.23 L 57.33,50.23 57.33,55.87 40.81,55.87 40.81,59.07 62.83,59.07 66.36,68.9 84.61,68.9 84.61,74.16 62.83,74.16 59.41,64.87 35.37,64.87 35.37,50.23 Z M 35.37,50.23" />
          <path id="ascmIcon-bezier2" transform={transform} stroke="none" fill={BgColor2} d="M 33.73,71.1 L 38.3,66.67 38.3,68.41 54.63,68.41 63.37,50.68 81.75,50.68 81.75,50.68 81.75,48.81 86.95,53.21 81.75,58.1 81.75,56.03 68.07,56.03 58.56,74.65 38.3,74.65 38.3,76.34 33.73,71.1 Z M 33.73,71.1" />
          <path id="ascmIcon-bezier4" transform={transform} stroke="none" fill={BgColor} d="M 89.37,50.23 L 113.12,50.23 113.12,74.13 107.42,74.13 107.42,56.17 104.38,56.17 104.38,74.13 98.51,74.13 98.51,56.17 95.36,56.17 95.36,74.13 89.37,74.13 89.37,50.23 Z M 89.37,50.23" />
      </svg>
    )
  }
}

export class ChatIcon extends React.Component {
  render() {
    const { BgColor, BadgeCount} = this.props
    const ShowBadge = BadgeCount ? BadgeCount > 0: false
    return (
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="60" height="60" id="chat">
          <path id="chat-bezier4" stroke={BgColor} strokeWidth="3" strokeMiterlimit="10" fill="none" d="M 14.37,13.9 C 20.35,13.82 36.98,13.95 43.25,13.9 49.51,13.85 48.01,18.65 48.01,18.65 L 48.01,38.19 C 48.01,38.19 49.24,43.57 43.25,43.52 37.26,43.47 16.32,43.59 12.82,43.52 9.33,43.45 9.73,40.51 9.73,40.51 L 9.73,18.65 C 9.73,18.65 8.38,13.98 14.37,13.9 Z M 17.13,24.84 L 40.51,24.84 M 17.13,32.91 L 31.7,32.91" />
          {ShowBadge ? (
            <Fragment>
              <ellipse id="chat-oval" stroke="none" fill="rgb(234, 5, 5)" cx="12.32" cy="12.64" rx="9.39" ry="8.64" />
              <text  fill="rgb(255, 255, 255)" fontFamily="HelveticaNeue, sans-serif" fontSize="11" x="9.26" y="7.28" textAnchor="middle"><tspan x="12.32" y="16.28">{BadgeCount}</tspan></text>
            </Fragment>
          ): undefined}
      </svg>
    )
  }
}

export class InsightsIcon extends React.Component {
  render() {
    const { BgColor } = this.props
    return (
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="62" height="60" id="insights">
        <path
          id="insights-bezier"
          stroke={BgColor}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeMiterlimit="10"
          fill="none"
          d="M 38,10 L 48,31"
        />
        <path
          id="insights-bezier2"
          stroke={BgColor}
          strokeWidth="3"
          strokeMiterlimit="10"
          fill="none"
          d="M 39.5,14.5 C 39.5,14.5 23.5,22.5 21.5,23.5 19.5,24.5 25.5,36.5 27.5,35.5 29.5,34.5 45.5,26.5 45.5,26.5"
        />
        <path
          id="insights-bezier3"
          stroke={BgColor}
          strokeWidth="3"
          strokeMiterlimit="10"
          fill="none"
          d="M 22.5,26.5 C 22.5,26.5 14.5,30.5 12.5,31.5 10.5,32.5 13.5,38.5 15.5,37.5 17.5,36.5 25.5,32.5 25.5,32.5"
        />
        <path id="insights-bezier4" stroke={BgColor} strokeWidth="3" strokeLinecap="round" strokeMiterlimit="10" fill="none" d="M 25.5,48.5 L 32.5,33.5" />
        <path id="insights-bezier5" stroke={BgColor} strokeWidth="3" strokeLinecap="round" strokeMiterlimit="10" fill="none" d="M 38.5,47.5 L 34.5,32.5" />
      </svg>
    )
  }
}

export class ResumeIcon extends React.Component {
  render() {
    const { BgColor } = this.props
    return (
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="60" height="60" id="resumeIcon">
        <g id="resumeIcon-group">
          <path
            id="resumeIcon-bezier2"
            stroke="none"
            fill={BgColor}
            d="M 35,15 L 34,15 34,20 40,20 35,15 Z M 31,12 L 17,12 C 17,12 16.95,12.05 16.87,12.13 16.6,12.4 16,13 16,13 L 16,44 17,46 42,46 43,44 43,23 31,23 31,12 Z M 34,9 C 34,9 46,21 46,21 L 46,46 43,49 16,49 13,46 13,12 C 13,12 13.86,11.14 14.65,10.35 15.35,9.65 16,9 16,9 L 34,9 Z M 34,9"
          />
          <path id="resumeIcon-bezier" stroke="none" fill={BgColor} d="M 20,24.67 L 21,22 26,22 27,24.67 26,26 21,26 20,24.67 20,24.67 Z M 20,24.67" />
          <path id="resumeIcon-bezier3" stroke="none" fill={BgColor} d="M 20,31 L 21,29 37,29 38,31 37,33 21,33 20,31 Z M 20,31" />
          <path id="resumeIcon-bezier4" stroke="none" fill={BgColor} d="M 20,38 L 21,36 37,36 38,38 37,40 21,40 20,38 Z M 20,38" />
        </g>
      </svg>
    )
  }
}

export class AssessmentsIcon extends React.Component {
  render() {
    const { BgColor } = this.props
    return (
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="60" height="60" id="assessmentsIcon">
        <path
          id="assessmentsIcon-bezier"
          stroke="none"
          fill={BgColor}
          d="M 18,33 L 13,33 13,46 18,46 18,33 Z M 21,30 C 21,30 21,49 21,49 L 10,49 10,30 21,30 21,30 Z M 21,30"
        />
        <path
          id="assessmentsIcon-bezier2"
          stroke="none"
          fill={BgColor}
          d="M 33,12 L 28,12 28,46 33,46 33,12 Z M 36,9.01 C 36,9 36,49 36,49 L 25,49 25,9 36,9 36,9.01 Z M 36,9.01"
        />
        <path
          id="assessmentsIcon-bezier3"
          stroke="none"
          fill={BgColor}
          d="M 48,23 L 43,23 43,46 48,46 48,23 Z M 51,20.01 C 51,20 51,49 51,49 L 40,49 40,20 51,20 51,20.01 Z M 51,20.01"
        />
      </svg>
    )
  }
}

export class InterviewIcon extends React.Component {
  render() {
    const { BgColor } = this.props
    return (
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="62" height="60" id="interviewIcon">
        <path
          id="interviewIcon-bezier"
          stroke="none"
          fill={BgColor}
          d="M 20.5,21 C 17.46,21 16,22.24 16,25 16,27.76 17.96,30 21,30 24.04,30 25,27.76 25,25 25,22.33 23.7,21.15 20.8,21.01 20.7,21 20.6,21 20.5,21 Z M 28,25.5 C 28,29.64 24.64,33 20.5,33 16.36,33 13,29.64 13,25.5 13,21.69 15.85,18.54 19.53,18.06 19.85,18.02 20.17,18 20.5,18 24.64,18 28,21.36 28,25.5 Z M 28,25.5"
        />
        <path
          id="interviewIcon-bezier2"
          stroke="none"
          fill={BgColor}
          d="M 7,49 C 7,49 6,45 7,42 8,39 11,37 13,37 15,37 23,35.75 28,37 33,38.25 33,42 33,42 L 33,49 30,49 30,43 28,40 13,40 10,43 10,49 7,49 Z M 7,49"
        />
        <path
          id="interviewIcon-bezier5"
          stroke="none"
          fill={BgColor}
          d="M 39,11 C 36.69,13.51 38,19 38,23 38,27 38,24 36,26 34,28 41,24 41,24 41,24 47.57,26.26 51,23 53.69,20.45 53.46,14.46 51,11.29 47.78,7.15 42.13,7.6 39,11 Z M 54,10 C 57.08,13.95 57.36,20.81 54,24 49.71,28.07 40,28 40,28 L 32,30 35,23 C 35,23 33,13 37,9 42.41,3.59 49.98,4.83 54,10 Z M 54,10"
        />
        <g id="interviewIcon-group">
          <path id="interviewIcon-bezier3" stroke="none" fill={BgColor} d="M 3,2 L 5,4 6,5 11,-0 12,-0 13,1 7,8 5,8 1,4 3,2 Z M 3,2" transform="translate(38, 14)" />
        </g>
      </svg>
    )
  }
}

export class CoachingIcon extends React.Component {

  render() {
    const { BgColor, Size } = this.props
    const size = Size ? Size : 54
    const tsize = size || 50;
    const tscale = tsize / 50.0;
    const transform = `scale(${tscale}, ${tscale})`;
    return (
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={tsize} height={tsize + 6} id="coachingIcon">
        <path
          transform={transform}
          id="coachingIcon-bezier3"
          stroke="none"
          fill={BgColor}
          d="M 32,17 L 31,17 C 31,17 28.62,23.34 26.31,29.5 24.13,35.33 22,41 22,41 L 29,37 33,37 41,41 32,17 Z M 33,10 L 46,46 42,46 32,40 30,40 20,46 17,44 C 17,44 29,12 30,10 31,8 32,9 32,9 L 33,10 Z M 33,10"
        />
      </svg>
    )
  }
}

export class PaycheckIcon extends React.Component {
  render() {
    const { BgColor } = this.props
    return (
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="62" height="60" id="paycheckIcon">
        <g id="paycheckIcon-group">
          <path
            id="paycheckIcon-bezier"
            stroke="none"
            fill={BgColor}
            d="M 43,21 L 38,21 C 38,21 37.75,18.25 35,17 32.25,15.75 29,17 28,17 27,17 25,18 25,21 25,24 28,25 28,25 28,25 32,27 36,28 40,29 41.25,30.75 43,34 44.75,37.25 43,41 43,41 43,41 41.25,45.5 37,47 32.75,48.5 30,48 26,47 22,46 20,40 20,40 L 20,38 24,38 24,40 C 24,40 27,42 28,43 29,44 35,44 35,44 35,44 38,43 39,41 40,39 39,36 39,36 39,36 38,35 36,33 34,31 26,30 26,30 26,30 20,26 20,21 20,16 26,13 26,13 26,13 31,11 36,13 41,15 43,21 43,21 L 43,21 Z M 43,21"
          />
          <rect id="paycheckIcon-rectangle" stroke="none" fill={BgColor} x="30" y="8" width="3" height="44" />
        </g>
      </svg>
    )
  }
}

export class JobsIcon extends React.Component {
  render() {
    const { BgColor } = this.props
    return (
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="62" height="60" id="jobsIcon">
        <path
          id="jobsIcon-bezier3"
          stroke="none"
          fill={BgColor}
          d="M 46.5,19.5 L 15.5,19.5 14,21 14,41 15.5,43.5 46.5,43.5 48,41 48,21 46.5,19.5 46.5,19.5 Z M 47.5,16.5 C 47.5,16.5 50,17 50.5,20.5 51,24 50.5,43.5 50.5,43.5 L 47.5,46.5 14.5,46.5 11.5,43.5 C 11.5,43.5 11,24 11.5,20.5 12,17 14.5,16.5 14.5,16.5 L 47.5,16.5 47.5,16.5 Z M 47.5,16.5"
        />
        <path
          id="jobsIcon-bezier"
          stroke="none"
          fill={BgColor}
          d="M 22,13 C 22,11 25,10 25,10 L 37,10 C 37,10 40,9 40,13 40,17 40,44 40,44 L 37,44 37,13 25,13 25,44 22,44 C 22,44 22,15 22,13 Z M 22,13"
        />
      </svg>
    )
  }
}

export class LearningIcon extends React.Component {
  render() {
    const { BgColor } = this.props
    return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="62" height="60" id="learningIcon">
      <path
      id="learningIcon-bezier"
      stroke="none"
      fill={BgColor}
      d="M25.76,20.02c-0.17,0-0.34-0.03-0.51-0.08l-6.01-1.83c-0.74-0.23-1.24-0.9-1.24-1.67V8.51
          c0-0.97,0.78-1.75,1.75-1.75s1.75,0.78,1.75,1.75v6.63l4.26,1.3l4.26-1.3V8.51c0-0.97,0.78-1.75,1.75-1.75
          c0.97,0,1.75,0.78,1.75,1.75v7.93c0,0.77-0.5,1.45-1.24,1.67l-6.01,1.83C26.11,19.99,25.94,20.02,25.76,20.02z M26.38,12.4
          l12.01-4.5c0.68-0.26,1.14-0.91,1.14-1.64s-0.45-1.38-1.14-1.64l-12.01-4.5c-0.4-0.15-0.83-0.15-1.23,0l-12.01,4.5
          C12.46,4.87,12,5.52,12,6.25s0.45,1.38,1.14,1.64l12.01,4.5c0.2,0.07,0.41,0.11,0.61,0.11S26.18,12.47,26.38,12.4z M25.76,3.62
          l7.03,2.64l-7.03,2.63l-7.03-2.63L25.76,3.62z M15.5,12.26V6.25c0-0.97-0.78-1.75-1.75-1.75S12,5.29,12,6.25v6.01
          c0,0.97,0.78,1.75,1.75,1.75S15.5,13.23,15.5,12.26z M42.53,28.77V10.76c0-0.97-0.78-1.75-1.75-1.75s-1.75,0.78-1.75,1.75v18.02
          c0,0.69-0.56,1.25-1.25,1.25H10.75c-0.69,0-1.25-0.56-1.25-1.25V10.76c0-0.97-0.78-1.75-1.75-1.75S6,9.79,6,10.76v18.02
          c0,2.62,2.13,4.75,4.75,4.75h27.03C40.4,33.53,42.53,31.4,42.53,28.77z M47.82,46.29c0.86-1.39,0.94-3.16,0.21-4.62l-3.67-7.35
          c-1.32-2.64-3.98-4.29-6.94-4.29H11.11c-2.96,0-5.61,1.64-6.93,4.29L0.5,41.66c-0.73,1.46-0.65,3.23,0.21,4.62
          c0.87,1.41,2.38,2.25,4.04,2.25c0,0,0,0,0,0h39.03c0,0,0,0,0,0C45.44,48.54,46.95,47.7,47.82,46.29z M4.75,45.04
          c-0.44,0-0.83-0.22-1.06-0.59c-0.23-0.37-0.25-0.83-0.05-1.22l3.68-7.35c0.73-1.45,2.18-2.35,3.81-2.35h26.31
          c1.62,0,3.08,0.9,3.81,2.35l3.68,7.35c0.19,0.39,0.17,0.85-0.05,1.22c-0.23,0.37-0.63,0.59-1.06,0.59h0c0,0,0,0,0,0H4.75
          C4.75,45.04,4.75,45.04,4.75,45.04z M29.02,40.79c0-0.97-0.78-1.75-1.75-1.75h-6.01c-0.97,0-1.75,0.78-1.75,1.75
          s0.78,1.75,1.75,1.75h6.01C28.23,42.54,29.02,41.75,29.02,40.79z"/>
    </svg>
    )
  }
}

export const Coaching = ({theme, color, size }) => {
  const tsize = size || 60;
  const tscale = tsize / 60.0;
  const transform = `scale(${tscale}, ${tscale})`;
  const stroke = color || (theme && theme.primaryButtonText) || "rgb(0, 0, 0)";
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={tsize}
      height={tsize}
      viewBox={`0 0 ${tsize} ${tsize}`}
      className="icon-coach"
    >
    <g transform={transform}>
      <path id="coaching-bezier15" stroke={stroke} strokeWidth="2" strokeMiterlimit="10" fill="none" d="M 21.95,36.91 C 21.95,40.5 18.6,43.41 14.45,43.41 10.31,43.41 6.95,40.5 6.95,36.91 6.95,33.32 10.31,30.41 14.45,30.41 18.6,30.41 21.95,33.32 21.95,36.91 Z M 47.94,40.84 C 47.94,44.43 44.58,47.34 40.44,47.34 36.29,47.34 32.94,44.43 32.94,40.84 32.94,37.25 36.29,34.34 40.44,34.34 44.58,34.34 47.94,37.25 47.94,40.84 Z M 5.49,49.67 C 5.49,49.67 7.83,43.68 14.8,43.27 21.77,42.86 24.51,52.2 24.51,52.2 M 31.48,52.6 C 31.48,52.6 33.69,47.56 40.3,47.21 46.91,46.86 49.51,54.74 49.51,54.74 M 28.64,23.26 L 24.02,23.26 18.78,26.77 15.13,23.26 C 15.13,23.26 10.87,23.33 9.2,23.26 7.54,23.19 5.57,21.7 5.49,20.13 5.42,18.57 5.34,9.5 5.49,7.66 5.65,5.82 7.09,5.41 9.2,5.4 11.31,5.38 28.29,5.3 30.31,5.4 32.33,5.5 33.46,8.75 33.46,8.75 33.46,8.75 50.11,8.8 52.1,8.75 54.1,8.69 54.36,10.43 54.35,11.63 54.35,12.82 54.36,23.88 54.35,24.98 54.35,26.09 53.6,26.76 52.1,26.77 50.61,26.78 43.62,26.77 43.62,26.77 43.62,26.77 38.49,31.29 38.49,30.97 38.49,30.79 35.74,26.77 35.74,26.77 35.74,26.77 32.63,26.64 31.34,26.77 30.04,26.91 28.67,26.28 28.64,24.98 28.6,23.69 28.64,11.63 28.64,11.63 L 31.34,8.75 33.46,8.75 33.46,8.75 M 12.79,13.8 L 16.05,17.48 16.57,17.09 23.54,11.35 M 37.03,14.56 L 46.13,20.61 M 38.12,20.61 C 45.12,13.61 45.04,13.68 45.04,13.68" />

    </g>
    </svg>
  );
};

export const icons = {
  AssessmentsIcon,
  ChatIcon,
  CoachingIcon,
  HomeIcon,
  InsightsIcon,
  InterviewIcon,
  JobsIcon,
  PaycheckIcon,
  ResumeIcon,
  SquaresIcon,
  AscmIcon,
  LearningIcon,
  Coaching,
}
