import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledContainer = styled.div`
  a {
    text-decoration: none;
    color: #222 !important;
    display: block;
    width: 100%;
    padding: 20px 0;
  }
`

const ToastRedirect = props => {
  return (
    <StyledContainer>
      <Link to={props.url}>{props.msg}</Link>
    </StyledContainer>
  )
}

export default ToastRedirect
