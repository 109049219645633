import mediaSizes from './media-sizes'

export default {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  'x-small': '(min-width:320px)',
  /* smartphones, Android phones, landscape iPhone */
  small: '(min-width:480px)',
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  medium: '(min-width:600px)',
  /* tablet, landscape iPad, lo-res laptops ands desktops */
  large: '(min-width:801px)',
  /* big landscape tablets, laptops, and desktops */
  'x-large': '(min-width:1025px)',
  /* hi-res laptops and desktops */
  'xx-large': '(min-width:1281px)',
}

//FROM REBOOT
export const screen = 'only screen'
export const mobile = `only screen and (max-width:${mediaSizes['mobile-screen'] - 1}px)`
export const tablet = `only screen and (min-width:${mediaSizes['mobile-screen']}px) and (max-width: ${mediaSizes['tablet-screen'] - 1}px)`
export const desktop = `only screen and (min-width:${mediaSizes['tablet-screen']}px)`
export const desktopLg = `only screen and (min-width:${mediaSizes['large-screen']}px)`
export const landscape = 'only screen and (orientation: landscape)'
export const portrait = 'only screen and (orientation: portrait)'

//CLIENT PORTAL
//https://material.io/design/layout/responsive-layout-grid.html#breakpoints
export const xs = `only screen and (min-device-width : 0px)`
export const sm = `only screen and (min-device-width :600px)`
export const mdDown = `only screen and (min-device-width : 0px) and (max-device-width :959px)`
export const md = `only screen and (min-device-width :960px)`
export const lg = `only screen and (min-device-width :1280px)`
export const xl = `only screen and (min-device-width :1920px)`

//export const minXS = `only screen and (min-width : 0px)`
// export const minSM = `only screen and (min-width :600px)`
// //export const mdDown = `only screen and (min-width : 0px) and (max-width :959px)`
// export const minMD = `only screen and (min-width :960px)`
// export const minMDX = `only screen and (min-width :995px)`
// export const minLG = `only screen and (min-width :1280px)`
// export const minXl = `only screen and (min-width :1920px)`

//offer Desktop Experience
//no user agent checking

//when variable its value is cached on orientation change in tablets
//export const isDesktop = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) > 1023
export const isDesktop = () => (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) > 959
export const isMobile = () => (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 600
export const isScreenLessthan = size => (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < size;
export const vw = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
