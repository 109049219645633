// @flow
import { connect } from 'react-redux'
import { actions } from '../../redux/modules/user-profile'
import PostSurveyModal from '../../components/Modals/PostSurveyModal'

const mapStateToProps = ({ userProfile }) => {
  const { isPostSurveyOpen } = userProfile
  return {
    isOpen: isPostSurveyOpen,
  }
}

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(actions.closePostSurvey())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostSurveyModal)
