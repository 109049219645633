import React from 'react'


export default class extends React.Component {
    render() {
     const { Color } = this.props
     const color = Color ? Color : "#3b3b3b";

      return (
        <svg width="42px" height="38px" viewBox="0 0 42 38" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Admin</title>
        <g id="201217_NewVersion" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g id="Client_Portal" transform="translate(-809.000000, -377.000000)" stroke={color} strokeWidth="2">
                <g id="Admin" transform="translate(810.000000, 377.000000)">
                    <g id="Group-2" transform="translate(0.000000, 5.000000)">
                        <circle id="Oval" cx="20" cy="16" r="16"></circle>
                        <circle id="Oval" cx="14" cy="16" r="4"></circle>
                        <circle id="Oval" cx="26" cy="16" r="4"></circle>
                        <line x1="0" y1="16" x2="10" y2="16" id="Shape"></line>
                        <line x1="30" y1="16" x2="40" y2="16" id="Shape"></line>
                    </g>
                </g>
            </g>
        </g>
    </svg>
      )
    }
  }