import axios from 'axios'
import humps from 'humps'

import { map } from 'ramda'
import { serialize, updateObject, getHost, getContent } from '../../shared/utility'
import { actions as actionAuth } from './auth'
import { actions as actionToaster } from './toaster'

var formatResponse = record => {
  return {
    productName: record.product,
    productCode: record.productCode,
    purchaseDT: record.purchaseDT,
    purchaseBundleCode: record.purchaseBundleCode,
    partnerKey: record.partnerKey,
    steps: record.details.steps,
    team: record.details.team,
    data: record.details.data,
  }
}
// ActionTypes

export const actionTypes = {
  GET_USER_PRODUCTS_SUCCESS: 'GET_USER_PRODUCTS_SUCCESS',
  GET_USER_PRODUCTS_START: 'GET_USER_PRODUCTS_START',
  GET_USER_PRODUCTS_FAIL: 'GET_USER_PRODUCTS_FAIL',
  PUSH_STEP_SUCCESS: 'PUSH_STEP_SUCCESS',
  PUSH_STEP_ERROR: 'PUSH_STEP_ERROR',
  REASSIGN_COACH_UPDATE: 'REASSIGN_COACH_UPDATE',
  BUY_PRODUCT_START: 'BUY_PRODUCT_START',
  BUY_PRODUCT_END: 'BUY_PRODUCT_END',
}

// Action Creators

export const actions = {
  getUserProductsSuccess: (filters, data, userKey) => ({
    type: actionTypes.GET_USER_PRODUCTS_SUCCESS,
    filters,
    data,
    userKey,
  }),
  getUserProductsFail: error => ({ type: actionTypes.GET_USER_PRODUCTS_FAIL, error: error }),

  getUserProductsStart: () => ({ type: actionTypes.GET_USER_PRODUCTS_START }),

  getUserProducts: (userKey, queryParams) => dispatch => {
    dispatch(actions.getUserProductsStart())
    let path = `${getHost().apiBaseUrl}/product` + (queryParams ? serialize(queryParams) : '')
    return axios(path, {
      method: 'GET',
      withCredentials: true,
    })
      .then(response => {
        return humps.camelizeKeys(response.data)
      })
      .then(response => {
        response = map(formatResponse, response)
        console.log('and here are products response', response)
        dispatch(actions.getUserProductsSuccess(queryParams, response, userKey))
      })
      .catch(error => {
        dispatch(actions.getUserProductsFail(error))
      })
  },
  canceledTheAppointment: payload => dispatch => {
    //console.log('CANCELED THE APPOINTMENT ', payload)
    dispatch(actions.pushStepSuccess(payload.response))
    dispatch(actionAuth.updateSteps(payload.response))
    dispatch(actionAuth.navToAppointment(payload.navToID))
  },

  pushStep: (payload, resumeUrl, coachingTab, paycheck) => dispatch => {
    console.log('IN USER PRODUCT WITH PUSH STEP ', payload)
    const itemKey = payload.PurchasedItemKey
    const path = `${getHost().apiBaseUrl}/product/bundle/${payload.PurchaseBundleCode}`
    let responseData = null
    return axios(path, {
      method: 'post',
      data: payload,
      withCredentials: true,
    })
      .then(response => {
        //console.log('THE REAL RESPONSE ', response.data)
        responseData = response.data
        return humps.camelizeKeys(response.data)
      })
      .then(response => {
        //console.log('AND HERE IS tHE RESPONSE FROM PUSH StEP ', response)
        dispatch(actions.pushStepSuccess(response))
        dispatch(actionAuth.updateSteps(responseData))
        if (paycheck) {
          dispatch(actionAuth.updatePurchaseStatePaycheck(payload.data))
        }
        if (resumeUrl) {
          dispatch(actionAuth.updatePurchaseStateResume('resumeUrl', resumeUrl))
        }
        dispatch(actionAuth.navToAppointment(-1))
        if (false && itemKey) {
          if(!payload.donotUseNavToAppointment) {
            console.log('PUSHED STEP AND NOW NAVING TO APPPOINTMENT', itemKey)
            dispatch(actionAuth.navToAppointment(itemKey, ''))
          }
        }
      })
      .catch(error => {
        console.error(error)
        dispatch(actions.pushStepError(error))
      })
  },
  buyAProduct: (productCode, price) => (dispatch, getState) => {

    let path = `${getHost().apiBaseUrl}/product`
    return axios(path, {
      method: 'post',
      data: {
        ProductCode: productCode,
        Price: price,
      },
      withCredentials: true,
    })
      .then(response => {
        const { content } = getState()
        //console.log('user-product buyAProduct response', response)
        dispatch(actionAuth.refreshProducts())

        setTimeout(() => {
          dispatch(actions.buyProductEnd())
        }, 8000)
        dispatch(actionToaster.showSuccess(getContent('Global.Message.CoachingAdded', content)))
      })
      .catch(error => {
        console.log('user-product buyAProduct error', error)
        //dispatch(actions.getUserResumeFail(error))
      })
  },
  buyProductStart: () => ({ type: actionTypes.BUY_PRODUCT_START }),
  buyProductEnd: () => ({type: actionTypes.BUY_PRODUCT_END}),
  pushStepSuccess: payload => ({
    type: actionTypes.PUSH_STEP_SUCCESS,
    payload,
  }),
  pushStepError: payload => ({
    type: actionTypes.PUSH_STEP_ERROR,
    payload,
  }),
  reassignCoachUpdate: payload => ({
    type: actionTypes.REASSIGN_COACH_UPDATE,
    payload,
  }),
}

// Reducer
const defaultState = {
  loading: false,
  data: null,
  userKey: null,
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.BUY_PRODUCT_START:
      return {...state, buyingProduct: true}
    case actionTypes.BUY_PRODUCT_END:
      return {...state, buyingProduct: false}
    case actionTypes.GET_USER_PRODUCTS_START:
      return updateObject(state, { loading: true, data: null })
    case actionTypes.GET_USER_PRODUCTS_SUCCESS:
      console.log('GOT USER PRODUCTS ', action.data)
      return updateObject(state, {
        loading: false,
        data: action.data,
        userKey: action.userKey,
      })
    case actionTypes.GET_USER_PRODUCTS_FAIL:
      return updateObject(state, { loading: false, data: null })
    case actionTypes.PUSH_STEP_SUCCESS:
      //console.log('THE PUSH STEP SUCCESS WITH PAYLOAD OF ', action)
      //console.log('AND THE STATE IS ', state)
      let prods = state.data
      if (prods) {
        map(x => {
          //console.log('GOT A PROD ', x)
          if (x.purchaseBundleCode === action.payload.purchaseBundleCode) {
            //console.log('SET THE FEATURE IN PUSH STEP')
            x.steps = action.payload.steps
          }
        }, prods)
      }
      return updateObject(state, {
        loading: false,
        data: prods,
      })
    case actionTypes.REASSIGN_COACH_UPDATE:
      const response = map(formatResponse, action.payload)
      return updateObject(state, {
        loading: false,
        data: response,
      })
    default:
      return state
  }
}
