export const getHost = () => {
  if (!window.__gethost) {
    const host = window.location.host;
    const hostname = window.location.hostname;
    let data;
    switch (host) {
      case "client.kfadvance.com":
        data = {
          apiBaseUrl: "https://api.kfadvance.com/v1",
          mainHost: "https://kfadvance.com",
          coachPortal: "https://coach.kfadvance.com",
          clientPortal: "https://client.kfadvance.com",
          jobs: "https://jobs.kfadvance.com",
          gtmCode: "GTM-TVCCNG8",
        };
        break;
      case "client.kfadvance.eu":
        data = {
          apiBaseUrl: "https://api.kfadvance.eu/v1",
          mainHost: "https://kfadvance.eu",
          coachPortal: "https://coach.kfadvance.eu",
          clientPortal: "https://client.kfadvance.eu",
          jobs: "https://jobs.kfadvance.eu",
          gtmCode: "GTM-TVCCNG8",
        };
        break;
      case "client.kfadvance-stage.com":
        data = {
          apiBaseUrl: "https://api.kfadvance-stage.com/vn",
          mainHost: "https://kfadvance-stage.com",
          coachPortal: "https://coach.kfadvance-stage.com",
          clientPortal: "https://client.kfadvance-stage.com",
          jobs: "https://jobs.kfadvance-qa.com",
          gtmCode: "GTM-T4FLDJ4",
        };
        break;
      case "client.kfadvance-stage.eu":
        data = {
          apiBaseUrl: "https://api.kfadvance-stage.eu/vn",
          mainHost: "https://kfadvance-stage.eu",
          coachPortal: "https://coach.kfadvance-stage.eu",
          clientPortal: "https://client.kfadvance-stage.eu",
          jobs: "https://jobs.kfadvance-stage.eu",
          gtmCode: "GTM-T4FLDJ4",
        };
        break;
      case "client.kfadvance-qa.com":
        data = {
          apiBaseUrl: "https://api.kfadvance-qa.com/vn",
          mainHost: "https://kfadvance-qa.com",
          coachPortal: "https://coach.kfadvance-qa.com",
          clientPortal: "https://client.kfadvance-qa.com",
          jobs: "https://jobs.kfadvance-qa.com",
          gtmCode: "GTM-T4FLDJ4",
        };
        break;
      case "client.kfadvance-qa.eu":
        data = {
          apiBaseUrl: "https://api.kfadvance-qa.eu/vn",
          mainHost: "https://kfadvance-qa.eu",
          coachPortal: "https://coach.kfadvance-qa.eu",
          clientPortal: "https://client.kfadvance-qa.eu",
          jobs: "https://jobs.kfadvance-qa.eu",
          gtmCode: "GTM-T4FLDJ4",
        };
        break;
      case "client.kfadvance-dev.eu":
        data = {
          apiBaseUrl: "https://api.kfadvance-dev.eu/vn",
          mainHost: "https://kfadvance-dev.eu",
          coachPortal: "https://coach.kfadvance-dev.eu",
          clientPortal: "https://client.kfadvance-dev.eu",
          jobs: "https://jobs.kfadvance-dev.eu",
          gtmCode: "GTM-T4FLDJ4",
        };
        break;
      case "client.kfadvance-dev2.com":
        data = {
          apiBaseUrl: "https://api.kfadvance-dev2.com/vn",
          mainHost: "https://kfadvance-dev2.com",
          coachPortal: "https://coach.kfadvance-dev2.com",
          clientPortal: "https://client.kfadvance-dev2.com",
          jobs: "https://jobs.kfadvance-dev2.com",
          gtmCode: "GTM-T4FLDJ4",
        };
        break;
      case "client.kfadvance-dev3.com":
        data = {
          apiBaseUrl: "https://api.kfadvance-dev3.com/vn",
          mainHost: "https://kfadvance-dev3.com",
          coachPortal: "https://coach.kfadvance-dev3.com",
          clientPortal: "https://client.kfadvance-dev3.com",
          jobs: "https://jobs.kfadvance-dev3.com",
          gtmCode: "GTM-T4FLDJ4",
        };
        break;
      case "client.kfadvance-dev4.com":
        data = {
          apiBaseUrl: "https://api.kfadvance-dev4.com/vn",
          mainHost: "https://kfadvance-dev4.com",
          coachPortal: "https://coach.kfadvance-dev4.com",
          clientPortal: "https://client.kfadvance-dev4.com",
          jobs: "https://jobs.kfadvance-dev4.com",
          gtmCode: "GTM-T4FLDJ4",
        };
        break;
      case "client.kfadvance-qa1.com":
        data = {
          apiBaseUrl: "https://api.kfadvance-qa1.com/vn",
          mainHost: "https://kfadvance-qa1.com",
          coachPortal: "https://coach.kfadvance-qa1.com",
          clientPortal: "https://client.kfadvance-qa1.com",
          jobs: "https://jobs.kfadvance-qa1.com",
          gtmCode: "GTM-T4FLDJ4",
        };
        break;
      default: {
        const localEnv = process.env.REACT_APP_API_ENV || 'dev';
        data = {
          apiBaseUrl: process.env.REACT_APP_EXPRESS_API_ENABLED === "true" ? `http://local-api.kfadvance-${localEnv}.com:3030/vn` : `https://api.kfadvance-${localEnv}.com/vn`,
          mainHost: `https://kfadvance-${localEnv}.com`,
          coachPortal: `https://coach.kfadvance-${localEnv}.com`,
          clientPortal: `https://client.kfadvance-${localEnv}.com`,
          jobs: `https://jobs.kfadvance-${localEnv}.com`,
          gtmCode: "GTM-T4FLDJ4",
        };
      }
    }

    data.host = host;
    data.managePayment = data.apiBaseUrl + "/payment/manage";
    data.sso = data.apiBaseUrl + "/account/ssologin";
    data.loginUrl = data.mainHost;
    data.imageBaseUrl = data.apiBaseUrl + "/image/thumb/";
    data.imageUrl = data.apiBaseUrl + "/image/square/";
    data.apiCheckLoggedInUrl = data.apiBaseUrl + "/account/checkloggedin";
    data.fetchUser = data.apiBaseUrl + "/user";
    data.fetchUserProducts = data.apiBaseUrl + "/product/work?details=true";
    data.fetchPurchaseState = data.apiBaseUrl + "/purchase-state/get";
    data.logoutUrl = data.apiBaseUrl + "/account/logout";
    data.avatatPlaceholder = host + "/app/assets/images/avatars/profile.jpg";
    data.insightsUrl = data.mainHost + "/career-insights/insight/?client=t";
    data.postEvent = data.apiBaseUrl + "/events";
    data.mapBox = 'pk.eyJ1Ijoic3RlaW5ob3QiLCJhIjoiY2p2NnRqdmNjMDh5YjQ0czF1czUwd24xMiJ9.NZHLDlR4AQZpdx9ApcvuNg';
    data.upload = {
      uploadImage: data.apiBaseUrl + "/image",
      imagePath: data.apiBaseUrl + "/image/",
      documents: data.apiBaseUrl + "/file/upload/"
    };

    window.__gethost = data;
    return data;
  } else {
    return window.__gethost;
  }
};