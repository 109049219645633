import { updateObject, api, getHost } from '../../shared/utility'

// Constants

export const actionTypes = {
  GET_REPORTS_SUCCESS: 'GET_REPORTS_SUCCESS',
  GET_REPORTS_START: 'GET_REPORTS_START',
  GET_REPORTS_FAIL: 'GET_REPORTS_FAIL',
}

// Action Creators

export const actions = {
  getReportsSuccess: data => ({
    type: actionTypes.GET_REPORTS_SUCCESS,
    data,
  }),

  getReportsFail: error => ({
    type: actionTypes.GET_REPORTS_FAIL,
    error,
  }),

  getReportsStart: () => ({
    type: actionTypes.GET_REPORTS_START,
  }),

  getPowerBIReports: () => dispatch => {
    dispatch(actions.getReportsStart())
    let path = `${getHost().apiBaseUrl}/reports`

    return api
      .get(path)
      .then(response => {
        dispatch(actions.getReportsSuccess(response.data))
      })
      .catch(error => {
        dispatch(actions.getReportsFail(error))
      })
  },
}

// Reducer

const defaultState = {
  loading: false,
  data: null,
  error: null,
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_REPORTS_START:
      return updateObject(state, { loading: true })
    case actionTypes.GET_REPORTS_SUCCESS:
      return updateObject(state, {
        loading: false,
        data: action.data,
      })
    case actionTypes.GET_REPORTS_FAIL:
      return updateObject(state, { loading: false, error: action.error })
    default:
      return state
  }
}
