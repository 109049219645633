export default {
    CODE_EMAIL_ALREADY_EXIST: "EMAIL_ALREADY_EXIST",
    CODE_PASSWORD_DONTMATCH: "PASSWORD_DONTMATCH",
    CODE_PASSWORD_NOTPROVIDED: "PASSWORD_NOTPROVIDED",
    CODE_PARTNERUSER_EXISTS: "PARTNERUSER_EXISTS",
    CODE_EMAIL_NOTFOUND: "EMAIL_NOTFOUND",
    CODE_EMAIL_DISABLED: "EMAIL_DISABLED",
    CODE_EMAIL_LOCKED: "EMAIL_LOCKED",
    CODE_EMAIL_SUSPENDED: "EMAIL_SUSPENDED",
    CODE_EMAIL_REVOKED: "EMAIL_REVOKED",
    CODE_INVALIDPASSWORD: "INVALIDPASSWORD",
    CODE_PREVIOUSPASSWORD: "PREVIOUSPASSWORD",
    CODE_SERVERERROR: "SERVERERROR",
    CODE_NOPRODUCT: "NOPRODUCT",
    CODE_NTUC_ERROR: "NTUC_ERROR",
    CODE_NTUC_INVALID: "NTUC_INVALID",
    CODE_SSO_ERROR: "SSO_ERROR",
    CODE_FAILURE_CODE_PARTNER_REGISTER: "FAILURE_CODE_PARTNER_REGISTER",
    CODE_INCORRECT_TOKEN: "INCORRECT_TOKEN",
    CODE_TOKEN_ALREADY_REDEEMED: "TOKEN_ALREADY_REDEEMED",
    CODE_LINK_EXPIRED: "LINK_EXPIRED",
    CODE_EMAIL_NOTFOUNDINELIGIBILITY: "EMAIL_NOTFOUNDINELIGIBILITY",
    CODE_WRONG_PARTNER: "WRONG_PARTNER",
    CODE_INVALID_PROVIDERCODE: "INVALID_PROVIDERCODE",
    CODE_WRONG_REDEMPTION_CODE: "WRONG_REDEMPTION_CODE",
    CODE_FIRST_NAME_ERROR: "FIRST_NAME_MISSING_ALPHABET",
    CODE_LAST_NAME_ERROR: "LAST_NAME_MISSING_ALPHABET"
  }