import React from 'react'


export default class extends React.Component {
    render() {
     const { Color } = this.props
     const color = Color ? Color : "#3b3b3b";

      return (
        <svg width="37px" height="37px" viewBox="0 0 37 37" version="1.1" xmlns="http://www.w3.org/2000/svg" >
        <title>Mentee</title>
        <g id="201217_NewVersion" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
            <g id="Client_Portal" transform="translate(-1008.000000, -147.000000)" stroke={color} stroke-width="1.75">
                <g id="Regular" transform="translate(1009.000000, 148.000000)">
                    <path d="M0.230263158,25.5592105 C0.232633007,22.07974 1.98043621,18.8335294 4.88386756,16.9160292 C7.78729891,14.998529 11.4590569,14.6655231 14.6600877,16.029386" id="Shape"></path>
                    <path d="M5.13486842,3.34802632 C7.11648246,5.40256667 9.84839295,6.56293595 12.7028509,6.56250239 C14.1344231,6.56346591 15.5511034,6.2719808 16.8660088,5.70592105" id="Shape"></path>
                    <circle id="Oval" cx="10.5921053" cy="6.5625" r="6.33223684"></circle>
                    <circle id="Oval" cx="25.5592105" cy="25.5592105" r="9.21052632"></circle>
                    <path d="M22.6809211,23.8322368 C22.6809211,22.2426015 23.9695751,20.9539474 25.5592105,20.9539474 C27.1488459,20.9539474 28.4375,22.2426015 28.4375,23.8322368 C28.4375,25.4218722 27.1488459,26.7105263 25.5592105,26.7105263" id="Shape"></path>
                    <path d="M25.5592105,30.1644716 C25.2412834,30.1644716 24.9835526,30.4222045 24.9835526,30.7401316 C24.9835526,31.0580587 25.2412834,31.3157895 25.5592105,31.3157895 C25.8771376,31.3157895 26.1348684,31.0580587 26.1348684,30.7401316 C26.1352775,30.5873326 26.0747594,30.4406739 25.9667138,30.3326283 C25.8586682,30.2245827 25.7120095,30.1640646 25.5592105,30.1644716" id="Shape"></path>
                </g>
            </g>
        </g>
    </svg>
      )
    }
  }