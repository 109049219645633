/* eslint-disable */
import axios from 'axios'
import humps from 'humps'


import { serialize, updateObject, findInArray, getHost,api } from '../../shared/utility'

// ActionTypes

export const actionTypes = {
  INVITE_COACH_START: 'INVITE_COACH_START',
  INVITE_COACH_SUCCESS: 'INVITE_COACH_SUCCESS',
  INVITE_COACH_FAIL: 'INVITE_COACH_FAIL',
  INVITE_COACH_RESET: 'INVITE_COACH_RESET',
}

// Action Creators

export const actions = {
  inviteCoachSuccess: data => ({ type: actionTypes.INVITE_COACH_SUCCESS, data }),
  inviteCoachFail: error => ({ type: actionTypes.INVITE_COACH_FAIL, error: error }),
  inviteCoachStart: () => ({ type: actionTypes.INVITE_COACH_START }),
  inviteCoachReset: () => ({ type: actionTypes.INVITE_COACH_RESET }),

  inviteCoach: (userKey, postData) => {
    return dispatch => {
      //console.log('lai-invite postData', postData)
      dispatch(actions.inviteCoachStart())
      let path = `${getHost().apiBaseUrl}/account/UserInvitation`

      return axios(path, {
        method: 'post',
        data: postData.userData,
        withCredentials: true,
      })
        .then(response => {
          //console.log("lai-invitecoach response", response)
          if (response.status === 200 && response.data.Code && response.data.Code === '-1') {
            dispatch(actions.inviteCoachFail(response.data.Message))
          }
          else {
            if (postData.imageFile) {
              dispatch(actions.uploadImage(response, postData.imageFile)).then(result => {
                  dispatch(actions.inviteCoachSuccess(response.data))
              })
            }
            else {
              dispatch(actions.inviteCoachSuccess(response.data))
            }
          }
        })
        .catch(error => {
          dispatch(actions.inviteCoachFail(error))
        })
    }
  },
  uploadImage: (response, imageFile) => {
    return dispatch => {
      var theUserKey = response.data.UserKey;
      var isACoach = response.data.IsCoach;
      let path = `${getHost().apiBaseUrl}/image/upload/${theUserKey}` + '/' + isACoach
      return axios(path, {
        method: 'PUT',
        headers: {
          'content-type': 'image/jpeg',
        },
        data: imageFile,
        withCredentials: true,
      })
        // .then((response) => {
        //   console.log("uploadImage reponse", response)
        //   //dispatch(actions.inviteCoachSuccess(response))
        // })
        // .catch(error => {
        //   console.error(error)
        //   dispatch(actions.inviteCoachFail(error.response))
        // })
    }
  },
}

// Reducer
const defaultState = {
  saving: false,
  error: null,
  success: null,
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.INVITE_COACH_START:
      return updateObject(state, {
        saving: true,
        error: null,
        success: null
      })
    case actionTypes.INVITE_COACH_SUCCESS:
      return updateObject(state, {
        saving: false,
        error: null,
        success: 'Your invite was sent to ' + action.data.FirstName + ' ' + action.data.LastName
      })
    case actionTypes.INVITE_COACH_FAIL:
      return updateObject(state, {
        saving: false,
        error: action.error,
        success: null
      })
    case actionTypes.INVITE_COACH_RESET:
      return updateObject(state, {
        saving: false,
        error: null,
        success: null,
      })
    default:
      return state
  }
}
