export default {
  video: 'video',
  article: 'article',
  document: 'document',
  insightsView: 'insightsView',
  insightsSlider: 'insightsSlider',

  assetVideoPlayButton: 'assetVideoPlayButton',
  assetArticleReadClick: 'assetArticleReadClick',
  assetDocumentDownloadClick: 'assetDocumentDownloadClick',

  topTools: 'topTools',
  topRightMenu: 'topRightMenu',
  topLeftMenu: 'topLeftMenu',

  careerInsights: 'Career Insights',
  resumeInsights: 'Resume Insights',
  myCareerProfile: 'My Career Profile',
  careerGoals: 'Career Goals',
  goToCareerProfile: 'Go To Career Profile',
  purchasedCoaching: 'Purchased Coaching',

  assessmentReportDownloadClick: 'assessmentReportDownloadClick',

  footer: 'footer',
}