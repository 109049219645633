import React from 'react';
import styled from 'styled-components';
import {aria} from '../../wcag';
import { getRoleSelectContent } from '../../shared/content.helper'
import Mentee from '../../assets/icons/Mentee'
import Mosaic from '../../assets/icons/Mosaic'
import Admin from '../../assets/icons/Admin'
import Coach from '../../assets/icons/Coach'
import Group3 from '../../assets/icons/Group3'





const StyledDiv = styled.div`

min-width: ${props => props.hasTwoRoles ? '240px' : '340px'};
background-color: #ffff;
color: #3b3b3b;
ul {
    list-style:none;
    display: flex;
    padding: 0px;
    margin: 0px;
    align-items: left;
    flex-wrap: wrap;
  }

  li {
    border: 1px #ffff solid;
    border-radius: 2px;
    padding: 5px;
    width: 80px;
    height: 90px;
    cursor: pointer;
    text-align: center;
    margin: 2px;
  }
  li:hover,li:focus, li.selected {
  border: solid 1px ${props => props.color};
  background-color: #f9f9f9;
  }
   li.selected {
        cursor: not-allowed;
   }


 fieldset{
    border-top:1px solid #ccc;
    border-left:0;
    border-bottom:0;
    border-right:0;
    padding:6px;
    margin:14px;
  }

  fieldset.no-border {
    border:0px;
  }
  
  legend{
    text-align:left;
    padding: 0 20px;
   }

   &.role-select::after,
   &.role-select::before {
       left: ${props => props.hasTwoRoles && '34px'};
   }
`

const getRoleSelectSvgIcon = (contentKey, isCoach) => {
    if(isCoach) {
        switch(contentKey) {
            case "admin" :
            case "sysadmin": 
            case "care":
                return Admin;
            default:
                return Coach;
        }
    } else {
        switch(contentKey) {
            case "execKFM" :
                return Mentee;
            case "execMosaic" :
                return Mosaic;
            case "execLSUB" :
            case "execLSUP" :
                return Group3;
            default:
                return Coach;
        }
    }
}

const RoleIcon = (props) => {
    const IconComponent = getRoleSelectSvgIcon(props.ContentKey, props.IsCoach);
    return <IconComponent {...props}/>
}

const MenuItemList = ({roles, assets}) => {
    return (
         <ul>
             {roles.map((role, index) => {
                 return ( 
                     <li key={index} class={`${role.selected ? "selected" : ""}`} 
                     {...(!role.selected ? {tabindex: '-1', role:aria.roles.link, onClick:role.onClick } : {})}
                      >
                       <RoleIcon  {...(role.selected ? {Color : assets.iconColor} : {})} ContentKey={role.contentKey} IsCoach={role.isCoach} />
                        <div>
                          {getRoleSelectContent(role.contentKey, role.name)}
                        </div>
                     </li> 
                 )
 
             })}
         </ul>
    )
 }

const MenuItems = ({roles, title, assets, showTitle}) => {
   return (<fieldset className={showTitle ? "" : "no-border"}>
                {showTitle && <legend>{title}</legend> }
                <MenuItemList {...{roles, assets}} />
            </fieldset>
   )
}


export default function RoleSelect({ rolesMenu, assets}) {
    const clientRoles = rolesMenu?.filter(i => i.isCoach === 0);
    const coachRoles = rolesMenu?.filter(i => i.isCoach === 1);
    const hasCoachPortalItems = !!coachRoles?.length;
    const hasTwoRoles = (clientRoles?.length === 2) && (coachRoles?.length < 3);
    return (
        <StyledDiv className="role-select" color={assets.iconColor} hasTwoRoles={hasTwoRoles}>
            {clientRoles?.length > 0 &&
                <MenuItems roles={clientRoles} title="Client Portal" assets={assets} showTitle={hasCoachPortalItems}  /> 
            }
            {hasCoachPortalItems &&
                <MenuItems roles={coachRoles} title="Coach Portal" assets={assets} showTitle={true} />
            }
        </StyledDiv>
            )
}