import React from "react"
import styled from 'styled-components'
import { mobile } from '../../styles/media-queries'


const StyledImg = styled.img`
  height: 114px;

  &.footer {
    margin: 0;
    // height: 40px
    width: 130px!important;
  }
  &.header {
    margin: 0;
    height: 80px
  }

  @media  ${mobile} {
    height: 35px;

    &.footer {
      margin: 0;
      height: 35px
    }
    &.header {
      margin: 0;
      height: 35px
    }
}`


export default ({type = "", dark = false}) => (
  <StyledImg  className={type}
    src={`/static/images/logos/${dark ? 'Intel-logo-dark' : 'Intel-logo-white'}.png`}
  />
)