import { getHost, formatDateChat, getInitials } from './utility'
import { sortBy, prop, sortWith, descend, ascend} from 'ramda'

export const formatMessage = message => {
  const fullName = message.fullName || message.firstName + ' ' + message.lastName

  return {
    ...message,
    sentFormatted: formatDateChat(message.sentDT),
    fullName: fullName,
    initials: getInitials(fullName),
    avatar: message.isPhoto ? getHost().imageBaseUrl + message.senderUserKey : null,
  }
}

export const formatSubject = subject => {
  const fullName = ((subject.firstName || '') + ' ' + (subject.lastName || '')).trim()
  return {
    ...subject,
    fullName: fullName,
    initials: getInitials(fullName),
    avatar: subject.isPhoto ? getHost().imageBaseUrl + subject.userKey : null,
  }
}

export const formatParticipant = participant => {
  //console.log(participant)
  return {
    ...participant,
    initials: getInitials(participant.firstName + ' ' + participant.lastName),
    fullName: participant.firstName + ' ' + participant.lastName,
    avatar: participant.isPhoto ? getHost().imageBaseUrl + participant.userKey : null, //getHost().avatarPlaceholder,
  }
}

export const formatSingleChat = (chat, noMessages) => {
  return {
    ...chat,
    fullName: chat.firstName + ' ' + chat.lastName,
    initials: getInitials(chat.firstName + ' ' + chat.lastName),
    avatar: chat.isPhoto ? getHost().imageBaseUrl + chat.userKey: undefined,
    messages: noMessages || !chat.messages ? [] : chat.messages.map(message => formatMessage(message)),
    subject: chat.subject ? formatSubject(chat.subject) : null,
    participants: chat.participants ? chat.participants.map(formatParticipant) : [],
  }
}

export const sortChatList = chats => {
  var chatSort = sortWith([
  descend(prop('unread')),
  descend(prop('lastMessageDT'))])
  return chatSort(chats.filter(item => !item.isRemoved))
}

export const formatChatsList = chats => {
  //const newChats = sortBy(prop('unread'),chats)
  return chats.map(chat => {
    return {
      ...chat,
      ...formatSingleChat(chat, true),
    }
  })
}
