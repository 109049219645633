
const SAMPLE_URL = 'https://vid.kfadvance-dev.com/Insights%2FInsights+-%20Kathy%20Vrabeck%2FInsight%20-%20Kathy%20Vrabeck%20-%20Insight%20-%20Kathy%20Vrabeck%20-%20Discussing%20Compensation%20for%20kfa/master-playlist.m3u8';
const SAMPLE_URL2 = 'https://vid.kfadvance-dev.com/Insights%2FInsights+-%20Elaine%20Gu%201080%2FInsight%20-%20Elaine%20Gu%20-%20How%20to%20Stand%20Out%20w%20keys/master-playlist.m3u8';



export const actions = {
  setItem: (key, value) => ({type: 'Video_setItem', payload: {value, key}}),
}

export const defaultState = {
  current: {
    videoUrl: SAMPLE_URL,
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'Video_setItem': {
      const {key, value} = action.payload;
      return {...state, current: {...state.current, [key]: value}}
    }

    default: 
      return state;
  }
}