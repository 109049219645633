import styled from 'styled-components'
import { desktop, sm } from '../../styles/media-queries'

export const StyledContainer = styled.div`
  background: ${props => props.theme.tbBackground};
  nav {
    width: 100%;
    padding-top: 65px;
    @media only screen and (min-width: 600px) {
      padding-top: 75px;
    }
    &.littleBar {
      background: ${props => props.theme.whiteColor};
    }
  }
  .home-icon {
    background: ${props => (props.theme.mobile && props.theme.mobile.bgIcon) || '#d9eef0'};

    opacity: 0.7;
    border-radius: 5px;
    width: 4rem;
    height: 2.5rem;
    position: absolute;
    right: 15px;
    top: 18px;
    padding: 0;
    &.open,
    &.open:hover {
      background: white;
      border: solid 1px ${props => props.theme.lightColor};
      color: ${props => props.theme.lightColor};
    }
    &:hover {
      background: ${props => (props.theme.mobile && props.theme.mobile.bgIcon) || '#d9eef0'};
    }
    svg {
      zoom: 0.4;
    }
  }

  ul.product-list {
    margin: 0.9rem auto 0 auto;
    transition: all 0.1s ease;
    z-index: 3;
    position: absolute;
    max-height: calc(100vh - 94px);
    top: 60px;
    padding: 0px;
    height: 100vh;
    padding-top: 5px;
    overflow: auto;
    cursor: auto;
    right: -100vw;
    width: 100%;
    max-width: 100%;
    @media ${sm} {
      height: auto;
      max-width: 300px;
    }
    &.open {
      display: none;
      right: 0;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 15px;
      &.selected {
        background-color: ${props => props.theme.lightColor};
      }
      &.notselected {
        svg {
          background: white;
        }
      }
      .label {
        font-size: 12pt;
        text-transform: capitalize; // this doesnt work if source is uppercase
        &.textselected {
          color: ${props => props.theme.whiteColor};
        }
        &.textnotselected {
          color: ${props => props.theme.lightColor};
        }
      }
      svg {
        zoom: 0.5;
        opacity: 0.7;
        border-radius: 14px;
        padding: 10px 28px;
        margin: 0 20px 0 0;
      }
    }
  }
  .responsive-menu {
    display: none;   
    @media ${desktop} {  
      display: block;
    }
  }
  ul.new-product-list {
    z-index: 0;
    background: ${props => props.theme.navBackground ? props.theme.navBackground : props.theme.whiteColor};
    position: relative;
    right: inherit;
    top: inherit;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;  
    margin: 0 auto;
    padding: 0;
    white-space: nowrap;
    li {
      padding: 6px 0;
      @media only screen and (min-width: 1200px){ 
        padding: 9px 0;      
      }
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0 8px 0 0;
      &:last-child {
        margin-right: 0;
      }
      
      border: none;
      cursor: pointer;

      text-align:center;
      &.selected {
        color: ${props => props.theme.selectedNavTextColor ? props.theme.selectedNavTextColor : props.theme.whiteColor};
        background: ${props => props.theme.selectedNavColor ? props.theme.selectedNavColor : props.theme.lightColor};
        border-color: ${props => props.theme.NavUnderlineColor ? props.theme.NavUnderlineColor : null};
        border-style: ${props => props.theme.NavUnderlineColor ? 'solid' : null};
        border-width: ${props => props.theme.NavUnderlineColor ? '0 0 5px 0' : null};
        padding-bottom: ${props => props.theme.NavUnderlineColor ? '4px' : null};
      }
      &.notselected {
        color: ${props => props.theme.nonSelectedNavTextColor ? props.theme.nonSelectedNavTextColor : props.theme.grayTextColor};
      }
      .label {
        font-size: 10pt;
        @media only screen and (min-width: 1200px){ 
          font-size: 11pt;
        }
        font-family: ${props => props.theme.fontTwo};
        letter-spacing: 2px;
        width: auto;
        padding: 0 10px;
      }
      .newLogo {
        margin-top: -4px;                  
        height: 15px;
        width: 15px;
        align: top;
      }
      a {
        color: inherit;
        padding: 2px 4px;
        margin: 0 1px;        
        @media ${desktop} {
          padding: 3px 3px;
          margin: 0 2px;
        }
        @media only screen and (min-width: 1200px){  
          padding: 4px 9px;
          margin: 0 4px;
        }
      }
    }
  }
  @media only screen and (min-width: 960px) {   
    ul.product-list {
      z-index: 0;
      background: ${props => props.theme.tbBackground};
      position: relative;
      right: inherit;
      top: inherit;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 100%;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 1rem 0.25rem;        
        @media ${desktop} {
          margin: 1rem 0.5rem;        
        }
        width: 13rem;
        height: 7.5rem;
        border: none;
        cursor: pointer;
        max-width: 170px;
        clip-path: polygon(10% 0, 100% 0, 90% 100%, 0 100%);
        padding: .4rem;
        text-align:center;
        &.selected {
          background-color: ${props => props.theme.lightColor};
        }
        &.notselected {
          background-color: ${props => props.theme.whiteColor};
        }
        svg {
          zoom: 1;
          background: inherit;
          padding: 0;
          margin: 0;
        }
        .label {
          font-size: 12px;
          font-family: ${props => props.theme.fontTwo};
          letter-spacing: 2px;
          font-weight: 400;
          width: auto;
          padding: 0 10px;
        }
      }
    }
  }
`

export const StyledBadge = styled.span`
  width: 16px;
  height: 16px;
  z-index: 2;
  font-size: 9px;
  line-height: 16px;
  top: 27%;
  right: 31%;
  position: absolute;
  border: 0;
  color: white;
  text-align: center;
  border-radius: 50%;
  background: #e6a102;
  box-sizing: content-box;
`

export const LittleStyledBadge = styled.span`
  width: 16px;
  height: 16px;
  z-index: 2;
  font-size: 9px;
  line-height: 16px;
  top: 6px;
  left: 6px;
  position: absolute;
  border: 0;
  color: white;
  text-align: center;
  border-radius: 50%;
  background: #e6a102;
  box-sizing: content-box;
`
