import React, { useReducer } from 'react'
import { useState } from "react";
import moment from 'moment'
import 'moment/min/locales'
import momenttz from 'moment-timezone'
import { extendMoment } from 'moment-range'
const momentTZ = extendMoment(momenttz)




export const GlobalContext = React.createContext([]);

const reducer = (state, action) => {
  switch (action.type) {
    case 'ASSETS_SET':
      return {
        ...state,
        assets: action.data,
        moment,
        momentTZ
      }
    default:
      return state;
  }
}

export const GlobalProvider =({ children }) => {
  const [state, dispatch] = useReducer(reducer, {})
  const contextValue = {state, dispatch}

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  )
}
