//modify constants per environment
//
// const API_HOST = 'kfadvance-dev.com'
// export const API_BASE_URL = 'https://api2.' + API_HOST + '/vn'
// export const LOGIN_URL = 'http://' + API_HOST + '?redirecturl='
// export const IMAGE_BASE_URL = `${API_BASE_URL}/image/thumb/`
// export const API_CHECK_LOGGED_IN_URL = `${API_BASE_URL}/account/checkloggedin`
// export const API_LOGOUT_URL = `${API_BASE_URL}/account/logout`
// export const AVATAR_PLACEHOLDER = 'https://' + API_HOST + '/app/assets/images/avatars/profile.jpg'

export const IMMEDIATE_NEEDS = {
  student: 'Seeking support for career planning',
  promo: 'Seeking support for promotion',
  change: 'Seeking support for job change',
  seeker: 'Urgently seeking a job now',
  careerchange: 'Seeking support for career change',
}

export const SYSTEM_SUPPORTED_ROLES = ['admin', 'coach', 'sysadmin', 'exec']

export const BREAK_POINTS = {
  mobile: 320,
  mobileLandscape: 480,
  tablet: 768,
  tabletLandscape: 1024,
  desktop: 1200,
  desktopLarge: 1500,
  desktopWide: 1920,
}

export const MIN_PASSWORD_LENGTH = 8;