import axios from 'axios/index';
import { getHost } from '../../../shared/utility';
import actionTypes from './actionTypes';
import { actions as authActions  } from "../auth";
const R = require('ramda')

const sortRoles = R.sortWith([
  R.ascend(R.prop('name'))
]);

export const actions = {
  apiStart: () => ({type: actionTypes.Access_apiStart}),
  apiSuccess: () => ({type: actionTypes.Access_apiSuccess}),
  apiFail: error => ({type: actionTypes.Access_apiFail, payload: {error}}),
  setAccess: (access, resources, resourceGroups, roles, selectedRole, entityKey, productData, entities) =>  (dispatch) => {


    if(productData?.length === 0){
      Object.keys(access).forEach(p => {
          if(p !== '/' && p !== '/account/logout')
            access[p] = 'not-allowed';
      })
    }


    if( roles && roles.length > 1 ){

    const tempObj = {}
    roles.forEach( r => {
      if (r.isVisibleOnMenu === 1) {
          const selected = r.RoleCode === selectedRole && entityKey === r.EntityKey;
          let contentKey = r.RoleCode;
          if(!r.isCoach) {
            const entity = entities.find(i => i.EntityKey === r.EntityKey);
              if(entity && entity.EntityNameAbbreviation) {
                contentKey = contentKey + entity.EntityNameAbbreviation
              }
          }
          let obj =  { type: "action",
          name: r.RoleName,
          isCoach: r.isCoach,
          code : r.RoleCode,
          contentKey,
          selected,
          onClick: selected ? () => {} : () => {  dispatch(authActions.roleChange(r.RoleCode, !r.isCoach ?  r.EntityKey : null ))  } ,
        }

        if( !r.isCoach) {
          tempObj[r.RoleCode + r.EntityKey] = obj
        } else {
          tempObj[r.RoleCode] = obj
        }
      }
    });

    const sorted = sortRoles(Object.values(tempObj));

    if(sorted && sorted.length > 0) {
      resourceGroups.rolesMenu = sorted;
    }


  

  }

  dispatch(actions.setAccess_(access, resources,resourceGroups ) );

    return ({type: actionTypes.Access_setAccess, payload: {access, resources, resourceGroups}})

  },

    setAccess_: (access, resources, resourceGroups) =>  {

      return ({type: actionTypes.Access_setAccess, payload: {access, resources, resourceGroups}})  },



  getAccess: () => dispatch => {
    dispatch(actions.apiStart());
    const url = `${getHost().apiBaseUrl}/accessrules/user?appkey=clientportal`;

    return axios(url, {
      method: 'GET',
      headers: {'content-type': 'application/json'},
      withCredentials: true,

    }).then(response => {
      const {access, resources, resourceGroups} = response.data;
      dispatch(actions.setAccess(access, resources, resourceGroups));
      dispatch(actions.apiSuccess());

    }).catch(error => dispatch(actions.apiFail(error)))
  }
}
