import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import shortid from 'shortid'
import ClickOutHandler from 'react-onclickout'
import Hidden from '@material-ui/core/Hidden'
import { actions } from '../../redux/modules/auth'
import { actions as frontEndActions } from '../../redux/modules/frontend'
import { StyledContainer, StyledBadge, LittleStyledBadge } from './styles'
import { disableScroll } from '../../shared/utility';
import {icons, HomeIcon} from '../../assets/icons/ToolbarIcons';
import { logEvent } from '../../shared/analytics';
import { analyticsTypes, analyticsActions } from '../../constants/analytics';
import { aria } from '../../wcag';
import './style.css';

class TopTools extends Component {
  static propTypes = {}
  state = {
    currentTool: null,
    isOpen: false,
  }

  getLabel = item => {
    const {c, content} = this.props;
    if (item.props && item.props.contentDef) return c(item.props.contentDef);
    if (content && content.Global) return content['Global']['Menu'][item.name].toUpperCase();
    return '-'
  }

  static getDerivedStateFromProps(props, state) {
    const host = window.location.pathname
    let whereToGo = ''
    switch (true) {
      case host.length < 3:
      case host.indexOf('home') !== -1:
        whereToGo = 'HOME'
        break
      case host.indexOf('chat') !== -1:
        whereToGo = 'CHAT'
        break
      case host.indexOf('insights') !== -1:
        whereToGo = 'INSIGHTS'
        break
      case host.indexOf('resume') !== -1:
        whereToGo = 'RESUME'
        break
      case host.indexOf('assessments') !== -1:
        whereToGo = 'ASSESSMENTS'
        break
      case host.indexOf('interview') !== -1:
        whereToGo = 'INTERVIEW'
        break
      case host.indexOf('coaching') !== -1:
        whereToGo = 'COACHING'
        break
      case host.indexOf('paycheck') !== -1:
        whereToGo = 'PAYCHECK'
        break
      case host.indexOf('job') !== -1:
        whereToGo = 'JOB'
        break
      case host.indexOf('mentoring') !== -1:
        whereToGo = 'MENTORING'
        break
      case host.indexOf('e-learning') !== -1:
        whereToGo = 'LEARNING'
        break
      default:
        break
    }

    let location = whereToGo
    if (!location) return null;

    if (state.currentTool
      && state.currentTool.text
      && (state.currentTool.text.toLowerCase() === location.toLowerCase()
      || state.currentTool.text.toLowerCase().indexOf(location.toLowerCase()) !== -1)) {
      return null
    }

    let { toolbarNavLinks } = props

    let currentTool = null
    let isModified = false

    const newToolbarNavLinks = toolbarNavLinks.map(item => {
      item.selected = false
      const theTool = item.text.toLowerCase()
      if (theTool.indexOf(location.toLowerCase()) !== -1) {  //(item.text.toLowerCase() === location.toLowerCase()) {
        currentTool = item
        item.selected = true
        isModified = true
      }
      return item
    })

    if (isModified) {
      props.setItem('TopTools', 'currentTool', currentTool)
      props.setItem('TopTools', 'toolbarNavLinks', newToolbarNavLinks)
      return { currentTool, toolbarNavLinks: newToolbarNavLinks }
    }
    return null
  }

  isAllowed = (path) => {
    if (!path) return true;
    const {access, loaded} = this.props;
    if (loaded && access[path] === 'allowed') return true;
    return false;
  }

  onMobileNavToggle = (e, forceClose) => {
    const isOpen = typeof forceClose !== 'undefined' ? false : !this.state.isOpen
    this.setState({ isOpen }, () => {
      disableScroll(isOpen)
    })
  }

  setCurrent = textName => {
    if (!textName) return false
    const state = this.state

    if (state.currentTool && state.currentTool.text && state.currentTool.text.toLowerCase() === textName.toLowerCase()) {
      return false
    }

    let { resources, access, topTools } = this.props

    let currentTool = null

    topTools && topTools.children && topTools.children.map(obj => {
      let item = resources[obj.resource];
      item['access'] = access[obj.resource];

      item.selected = false
      if (item.name.toLowerCase() === textName.toLowerCase()) {
        currentTool = {
          ...item,
          selected: true,
          text: item.name,
        }
      }
      return {...item, text: item.name}
    })

    this.setState({toolbarNavLinks: topTools, currentTool});
    this.props.setItem('TopTools', 'currentTool', currentTool);
    const theSelectedTool = currentTool && currentTool.text && document.getElementById(currentTool.text)
    theSelectedTool && theSelectedTool.focus()
    //this.props.setItem('TopTools', 'toolbarNavLinks', toolbarNavLinks);
  }

  onClickOut = e => {
    e.preventDefault()
    this.onMobileNavToggle(null, true)
  }

  clickedItem = clickedItem => {
    actions.postEvent('MainTools-' + clickedItem.text)
    this.setCurrent(clickedItem.text)
    clickedItem.onClick()
    this.onMobileNavToggle(null, true);
    logEvent('event', analyticsTypes.button, analyticsActions.navigate, {source: 'topTools:' + clickedItem.resource});
  }

  renderProducts = () => {
    const props = this.props
    const { isOpen } = this.state
    const { resources, access, getTopTools, currentTool, iconColor, iconColorSelected, content, assets, history, c} = this.props;
    const aMenu = content && content.Global ? content['Global']['Menu'] : '';
    const topTools = getTopTools(assets);
    const tools = topTools && topTools.children.filter(item => access[item.resource] === 'allowed' && ['route', 'link'].includes(item.type))
    const showTools = tools.length > 1

    let Wrapper = ({ children }) => <Fragment>{children}</Fragment>
    if (isOpen) {
      Wrapper = ({ children }) => <ClickOutHandler onClickOut={this.onClickOut}>{children}</ClickOutHandler>
    }

    return (
      <Wrapper>
        <nav className={assets.toolbar} role={aria.roles.navigation} aria-label={c(aria.labels.mainNavigation.key)}>
        {showTools &&

            <Hidden smDown>
              <div className="responsive-menu">
            {assets.toolbar === 'littleBar'
              ? (<ul className='new-product-list'>
                  { topTools && topTools.children && topTools.children
                      .filter(item => access[item.resource] === 'allowed' && ['route', 'link'].includes(item.type) )
                      .map(obj => {
                        const resObj = resources[obj.resource];
                        //console.log('THE RES OBJ IS ', resObj)
                        const item = {
                          ...resObj,
                          ...obj,
                          access: access[obj.resource],
                          text: resObj.name,
                          onClick: () => {
                            if (obj.type === 'route') {
                              history.push(obj.resource)
                              return;
                            }

                            if (obj.type === 'link') {
                              window.open(obj.resource, '_blank');
                              return;
                            }

                            return;
                          },
                          props: resObj.props || {}
                        };
                        //console.log('THE NEW ITEM IS ', item)
                        return (
                          <li style={{ position: 'relative' }}
                              id={item.text}
                              onClick={() => this.clickedItem(item)}
                              onKeyUp={(e) => {
                                if (e.keyCode === 13) {
                                  e.preventDefault();
                                  this.clickedItem(item);
                                }
                              }}
                              className={item.text === currentTool.text ? 'selected' : 'notselected'}
                              key={shortid.generate()}>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a tabIndex="0" aria-label={this.getLabel(item)} role={aria.roles.link}>
                              {item.text.toLowerCase() === 'chat' && props.unread ? <LittleStyledBadge>{props.unread}</LittleStyledBadge> : null}
                              <span className={'label ' + (item.text === currentTool.text ? 'textselected' : 'textnotselected')}>
                                {this.getLabel(item) || aMenu[item.text]}
                              </span>
                            </a>
                          </li>
                      )})
                  }
                </ul>)
              : (<ul className='product-list' role={aria.roles.toolbar}>
                  { topTools && topTools.children && topTools.children
                      .filter(item => access[item.resource] === 'allowed' && ['route', 'link'].includes(item.type) )
                      .map(obj => {
                        const resObj = resources[obj.resource];
                        const item = {
                          ...resObj,
                          ...obj,
                          access: access[obj.resource],
                          text: resObj.name,
                          onClick: () => {
                            if (obj.type === 'route') {
                              history.push(obj.resource)
                              return;
                            }

                            if (obj.type === 'link') {
                              window.open(obj.resource, '_blank');
                              return;
                            }

                            return;
                          },
                          props: resObj.props || {},
                          svg: resObj.props && resObj.props.toolbarIcon ? icons[resObj.props.toolbarIcon] : HomeIcon,
                        };

                        return (
                          <li style={{ position: 'relative' }} role={aria.roles.listitem}
                                id={item.text}
                                onClick={() => this.clickedItem(item)}
                                onKeyUp={(e) => {
                                  if (e.keyCode === 13) {
                                    e.preventDefault();
                                    this.clickedItem(item);
                                  }
                                }}
                                className={item.text === currentTool.text ? 'selected' : 'notselected'}
                                key={shortid.generate()} >
                            <div tabIndex="0" aria-label={this.getLabel(item)} role={aria.roles.button} >
                              {item.text.toLowerCase() === 'chatter' && props.unread ? <StyledBadge>{props.unread}</StyledBadge> : null}
                              <item.svg BgColor={item.text === currentTool.text ? iconColorSelected : iconColor} BadgeCount={props.unread} />
                              <div className={'label ' + (item.text === currentTool.text ? 'textselected' : 'textnotselected')}>{this.getLabel(item) || aMenu[item.text]}</div>
                            </div>
                          </li>
                        )
                    })}
                </ul>)}
                </div>
            </Hidden>
          }
          </nav>
      </Wrapper>
    )
  }

  render() {
    const { hideMenu, history } = this.props;

    if (hideMenu) return null;

    return (
      <StyledContainer>
        {history.location.pathname !== '/interview/tips' ? this.renderProducts() :
          <div style={{height: '120px'}}></div>}
      </StyledContainer>
    )
  }
}

const mapStateToProps = state => ({
  hideMenu: state.frontend.TopTools.hideMenu,
  currentTool: { ...state.frontend.TopTools.currentTool },
  unread: state.chat.unread,
  access: state.access.access,
  resources: state.access.resources,
  getTopTools: assets => assets.menus && assets.menus.topTools
    ? state.access.resourceGroups[assets.menus.topTools]
    : state.access.resourceGroups['group:top_tools'],
  loaded: state.access.loaded,
})

const mapDispatchToProps = dispatch => ({
  setItem: (group, key, value) => dispatch(frontEndActions.setItem(group, key, value)),
  clearSelected: () => dispatch(frontEndActions.TopTools.clearSelected()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopTools)
