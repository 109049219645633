import axios from 'axios'
import humps from 'humps'
import { logEvent } from '../../shared/analytics'
import {
  updateObject,
  getHost,
  api,
  getContent
} from '../../shared/utility'
import {
  actions as actionUserProducts
} from './user-product'
import {
  actions as actionCoaches
} from './coaches'
import {
  actions as actionToaster
} from './toaster'
import {
  actions as actionScheduling
} from './coach-schedule'
import {
  actions as actionCoachDetails
} from './coach-details'
import {
  actions as actionAuth
} from './auth'
import { actions as attributeActions } from './attributes'
import analyticsTypeConstants from '../../constants/analytics/analytics-type.constants';

export const actionTypes = {
  OPEN_CONFIRM_ROLLBACK_APPOINTMENT: 'OPEN_CONFIRM_ROLLBACK_APPOINTMENT',
  CLOSE_CONFIRM_ROLLBACK_APPOINTMENT: 'CLOSE_CONFIRM_ROLLBACK_APPOINTMENT',
  ROLLBACK_APPOINTMENT_START: 'ROLLBACK_APPOINTMENT_START',
  ROLLBACK_APPOINTMENT_SUCCESS: 'ROLLBACK_APPOINTMENT_SUCCESS',
  ROLLBACK_APPOINTMENT_ERROR: 'ROLLBACK_APPOINTMENT_ERROR',
  ROLLBACK_APPOINTMENT_RESET: 'ROLLBACK_APPOINTMENT_RESET',

  OPEN_CONFIRM_CANCEL_APPOINTMENT: 'OPEN_CONFIRM_CANCEL_APPOINTMENT',
  CLOSE_CONFIRM_CANCEL_APPOINTMENT: 'CLOSE_CONFIRM_CANCEL_APPOINTMENT',
  CANCEL_APPOINTMENT_START: 'CANCEL_APPOINTMENT_START',
  CANCEL_APPOINTMENT_SUCCESS: 'CANCEL_APPOINTMENT_SUCCESS',
  CANCEL_APPOINTMENT_ERROR: 'CANCEL_APPOINTMENT_ERROR',
  CANCEL_APPOINTMENT_RESET: 'CANCEL_APPOINTMENT_RESET',

  OPEN_CHOICE_SELECT: 'OPEN_CHOICE_SELECT',
  CLOSE_CHOICE_SELECT: 'CLOSE_CHOICE_SELECT',

  OPEN_SELECT_COACH: 'OPEN_SELECT_COACH',
  CLOSE_SELECT_COACH: 'CLOSE_SELECT_COACH',

  OPEN_SCHEDULE_APPOINTMENT: 'OPEN_SCHEDULE_APPOINTMENT',
  CLOSE_SCHEDULE_APPOINTMENT: 'CLOSE_SCHEDULE_APPOINTMENT',

  ASSIGN_COACH_START: 'ASSIGN_COACH_START',
  ASSIGN_COACH_SUCCESS: 'ASSIGN_COACH_SUCCESS',
  ASSIGN_COACH_ERROR: 'ASSIGN_COACH_ERROR',

  POST_PRODUCT_START: 'POST_PRODUCT_START',
  POST_PRODUCT_SUCCESS: 'POST_PRODUCT_SUCCESS',
  POST_PRODUCT_ERROR: 'POST_PRODUCT_ERROR',

  UPDATE_COACH_START: 'UPDATE_COACH_START',
  UPDATE_COACH_SUCCESS: 'UPDATE_COACH_SUCCESS',
  UPDATE_COACH_ERROR: 'UPDATE_COACH_ERROR',

  EVENT_SELECTED: 'EVENT_SELECTED',

  OPEN_CONFIRM_CANCEL_SUBSCRIPTION: 'OPEN_CONFIRM_CANCEL_SUBSCRIPTION',
  CLOSE_CONFIRM_CANCEL_SUBSCRIPTION: 'CLOSE_CONFIRM_CANCEL_SUBSCRIPTION',
  CANCEL_SUBSCRIPTION_START: 'CANCEL_SUBSCRIPTION_START',
  CANCEL_SUBSCRIPTION_SUCCESS: 'CANCEL_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_ERROR: 'CANCEL_SUBSCRIPTION_ERROR',
  CANCEL_SUBSCRIPTION_RESET: 'CANCEL_SUBSCRIPTION_RESET',

  GET_CANCEL_SUBSCRIPTION_DATA_START: 'GET_CANCEL_SUBSCRIPTION_DATA_START',
  GET_CANCEL_SUBSCRIPTION_DATA_SUCCESS: 'GET_CANCEL_SUBSCRIPTION_DATA_SUCCESS',
  GET_CANCEL_SUBSCRIPTION_DATA_ERROR: 'GET_CANCEL_SUBSCRIPTION_DATA_ERROR',

  RESCHEDULE_APPOINTMENT_MODE: 'RESCHEDULE_APPOINTMENT_MODE',
  SKIP_PROFILE_UPDATE_TOASTER: 'SKIP_PROFILE_UPDATE_TOASTER',

}

export const actions = {
  rescheduleAppointmentMode: appointment => {
    return {
      type: actionTypes.RESCHEDULE_APPOINTMENT_MODE,
      appointment
    }
  },
  getCancelSubscriptionDataStart: () => {
    return {
      type: actionTypes.GET_CANCEL_SUBSCRIPTION_DATA_START
    }
  },
  getCancelSubscriptionDataSuccess: data => {
    return {
      type: actionTypes.GET_CANCEL_SUBSCRIPTION_DATA_SUCCESS,
      data
    }
  },
  getCancelSubscriptionDataError: error => {
    return {
      type: actionTypes.GET_CANCEL_SUBSCRIPTION_DATA_ERROR,
      error
    }
  },

  getCancelSubscriptionData: () => dispatch => {
    dispatch(actions.getCancelSubscriptionDataStart())
    let path = `${getHost().apiBaseUrl}/payment/cancel`
    return axios(path, {
        method: 'GET',
        withCredentials: true,
      })
      .then(response => {
        dispatch(actions.getCancelSubscriptionDataSuccess(response.data))
      })
      .catch(error => {
        console.error(error)
        dispatch(actions.getCancelSubscriptionDataError(error.response && error.response.data ? error.response.data : ''))
      })
  },



  openConfirmCancelSubscriptionModal: () => ({
    type: actionTypes.OPEN_CONFIRM_CANCEL_SUBSCRIPTION
  }),
  closeConfirmCancelSubscriptionModal: () => {
    return {
      type: actionTypes.CLOSE_CONFIRM_CANCEL_SUBSCRIPTION
    }
  },

  cancelSubscription: () => (dispatch, getState) => {
    dispatch(actions.cancelSubscriptionStart())
    const { content, auth } = getState()
    const userKey = auth.userKey
    let path = `${getHost().apiBaseUrl}/payment/subscription`
    return axios(path, {
        method: 'DELETE',
        data: {
          userKey
        },
        withCredentials: true,
      })
      .then(response => {
        logEvent('event', analyticsTypeConstants.subscription, 'cancel-subscription')
        dispatch(actions.cancelSubscriptionSuccess(response.data))
        dispatch(actionAuth.refreshProducts())
        dispatch(actions.getCancelSubscriptionData())
        dispatch(actionToaster.showSuccess(getContent('Global.Message.SubscriptionCanceld', content)))
      })
      .catch(error => {
        console.error(error)
        dispatch(actionToaster.showError(getContent('Global.Message.Error', content)))
        dispatch(actions.cancelSubscriptionError(error.response && error.response.data ? error.response.data : ''))
      })
  },

  cancelSubscriptionStart: () => {
    return {
      type: actionTypes.CANCEL_SUBSCRIPTION_START
    }
  },
  cancelSubscriptionSuccess: () => {
    return {
      type: actionTypes.CANCEL_SUBSCRIPTION_SUCCESS
    }
  },
  cancelSubscriptionError: error => {
    return {
      type: actionTypes.CANCEL_SUBSCRIPTION_ERROR,
      error
    }
  },
  cancelSubscriptionResetMessages: error => {
    return {
      type: actionTypes.CANCEL_SUBSCRIPTION_RESET,
      error
    }
  },



  openConfirmRollbackAppointmentModal: appointmentKey => ({
    type: actionTypes.OPEN_CONFIRM_ROLLBACK_APPOINTMENT,
    appointmentKey
  }),
  closeConfirmRollbackAppointmentModal: () => {
    return {
      type: actionTypes.CLOSE_CONFIRM_ROLLBACK_APPOINTMENT
    }
  },

  rollbackAppointment: () => (dispatch, getState) => {
    dispatch(actions.rollbackAppointmentStart())
    const appointmentKey = getState().userActions.appointmentKey
    let path = `${getHost().apiBaseUrl}/scheduler/rollback/${appointmentKey}`

    return axios(path, {
        method: 'DELETE',
        withCredentials: true,
      })
      .then(response => {
        return humps.camelizeKeys(response.data)
      })
      .then(response => {
        //console.log('GOT RESPONSE FROM ROLBACK OF ', response);
        dispatch(actions.rollbackAppointmentSuccess(response))
        dispatch(actionUserProducts.pushStepSuccess(response))
        // dispatch(actions.rollbackAppointmentSuccess(response))
        // const userProfile = getState().userProfile
        // if (userProfile && userProfile.data && userProfile.data.userKey) {
        //   dispatch(actionUserProducts.getUserProducts(userProfile.data.userKey))
        // }
      })
      .catch(error => {
        console.error(error)
        dispatch(actions.rollbackAppointmentError(error.response && error.response.data ? error.response.data : ''))
      })
  },

  rollbackAppointmentStart: () => {
    return {
      type: actionTypes.ROLLBACK_APPOINTMENT_START
    }
  },
  rollbackAppointmentSuccess: () => {
    return {
      type: actionTypes.ROLLBACK_APPOINTMENT_SUCCESS
    }
  },
  rollbackAppointmentError: error => {
    return {
      type: actionTypes.ROLLBACK_APPOINTMENT_ERROR,
      error
    }
  },
  rollbackAppointmentResetMessages: error => {
    return {
      type: actionTypes.ROLLBACK_APPOINTMENT_RESET,
      error
    }
  },

  openConfirmCancelAppointmentModal: appointmentKey => ({
    type: actionTypes.OPEN_CONFIRM_CANCEL_APPOINTMENT,
    appointmentKey
  }),
  closeConfirmCancelAppointmentModal: () => {
    return {
      type: actionTypes.CLOSE_CONFIRM_CANCEL_APPOINTMENT
    }
  },

  cancelAppointment: payload => (dispatch, getState) => {
    dispatch(actions.cancelAppointmentStart())
    dispatch(actionScheduling.scheduleCoachingAppointmentStart())

    const appointmentKey = payload.appointmentKey
    const navToID = payload.purchasedItemKey
    const coachDetails = getState().coachDetails;
    const coachId = coachDetails && coachDetails.userKey;

    let path = `${getHost().apiBaseUrl}/scheduling/appointment/${appointmentKey}`
    return axios(path, {
        method: 'DELETE',
        withCredentials: true,
      })
      .then(response => {
        console.log('cancelAppointment : ', response)
        dispatch(actionScheduling.resetGoals())
        dispatch(actions.cancelAppointmentSuccess(response.data))
        dispatch(actionScheduling.scheduleCoachingAppointmentFinish())
        const cancelPayload = {
          response: response.data,
          navToID,
        }
        dispatch(actionUserProducts.canceledTheAppointment(cancelPayload))
        dispatch(actionCoachDetails.getCoachBio(coachId));
        const { content } = getState()
        dispatch(actionToaster.showSuccess(getContent('Global.Message.AppointmentCancelled', content)));
        return response
      })
      .catch(error => {
        console.log('cancelAppointment :error :  ', error)
        const message = error?.response?.data?.friendlyMessage || error?.response?.data?.message || 'Error occurred. Please try again.'
        dispatch(actionScheduling.scheduleCoachingAppointmentFail(message))
        console.error(error)
        dispatch(actions.cancelAppointmentError(error.response && error.response.data ? error.response.data : ''))
        dispatch(actionAuth.refreshPurchStateV2())
        return error
      })
  },

  cancelAppointmentStart: () => {
    return {
      type: actionTypes.CANCEL_APPOINTMENT_START
    }
  },
  cancelAppointmentSuccess: () => {
    return {
      type: actionTypes.CANCEL_APPOINTMENT_SUCCESS
    }
  },
  cancelAppointmentError: error => {
    return {
      type: actionTypes.CANCEL_APPOINTMENT_ERROR,
      error
    }
  },
  cancelAppointmentResetMessages: error => {
    return {
      type: actionTypes.CANCEL_APPOINTMENT_RESET,
      error
    }
  },
  openSelectCoachModal: (product, step) => {
    return {
      type: actionTypes.OPEN_SELECT_COACH,
      step,
      product
    }
  },
  closeSelectCoach: error => {
    return {
      type: actionTypes.CLOSE_SELECT_COACH,
      error
    }
  },

  openScheduleAppointment: (product, step, coachType) => {
    return {
      type: actionTypes.OPEN_SCHEDULE_APPOINTMENT,
      product,
      step,
      coachType
    }
  },
  closeScheduleAppointment: error => {
    return {
      type: actionTypes.CLOSE_SCHEDULE_APPOINTMENT,
      error
    }
  },
  eventSelected: data => ({
    type: actionTypes.EVENT_SELECTED,
    data,
  }),

  rescheduleCoachingAppointment: (appointmentKey, coachId, schedulePayload, pushPayload) => {
    return (dispatch, getState) => {
      schedulePayload.coachUserKey = coachId;
      dispatch(actionScheduling.scheduleCoachingAppointmentStart())
      let path = `${getHost().apiBaseUrl}/scheduling/appointment/${appointmentKey}/reschedule`
      const { content } = getState()
      return axios(path, {
          method: 'post',
          data: schedulePayload,
          withCredentials: true,
        })
        .then(response => {
          dispatch(actions.closeScheduleAppointment())
          dispatch(actions.rescheduleAppointmentMode(undefined))
          dispatch(actionScheduling.scheduleCoachingAppointmentFinish())
          console.log('')
          if (schedulePayload.userKey) {
            dispatch(actionUserProducts.pushStep(pushPayload, undefined, 'career'))
            dispatch(actionToaster.showSuccess(getContent('Global.Message.AppointmentRescheduled', content)));
          } else {
            console.log('THE SCHEDULE PAYLOAD HAS NO USER KEY SO STEP WAS NOT ADVANCED!!!!!!!!!!!')
          }
          // console.log('rescheduleCoachingAppointment response : ', response)
          return response
        })
        .catch(error => {
          console.error(error)
          const message = error && error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Error occurred. Please try again.';
          dispatch(actionToaster.showError(getContent('Global.Message.Error', content)))
          dispatch(actions.closeScheduleAppointment())
        })
      }
  },

  scheduleCoachingAppointment: (coachId, schedulePayload, pushPayload) => {
    var query = '?bookingType=confirmBooking'

    return (dispatch, getState) => {
      dispatch(actionScheduling.scheduleCoachingAppointmentStart())
      let path = `${getHost().apiBaseUrl}/scheduling/appointment/coach/${coachId}${query}`
      const { content } = getState()
      return axios(path, {
          method: 'post',
          data: schedulePayload,
          withCredentials: true,
        })
        .then(response => {
          dispatch(actions.closeScheduleAppointment())
          dispatch(actionScheduling.scheduleCoachingAppointmentFinish())
          if (schedulePayload.userKey) {
            dispatch(actionUserProducts.pushStep(pushPayload, undefined, 'career'))
            dispatch(actionToaster.showSuccess(getContent('Global.Message.AppointmentScheduled', content)));
          } else {
            console.log('THE SCHEDULE PAYLOAD HAS NO USER KEY SO STEP WAS NOT ADVANCED!!!!!!!!!!!')
          }
          // console.log('scheduleCoachingAppointment response : ', response)
          return response
        })
        .catch(error => {
          console.error(error)
          const message = error?.response?.data?.friendlyMessage || error?.response?.data?.message || 'Error occurred. Please try again.'
          dispatch(actionToaster.showError(getContent('Global.Message.Error', content)))
          dispatch(actions.closeScheduleAppointment())
          dispatch(actionAuth.refreshPurchStateV2())
        })
    }
  },
  scheduleOutlookAppointment: (coachId, schedulePayload, pushPayload) => {
    return (dispatch, getState) => {
      dispatch(actionScheduling.scheduleCoachingAppointmentStart())
      let path = `${getHost().apiBaseUrl}/scheduling/calendar-availability/book`
      return axios(path, {
          method: 'post',
          data: schedulePayload,
          withCredentials: true,
        })
        .then(response => {
          dispatch(actions.closeScheduleAppointment())
          dispatch(actionScheduling.scheduleCoachingAppointmentFinish())
          if (schedulePayload.userKey) {
            dispatch(actionUserProducts.pushStep(pushPayload, undefined, 'career'))
            const { content } = getState()
            dispatch(actionToaster.showSuccess(getContent('Global.Message.AppointmentScheduled', content)))
          } else {
            console.log('THE SCHEDULE PAYLOAD HAS NO USER KEY SO STEP WAS NOT ADVANCED!!!!!!!!!!!')
          }
          return response
        })
        .catch(error => {
          console.error(error)
          const message = error && error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Error occurred. Please try again.';
          dispatch(actionScheduling.scheduleCoachingAppointmentFail(message))
          dispatch(actions.closeScheduleAppointment())
        })
    }
  },
  scheduleAutoCoachingAppointment: (coachId, schedulePayload, pushPayload, options = {}) => {
    const {refreshAttributes} = options
    return (dispatch, getState) => {
      dispatch(actionScheduling.scheduleCoachingAppointmentStart())
      let path = `${getHost().apiBaseUrl}/scheduling/appointment/auto`
      axios(path, {
          method: 'post',
          data: schedulePayload,
          withCredentials: true,
        })
        .then(response => {
          dispatch(actionScheduling.scheduleCoachingAppointmentFinish())
          dispatch(actions.closeScheduleAppointment())
          if (schedulePayload.userKey) {
            //console.log('GONNA  PUSH STEP WITH ', theData);
            dispatch(actionUserProducts.pushStep(pushPayload, undefined, 'career'))
            const { content } = getState()
            dispatch(actionToaster.showSuccess(getContent('Global.Message.AppointmentScheduled', content)))
            //dispatch(actionUserProducts.getUserProducts(data.userKey))
          }
          return response
        })
        .catch(error => {
          console.error(error)
          const message = error && error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Error occurred. Please try again.';
          dispatch(actionScheduling.scheduleCoachingAppointmentFail(message))
          dispatch(actions.closeScheduleAppointment())
          dispatch(actionAuth.refreshPurchStateV2())
          refreshAttributes && dispatch(attributeActions.getUserAttributes())
        })
    }
  },
  openSelectCoach: (product, step) => (dispatch, getState) => {
    const coaches = getState().coaches
    const partnerKey = product.partnerKey
    if (step && step.pool && coaches.coaches[step.pool] && coaches.coaches[step.pool][partnerKey]) {
      //_coaches = coaches.coaches[step.pool][partnerKey]
    } else {
      if (step && step.pool) {
        dispatch(actionCoaches.getCoaches(step.pool, product.purchaseBundleCode ))
      }
    }
    dispatch(actions.openSelectCoachModal(product, step))
  },

  assignCoach: selectedCoach => (dispatch, getState) => {
    dispatch(actions.asssignCoachStart())
    const userActions = getState().userActions
    const userProfile = getState().userProfile
    const userKey = userProfile && userProfile.data ? userProfile.data.userKey : null
    if (!userKey) {
      dispatch(actions.asssignCoachError())
      dispatch(actionToaster.showError('User not found'))
      return
    }
    const step = userActions.step
    const product = userActions.product
    const role = step.pool
    let updateCoach = true
    let data = {}
    if (product.team && product.team[role]) {
      data = {
        PurchasedItemKey: step.purchasedItemKey,
        newcoachkey: selectedCoach.userKey,
        oldcoachkey: product.team[role].userKey,
        role: role, //$ctrl.step.Pool,
        purchaseBundleCode: product.purchaseBundleCode,
        userkey: userKey,
        updateCoach,
      }
    } else {
      updateCoach = false

      data = {
        PurchaseBundleCode: product.purchaseBundleCode,
        PurchasedItemKey: step.purchasedItemKey,
        Coach: selectedCoach.userKey,
        CurrentStep: step.currentStep,
        Action: step.action,
        Role: role,
        updateCoach,
      }
    }
    data.successMessage = 'Coach has been assigned!!'
    if (updateCoach) {
      dispatch(actions.updateCoach(data))
    } else {
      console.log('PUSHING STEP WITH ', data)
      //dispatch(actionUserProducts.pushStep(data))
      dispatch(actions.postProduct(data))
    }
  },

  asssignCoachStart: () => {
    return {
      type: actionTypes.ASSIGN_COACH_START
    }
  },

  asssignCoachSuccess: () => {
    return {
      type: actionTypes.ASSIGN_COACH_SUCCESS
    }
  },

  asssignCoachError: () => {
    return {
      type: actionTypes.ASSIGN_COACH_ERROR
    }
  },

  updateCoachStart: () => {
    return {
      type: actionTypes.UPDATE_COACH_START
    }
  },

  updateCoachSuccess: () => {
    return {
      type: actionTypes.UPDATE_COACH_SUCCESS
    }
  },

  updatenCoachError: () => {
    return {
      type: actionTypes.UPDATE_COACH_ERROR
    }
  },
  postProductStart: () => {
    return {
      type: actionTypes.POST_PRODUCT_START
    }
  },

  postProductSuccess: () => {
    return {
      type: actionTypes.POST_PRODUCT_SUCCESS
    }
  },

  postProductError: () => {
    return {
      type: actionTypes.POST_PRODUCT_ERROR
    }
  },

  postProduct: payload => (dispatch, getState) => {
    dispatch(actions.postProductStart())
    let path = `${getHost().apiBaseUrl}/product/bundle/${payload.PurchaseBundleCode}`
    const { userProfile, content } = getState()
    return api
      .post(path, payload)
      .then(() => {

        dispatch(actionToaster.showSuccess(getContent('Global.Message.Success', content)))
        dispatch(actions.postProductSuccess())


        if (userProfile && userProfile.data && userProfile.data.userKey) {
          dispatch(actionUserProducts.getUserProducts(userProfile.data.userKey))
        }
      })
      .catch(error => {
        console.error('postProduct', error)
        dispatch(actions.postProductError(error))
        dispatch(actionToaster.showError(getContent('Global.Message.Error', content)))
      })
  },

  updateCoach: payload => (dispatch, getState) => {
    dispatch(actions.updateCoachStart())

    let path = `${getHost().apiBaseUrl}/product/updatecoachassignment`

    return (
      api
      .post(path, payload)
      .then(response => {
        return humps.camelizeKeys(response.data)
      })
      .then(response => {
        const { content } = getState()
        dispatch(actionToaster.showSuccess(getContent('Global.Message.Success', content)))
        dispatch(actions.updateCoachSuccess())
        dispatch(actionUserProducts.reassignCoachUpdate(response))
      })
      // .then(() => {

      // const userProfile = getState().userProfile
      // if (userProfile && userProfile.data && userProfile.data.userKey) {
      //   dispatch(actionUserProducts.getUserProducts(userProfile.data.userKey))
      // }
      // })
      .catch(error => {
        console.error('updateCoach', error)
        dispatch(actions.updatenCoachError(error))
        dispatch(actionToaster.showError('Update coach error, Please see logs'))
      })
    )
  },

  openChoiceSelectModal: (product, step) => ({
    type: actionTypes.OPEN_CHOICE_SELECT,
    product,
    step
  }),
  closeChoiceSelectModal: () => {
    return {
      type: actionTypes.CLOSE_CHOICE_SELECT
    }
  },

  choiceSelect: () => (dispatch, getState) => {
    const { userActions, content } = getState()
    let theData

    switch (userActions.step.currentStep) {
      case 'chooseForTrial':
        theData = {
          choice: true,
          PurchaseBundleCode: userActions.product.purchaseBundleCode,
          PurchasedItemKey: userActions.step.purchasedItemKey,
          CurrentStep: userActions.step.currentStep,
          Action: userActions.step.action,
        }
        break
      case 'coachingCall':
      case 'coachingCallComplete':
      case 'resumeCoachingCall':
      case 'resumeCoachingCallComplete':
      case 'verifySurvery':
      case 'coachingCall2':
      case 'coachingCall2Complete':
      case 'verifySurvery2':
      case 'resumeCoachingCall2':
      case 'resumeCoachingCall2Complete':
      case 'interviewPrepcoachingCall':
      case 'interviewPrepcoachingCallComplete':
      case 'interviewPrepcoachingCall2':
      case 'interviewPrepcoaching2Complete':
      case 'singleresumeCoachingCallComplete':
      case 'uomCoachcoachingCall':
      case 'uomCoachCallComplete':
        theData = {
          PurchaseBundleCode: userActions.product.purchaseBundleCode,
          PurchasedItemKey: userActions.step.purchasedItemKey,
          CurrentStep: userActions.step.currentStep,
          Action: userActions.step.action,
          Role: userActions.step.role,
        }
        break
      case 'salaryCall':
      case 'salaryCallComplete':
      case 'interviewprepsalaryCall':
      case 'interviewprepsalaryCallComplete':
        theData = {
          PurchaseBundleCode: userActions.product.purchaseBundleCode,
          CurrentStep: userActions.step.currentStep,
          PurchasedItemKey: userActions.step.purchasedItemKey,
          Action: userActions.step.Action,
          Submit: userActions.step.productFeatureCode, //'submit',
        }
        break
      default:
        break
    }
    let toastMessage = ''
    switch (userActions.step.currentStep) {
      case 'chooseForTrial':
        toastMessage = getContent('Global.Message.ThankForSelection', content)
        break
      case 'coachingCall':
      case 'resumeCoachingCall':
      case 'coachingCall2':
      case 'resumeCoachingCall2':
      case 'interviewPrepcoachingCall':
      case 'interviewPrepcoachingCall2':
      case 'salaryCall':
      case 'interviewprepsalaryCall':
      case 'uomCoachcoachingCall':
        toastMessage = getContent('Global.Message.SessionStarted', content)
        break
      case 'coachingCallComplete':
      case 'resumeCoachingCallComplete':
      case 'coachingCall2Complete':
      case 'resumeCoachingCall2Complete':
      case 'interviewPrepcoachingCallComplete':
      case 'interviewPrepcoaching2Complete':
      case 'singleresumeCoachingCallComplete':
      case 'salaryCallComplete':
      case 'interviewprepsalaryCallComplete':
      case 'uomCoachCallComplete':
        toastMessage = getContent('Global.Message.SessionEnd', content)
        break
      case 'verifySurvery':
      case 'verifySurvery2':
        toastMessage = getContent('Global.Message.ThankForSurvey', content)
        break
      default:
        break
    }
    theData.successMessage = getContent('Global.Message.Success', content)
    dispatch(actionToaster.showSuccess(toastMessage))
    dispatch(actions.closeChoiceSelectModal())
    dispatch(actionUserProducts.pushStep(theData))
    //dispatch(actions.postProduct(theData))
  },
  
  hideProfileUpdateToaster: data => {
    return {
      type: actionTypes.SKIP_PROFILE_UPDATE_TOASTER,
      hideProfileUpdateToaster: data
    }
  },
  
}

// Reducer
const defaultState = {
  appointmentKey: null,
  loading: false,
  isConfirmChoiceSelectOpen: false,
  isConfirmRollbackAppointmentOpen: false,
  isRollbackAppointmentError: false,
  rollbackAppointmentErrorData: null,
  showRollbackAppointmentSuccessMessage: false,
  isConfirmCancelAppointmentOpen: false,
  isCancelAppointmentError: false,
  cancelAppointmentErrorData: null,
  showCancelAppointmentSuccessMessage: false,

  isConfirmCancelSubscriptionOpen: false,
  isCancelSubscriptionError: false,
  cancelSubscriptionErrorData: null,

  isSelectCoachOpen: false,
  step: null,
  product: null,
  coachType: null,
  isScheduleAppointmentOpen: false,
  selectedEvent: null,
  saveEnabled: false,

  canCencelSubscriptionLoading: false,
  canCencelSubscription: false,
  hideProfileUpdateToaster: false

}

export default function (state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SKIP_PROFILE_UPDATE_TOASTER:
      return {
        ...state,
        hideProfileUpdateToaster: action.hideProfileUpdateToaster
      }
    case actionTypes.RESCHEDULE_APPOINTMENT_MODE:
      return {
        ...state,
        appointmentToReschedule: action.appointment
      }
    case actionTypes.OPEN_CONFIRM_CANCEL_SUBSCRIPTION:
      return updateObject(state, {
        isConfirmCancelSubscriptionOpen: true
      })

    case actionTypes.CLOSE_CONFIRM_CANCEL_SUBSCRIPTION:
      return updateObject(state, {
        isConfirmCancelSubscriptionOpen: false
      })

    case actionTypes.CANCEL_SUBSCRIPTION_START:
      return updateObject(state, {
        loading: true,
        isCancelSubscriptionError: false
      })
    case actionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
      return updateObject(state, {
        loading: false,
        isCancelSubscriptionError: false,
        isConfirmCancelSubscriptionOpen: false,
        isExitSurveyOpen: false,
      })
    case actionTypes.CANCEL_SUBSCRIPTION_ERROR:
      return updateObject(state, {
        loading: false,
        isCancelSubscriptionError: true,
        cancelAppointmentErrorData: action.error,
        appointmentKey: null,
        isConfirmCancelSubscriptionOpen: false,
        isExitSurveyOpen: false,
      })
    case actionTypes.CANCEL_SUBSCRIPTION_RESET:
      return updateObject(state, {
        isCancelSubscriptionError: false,
        cancelAppointmentErrorData: false,
        //showCancelAppointmentSuccessMessage: false,
      })

    case actionTypes.OPEN_CONFIRM_ROLLBACK_APPOINTMENT:
      return updateObject(state, {
        isConfirmRollbackAppointmentOpen: true,
        appointmentKey: action.appointmentKey,
      })

    case actionTypes.CLOSE_CONFIRM_ROLLBACK_APPOINTMENT:
      return updateObject(state, {
        isConfirmRollbackAppointmentOpen: false
      })

    case actionTypes.ROLLBACK_APPOINTMENT_START:
      return updateObject(state, {
        loading: true,
        isRollbackAppointmentError: false
      })
    case actionTypes.ROLLBACK_APPOINTMENT_SUCCESS:
      return updateObject(state, {
        loading: false,
        appointmentKey: null,
        isRollbackAppointmentError: false,
        isConfirmRollbackAppointmentOpen: false,
        showRollbackAppointmentSuccessMessage: true,
      })
    case actionTypes.ROLLBACK_APPOINTMENT_ERROR:
      return updateObject(state, {
        loading: false,
        isRollbackAppointmentError: true,
        rollbackAppointmentErrorData: action.error,
        appointmentKey: null,
        isConfirmRollbackAppointmentOpen: false,
        showRollbackAppointmentSuccessMessage: false,
      })
    case actionTypes.ROLLBACK_APPOINTMENT_RESET:
      return updateObject(state, {
        isRollbackAppointmentError: false,
        rollbackAppointmentErrorData: false,
        showRollbackAppointmentSuccessMessage: false,
      })
    case actionTypes.OPEN_CONFIRM_CANCEL_APPOINTMENT:
      return updateObject(state, {
        isConfirmCancelAppointmentOpen: true,
        appointmentKey: action.appointmentKey
      })

    case actionTypes.CLOSE_CONFIRM_CANCEL_APPOINTMENT:
      return updateObject(state, {
        isConfirmCancelAppointmentOpen: false
      })

    case actionTypes.CANCEEL_APPOINTMENT_START:
      return updateObject(state, {
        loading: true,
        isCancelAppointmentError: false
      })
    case actionTypes.CANCEL_APPOINTMENT_SUCCESS:
      return updateObject(state, {
        loading: false,
        appointmentKey: null,
        isCancelAppointmentError: false,
        isConfirmCancelAppointmentOpen: false,
        showCancelAppointmentSuccessMessage: true,
      })
    case actionTypes.CANCEL_APPOINTMENT_ERROR:
      return updateObject(state, {
        loading: false,
        isCancelAppointmentError: true,
        cancelAppointmentErrorData: action.error,
        appointmentKey: null,
        isConfirmCancelAppointmentOpen: false,
        showCancelAppointmentSuccessMessage: false,
      })
    case actionTypes.CANCEL_APPOINTMENT_RESET:
      return updateObject(state, {
        isCancelAppointmentError: false,
        cancelAppointmentErrorData: false,
        showCancelAppointmentSuccessMessage: false,
      })
    case actionTypes.OPEN_SELECT_COACH:
      return updateObject(state, {
        isSelectCoachOpen: true,
        step: action.step,
        product: action.product
      })
    case actionTypes.CLOSE_SELECT_COACH:
      return updateObject(state, {
        isSelectCoachOpen: false,
        step: null,
        product: null
      })

    case actionTypes.OPEN_SCHEDULE_APPOINTMENT:
      return updateObject(state, {
        isScheduleAppointmentOpen: true,
        step: action.step,
        coachType: action.coachType,
        product: action.product,
        saveEnabled: false,
      })
    case actionTypes.CLOSE_SCHEDULE_APPOINTMENT:
      //console.log('SCHEDULE APPOINTMENT AND STATE IS ', state);
      return updateObject(state, {
        isScheduleAppointmentOpen: false,
        step: null,
        coachType: null,
        product: null
      })
    case actionTypes.EVENT_SELECTED:
      return {
        ...state,
        selectedEvent: action.data,
        saveEnabled: true,
      }
    case actionTypes.ASSIGN_COACH_START:
    case actionTypes.POST_PRODUCT_START:
    case actionTypes.UPDATE_COACH_START:
      return updateObject(state, {
        loading: true
      })

    case actionTypes.ASSIGN_COACH_SUCCESS:
    case actionTypes.POST_PRODUCT_SUCCESS:
    case actionTypes.UPDATE_COACH_SUCCESS:
    case actionTypes.ASSIGN_COACH_ERROR:
    case actionTypes.POST_PRODUCT_ERROR:
    case actionTypes.UPDATE_COACH_ERROR:
      return updateObject(state, {
        loading: false,
        isSelectCoachOpen: false,
        isConfirmChoiceSelectOpen: false
      })

    case actionTypes.OPEN_CHOICE_SELECT:
      return updateObject(state, {
        isConfirmChoiceSelectOpen: true,
        step: action.step,
        product: action.product,
      })

    case actionTypes.CLOSE_CHOICE_SELECT:
      return updateObject(state, {
        isConfirmChoiceSelectOpen: false,
        step: null,
        product: null,
      })

    case actionTypes.GET_CANCEL_SUBSCRIPTION_DATA_START:
      return updateObject(state, {
        canCencelSubscriptionLoading: true,
        canCencelSubscription: false
      })
    case actionTypes.GET_CANCEL_SUBSCRIPTION_DATA_ERROR:
      return updateObject(state, {
        canCencelSubscriptionLoading: false,
        canCencelSubscription: false
      })
    case actionTypes.GET_CANCEL_SUBSCRIPTION_DATA_SUCCESS:
      return updateObject(state, {
        canCencelSubscriptionLoading: false,
        canCencelSubscription: action.data
      })
    default:
      return state
  }
}
