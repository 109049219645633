import React, { Component, useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { actions } from './redux/modules/auth'
import { actions as userActions } from './redux/modules/user-actions'
import { actions as userDocs } from './redux/modules/user-docs'
import { actions as userProfileActions } from './redux/modules/user-profile'

//import { actions as accessActions} from './redux/modules/access'
// import { actions as actionMQTT } from './redux/modules/mqtt'
import Routes from './Routes'
import Layout from './components/Layout/Layout'
import { getPartnerAssets } from './shared/get-partner-assets'
import MqttContext from './mqttClient-context'
import MqttClient from './mqttClient'
import { getContent, getModifiedLongDateFormatObjByLocale } from './shared/utility'
import Spinner from "./components/Spinner/Spinner"
import { GlobalContext } from './contexts/global.context';
import { StaticProvider, useLiveSignalsMaster } from '@kornferry/kfadvance_journeys/client'

const chatter = new MqttClient()

function App(props) {
  let { state: { assets: contextAssets, moment, momentTZ }, dispatch: dispatchGlobal } = useContext(GlobalContext)

  useLiveSignalsMaster()
  const {
    content,
    auth,
    user,
    authLoading,
    coachSchedule,
    settings,
    isInterviewApp,
    // state
    saveUserProfile,
    cancelAppointment,
    scheduleCoachingAppointment,
    scheduleAutoCoachingAppointment,
    updatePurchstateTimezone,
    rescheduleCoachingAppointment,
    navToAppointment,
    refreshPurchStateV2,
    c = key => getContent(key, content),
  } = props;
  console.log('AND refreshPurchStateV2 IS ', refreshPurchStateV2)
  console.log('APPJS => auth: ', props.auth)
  let assets = contextAssets;
  const staticProviderState = {
    content,
    auth,
    settings,
    user,
    coachSchedule,
    assets,
    isInterviewApp
  }


  useEffect(() => {
    if (moment && momentTZ) {
      // const theL = user?.LanguageCode?.substring(0,2) || 'en'

      // For moment to properly update the locale, the language code must be complete 
      //and in all lower case (i.e. 'zh-ch' and not 'zh-CN')
      const theL = user?.LanguageCode ? user.LanguageCode.toLowerCase() : 'en-us'
      moment.locale(theL)
      momentTZ.updateLocale(theL, moment.localeData()._config); // copy locale to moment-timezone
      momentTZ.locale(theL)
      // console.log('AND A moment DATE IS ' + moment(new Date()).format('MMM D, YYYY[,] dddd [at] h:mm z'))
      // console.log('AND A momentTZ DATE IS ' + momentTZ(new Date()).format('MMM D, YYYY[,] dddd [at] h:mm z'))

      // Updates the moment.format('LLLL') option to the string defined in Global.Coaching.AppointmentDateTimeLongFormat for the given locale
      moment.updateLocale(theL, { longDateFormat: getModifiedLongDateFormatObjByLocale(theL, c('Global.Coaching.AppointmentDateTimeLongFormat')) })
      // console.log('AND A moment.tz long-formatted DATE IS ' + moment.tz(new Date(),'America/Los_Angeles').format('LLLL'))
      // console.log('AND A moment long-formatted DATE IS ' + moment(new Date(),'America/Los_Angeles').format('LLLL'))

    }
  }, [user])

  useEffect(() => {
    if (settings?.meridiem === 'special') {
      moment.updateLocale('en', {
        meridiem: function (hour) {
          if (hour < 12) {
            return 'a.m.';
          } else {
            return 'p.m.';
          }
        }
      })
    }
  }, [settings])

  useEffect(() => {
    props.checkLoggedIn();
    props.checkMobileApp();
  }, [])

  // useEffect(() => {
  //   if (!user) return;
  //   isDev && (() => {
  //     console.log('iframe - add eventlistener')
  //     window.addEventListener('message', (e) => {
  //       if (e.origin !== 'http://localhost:3000') return;
  //       if (e.data !== 'getStaticProviderState') return;
  //       debugger;
  //       console.log('iframe - msg received:', e.data)
  //       e.source.postMessage({
  //         type: 'staticProviderState',
  //         data: staticProviderState
  //       }, 'http://localhost:3000')
  //     });
  //   })()
  // }, [user])

  useEffect(() => {
    if (!user) return
    if (window.location.host !== 'localhost:3001') {
      return
    }

    const journeyOrigin = 'http://localhost:3000'
    window.addEventListener('message', e => {
      if (e.origin !== journeyOrigin) return;
      // if (e.data)

      console.log('iframe - reply received:', e.data)
    })

    var i = document.createElement('iframe');
    i.style.display = 'none';
    i.onload = function () {
      console.log('iframe - loaded')

      i.contentWindow.postMessage({
        type: 'staticProviderState',
        data: JSON.stringify(staticProviderState)
      }, journeyOrigin)
    };
    i.src = journeyOrigin;
    document.body.appendChild(i);
  }, [auth.purchState])

  useEffect(() => {
    assets = props && props.partnerKey ? getPartnerAssets(props.partnerKey, props.history) : getPartnerAssets('', props.history);
    dispatchGlobal({
      type: 'ASSETS_SET',
      data: assets
    });
  }, [props.partnerKey])

  const { purchState, uploadUserDocs } = props

  if (authLoading) {
    return <Spinner />
  }

  const updatedPurchState = {
    ...purchState,

    c: key => getContent(key, content),
    uploadUserDocs,
  }
  //console.log('AND PURCHSTATE IS ', updatedPurchState)
  const childProps = {
    loading: props.loading,
    isAuthenticated: props.isAuthenticated,
    userKey: props.userKey,
    partnerKey: props.partnerKey,
    name: props.name,
    user,
    assets,
    products: props.products,
    purchState: updatedPurchState,
    content: props.content,
    settings,
    c: key => getContent(key, content),
    isInterviewApp,
    momentTZ,
  }

  return (
    <MqttContext.Provider value={chatter}>

      <StaticProvider state={staticProviderState} methods={{
        updatePurchstateTimezone,
        saveUserProfile,
        navToAppointment,
        c,
        refreshPurchStateV2
      }}>
        <Layout {...childProps}>
          <Routes childProps={childProps} />
        </Layout>
      </StaticProvider>
    </MqttContext.Provider>
  )
}

const mapStateToProps = state => {
  return {
    authLoading: state.auth.authLoading,
    loading: state.auth.loading,
    isAuthenticated: state.auth.isAuthenticated,
    userKey: state.auth.userKey,
    partnerKey: state.settings ? state.settings.PartnerKey : '',
    name: state.auth.name,
    user: state.auth.user,
    assets: state.auth.assets,
    products: state.auth.products,
    purchState: state.auth.purchState,

    content: state.content,
    settings: state.settings,
    isInterviewApp: state.auth.isInterviewApp,
    auth: state.auth,
    coachSchedule: state.coachSchedule,
    // state
  }
}

const mapDispatchToProps = dispatch => ({
  // mqttConnect: () => dispatch(actionMQTT.mqttConnect()),
  // mqttSubscribe: sub => dispatch(actionMQTT.mqttSubscribe(sub)),
  checkLoggedIn: () => dispatch(actions.authCheckState()),
  //getAccess: () => dispatch(accessActions.getAccess()),
  checkMobileApp: () => dispatch(actions.checkForMobileApp()),

  // scheduleOutlookAppointment: (coachId, schedulePayload, pushStepPayload) =>
  //     dispatch(userActions.scheduleOutlookAppointment(coachId, schedulePayload, pushStepPayload)),
  uploadUserDocs: (userKey, postData) => dispatch(userDocs.uploadUserDocs(userKey, postData)),
  cancelAppointment: (appointmentKey) => dispatch(userActions.cancelAppointment(appointmentKey)),
  rescheduleCoachingAppointment: (appointmentKey, coachId, schedulePayload, pushStepPayload) =>
    dispatch(userActions.rescheduleCoachingAppointment(appointmentKey, coachId, schedulePayload, pushStepPayload)),
  scheduleCoachingAppointment: (coachId, schedulePayload, pushStepPayload) =>
    dispatch(userActions.scheduleCoachingAppointment(coachId, schedulePayload, pushStepPayload)),
  scheduleAutoCoachingAppointment: (coachID, scheduleData, pushData) =>
    dispatch(userActions.scheduleAutoCoachingAppointment(coachID, scheduleData, pushData)),
  updatePurchstateTimezone: timezone => dispatch(actions.updatePurchstateTimezone(timezone)),
  saveUserProfile: (userKey, postData) => dispatch(userProfileActions.saveUserProfile(userKey, postData, { disableStartDispatch: true })),
  navToAppointment: (itemKey, button) => dispatch(actions.navToAppointment(itemKey, button)),
  refreshPurchStateV2: () => dispatch(actions.refreshPurchStateV2()),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
)
