import {
  useContext
} from 'react'
import {
  updateObject,
  api,
  getHost,
  handleError
} from '../../shared/utility'
import {
  SYSTEM_SUPPORTED_ROLES
} from '../../constants/index'
//import moment from 'moment-timezone'
import {
  selectors as workSelectors
} from '../../redux/modules/work'
import {
  actions as actionMQTT
} from '../../redux/modules/mqtt'
import {
  formatDateLong,
  formatDateShort
} from '../../shared/utility'
import {
  logEvent
} from '../../shared/analytics'
import analyticsTypeConstants from '../../constants/analytics/analytics-type.constants';
import {
  map,
  sortBy,
  prop,
  sortWith,
  descend,
  ascend
} from 'ramda'
import routeConstants from '../../constants/route.constants'
import {
  GlobalContext
} from '../../contexts/global.context';
import localmoment from 'moment'
import momenttz from 'moment-timezone'
import {
  extendMoment
} from 'moment-range'
const moment = extendMoment(momenttz)

const westPoint = '505a4b6298b54271a35f637656260a17'
const checkWestPoint = partenrKey => {
  //TODO: move to settings
  return partenrKey === westPoint
}

const R = require('ramda')


const todayGreeting = () => {
  const theDay = moment().day()
  const theHour = moment().hour()
  switch (true) {
    case theHour < 11 && theDay !== 5:
      return 'GoodMorning'
    case theHour > 19:
      return 'GoodEvening'
    case theDay < 2:
      return 'Greetings'
    case theDay > 5:
      return 'Hello'
    default:
      return 'Welcome'
  }
}

const getNewMenuItem = (text, item) => {
  let theItem = {
    text,
    item,
    subs: [],
  }

  return theItem
}

const getAddOnList = products => {
  let result = {}
  for (let product of products) {
    if (product.AddOnProductKey) {
      if (!result[product.ProductKey]) {
        result[product.ProductKey] = product.AddOnProductKey
      }
    }
  }
  return result
}

const getDefaultByPartner = (settings, applicationContent) => {
  const {
    PurchaseState
  } = settings
  const {
    Products
  } = applicationContent
  // console.log('AND APP CONTENT IS ', applicationContent)
  let coachingAddOns = getAddOnList(Products)
  // console.log('SO PRODUCTS AND ADDONS ', Products, coachingAddOns)
  let mentoring = getPageObject(PurchaseState.Mentoring)
  mentoring = {
    ...mentoring,
    appointments: []
  }
  return {
    freeCoaching: PurchaseState.FreeCoaching,
    coachingAddOn: null,
    coachingAddOns: coachingAddOns,
    dashboard: getPageObject(PurchaseState.Dashboard),
    insights: getPageObject(PurchaseState.Insights),
    profile: getPageObject(PurchaseState.Profile),
    account: getPageObject(PurchaseState.Account),
    resume: getPageObject(PurchaseState.Resume),
    traitsAssessment: getPageObject(PurchaseState.TraitsAssessment),
    fullAssessment: getPageObject(PurchaseState.FullAssessment),
    interview: getPageObject(PurchaseState.Interview),
    chat: getPageObject(PurchaseState.Chat),
    coaching: getCoachingObject(true, JSON.parse(PurchaseState.Coaching)),
    mentoring,
    paycheck: getPageObject(PurchaseState.Paycheck),
    jobs: getPageObject(PurchaseState.Jobs),
    services: getPageObject(PurchaseState.Services),
    faq: getPageObject(PurchaseState.Faq),
    about: getPageObject(PurchaseState.About),
  }
}

const getCoachingObject = (PartnerAccess, IndiAccess) => {

  return {
    partnerAccess: PartnerAccess,
    hasPurchased: false,
    //careerOnly: IndiAccess.Career && !(IndiAccess.Paycheck || IndiAccess.Resume || IndiAccess.Interview || IndiAccess.Placement || IndiAccess.Westpoint),
    career: {
      partnerAccess: IndiAccess.Career,
      selected: false,
      hasPurchased: false,
      appointments: [],
      color: '#03c096',
      requiresAssessment: false,
      tabName: 'career',
      roleCode: 'coach',
    },
    paycheck: {
      partnerAccess: IndiAccess.Paycheck,
      selected: false,
      hasPurchased: false,
      appointments: [],
      color: '#76E6D2',
      requiresAssessment: true,
      tabName: 'paycheck',
      roleCode: 'salaryCoach',
    },
    resume: {
      partnerAccess: IndiAccess.Resume,
      selected: false,
      hasPurchased: false,
      appointments: [],
      color: '#306259',
      requiresAssessment: false,
      tabName: 'resume',
      roleCode: 'resumeCoach',
    },
    interview: {
      partnerAccess: IndiAccess.Interview,
      selected: false,
      hasPurchased: false,
      appointments: [],
      color: '#009396',
      requiresAssessment: false,
      tabName: 'interview',
      roleCode: 'interviewPrepCoach',
    },
    placement: {
      partnerAccess: IndiAccess.Placement,
      selected: false,
      hasPurchased: false,
      appointments: [],
      color: '#1e3857',
      requiresAssessment: false,
      tabName: 'placement',
      roleCode: 'talentAcquisitionCoach',
    },
    partnerCoach: {
      partnerAccess: IndiAccess.Partner,
      selected: false,
      hasPurchased: false,
      appointments: [],
      color: '#306259',
      requiresAssessment: false,
      tabName: 'partnerCoach',
      roleCode: 'partnerCoach',
    },
    feedbackCoach: {
      partnerAccess: IndiAccess.Feedback,
      selected: false,
      hasPurchased: false,
      appointments: [],
      color: '#306259',
      requiresAssessment: false,
      tabName: 'feedbackCoach',
      roleCode: 'feedbackCoach',
    },
  }
}

const getPageObject = PartnerAccess => {
  return {
    partnerAccess: PartnerAccess,
    hasPurchased: false,
    isComplete: false,
    pushSteps: [],
  }
}

const getSessionLabel = feature => {
  switch (feature.ProductFeatureCode) {
    case 'paidcoaching2':
    case 'coaching2':
      return 'CoachingSession' // Coaching Session 2 (' + feature.Quantity + 'minutes)'
    case 'prysmianonboardingcareercoaching':
      return 'CareerCoachingWelcomeSession'
    case 'prysmianonboardingplacementcoaching':
      return 'PlacementCoachingWelcomeSession' //'Welcome/Onboarding Session (' + feature.Quantity + ' minutes)'
    default:
      return 'CoachingSession' //'Coaching Session (' + feature.Quantity + ' minutes)'
  }
}

const getAppointmentObject = (item, feature, coachColorClass, coachId, showScheduling, userKey, partnerKey, user) => {
  //console.log('GET APPOINTMENT OBJECT WITH ITEM AND FEATURE ', item, feature)
  const sessionLabel = getSessionLabel(feature)
  const timeBlock = feature.TimeBlock
  let showMessage = false
  let showLockout = false
  let showAppointmentScheduled = false
  let msgLockout = {}
  const appointmentDate = feature.AppointmentDate
  // console.log('getAppointmentObject feature.CurrentStep', feature.CurrentStep)
  // console.log('getAppointmentObject feature.ProductFeatureCode', feature.ProductFeatureCode)
  // console.log('getAppointmentObject feature.FeatureType', feature.FeatureType)
  switch (feature.CurrentStep) {
    case 'assignResumeCoach':
    case 'assignInterviewPrepCoach':
    case 'assignCoach':
    case 'assignSalaryCoach':
      showLockout = true
      msgLockout = getMsgObject('custom', false, false, 'HangTight', 'WaitingCoachAssignment', '', '')
      break
    case 'assignAdmin':
    case 'assignfeedbackAdmin':
      switch (true) {
        case feature.ProductFeatureCode === 'paidcoaching2':
        case feature.ProductFeatureCode === 'coaching2':
        case feature.ProductFeatureCode === 'interviewprepcoaching2':
        case feature.ProductFeatureCode === 'resumepaidcoaching2':
          showLockout = true
          msgLockout = getMsgObject('custom', false, false, 'NotYet', 'AllModulesFirst', '', '')
          break
        case feature.FeatureType === 'salaryreview':
        case feature.FeatureType === 'interviewprepsalaryreview':
          showLockout = true
          if (feature.ProductFeatureCode === 'westpointsalaryreview') {

            msgLockout = getMsgObject('custom', false, false, 'HoldUp', 'FirstBeforeWestpointPaycheck', '', '')
          } else {
            msgLockout = getMsgObject('custom', false, false, 'HoldUp', 'FirstBeforePaycheck', '', '')
          }
          break
        case feature.FeatureType === 'coaching':
        case feature.FeatureType === 'placementcoaching':
          showLockout = true
          switch (feature.ProductFeatureCode) {
            case 'prysmianonboardingplacementcoaching':
              msgLockout = getMsgObject(
                'custom',
                false,
                false,
                'HangTight',
                'CompleteCareerFirst', //"You'll need to complete your Career Coach Onboarding Session before scheduling with a Placement Coach!",
                '',
                ''
              )
              break
            case 'westpointcoaching2':
              console.log('HEY HERE WE ARE IN WESTPOINT COACHING 2')
              msgLockout = getMsgObject(
                'custom',
                false,
                false,
                'HangTight',
                'CompleteFirstCareerFirst', //"You'll need to complete your Career Coach Onboarding Session before scheduling with a Placement Coach!",
                '',
                ''
              )
              break
            case 'paidcoaching':
              msgLockout = getMsgObject('custom', false, true, 'HoldUp', 'AssessmentFirst', routeConstants.assessments, 'CompleteAssessment')
              break
            case 'feedbackcoachingSession45':
            case 'assessmentfeedbackcoachingSession45':
              msgLockout = getMsgObject('custom', false, false, 'HoldUp', 'AssessmentFirst', routeConstants.assessments, 'CompleteAssessment')
              break
            case 'soficoaching':
              const resStep = R.find(R.propEq('FeatureType', 'resume'))(item.details.Steps)
              if (resStep && resStep.CurrentStep !== 'complete') {
                msgLockout = getMsgObject('custom', false, true, 'HoldUp', 'ResumeFirst', '/resume', 'UploadResume')
              }
              break
            default:
              const resumeStep = R.find(R.propEq('FeatureType', 'resume'))(item.details.Steps)
              let nextThing = 'ResumeFirst'
              let nextUrl = '/resume'
              let nextButton = 'UploadResume'
              if (resumeStep && resumeStep.CurrentStep === 'complete') {
                nextThing = 'AssessmentFirst'
                nextUrl = routeConstants.assessments
                nextButton = 'CompleteAssessment'
              }
              checkWestPoint(item.PartnerKey) ?
                (msgLockout = getMsgObject('custom', false, true, 'HoldUp', nextThing, nextUrl, nextButton)) :
                (msgLockout = getMsgObject(
                  'custom',
                  false,
                  false,
                  'HoldUp',
                  'CompleteEarlierFirst', //"You'll need to complete all earlier sessions before scheduling this appointment!",
                  '',
                  ''
                ))
          }

          break
        default:
          break
      }
      break
    case 'scheduleCoachingCall':
      if (feature.ProductFeatureCode === 'autoAssignCoachCoaching') {
        const resumeStep = R.find(R.propEq('FeatureType', 'resume'))(item.details.Steps)

        if (resumeStep && resumeStep.CurrentStep !== 'complete') {
          showLockout = true
          showScheduling = false
          msgLockout = getMsgObject('custom', false, true, 'HoldUp', 'ResumeFirst', '/resume', 'UploadResume')
        } else {
          const assessmentStep = R.find(R.propEq('FeatureType', 'assessment'))(item.details.Steps)
          if (assessmentStep && assessmentStep.CurrentStep !== 'complete') {
            showLockout = true
            showScheduling = false
            msgLockout = getMsgObject('custom', false, true, 'HoldUp', 'AssessmentFirst', routeConstants.assessments, 'CompleteAssessment')
          }
        }
      }
      break;
    case 'coachingCall':
    case 'coachingCall2':
    case 'singleresumeCoachingCall':
    case 'resumeCoachingCall':
    case 'resumeCoachingCall2':
    case 'salaryCall':
    case 'interviewPrepcoachingCall':
    case 'interviewPrepcoachingCall2':
    case 'placementcoachingCall':
    case 'coachingCall1':
    case 'coachingCall3':
    case 'placementcoachingCall1':
    case 'placementcoachingCall2':
    case 'placementcoachingCall3':
    case 'westpointcoachingCall':
    case 'uomCoachcoachingCall':
    case 'partnerCoachcoachingCall':
    case 'feedbackcoachingCall':
      showAppointmentScheduled = true
      break
    case 'resumeCoachingCallComplete':
    case 'resumeCoachingCall2Complete':
    case 'interviewPrepcoachingCallComplete':
    case 'interviewPrepcoaching2Complete':
    case 'coachingCallComplete':
    case 'coachingCall2Complete':
    case 'salaryCallComplete':
    case 'interviewprepsalaryCallComplete':
    case 'singleresumeCoachingCallComplete':
    case 'placementcoachingCallComplete':
    case 'coachingCallComplete1':
    case 'coachingCallComplete2':
    case 'coachingCallComplete3':
    case 'placementcoachingCallComplete1':
    case 'placementcoachingCallComplete2':
    case 'placementcoachingCallComplete3':
    case 'westpointcoachingCallComplete':
    case 'westpointCoachingCall1Complete':
    case 'uomCoachCallComplete':
    case 'partnerCoachCallComplete':
    case 'feedbackcoachingCallComplete':
      showMessage = true
      msgLockout = getMsgObject('custom', false, false, 'CoachingInProgress', '', '/services', 'Services')
      break
    case 'complete':
      showMessage = true
      switch (feature.ProductFeatureCode) {
        case 'prysmianonboardingplacementcoaching':
        case 'prysmianplacementcoaching1':
        case 'prysmianplacementcoaching2':
        case 'prysmianplacementcoaching3':
        case '30placementcoaching':
        case 'prysmianonboardingcareercoaching':
        case 'prysmiancareercoaching1':
        case 'prysmiancareercoaching2':
        case 'prysmiancareercoaching3':
        case 'westpointsinglecareercoaching':
          msgLockout = getMsgObject('custom', false, false, 'SessionCompleted', '', '/', 'GetMore', true, moment(appointmentDate).format('MM/DD/YY') + '!')
          break
        case 'soficoaching':
        case 'sofisinglecareercoaching':
        case 'uomtechcareercoaching':
          msgLockout = getMsgObject(
            'custom',
            false,
            true,
            'SessionCompleted', // + moment(appointmentDate).format('MM/DD/YY') + '!',
            'LikeThisGetSessions', //'Like this? Get additional sessions.',
            '/',
            'GetMore',
            true,
            moment(appointmentDate).format('MM/DD/YY') + '!'
          )
          break
        default:
          checkWestPoint(item.PartnerKey) ?
            (msgLockout = getMsgObject(
              'custom',
              false,
              false,
              'SessionCompleted', // + moment(appointmentDate).format('MM/DD/YY') + '!',
              '',
              '/services',
              'BuyMore',
              true,
              moment(appointmentDate).format('MM/DD/YY') + '!'
            )) :
            feature.ProductFeatureCode === 'subscriptioncareercoaching' || feature.ProductFeatureCode === 'subscriptionsinglecareercoaching' || feature.ProductFeatureCode === 'addOnSingleCareerCoaching' || feature.ProductFeatureCode === 'coachingSession45' ?
            (msgLockout = getMsgObject(
              'custom',
              false,
              true,
              'SessionCompleted',
              '', //LikeThisBuySessions', //'Like this? Buy additional sessions.',
              '/',
              'GetMore',
              true,
              moment(appointmentDate).format('MM/DD/YY') + '!'
            )) :
            (msgLockout = getMsgObject(
              'custom',
              false,
              false,
              'SessionCompleted',
              '', //LikeThisBuySessions', //'Like this? Buy additional sessions.',
              '/services',
              'BuyMore',
              true,
              moment(appointmentDate).format('MM/DD/YY') + '!'
            ))
      }
      break
    case 'salaryQuestionaire':
      showMessage = true
      msgLockout = getMsgObject('custom', false, true, 'BriefQuestions', '', '/paycheck', 'Paycheck', false)
      break
    default:
      break
  }
  return {
    showScheduling,
    appointmentDate,
    showAppointmentScheduled,
    appointmentKey: feature.AppointmentKey,
    coachId,
    showLockout,
    showMessage,
    allotedSpeakTime: feature.Quantity,
    productFeatureCode: feature.ProductFeatureCode,
    productCode: item.ProductCode,
    msgLockout,
    timeBlock,
    coachColorClass,
    sessionLabel,
    selected: false,
    pushStepData: getPushStepPayload(item, feature),
    schedulePayload: getShedulePayload(item, feature, userKey, partnerKey, user),
    purchaseBundleCode: item.PurchaseBundleCode,
    purchasedItemKey: feature.PurchasedItemKey,
    currentStep: feature.CurrentStep,
    isComplete: feature.CurrentStep === 'complete',
    action: feature.Action,
    submit: feature.ProductFeatureCode,
    team: item && item.details && item.details.team,
    schedulingMode: feature.SchedulingMode,
  }
}

const getShedulePayload = (item, feature, userKey, partnerKey, user) => {
  var data = {
    duration: feature.TimeBlock,
    AllottedSpeakTime: feature.Quantity,
    ProductFeatureCode: feature.ProductFeatureCode,
    PurchasedItemKey: feature.PurchasedItemKey,
    PartnerKey: partnerKey,
    userKey: userKey,
    CurrentStep: feature.CurrentStep,
    Pool: feature.Pool,
    ProductCode: item.ProductCode,
    PurchaseBundleCode: item.PurchaseBundleCode,
    entityKey: user.EntityKey
  }
  return data
}

const getMsgObject = (id, blocker, showButton, msgTitle, msg, navPath, buttonLabel, showStars, msgValue) => {
  return {
    id,
    blocker,
    showButton,
    msgTitle,
    msg,
    navPath,
    buttonLabel,
    showStars,
    msgValue,
  }
}

const getMainStep = (id, showButton, msgTitle, msg, navPath, buttonLabel, msgValue, meetingDetails) => {
  return {
    id,
    showButton,
    msgTitle,
    msg,
    navPath,
    buttonLabel,
    msgValue,
    meetingDetails
  }
}

const sortProductsForMultiple = products => {
  //console.log('OK SORTING USER PRODUCTS ', products)
  const theProds = products.map(p => {
    let hasCoaching = false
    if (p.details) {
      p.details.Steps.map(f => {
        if (f.FeatureType === 'coaching' || f.FeatureType === 'placementcoaching') {
          p.completed = f.CurrentStep === 'complete'
          p.appointmentDate = f.AppointmentDate !== null ? f.AppointmentDate : moment.utc(new Date()).format()
          hasCoaching = true
        }
        return f
      })
    }
    if (!hasCoaching) {
      p.appointmentDate = p.PurchaseDT
      p.completed = true
    }
    return p
  })
  let completedCoaching = theProds.filter(p => p.completed)
  let unCompletedCoaching = theProds.filter(p => !p.completed)
  var chatSortcompleted = sortWith([
    descend(prop('appointmentDate'))
  ])
  completedCoaching = chatSortcompleted(completedCoaching)
  var chatSortUncompleted = sortWith([
    ascend(prop('appointmentDate'))
  ])
  unCompletedCoaching = chatSortUncompleted(unCompletedCoaching)
  completedCoaching.forEach(p => {
    unCompletedCoaching.push(p)
  })
  return unCompletedCoaching
}

const getProductsObjectV2 = (prods, timezone, userKey, partnerKey, user) => {
  //console.log('GET NEW PRODS FROM ', prods, timezone)
  const appoints = []
  prods.forEach(p => {
    p.details.Steps.forEach(s => {
      if (s.FeatureType === "coaching") {
        const theState2 = getFeatureState(s, timezone)
        const theCoach2 = getCoachOrAdmin(s.Steps, false)
        const showScheduling = (s.CurrentStep === 'scheduleCoachingCall' || s.CurrentStep === 'scheduleCoachingCall2' || s.CurrentStep === 'uomCoachscheduleCoachingCall' || s.CurrentStep === 'scheduleFeedbackCoachingCall')
        //console.log('AND THE STATE IS ', theState2)
        appoints.push({
          productFeature: getFeatureType(s),
          featureUrl: getFeatureUrl(s),
          currentStep: s.CurrentStep,
          appointmentKey: s.AppointmentKey,
          currentState: theState2.state,
          currentDate: theState2.date || '',
          coach: theCoach2,
          timezone,
          completed: s.CurrentStep === 'complete',
          appointment: getAppointmentObject(p, s, 'careerColor', theCoach2.roleUserKey, showScheduling, userKey, partnerKey, user)
        })
      }
    })
  })
  //console.log('NEW PRODSV2 IS appoints', appoints)
  return {
    currentAppointments: appoints.filter(p => !p.completed),
    pastAppointments: appoints.filter(p => p.completed)
  }
}
const getProductsObject = (prods, showTitle, timezone, userKey) => {
  const productsFiltered = prods.filter(
    p =>
    p.ProductCode !== 'Freemium' &&
    p.ProductCode !== 'TraitsonlyAssessment' &&
    p.ProductCode !== 'SofiSingleCareerCoaching' &&
    p.ProductCode !== 'SinglePlacementCoaching' &&
    p.ProductCode !== 'WestPointSingleCareerCoaching' &&
    p.ProductCode !== 'SubscriptionSingleCareerCoaching' &&
    p.ProductCode !== 'AddOnSingleCareerCoaching' &&
    p.ProductCode !== 'AddOnPartnerCareerCoaching' &&
    p.ProductCode !== 'AddOnKFMentoringSession' &&
    p.ProductCode !== 'AddOnMosaicCoachingSession' &&
    p.ProductCode !== 'FreeSingleCareerCoaching' &&
    p.ProductCode !== 'AddOnSingleGoogleCareerCoaching' &&
    p.ProductCode !== 'AddOnSingleIntelCareerCoaching'
  )


  const productsAddons = prods.filter(
    p =>
    (p.ProductCode === 'SofiSingleCareerCoaching' ||
      p.ProductCode === 'SinglePlacementCoaching' ||
      p.ProductCode === 'WestPointSingleCareerCoaching' ||
      p.ProductCode === 'SubscriptionSingleCareerCoaching' ||
      p.ProductCode === 'AddOnSingleCareerCoaching' ||
      p.ProductCode === 'AddOnPartnerCareerCoaching' ||
      p.ProductCode === 'AddOnMosaicCoachingSession' ||
      p.ProductCode === 'AddOnKFMentoringSession') ||
    p.ProductCode === 'FreeSingleCareerCoaching' ||
    p.ProductCode === 'AddOnSingleGoogleCareerCoaching' ||
    p.ProductCode === 'AddOnSingleIntelCareerCoaching'
  )

  let finalProducts = []
  let addOns = {
    showIcon: false,
    productCode: 'addons',
    product: 'AdditionalCoachingSessions',
    purchaseDate: undefined,
    features: [],
  }
  //console.log('AND THE GET PURCHASES TIMEZONE IS ', timezone)
  productsAddons.forEach(item => {
    item.details.Steps.forEach(feature => {
      const theCoach2 = getCoachOrAdmin(feature.Steps, false)
      const theState2 = getFeatureState(feature, timezone)
      addOns.features.push({
        productFeature: getFeatureType(feature),
        featureUrl: getFeatureUrl(feature),
        currentStep: feature.CurrentStep,
        appointmentKey: feature.AppointmentKey,
        currentState: theState2.state,
        currentDate: theState2.date,
        coach: theCoach2,
        timezone,
      })
    })
  })
  //  console.log('AND ADDONS IS ', productsAddons)
  if (addOns.features.length > 0) {
    finalProducts.push(addOns)
  }
  let showMenteeProducts = true
  productsFiltered.forEach(item => {
    finalProducts.push({
      showIcon: item.ProductCode !== 'PrysmianCoaching',
      productCode: item.ProductCode,
      product: item.ProductCode !== 'PrysmianCoaching' ? item.ProductCode : 'YourCoachingSessions',
      purchaseDate: formatDateLong(item.PurchaseDT),
      effectiveCanceledDate: item.EffectiveCanceledDate,
      isCanceled: item.IsCanceled,
      canceledDate: item.CanceledDate,
      features: R.map(feature => {
        const theCoach = getCoachOrAdmin(feature.Steps, false)
        const theState = getFeatureState(feature, timezone)
        showMenteeProducts = feature.ProductFeatureCode === 'menteeQuestionaire' && feature.CurrentStep === 'submitMenteeQuestionaire' ? false : showMenteeProducts
        //console.log('SHOW MENTEE PRODUCTS IS ', showMenteeProducts)
        return {
          pushStepPayload: getPushStepPayload(item, feature),
          appointmentKey: feature.AppointmentKey,
          productFeature: getFeatureType(feature),
          featureUrl: getFeatureUrl(feature),
          currentStep: feature.CurrentStep,
          currentState: theState.state,
          currentDate: theState.date, //feature.AppointmentDate ? feature.AppointmentDate :
          timezone,
          coach: theCoach,
        }
      }, item.details.Steps),

    })
  })
  return {
    //serviceTitle: showTitle ? 'Your Services' : '',
    products: finalProducts,
    showMenteeProducts,
  }
}

const getMeetingDetails = (feature) => {

  let result = {}

  if (feature && feature.Steps) {
    feature.Steps.forEach(item => {
      if (['coachingCall', 'feedbackcoachingCall', 'appointmentpending', 'mentoringCall'].includes(item.Name) && item.MeetingDetails) {
        item.MeetingDetails.forEach(c => {
          const meetingDetails = JSON.parse(c.MeetingDetails);
          const meetingDetail = meetingDetails && meetingDetails.meetingDetail;
          if (!c.IsHost) {
            //  const TypeHard = 'Zoom'
            //  const MeetingLinkHard = 'https://kornferry.zoom.com/j/430285445'
            //  const MeetingIDHard = '430285445'
            //  const MeetingDetailsHard = '[{\"country_name\":\"US\",\"city\":\"San Jose\",\"number\":\"+1 6699006833\",\"type\":\"toll\",\"country\":\"US\"},{\"country_name\":\"US\",\"city\":\"San Jose\",\"number\":\"+1 4086380968\",\"type\":\"toll\",\"country\":\"US\"},{\"country_name\":\"US\",\"city\":\"Houston\",\"number\":\"+1 3462487799\",\"type\":\"toll\",\"country\":\"US\"},{\"country_name\":\"US\",\"city\":\"New York\",\"number\":\"+1 6468769923\",\"type\":\"toll\",\"country\":\"US\"},{\"country_name\":\"US\",\"city\":\"\",\"number\":\"+1 2532158782\",\"type\":\"toll\",\"country\":\"US\"},{\"country_name\":\"US\",\"city\":\"\",\"number\":\"+1 3017158592\",\"type\":\"toll\",\"country\":\"US\"},{\"country_name\":\"US\",\"city\":\"Chicago\",\"number\":\"+1 3126266799\",\"type\":\"toll\",\"country\":\"US\"},{\"country_name\":\"US\",\"city\":\"\",\"number\":\"+1 8887880099\",\"type\":\"tollfree\",\"country\":\"US\"},{\"country_name\":\"US\",\"city\":\"\",\"number\":\"+1 8778535247\",\"type\":\"tollfree\",\"country\":\"US\"}]'

            result = {
              // meetingCoach: feature.AppointmentCoachName,
              // meetingType: TypeHard,
              // meetingURL: MeetingLinkHard,
              // meetingID: MeetingIDHard,
              // meetingPhone: JSON.parse(MeetingDetailsHard).find((numObj) => {
              //   if(numObj.type === 'tollfree'){
              //     return numObj
              //   }
              // })
              meetingCoach: feature.AppointmentCoachName,
              // meetingType: c.Type, // No longer neede because we are getting this from SchedulingMode
              meetingURL: c.MeetingLink,
              meetingID: c.MeetingID,
              meetingPhone: meetingDetail?.find( numObj => numObj.type === 'tollfree')
            }
          }
        })
      }
    })
  }
  return result
}

const getCoachOrAdmin = (Steps, GetID) => {
  let result = {}

  if (Steps) {
    Steps.forEach(item => {
      if (item.AssignedCoaches) {
        item.AssignedCoaches.forEach(c => {
          if (c.RoleCode !== 'admin' && c.RoleCode !== 'feedbackadmin') {
            result = {
              roleUserKey: c.roleUserKey,
              roleEmailAddress: c.AdminAccountAddress,
              adminFirstName: c.AdminFirstName,
              adminLastName: c.AdminLastName,
            }
          }
        })
      }
    })
  }
  return result
}

const getFeatureState = (feature, timezone) => {
  switch (feature.CurrentStep) {
    case 'complete':
      switch (feature.FeatureType) {
        case 'insights':
        case 'interviewprep':
        case 'jobs':
          return {
            state: 'FirstViewed', date: moment(feature.StepDT).tz(timezone).format()
          } //'First Viewed : ' + formatDateLong(feature.StepDT)
          case 'resume':
            return {
              state: 'FirstUploaded', date: moment(feature.StepDT).tz(timezone).format()
            }
            case 'chat':
              return {
                state: 'FirstChat', date: moment(feature.StepDT).tz(timezone).format()
              }
              case 'mentoring':
              case 'coaching':
                return {
                  state: 'Completed', date: feature.AppointmentDate ? moment(feature.AppointmentDate).tz(timezone).format() : 'Problem'
                }
                default:
                  return {
                    state: 'Completed', date: moment(feature.StepDT).tz(timezone).format()
                  }
      }
      case 'assignMentor':
        return {
          state: 'WaitingforMentorAssignment', date: ''
        }
        case 'assignInterviewPrepCoach':
        case 'assignResumeCoach':
        case 'assignCoach':
        case 'assignSalaryCoach':
          return {
            state: 'WaitingforCoachingAssignment', date: ''
          }
          case 'salaryQuestionaire':
            return {
              state: 'SalaryQuestionnaire', date: ''
            }
            case 'scheduleInterviewPrepCoachingCall':
            case 'scheduleInterviewPrepCoachingCall2':
            case 'scheduleFeedbackCoachingCall':
            case 'scheduleResumeCoachingCall':
            case 'scheduleCoachingCall':
            case 'scheduleCoachingCall1':
            case 'scheduleCoachingCall2':
            case 'scheduleResumeCoachingCall2':
            case 'scheduleSalaryCoach':
            case 'schedulePlacementCoachingCall':
            case 'scheduleCoachingCall3':
            case 'schedulePlacementCoachingCall2':
            case 'schedulePlacementCoachingCall3':
            case 'schedulePlacementCoachingCall1':
            case 'westpointscheduleCoachingCall':
            case 'uomCoachscheduleCoachingCall':
            case 'partnerCoachscheduleCoachingCall':
            case 'scheduleMentoringCall':
              return {
                state: 'ClienttoSchedule', date: ''
              }
              case 'appointmentpending':
                return {
                  state: 'AppointmentPending', date: feature.AppointmentDate ? moment(feature.AppointmentDate).tz(timezone).format() : 'Problem'
                }
                case 'interviewPrepcoachingCall':
                case 'interviewPrepcoachingCall2':
                case 'coachingCall':
                case 'coachingCall2':
                case 'resumeCoachingCall':
                case 'salaryCall':
                case 'placementcoachingCall':
                case 'coachingCall1':
                case 'coachingCall3':
                case 'placementcoachingCall1':
                case 'placementcoachingCall2':
                case 'placementcoachingCall3':
                case 'westpointcoachingCall':
                case 'uomCoachcoachingCall':
                case 'partnerCoachcoachingCall':
                case 'mentoringCall':
                  return {
                    state: 'AppointmentScheduled', date: feature.AppointmentDate ? moment(feature.AppointmentDate).tz(timezone).format() : 'Problem'
                  }
                  case 'interviewPrepcoachingCallComplete':
                  case 'interviewPrepcoaching2Complete':
                  case 'coachingCallComplete':
                  case 'coachingCall2Complete':
                  case 'singleresumeCoachingCallComplete':
                  case 'resumeCoachingCallComplete':
                  case 'resumeCoachingCall2Complete':
                  case 'interviewprepsalaryCallComplete':
                  case 'placementcoachingCallComplete':
                  case 'coachingCallComplete1':
                  case 'coachingCallComplete2':
                  case 'coachingCallComplete3':
                  case 'placementcoachingCallComplete1':
                  case 'placementcoachingCallComplete2':
                  case 'placementcoachingCallComplete3':
                  case 'salaryCallComplete':
                  case 'westpointcoachingCallComplete':
                  case 'westpointCoachingCall1Complete':
                  case 'uomCoachCallComplete':
                  case 'partnerCoachCallComplete':
                    return {
                      state: 'Sessioninprogress', date: ''
                    }
                    default:
                      if (feature.FeatureType === 'chat') {
                        return {
                          state: 'ChatNotStarted',
                          date: ''
                        }
                      } else {
                        return {
                          state: 'NotStarted',
                          date: ''
                        }
                      }
  }
}

const getFeatureType = feature => {
  switch (feature.ProductFeatureCode) {
    case 'onboarding':
    case 'singaporeonboarding':
      return 'Onboarding'
    case 'insights':
      return 'Insights'
    case 'singaporedigitalresume':
    case 'digitalresume':
      return 'Resume'
    case 'singaporeenterpriseassessment':
    case 'enterpriseassessment':
    case 'uploadgoogleassessment':
      return 'Assessment'
    case 'traitsassessment':
      return 'TraitsAssessment'
    case 'singaporecareersearchcoaching':
    case 'singaporecareercoaching':
    case 'singaporecareeracceleratecoaching':
      return '60minsession'
    case 'singaporecareeracceleratecoaching2':
      return '60minsession2'
    case 'singaporecareerinventoryquestionnaire':
    case 'singaporejobsearchinventoryquestionnaire':
      return 'Questionnaire'
    case 'paidcoaching':
    case 'resumepaidcoaching':
    case 'interviewprepcoaching':
      return 'Initial45minsession'
    case 'interviewprepcoaching2':
    case 'resumepaidcoaching2':
      return 'Final45minsession'
    case 'coaching':
    case 'soficoaching':
    case 'sofisinglecareercoaching':
    case 'singlecareercoaching':
    case 'singleinterviewcoaching':
    case 'singleresumecoaching':
    case 'subscriptioncareercoaching':
    case 'subscriptionsinglecareercoaching':
    case 'uopcoachingSession45':
    case 'coachingSession45':
    case 'addOnSingleCareerCoaching':
    case 'westpointcoaching':
    case 'uomtechcareercoaching':
    case 'addOnUMTechSingleCareerCoaching':
    case 'secondcoachingSession45':
    case 'partnercoachingSession45':
    case 'freecoaching':
    case 'freesinglecareercoaching':
    case 'assessmentfeedbackcoachingSession45':
    case 'feedbackcoachingSession45':
    case 'tdcoaching':
    case 'tdstrechgoalcoaching':
      return '45minsession'
    case 'addOnPartnerSingleCareerCoaching':
      return '45minsession2'
    case 'westpointcoaching2':
    case 'paidcoaching2':
      return '45minfinalsession'
    case 'salaryreview':
    case 'interviewprepsalaryreview':
    case 'westpointsalaryreview':
      return 'Paycheck'
    case 'jobs':
      return 'Jobs'
    case 'interviewprep':
      return 'InterviewPrep'
    case 'prysmianonboardingcareercoaching':
      return 'CareerCoachingWelcomeSession'
    case 'prysmianonboardingplacementcoaching':
      return 'PlacementCoachingWelcomeSession'
    case 'prysmiancareercoaching1':
      return 'CareerCoachingSession1'
    case 'prysmiancareercoaching2':
      return 'CareerCoachingSession2'
    case 'prysmiancareercoaching3':
      return 'CareerCoachingSession3'
    case 'prysmianplacementcoaching1':
    case 'talentacquisitioncoaching45':
      return 'PlacementCoachingSession1'
    case 'prysmianplacementcoaching2':
      return 'PlacementCoachingSession2'
    case 'prysmianplacementcoaching3':
      return 'PlacementCoachingSession3'
    case '30placementcoaching':
      return 'PlacementCoachingAdditionalSession'
    case 'westpointsinglecareercoaching':
      return 'WestPointCoachingSession'
    case 'chat':
      return 'Chat'
    case 'autoAssignCoachCoaching':
      return 'AutoAssignCoachCoaching'
    case 'externalAssessment':
      return 'ExternalAssessment'
    case 'mentoring':
      return 'Mentoring'
    case 'kfmentoringSession':
    case 'addOnKFMentoringSession':
      return 'KfMentoringSession'
    case 'uploadAssessment':
      return 'UploadAssessment'
    case 'addOnSingleGoogleCareerCoaching':
    case 'addOnSingleIntelCareerCoaching':
    case 'googlecoachingSession':
    case 'intelcoachingSession':
      return '60minsession'
    default:
      break
  }
}


const getFeatureUrl = feature => {
  switch (feature.FeatureType) {
    case 'onboarding':
      return '/profile'
    case 'resume':
      return '/resume'
    case 'assessment':
      return routeConstants.assessments
    case 'insights':
      return '/insights'
    case 'interviewprep':
      return '/interview'
    case 'jobs':
      return '/job'
    case 'salaryreview':
    case 'interviewprepsalaryreview':
      switch (feature.CurrentStep) {
        case 'scheduleSalaryCoach':
        case 'salaryCall':
        case 'interviewprepsalaryCallComplete':
          return '/coaching/' + feature.PurchasedItemKey
        default:
          return '/paycheck'
      }
      case 'resumecoaching':
      case 'interviewcoaching':
      case 'coaching':
        return '/coaching/' + feature.PurchasedItemKey
      case 'mentoring':
        return '/mentoring/' + feature.PurchasedItemKey
      case 'chat':
        return '/chat'
      default:
        return '/coaching/' + feature.PurchasedItemKey
  }
}

const skipResumeAssessment = (productCode, partnerKey) => {
  switch (productCode) {
    case 'AcmeAssociationCoaching':
    case 'ACMEUNIV':
    case 'AcmeOutsourcingCoaching':
    case 'PrysmianCoaching':
    case 'AFPCoaching':
    case 'subscription':
    case 'NFLCoaching':
    case 'ACSCoaching':
    case 'abasubscription':
    case 'ABBCoaching':
    case 'MAPITech':
    case 'mapisubscription':
    case 'UOPCoaching':
    case 'CareerCoachingGift-3M':
    case 'CareerCoachingGift-6M':
    case 'CareerCoachingGift-12M':
    case 'AssessmentFeedBackCoaching':
    case 'FeedBackCoaching':
    case 'UOMCoaching':
    case 'PgaCareerCoaching':
    case 'FreeCoaching':
    case 'ascm_subscription_plus':
    case 'GoogleCoaching':
    case 'IntelCareerCoaching':
      return true

      // case 'FreeCoaching':
      //   switch (partnerKey) {
      //     case '80dd4ab2fd314cd69c4e865433bdcb55': // University
      //       return false
      //     default:
      //       return true
      //   }

    default:
      return false
  }
}

const skipOnboarding = productCode => {
  switch (productCode) {
    case 'UOPCoaching':
    case 'AssessmentFeedBackCoaching':
    case 'FeedBackCoaching':
    case 'GoogleCoaching':
    case 'IntelCareerCoaching':
      return true
    default:
      return false
  }
}

const getNextStep = (prods, forCoaching, userIsVerified, freeCoaching, timezone, blockSubscription, allowAutoAddon, hasSubscription) => {
  let hasResumeArchitectOnly = false
  let hasRequiresAssessment = false
  R.forEach(item => {
    hasResumeArchitectOnly = hasResumeArchitectOnly ? true : item.ProductCode === 'ResumeArchitect'
    hasRequiresAssessment = hasRequiresAssessment ? true : item.ProductCode === 'InterviewPrep' || item.ProductCode === 'standard'
  }, prods)
  hasResumeArchitectOnly = hasResumeArchitectOnly && !hasRequiresAssessment
  let theReturn = undefined
  let allComplete = true
  let freemiumOnly = true
  let UOMTECHEXCEPTION = false
  let MENTOREXCEPTION = false
  let ADDONFROMCOACH = false
  R.forEach(item => {
    //console.log('AND THE ITEM IN NEXT STEP IS ', item)
    ADDONFROMCOACH = item.ProductCode === 'GoogleCoaching' ? true : ADDONFROMCOACH
    UOMTECHEXCEPTION = item.ProductCode === 'UOMTECH'
    MENTOREXCEPTION = item.ProductCode === 'KFMentee' ? true : MENTOREXCEPTION
    freemiumOnly = (item.ProductCode !== 'Freemium' && item.ProductCode !== 'AAAECareerCoaching') ? false : freemiumOnly
    //console.log('AND freemiumOnly IS ', freemiumOnly)
    R.forEach(feature => {
      if (feature.CurrentStep !== 'complete') {
        allComplete = false
        if (theReturn === undefined) {
          switch (feature.FeatureType) {
            case 'onboarding':
            case 'feedbackonboarding':
              //console.log('ONBOARDING NEXTSTEP WITH ITEM ', item)
              if (
                item.ProductCode === 'SofiPilot' ||
                item.ProductCode === 'PrysmianCoaching' ||
                item.ProductCode === 'subscription' ||
                item.ProductCode === 'Freemium'
              ) {
                theReturn = forCoaching ?
                  getMsgObject(
                    feature.FeatureType,
                    true,
                    true,
                    'HoldUp',
                    'CompleteOnboarding', //'Please complete your Onboarding before advancing to coaching sessions.',
                    '/profile',
                    'CompleteOnboarding'
                  ) :
                  getMainStep(
                    feature.FeatureType,
                    true,
                    'TellUsAbout', //'Tell us a little bit more about yourself.',
                    'KeepProfileUp', //'Keep your Career Profile up to date',
                    '/profile',
                    'ManageProfile' //'Manage Your Profile'
                  )
              } else { // OnboardingOnly    OnboardingResumeFirst

                if (feature.productFeatureCode === 'coachingSession45') {
                  theReturn = forCoaching ?
                    getMsgObject(feature.FeatureType, true, true, 'HoldUp', 'OnboardingOnly', '/profile', 'CompleteOnboarding') :
                    getMainStep(
                      feature.FeatureType,
                      true,
                      'TellUsAbout', //'Tell us a little bit more about yourself.',
                      'KeepProfileUp', //'Keep your Career Profile up to date',
                      '/profile',
                      'ManageProfile' //'Manage Your Profile'
                    )
                } else {
                  theReturn = forCoaching ?
                    getMsgObject(feature.FeatureType, true, true, 'HoldUp', 'OnboardingResumeFirst', '/profile', 'CompleteOnboarding') :
                    getMainStep(
                      feature.FeatureType,
                      true,
                      'TellUsAbout', //'Tell us a little bit more about yourself.',
                      'KeepProfileUp', //'Keep your Career Profile up to date',
                      '/profile',
                      'ManageProfile' //'Manage Your Profile'
                    )
                }
                theReturn = forCoaching ?
                  getMsgObject(feature.FeatureType, true, true, 'HoldUp', 'OnboardingOnly', '/profile', 'CompleteOnboarding') :
                  getMainStep(
                    feature.FeatureType,
                    true,
                    'TellUsAbout', //'Tell us a little bit more about yourself.',
                    'KeepProfileUp', //'Keep your Career Profile up to date',
                    '/profile',
                    'ManageProfile' //'Manage Your Profile'
                  )
              }
              // theReturn = item.ProductCode === 'UOPCoaching' ? undefined : theReturn
              if (skipOnboarding(item.ProductCode))
                theReturn = undefined;

              break
            case 'resume': //|| item.ProductCode === 'UOMTECH'
              if (skipResumeAssessment(item.ProductCode, item.PartnerKey)) { //}(item.ProductCode === 'SofiPilot' || item.ProductCode === 'AcmeAssociationCoaching' || item.ProductCode === 'ACMEUNIV' || item.ProductCode === 'PrysmianCoaching' || item.ProductCode === 'AFPCoaching'|| item.ProductCode === 'subscription' ) {
                console.log('SKIPING RESUME')
              } else {
                theReturn = forCoaching ?
                  getMsgObject(feature.FeatureType, false, true, 'HangOn', 'ResumeFirst', '/resume', 'UploadResume') :
                  item.ProductCode === 'Freemium' ?
                  blockSubscription ?
                  getMainStep(feature.FeatureType, true, 'CheckOutSubscription', 'CheckOutSubscription', '/services', 'Services') :
                  undefined :
                  getMainStep(feature.FeatureType, true, 'AnalysisAndReview', 'DigitalTool', '/resume', 'UploadResume')
              }
              console.log()
              break
            case 'assessment':
              //console.log("ASSESSMENT STEP WITH ITEM , Feature", item, feature)
              if (item.ProductCode === 'SofiPilot' || item.ProductCode === 'ACMEUNIV' || item.ProductCode === 'AcmeAssociationCoaching' || item.ProductCode === 'PrysmianCoaching' ||
                item.ProductCode === 'subscription' || (item.ProductCode === 'FreeCoaching' && item.PartnerKey === '80dd4ab2fd314cd69c4e865433bdcb55')) {
                //console.log('SKIPPING TRAITS ASSESSMENT')
              } else {
                if (item.ProductCode === 'UOMTECH' || item.ProductCode === 'AssessmentFeedBackCoaching' || item.ProductCode === 'FeedBackCoaching') {
                  theReturn = (item.ProductCode === 'FeedBackCoaching') ?
                    getMainStep(feature.FeatureType, true, 'AssessmentUpload', 'PendingAssessmentResults', routeConstants.assessments, 'PendingAssessmentResults') :
                    getMainStep(feature.FeatureType, true, 'TraitsAnalyzed', 'TakeTheAssessment', routeConstants.assessments, 'TakeAssessment')
                }
                if (!hasResumeArchitectOnly) {
                  if (userIsVerified) {
                    if (blockSubscription) {
                      theReturn = forCoaching ?
                        getMsgObject(feature.FeatureType, false, true, 'TakeItEasy!', 'CompleteAssessmentFirst', routeConstants.assessments, 'CompleteAssessment') :
                        getMainStep(feature.FeatureType, true, 'TraitsAnalyzed', 'TakeTheAssessment', routeConstants.assessments, 'TakeAssessment')
                    } else {
                      //console.log('OK HERE WE ARE')
                      if (item.ProductCode === 'MAPITech' || item.ProductCode === 'TDTech') {
                        //console.log("AND NOW GETTING THE REETURN")
                        theReturn = forCoaching ?
                          getMsgObject(feature.FeatureType, false, true, 'TakeItEasy!', 'CompleteAssessmentFirst', routeConstants.assessments, 'CompleteAssessment') :
                          getMainStep(feature.FeatureType, true, 'TakeItEasy', 'CompleteAssessmentFirst', routeConstants.assessments, 'TakeAssessment')
                      }
                    }
                  } else {
                    theReturn = forCoaching ?
                      getMsgObject(feature.FeatureType, false, true, 'TakeItEasy!', 'CompleteAssessmentFirst', routeConstants.assessments, 'CompleteAssessment') :
                      getMainStep(feature.FeatureType, false, 'VerifyEmailFirst', 'CheckEmail', routeConstants.assessments, 'TakeAssessment')
                  }
                }
              }
              break
            case 'jobs':
              if (item.ProductCode === 'UOMTECH') {
                theReturn = getMainStep(feature.FeatureType, true, 'JobBoard', 'JobBoard', '/job', 'Jobs')
              }
              break
            case 'resumecoaching':
            case 'interviewcoaching':
            case 'coaching':
            case 'placementcoaching':
            case 'mentoring':

              //console.log('feature',feature)
              if (feature.CurrentStep !== 'complete' && !forCoaching) {
                switch (feature.CurrentStep) {
                  case 'assignInterviewPrepCoach':
                  case 'assignResumeCoach':
                  case 'assignCoach':
                    theReturn = getMainStep(feature.FeatureType, true, 'CoachingAssign', 'ExploreInsights', '/insights', 'Insights')
                    theReturn.SchedulingMode = feature.SchedulingMode ? feature.SchedulingMode : null
                    break
                  case 'assignMentor':
                    theReturn = getMainStep(feature.FeatureType, false, 'CoachingAssign', 'ExploreInsights', '/insights', 'Insights')
                    theReturn.SchedulingMode = feature.SchedulingMode ? feature.SchedulingMode : null
                    break
                  case 'scheduleInterviewPrepCoachingCall':
                  case 'scheduleInterviewPrepCoachingCall2':
                  case 'scheduleResumeCoachingCall':
                  case 'scheduleFeedbackCoachingCall':
                  case 'scheduleCoachingCall':
                  case 'scheduleCoachingCall2':
                  case 'schedulePlacementCoachingCall':
                  case 'scheduleCoachingCall3':
                  case 'scheduleCoachingCall1':
                  case 'schedulePlacementCoachingCall1':
                  case 'schedulePlacementCoachingCall2':
                  case 'schedulePlacementCoachingCall3':
                  case 'westpointscheduleCoachingCall':
                  case 'uomCoachscheduleCoachingCall':
                  case 'partnerCoachscheduleCoachingCall':
                    theReturn = getMainStep(
                      feature.FeatureType,
                      true,
                      'AllSetSchedule',
                      'ScheduleTimeCoach',
                      '/coaching/' + feature.PurchasedItemKey,
                      'ScheduleAppointment'
                    )
                    theReturn.SchedulingMode = feature.SchedulingMode ? feature.SchedulingMode : null
                    break
                  case 'scheduleMentoringCall':
                    if (item.ProductCode === 'KFMentee') {
                      theReturn = getMainStep(
                        feature.FeatureType,
                        false,
                        'AllSetMentoring',
                        'ScheduleTimeCoach',
                        '',
                        ''
                      )
                    } else {
                      theReturn = getMainStep(
                        feature.FeatureType,
                        false,
                        'AllSetSchedule',
                        'ScheduleTimeCoach',
                        '',
                        ''
                      )
                    }
                    theReturn.SchedulingMode = feature.SchedulingMode ? feature.SchedulingMode : null
                    break
                  case 'appointmentpending':
                    theReturn = getMainStep(
                      feature.FeatureType,
                      false,
                      'AppntPending',
                      'LetYouKnow',
                      '',
                      '',
                      '',
                      getMeetingDetails(feature)
                    )
                    theReturn.SchedulingMode = feature.SchedulingMode ? feature.SchedulingMode : null
                    break
                  case 'mentoringCall':

                    theReturn = getMainStep(
                      feature.FeatureType,
                      false,
                      '',
                      '',
                      '',
                      '',
                      '',
                      getMeetingDetails(feature)
                    )
                    theReturn.SchedulingMode = feature.SchedulingMode ? feature.SchedulingMode : null
                    break
                  case 'scheduleResumeCoachingCall2':
                    theReturn = getMainStep(
                      feature.FeatureType,
                      true,
                      'UploadRevisions',
                      'ScheduleResumeCoach',
                      '/coaching/' + feature.PurchasedItemKey,
                      'ScheduleAppointment'
                    )
                    theReturn.SchedulingMode = feature.SchedulingMode ? feature.SchedulingMode : null
                    break
                  case 'interviewPrepcoachingCall':
                  case 'interviewPrepcoachingCall2':
                  case 'coachingCall':
                  case 'coachingCall2':
                  case 'resumeCoachingCall':
                  case 'feedbackcoachingCall':
                  case 'placementcoachingCall':
                  case 'coachingCall1':
                  case 'coachingCall3':
                  case 'placementcoachingCall1':
                  case 'placementcoachingCall2':
                  case 'placementcoachingCall3':
                  case 'westpointcoachingCall':
                  case 'uomCoachcoachingCall':
                  case 'partnerCoachcoachingCall':

                    theReturn = getMainStep(
                      feature.FeatureType,
                      true,
                      'YouHaveAppointment',
                      '',
                      '/coaching/' + feature.PurchasedItemKey,
                      'Coaching',
                      '<br/>' + feature.AppointmentCoachName +
                      ' <br/>' +
                      moment(feature.AppointmentDate)
                      .tz(timezone)
                      .format('dddd') +
                      ', ' +
                      moment(feature.AppointmentDate)
                      .tz(timezone)
                      .format('MMMM DD') +
                      ', ' +
                      moment(feature.AppointmentDate)
                      .tz(timezone)
                      .format('h:mm a') +
                      ' ' +
                      moment.tz(timezone).zoneAbbr(),
                      getMeetingDetails(feature)
                    )
                    theReturn.SchedulingMode = feature.SchedulingMode ? feature.SchedulingMode : null

                    break
                  case 'interviewPrepcoachingCallComplete':
                  case 'interviewPrepcoaching2Complete':
                  case 'coachingCallComplete':
                  case 'coachingCall2Complete':
                  case 'singleresumeCoachingCallComplete':
                  case 'feedbackcoachingCallComplete':
                  case 'resumeCoachingCallComplete':
                  case 'resumeCoachingCall2Complete':
                  case 'placementcoachingCallComplete':
                  case 'coachingCallComplete1':
                  case 'coachingCallComplete2':
                  case 'coachingCallComplete3':
                  case 'placementcoachingCallComplete1':
                  case 'placementcoachingCallComplete2':
                  case 'placementcoachingCallComplete3':
                  case 'westpointcoachingCallComplete':
                  case 'westpointCoachingCall1Complete':
                  case 'uomCoachCallComplete':
                  case 'partnerCoachCallComplete':
                    theReturn = getMainStep(feature.FeatureType, true, 'CoachingInProgress', '', '/coaching/' + feature.PurchasedItemKey, 'ViewSession')
                    theReturn.SchedulingMode = null
                    break
                  default:
                    break;
                }
              }
              break
            case 'salaryreview':
            case 'interviewprepsalaryreview':
              switch (feature.CurrentStep) {
                case 'assignSalaryCoach':
                  theReturn = getMainStep(feature.FeatureType, true, 'PaycheckAssign', 'ExploreInsights?', '/insights', 'Insights')
                  theReturn.SchedulingMode = feature.SchedulingMode ? feature.SchedulingMode : null
                  break
                case 'salaryQuestionaire':
                  theReturn = getMainStep(feature.FeatureType, true, 'BriefQuestions', 'CompleteQuestions', '/paycheck', 'Paycheck')
                  theReturn.SchedulingMode = feature.SchedulingMode ? feature.SchedulingMode : null
                  break
                case 'scheduleSalaryCoach':
                  theReturn = getMainStep(feature.FeatureType, true, 'AllSetPaycheck', '', '/coaching/' + feature.PurchasedItemKey, 'ScheduleAppointment')
                  theReturn.SchedulingMode = feature.SchedulingMode ? feature.SchedulingMode : null
                  break
                case 'salaryCall':
                  theReturn = getMainStep(
                    feature.FeatureType,
                    true,
                    'YouHaveAppointment', //'You have a Paycheck appointment with ' +
                    '',
                    '/coaching/' + feature.PurchasedItemKey,
                    'Coaching',
                    feature.AppointmentCoachName +
                    ' ' +
                    moment(feature.AppointmentDate)
                    .tz(timezone)
                    .format('dddd') +
                    ', ' +
                    moment(feature.AppointmentDate)
                    .tz(timezone)
                    .format('MMMM DD') +
                    ', at ' +
                    moment(feature.AppointmentDate)
                    .tz(timezone)
                    .format('hh:mm a')
                  )
                  theReturn.SchedulingMode = feature.SchedulingMode ? feature.SchedulingMode : null
                  break
                case 'interviewprepsalaryCallComplete':
                case 'salaryCallComplete':
                  theReturn = getMainStep(feature.FeatureType, true, 'PaycheckInProgress', '', '/coaching/' + feature.PurchasedItemKey, 'Coaching')
                  theReturn.SchedulingMode = feature.SchedulingMode ? feature.SchedulingMode : null
                  break
                default:
                  break
              }
              break
            case 'interviewprep':
              if (item.ProductCode === 'UOMTECH') {
                theReturn = getMsgObject(feature.FeatureType, false, true, 'CheckItOut', 'CheckInvPrep', '/interview', 'InterviewPrep')
              }
              break
            default:
              //theReturn = getMsgObject(feature.FeatureType, false, false, 'Hold Up', 'Please complete your Onboarding and upload your Resume before advancing to coaching sessions.', '/profile','Complete Onboarding')
          }
        }
      }
    }, item.details.Steps)
  }, prods)
  if (!theReturn) {
    //console.log('ALL COMPLETE IIS TRUE')
    allComplete = true
  }

  if (allComplete) {
    if (freeCoaching) {
      if (blockSubscription) {
        if (allowAutoAddon) {
          theReturn = getMsgObject('custom', false, false, 'WeHereNeedUs', 'ScheduleBelow', '/services', 'Services')
        } else {
          theReturn = getMsgObject('custom', false, false, 'AllDoneRetail', 'WaitForEmail', '/services', 'Services')
        }
      } else {
        if (UOMTECHEXCEPTION) {
          theReturn = getMainStep('custom', true, 'ExploreInsights', '', '/insights', 'Insights')
        } else {
          if (freemiumOnly) {
            theReturn = getMainStep('custom', true, 'CheckOutSubscription', 'CheckOutSubscription', '/services', 'Services')
          } else {

            if (hasSubscription || allowAutoAddon) {
              //  console.log("THIS IS THE ONE BEING USED!!!!!!!!!!!!!!!!!!!")
              if (ADDONFROMCOACH) {
                theReturn = getMainStep(
                  'custom',
                  true,
                  'AllSetAddSession',
                  'AddNewCoachingSession',
                  '/coaching/',
                  'Coaching',

                )
              } else {
                theReturn = getMsgObject('custom', false, false, 'WeHereNeedUs', 'ScheduleBelow', '/services', 'Services')
              }
            } else {
              if (MENTOREXCEPTION) {
                theReturn = getMainStep('custom', false, '', '', '', '')
              } else {
                theReturn = getMainStep('custom', true, 'CheckOutSubscription', 'CheckOutSubscription', '/services', 'Services')
              }
            }
          }
        }
      }
    } else {
      theReturn = getMsgObject('custom', false, true, 'MoreExplore', 'CheckOutServices', '/services', 'Services')
    }
  }
  //console.log('AND NOW THE MSG RETURN IS ', theReturn)
  return theReturn
}

const getAddonCoachID = (prods, coachingType) => {
  let theCoachKey = {}
  R.forEach(prod => {
    R.forEach(feature => {
      if (feature.FeatureType === coachingType) {
        theCoachKey = theCoachKey === {} ? getCoachOrAdmin(feature.Steps, true) : theCoachKey
      }
    }, prod.details.Steps)
  }, prods)
  return theCoachKey.roleUserKey
}

const checkForAddOn = prods => {
  const canHaveAddons = prods.filter(
    p =>
    p.ProductCode === 'PrysmianCoaching' ||
    p.ProductCode === 'SofiPilot' ||
    p.ProductCode === 'JJCoaching' ||
    p.ProductCode === 'WestPointSingleCareerCoaching' ||
    p.ProductCode === 'subscription' ||
    p.ProductCode === 'UOMCoaching' ||
    p.ProductCode === 'ACMETDCoaching' ||
    p.ProductCode === 'ACMEUNIV' ||
    p.ProductCode === 'AcmeOutsourcingCoaching' ||
    p.ProductCode === 'NFLCoaching' ||
    p.ProductCode === 'PayPalCoaching' ||
    p.ProductCode === 'ACSCoaching' ||
    p.ProductCode === 'AddOnPartnerCareerCoaching' ||
    p.ProductCode === 'abasubscription' ||
    p.ProductCode === 'ABBCoaching' ||
    p.ProductCode === 'UOPCoaching' ||
    p.ProductCode === 'mapisubscription' ||
    p.ProductCode === 'FreeCoaching' ||
    p.ProductCode === 'AssessmentFeedBackCoaching' ||
    p.ProductCode === 'FeedBackCoaching' ||
    p.ProductCode === 'PgaCareerCoaching' ||
    p.ProductCode === 'GoogleCoaching' ||
    p.ProductCode === 'IntelCareerCoaching' ||
    p.ProductCode === 'CareerCoachingGift-3M' ||
    p.ProductCode === 'CareerCoachingGift-6M' ||
    p.ProductCode === 'CareerCoachingGift-12M' ||
    p.ProductCode === 'TDCoaching' ||
    p.ProductCode === 'TDStrechGoal' ||
    p.ProductCode === 'ascm_subscription_plus'

  )
  //console.log('AND CAN HAVE prods IS ', prods)  ||  p.ProductCode === 'UOMTECH'
  if (canHaveAddons && canHaveAddons.length > 0) {
    //const coach = canHaveAddons[0].details.team && (canHaveAddons[0].details.team.coach || canHaveAddons[0].details.team.westpointCoach) ? true : false
    //console.log('IN CHECK FOR ADDON COACH IS ', coach)
    let allowAddOn = true
    prods.forEach(product => {
      product.details.Steps.forEach(step => {
        //console.log('LOOKING TO TURN OFF ADDON WITH STEP ', step)
        if ((step.FeatureType === 'coaching' || step.FeatureType === 'placementcoaching') && step.CurrentStep !== 'complete') {
          //console.log('GONNA TURN OFF ALLOW ADDON WITH STEP ', step)
          allowAddOn = false
        }
      })
    })
    return allowAddOn
  } else {
    return false
  }
}

const isGiftProduct = prodctKeyLowerCase => {
  switch (prodctKeyLowerCase) {
    case 'careercoachinggift-12m':
    case 'careercoachinggift-3m':
    case 'careercoachinggift-6m':
      return true;
    default:
      return false;
  }
}


const getPurchaseState = (prods, user, settings, applicationContent, attributes = null) => {
  //   const theL = user?.LanguageCode?.substring(0,2) || 'en'
  //   console.log('AND THE GET PURCHASE LANGUAGE IN APP IS ', theL, user)
  //   localmoment.locale(theL)
  //   moment.defineLocale(theL, localmoment.localeData()._config)
  //   moment.locale(theL)
  //   var diff = function(a, b) {
  //     switch (true) {
  //       case moment(a.PurchaseDT).isBefore(moment(b.PurchaseDT)):
  //         return 1
  //       case moment(a.PurchaseDT).isAfter(moment(b.PurchaseDT)):
  //         return -1
  //       default:
  //         return 0
  //     }
  //   }
  //   //const AddOnCoachID = getAddonCoachID(prods, 'placementcoaching')
  //   const RevProds = sortProductsForMultiple(prods) //R.sort(diff, prods)

  //   const partnerKey = user ? user.PartnerKey : undefined
  //   const userKey = user ? user.UserKey : undefined
  //   const userFirst = user ? user.FirstName : undefined
  //   const userLast = user ? user.LastName : undefined
  //   const userIsVerified = user ? user.isVerified : false

  //   let allowAutoAddon = checkForAddOn(RevProds)
  //   let PurchaseState = getDefaultByPartner(settings, applicationContent)
  //   PurchaseState.hasNoProduct = prods.length === 0 ? true : false
  //   PurchaseState.timezone = user ? user.Timezone : moment.tz.guess()

  //   PurchaseState.purchases = getProductsObject(RevProds, settings.PurchaseState.ShowTitle, PurchaseState.timezone, userKey)
  //   PurchaseState.purchasesV2 = getProductsObjectV2(RevProds, PurchaseState.timezone,userKey, partnerKey, user)
  //   PurchaseState.isCanceled = false
  //   PurchaseState.canceledDate = null
  //   PurchaseState.externalAssessment = false
  //   PurchaseState.isGift = false;

  //   let whatTheyGot = {
  //     Freemium: false,
  //     ResumeArchitect: false,
  //     InterviewPrep: false,
  //     CareerAnswers: false,
  //     Tcm: false,
  //     Subscription: false,
  //     IsCanceled: false,
  //     CanceledDate: null,
  //     EffectiveCanceledDate: null,
  //     freeCoaching: false,
  //     WestPoint: false,
  //     AbaTech: false,
  //   }
  //   let memberSinceDate = moment(new Date()).add(3, 'years')
  //   R.map(item => {
  //     memberSinceDate = moment(item.PurchaseDT).isBefore(memberSinceDate) ? moment(item.PurchaseDT) : memberSinceDate
  //     if(item.IsCanceled) {
  //        whatTheyGot.IsCanceled = true
  //     }
  //     if(item.CanceledDate) {
  //       whatTheyGot.CanceledDate = formatDateLong(item.CanceledDate)
  //    }
  //    if(item.EffectiveCanceledDate) {
  //       whatTheyGot.EffectiveCanceledDate = item.EffectiveCanceledDate
  //    }

  //    const productKeyLower = item.ProductCode.toLowerCase()

  //    switch (productKeyLower) {
  //       case 'abatech':
  //         whatTheyGot.AbaTech = true
  //         break;
  //       case 'mapitech':
  //         whatTheyGot.MapiTech = true
  //       case 'afptech':
  //       case 'acmeassociationcoaching':
  //       case 'pgacareercoaching':

  //         whatTheyGot.freeCoaching = true
  //         break
  //       case 'traitsonlyassessment':
  //       case 'freemium':
  //         whatTheyGot.Freemium = true
  //         break
  //       case 'standard':
  //         whatTheyGot.Tcm = true
  //         break
  //       case 'resumearchitect':
  //         whatTheyGot.ResumeArchitect = true
  //         break
  //       case 'interviewprep':
  //         whatTheyGot.InterviewPrep = true
  //         break
  //       case 'careeranswers':
  //         whatTheyGot.CareerAnswers = true
  //         break
  //       case 'careercoachinggift-3m':
  //       case 'careercoachinggift-6m':
  //       case 'careercoachinggift-12m':
  //       case 'sofipilot':
  //       case 'abbcoaching':
  //       case 'abasubscription':
  //       case 'subscription':
  //       case 'freecoaching':
  //       case 'mapisubscription':
  //       case 'acmetdcoaching':
  //       case 'googlecoaching':
  //       case 'feedbackcoaching':
  //       case 'kfmentee':
  //       case 'tdtech':
  //       case 'tdcoaching':
  //       case 'tdstrechgoal':
  //       case 'ascm_subscription_plus':
  //         whatTheyGot.Subscription = true
  //         logEvent('event', analyticsTypeConstants.subscription, 'hasSubscription')
  //         break
  //       case 'westpointcareermanagement':
  //         whatTheyGot.WestPoint = true
  //         break
  //       default:
  //         break
  //     }

  //     if (isGiftProduct(productKeyLower)) {
  //       PurchaseState.isGift = true;
  //     }
  //     PurchaseState.isCanceled = whatTheyGot.IsCanceled
  //     PurchaseState.canceledDate = whatTheyGot.CanceledDate
  //     PurchaseState.effectiveCanceledDate = whatTheyGot.EffectiveCanceledDate
  //     PurchaseState.hasSubscription = whatTheyGot.Subscription
  //     PurchaseState.blockSubscription = whatTheyGot.Tcm || whatTheyGot.ResumeArchitect || whatTheyGot.InterviewPrep || whatTheyGot.CareerAnswers || whatTheyGot.WestPoint
  //     PurchaseState.showCoaching = PurchaseState.showCoaching ? true : item.ProductCode  === 'UOMCoaching'
  //     PurchaseState.showChat = PurchaseState.showChat ? true : doTheyGetChat(item.ProductCode)

  //     //After completing the first session, we need to show the upgrade subscription
  //     if (!whatTheyGot.Subscription && productKeyLower === 'aaaefirstbatchusercoaching') {
  //       if (item.details.Steps.find(x => x.FeatureType === 'coaching' && x.CurrentStep === 'complete')) {
  //         PurchaseState.blockSubscription = false
  //         whatTheyGot.freeCoaching = true
  //       } else {
  //         PurchaseState.blockSubscription = true
  //       }
  //     }

  //     if (!whatTheyGot.Subscription && (productKeyLower === 'aaaecareercoaching' || productKeyLower === 'apicscareercoaching' || productKeyLower === 'afpcoaching')) {
  //       PurchaseState.blockSubscription = false
  //       whatTheyGot.freeCoaching = true
  //     }

  //     const nextStep = getNextStep(RevProds, true, userIsVerified, PurchaseState.freeCoaching, PurchaseState.timezone, PurchaseState.blockSubscription, allowAutoAddon, PurchaseState.hasSubscription)
  //     //console.log('AND NEXT STEP IS ', nextStep)

  //     const mainNextStep = getNextStep(RevProds, false, userIsVerified, PurchaseState.freeCoaching, PurchaseState.timezone, PurchaseState.blockSubscription, allowAutoAddon, PurchaseState.hasSubscription)
  //     //console.log('AND MAIN PurchaseState IS ', PurchaseState)
  //     //console.log('AND MAIN NEXT STEP IS ', mainNextStep)


  //     PurchaseState.nextStep = nextStep
  //     PurchaseState.mainNextStep = mainNextStep
  //     if(!PurchaseState.coachingAddOn && PurchaseState.coachingAddOns && PurchaseState.coachingAddOns[item.ProductCode] ) {
  //         PurchaseState.coachingAddOn = PurchaseState.coachingAddOns[item.ProductCode]
  //     }

  //     R.map(feature => {
  //       switch (feature.ProductFeatureCode) {
  //         case 'digitalresume':
  //         case 'singaporedigitalresume':
  //           const isComplete = feature.CurrentStep === 'complete' ? true : false //feature.CurrentStep === 'complete'
  //           let url = isComplete && user && user.resumes.length > 0 ? user.resumes[0].Url : undefined
  //           const uploadDate = isComplete && user && user.resumes.length > 0 ? moment(user.resumes[0].Created).format('MM/DD/YY') : undefined
  //           const resumeDocKey = isComplete && user && user.resumes.length > 0 ? user.resumes[0].DocumentKey : undefined
  //           PurchaseState.resume.resumes = user.resumes
  //           PurchaseState.resume.pushSteps.push(getPushStepPayload(item, feature))
  //           PurchaseState.resume.hasPurchased = true
  //           if (PurchaseState.resume.purchaseBundleCode === undefined) {
  //             PurchaseState.resume = updateObject(PurchaseState.resume, {
  //               userKey,
  //               uploadDate,
  //               isComplete,
  //               resumeDocKey,
  //               purchaseBundleCode: item.PurchaseBundleCode,
  //               purchasedItemKey: feature.PurchasedItemKey,
  //               currentStep: feature.CurrentStep,
  //               resumeUrl: url,
  //               action: feature.Action,
  //               submit: feature.ProductFeatureCode,
  //             })
  //           }
  //           break
  //         case 'onboarding':
  //         case 'singaporeonboarding':
  //         case 'menteeQuestionaire':
  //         case 'feedbackonboarding':
  //           PurchaseState.profile.hasPurchased = true
  //           PurchaseState.profile.pushSteps.push(getPushStepPayload(item, feature))
  //           PurchaseState.profile.isComplete = feature.CurrentStep === 'complete'
  //           break
  //         case 'externalAssessment':
  //         case 'traitsassessment':
  //         case 'uploadAssessment':
  //           PurchaseState.traitsAssessment.pushSteps.push(getPushStepPayload(item, feature))
  //           PurchaseState.externalAssessment = feature.ProductFeatureCode === 'externalAssessment'
  //           if (PurchaseState.traitsAssessment.purchaseBundleCode === undefined) {
  //             PurchaseState.traitsAssessment = updateObject(PurchaseState.traitsAssessment, {
  //               isComplete: feature.CurrentStep === 'complete',
  //             })
  //           }
  //           break
  //         case 'chat':
  //           PurchaseState.chat.hasPurchased = true
  //           PurchaseState.chat.pushSteps.push(getPushStepPayload(item, feature))
  //           PurchaseState.chat.isComplete = feature.CurrentStep === 'complete' ? true : PurchaseState.chat.isComplete
  //           break
  //         case 'insights':
  //           PurchaseState.insights.hasPurchased = true
  //           PurchaseState.insights.pushSteps.push(getPushStepPayload(item, feature))
  //           PurchaseState.insights.isComplete = feature.CurrentStep === 'complete' ? true : PurchaseState.insights.isComplete
  //           break
  //         case 'kfmentoringSession':
  //         case 'addOnKFMentoringSession':
  //           //console.log('HIT MENTORING IN PURCH STATE', item, feature)
  //           PurchaseState.mentoring.hasPurchased = true
  //           PurchaseState.mentoring.pushSteps.push(getPushStepPayload(item,feature))
  //           PurchaseState.mentoring.showScheduling = feature.CurrentStep === 'scheduleMentoringCall' ? true : PurchaseState.mentoring.showScheduling
  //           const theMentor = getCoachOrAdmin(feature.Steps, true)
  //           PurchaseState.mentoring.mentorID = theMentor.roleUserKey
  //           PurchaseState.mentoring.mentorEmail = theMentor.roleEmailAddress
  //           PurchaseState.mentoring.mentorFirstName = item.details.team && item.details.team.mentor && item.details.team.mentor.FirstName
  //           PurchaseState.mentoring.mentorLastName =  item.details.team && item.details.team.mentor && item.details.team.mentor.LastName
  //           PurchaseState.mentoring.mentorCompany = item.details.team && item.details.team.mentor && item.details.team.mentor.CurrentCompany
  //           PurchaseState.mentoring.mentorPosition = item.details.team && item.details.team.mentor && item.details.team.mentor.CurrentPosition
  //           PurchaseState.mentoring.mentorPhone = item.details.team && item.details.team.mentor && item.details.team.mentor.Phone
  //           PurchaseState.mentoring.mentorLOB = item.details.team && item.details.team.mentor && item.details.team.mentor.LOB
  //           PurchaseState.mentoring.mentorOffice = item.details.team && item.details.team.mentor && item.details.team.mentor.Office

  //           PurchaseState.mentoring.appointments.push(
  //             getAppointmentObject(item, feature, 'careerColor', theMentor.roleUserKey, PurchaseState.mentoring.showScheduling, userKey, partnerKey, user))
  //           if (feature.CurrentStep !== 'complete' && !PurchaseState.mentoring.currentSession) {
  //             PurchaseState.mentoring.currentSession = PurchaseState.mentoring.appointments[PurchaseState.mentoring.appointments.length - 1]
  //           }
  //           break
  //         case 'addOnUMTechSingleCareerCoaching':
  //         case 'uomtechcareercoaching':
  //         case 'partnercoachingSession45':
  //         case 'addOnPartnerSingleCareerCoaching':
  //           const theCoachA = getCoachOrAdmin(feature.Steps, true)
  //           const careerCoachKey32 = theCoachA.roleUserKey  //workSelectors.coachId(feature)
  //           const showScheduling32 = feature.CurrentStep === 'uomCoachscheduleCoachingCall' || feature.CurrentStep === 'partnerCoachscheduleCoachingCall'
  //           PurchaseState.coaching.partnerCoach.partnerAccess = true
  //           PurchaseState.coaching.partnerCoach.requiresAssessment = false
  //           PurchaseState.coaching.partnerCoach.hasBlocker = PurchaseState.nextStep.blocker
  //             ? true
  //             : PurchaseState.nextStep.id === 'assessment' && PurchaseState.coaching.partnerCoach.requiresAssessment
  //           PurchaseState.coaching.hasPurchased = true
  //           PurchaseState.coaching.partnerCoach.hasPurchased = true
  //           PurchaseState.coaching.partnerCoach.appointments.push(
  //             getAppointmentObject(item, feature, 'careerColor', careerCoachKey32, showScheduling32, userKey, partnerKey, user)
  //           )
  //           PurchaseState.coaching.partnerCoach.partnerKey = partnerKey
  //           PurchaseState.coaching.partnerCoach.userKey = userKey
  //           break
  //         case 'talentacquisitioncoaching45':
  //         case 'prysmianonboardingplacementcoaching':
  //         case 'prysmianplacementcoaching1':
  //         case 'prysmianplacementcoaching2':
  //         case 'prysmianplacementcoaching3':
  //         case '30placementcoaching':
  //           //allowAutoAddon = feature.CurrentStep !== 'complete' ? false : allowAutoAddon
  //           const theCoachB =  getCoachOrAdmin(feature.Steps, true)
  //           const careerCoachKey9 = theCoachB.roleUserKey //workSelectors.AutoPlacementCoachID(feature)
  //           const showScheduling9 =
  //             feature.CurrentStep === 'schedulePlacementCoachingCall' ||
  //             feature.CurrentStep === 'schedulePlacementCoachingCall1' ||
  //             feature.CurrentStep === 'schedulePlacementCoachingCall2' ||
  //             feature.CurrentStep === 'schedulePlacementCoachingCall3'
  //           PurchaseState.coaching.placement.requiresAssessment = false //PurchaseState.coaching.career.requiresAssessment ? true : (item.ProductCode === 'standard' || item.ProductCode === 'InterviewPrep' || item.ProductCode === 'ResumeArchitect')
  //           PurchaseState.coaching.placement.hasBlocker = PurchaseState.nextStep.id === 'onboarding' ? true : false
  //           PurchaseState.coaching.hasPurchased = true
  //           PurchaseState.coaching.placement.hasPurchased = true
  //           PurchaseState.coaching.placement.appointments.push(
  //             getAppointmentObject(item, feature, 'resumeColor', careerCoachKey9, showScheduling9, userKey, partnerKey, user)
  //           )
  //           PurchaseState.coaching.placement.partnerKey = partnerKey
  //           PurchaseState.coaching.placement.userKey = userKey
  //           break
  //         case 'secondcoachingSession45':
  //         case 'coachingSession45':
  //         case 'uopcoachingSession45':
  //         case 'prysmianonboardingcareercoaching':
  //         case 'prysmiancareercoaching1':
  //         case 'prysmiancareercoaching2':
  //         case 'prysmiancareercoaching3':
  //         case 'soficoaching':
  //         case 'sofisinglecareercoaching':
  //         case 'assessmentfeedbackcoachingSession45':
  //         case 'googlecoachingSession':
  //         case 'feedbackcoachingSession45':
  //         case 'tdcoaching':
  //         case 'tdstrechgoalcoaching':
  //         case 'googlecoachingSession':
  //           //allowAutoAddon = feature.CurrentStep !== 'complete' ? false : allowAutoAddon
  //           const theCoachC = getCoachOrAdmin(feature.Steps, true)
  //           const careerCoachKey2 = theCoachC.roleUserKey //feature.ProductFeatureCode === 'soficoaching' || feature.ProductFeatureCode === 'sofisinglecareercoaching' ? workSelectors.sofiAutoCoachID(feature) : workSelectors.AutoRepeatCoachID(feature)

  //           const coachItem = (feature.ProductFeatureCode === 'feedbackcoachingSession45'
  //           || feature.ProductFeatureCode === 'assessmentfeedbackcoachingSession45') ? "feedbackCoach" : "career";

  //           const showScheduling4 =
  //             feature.CurrentStep === 'scheduleCoachingCall' ||
  //             feature.CurrentStep === 'scheduleCoachingCall2' ||
  //             feature.CurrentStep === 'scheduleCoachingCall1' ||
  //             feature.CurrentStep === 'scheduleCoachingCall3' ||
  //             feature.CurrentStep === 'scheduleFeedbackCoachingCall'
  //           PurchaseState.coaching[coachItem].requiresAssessment = PurchaseState.coaching[coachItem].requiresAssessment
  //             ? true
  //             : item.ProductCode === 'standard' || item.ProductCode === 'InterviewPrep' || item.ProductCode === 'ResumeArchitect'
  //           PurchaseState.coaching[coachItem].hasBlocker = PurchaseState.nextStep.id === 'onboarding'
  //           PurchaseState.coaching.hasPurchased = true
  //           PurchaseState.coaching[coachItem].hasPurchased = true
  //           PurchaseState.coaching[coachItem].appointments.push(getAppointmentObject(item, feature, 'careerColor', careerCoachKey2, showScheduling4, userKey, partnerKey, user))
  //           PurchaseState.coaching[coachItem].partnerKey = partnerKey
  //           PurchaseState.coaching[coachItem].userKey = userKey
  //           break
  //         case 'singaporecareercoaching':
  //         case 'singaporecareersearchcoaching':
  //         case 'singaporecareeracceleratecoaching':
  //         case 'sgcoaching':
  //         case 'careeranswerspaidcoaching':
  //         case 'paidcoaching':
  //         case 'paidcoaching2':
  //         case 'singlecareercoaching':
  //         case 'coaching':
  //         case 'coaching2':
  //         case 'subscriptioncareercoaching':
  //         case 'subscriptionsinglecareercoaching':
  //         case 'autoAssignCoachCoaching':
  //         case 'coachingSession45':
  //         case 'assessmentfeedbackcoachingSession45':
  //         case 'uopcoachingSession45':
  //         case 'addOnSingleCareerCoaching':
  //         case 'westpointcoaching':
  //         case 'westpointcoaching2':
  //         case 'freecoaching':
  //         case 'freesinglecareercoaching':
  //         case 'assessmentfeedbackcoachingSession45':
  //         case 'feedbackcoachingSession45':
  //         case 'googlecoachingSession':
  //         case 'addOnSingleGoogleCareerCoaching':

  // //console.log('were in ',feature.ProductFeatureCode)
  //           const theCoachD = getCoachOrAdmin(feature.Steps, true)
  //           const careerCoachKey = theCoachD.roleUserKey //workSelectors.coachId(feature)
  //           const showScheduling = (feature.CurrentStep === 'scheduleCoachingCall' || feature.CurrentStep === 'scheduleCoachingCall2' || feature.CurrentStep === 'uomCoachscheduleCoachingCall' || feature.CurrentStep === 'scheduleFeedbackCoachingCall')
  //           PurchaseState.coaching.career.requiresAssessment = PurchaseState.coaching.career.requiresAssessment
  //             ? true
  //             : item.ProductCode === 'standard' || item.ProductCode === 'InterviewPrep' || item.ProductCode === 'ResumeArchitect'
  //           PurchaseState.coaching.career.hasBlocker = PurchaseState.nextStep.blocker
  //             ? true
  //             : PurchaseState.nextStep.id === 'assessment' && PurchaseState.coaching.career.requiresAssessment

  //           PurchaseState.coaching.hasPurchased = true
  //           PurchaseState.coaching.career.hasPurchased = true
  //           PurchaseState.coaching.career.appointments.push(getAppointmentObject(item, feature, 'careerColor', careerCoachKey, showScheduling, userKey, partnerKey, user))
  //           PurchaseState.coaching.career.partnerKey = partnerKey
  //           PurchaseState.coaching.career.userKey = userKey
  //           PurchaseState.coaching.career.partnerAccess = true
  //           break
  //         case 'resumepaidcoaching':
  //         case 'resumepaidcoaching2':
  //         case 'singleresumecoaching':
  //           //allowAutoAddon = false
  //           const resumeCoachKey = workSelectors.resumeCoachId(feature)
  //           const showScheduling2 = feature.CurrentStep === 'scheduleResumeCoachingCall' || feature.CurrentStep === 'scheduleResumeCoachingCall2'
  //           PurchaseState.coaching.resume.requiresAssessment = PurchaseState.coaching.resume.requiresAssessment
  //             ? true
  //             : item.ProductCode === 'standard' || item.ProductCode === 'InterviewPrep'
  //           PurchaseState.coaching.hasPurchased = true
  //           PurchaseState.coaching.resume.hasBlocker = PurchaseState.nextStep.blocker
  //             ? true
  //             : PurchaseState.nextStep.id === 'assessment' && PurchaseState.coaching.resume.requiresAssessment
  //           PurchaseState.coaching.resume.hasPurchased = true
  //           PurchaseState.coaching.resume.appointments.push(getAppointmentObject(item, feature, 'resumeColor', resumeCoachKey, showScheduling2, userKey, partnerKey, user))
  //           PurchaseState.coaching.resume.partnerKey = partnerKey
  //           PurchaseState.coaching.resume.userKey = userKey
  //           PurchaseState.coaching.resume.partnerAccess = true
  //           break
  //         case 'interviewprepcoaching':
  //         case 'interviewprepcoaching2':
  //         case 'singleinterviewcoaching':
  //           //allowAutoAddon = false
  //           const interviewCoachKey = workSelectors.interviewCoachId(feature)
  //           const showScheduling1 = feature.CurrentStep === 'scheduleInterviewPrepCoachingCall' || feature.CurrentStep === 'scheduleInterviewPrepCoachingCall2'
  //           PurchaseState.coaching.interview.requiresAssessment = PurchaseState.coaching.interview.requiresAssessment
  //             ? true
  //             : item.ProductCode === 'standard' || item.ProductCode === 'InterviewPrep'
  //           PurchaseState.coaching.hasPurchased = true
  //           PurchaseState.coaching.interview.hasBlocker = PurchaseState.nextStep.blocker
  //             ? true
  //             : PurchaseState.nextStep.id === 'assessment' && PurchaseState.coaching.interview.requiresAssessment
  //           PurchaseState.coaching.interview.hasPurchased = true
  //           PurchaseState.coaching.interview.appointments.push(
  //             getAppointmentObject(item, feature, 'interviewColor', interviewCoachKey, showScheduling1, userKey, partnerKey, user)
  //           )
  //           PurchaseState.coaching.interview.partnerKey = partnerKey
  //           PurchaseState.coaching.interview.userKey = userKey
  //           PurchaseState.coaching.interview.partnerAccess = true
  //           break
  //         case 'salaryreview':
  //         case 'interviewprepsalaryreview':
  //         case 'westpointsalaryreview':
  //           //allowAutoAddon = false
  //           const coachKey = workSelectors.salaryCoachId(feature)
  //           const showScheduling3 = feature.CurrentStep === 'scheduleSalaryCoach'
  //           PurchaseState.coaching.paycheck.requiresAssessment = PurchaseState.coaching.paycheck.requiresAssessment
  //             ? true
  //             : item.ProductCode === 'standard' || item.ProductCode === 'InterviewPrep'
  //           PurchaseState.coaching.hasPurchased = true
  //           PurchaseState.coaching.paycheck.hasPurchased = true
  //           PurchaseState.coaching.paycheck.hasBlocker = PurchaseState.nextStep.blocker
  //             ? true
  //             : PurchaseState.nextStep.id === 'assessment' && PurchaseState.coaching.paycheck.requiresAssessment
  //           PurchaseState.coaching.paycheck.showLockout =
  //             feature.CurrentStep === 'assignAdmin' ||
  //             feature.CurrentStep === 'resume' ||
  //             feature.CurrentStep === 'assessment' ||
  //             feature.CurrentStep === 'assignSalaryCoach'
  //           PurchaseState.coaching.paycheck.partnerKey = partnerKey
  //           PurchaseState.coaching.paycheck.userKey = userKey
  //           PurchaseState.paycheck.questionData = item.details.Data
  //           if (PurchaseState.coaching.paycheck.hasBlocker) {
  //             PurchaseState.paycheck.hasBlocker = true
  //             PurchaseState.paycheck.blockerMsg = nextStep
  //           } else if (PurchaseState.coaching.paycheck.showLockout) {
  //             PurchaseState.paycheck.hasBlocker = true
  //             PurchaseState.paycheck.blockerMsg = getPaycheckLockoutMessage(item, feature.CurrentStep)
  //           }
  //           PurchaseState.coaching.paycheck.appointments.push(getAppointmentObject(item, feature, 'paycheckColor', coachKey, showScheduling3, userKey, partnerKey, user))
  //           PurchaseState.paycheck = updateObject(PurchaseState.paycheck, {
  //             hasPurchased: true,
  //             showQuestionaire: feature.CurrentStep === 'salaryQuestionaire',
  //             coachKey: coachKey,
  //             purchaseBundleCode: item.PurchaseBundleCode,
  //             purchasedItemKey: feature.PurchasedItemKey,
  //             currentStep: feature.CurrentStep,
  //             isComplete: feature.CurrentStep === 'complete',
  //             action: feature.Action,
  //             submit: feature.ProductFeatureCode,
  //             team: item && item.details && item.details.team,
  //           })
  //           PurchaseState.coaching.paycheck.partnerAccess = true
  //           break
  //         case 'jobs':
  //           PurchaseState.jobs = updateObject(PurchaseState.jobs, {
  //             hasPurchased: true,
  //             isComplete: feature.CurrentStep === 'complete',
  //             pushStepData: getPushStepPayload(item, feature),
  //           })
  //       }
  //     }, item.details.Steps)
  //   }, RevProds)

  //   const theReturn = getAvailableServices(whatTheyGot, attributes)
  //   const FreemiumOnly =
  //     whatTheyGot.Freemium && !(whatTheyGot.Tcm || whatTheyGot.ResumeArchitect || whatTheyGot.InterviewPrep || whatTheyGot.CareerAnswers || whatTheyGot.Subscription)

  //   PurchaseState.freemiumOnly = FreemiumOnly
  //   PurchaseState.resume.uploadLockout = FreemiumOnly && PurchaseState.resume.isComplete
  //   PurchaseState.resume.showAssessmentAd = !PurchaseState.traitsAssessment.isComplete
  //   PurchaseState.jobs.availableServices = ['standard', 'InterviewPrep']
  //   PurchaseState.services.availableServices = theReturn.availableServices
  //   PurchaseState.services.membershipLevel = theReturn.membershipLevel
  //   PurchaseState.services.hidefreeAccount = theReturn.hidefreeAccount
  //   PurchaseState.services.memberSinceDate = memberSinceDate.format('MMMM-YYYY')
  //   PurchaseState.coaching.career.preBuy = getMsgObject('custom', false, true, 'CareerServices', 'GetAccess', '/services', 'Services')
  //   PurchaseState.coaching.paycheck.preBuy = getMsgObject('custom', false, true, 'PaycheckServices', 'GetAccess', '/services', 'Services')
  //   PurchaseState.coaching.resume.preBuy = getMsgObject('custom', false, true, 'ResumeServices', 'GetAccess', '/services', 'Services')
  //   PurchaseState.coaching.interview.preBuy = getMsgObject('custom', false, true, 'InterviewServices', 'GetAccess', '/services', 'Services')
  //   PurchaseState.coaching.partnerCoach.preBuy = getMsgObject('custom', false, false, 'WestpointCoachIntro', 'WestpointSchedule', '/services', 'Services')
  //   PurchaseState.coaching.feedbackCoach.preBuy = getMsgObject('custom', false, true, 'CareerServices', 'GetAccess', '/services', 'Services')
  //   PurchaseState.userKey = userKey
  //   PurchaseState.userFirst = userFirst
  //   PurchaseState.userLast = userLast
  //   PurchaseState.todayGreeting = todayGreeting()
  //   PurchaseState.coaching.defaultCoachingFeature = getDefaultCoachingView(RevProds)
  //   PurchaseState.coaching.currentSession = undefined
  //   PurchaseState.coaching.currentGroup = undefined
  //   PurchaseState.coaching.allowAutoAddon = allowAutoAddon
  //   PurchaseState.resumes = user.resumes
  //   console.log('AND THE RAW PURCHSTATE IS ', PurchaseState)
  //   return PurchaseState
  // }

  // const getDefaultCoachingView = prods => {
  //   let defaultID = -1
  //   let nothingToDoID = -1
  //   prods.map(item => {
  //     item.details.Steps.map(feature => {
  //       if (
  //         feature.FeatureType === 'coaching' ||
  //         feature.FeatureType === 'placementcoaching' ||
  //         feature.FeatureType === 'resumecoaching' ||
  //         feature.FeatureType === 'interviewcoaching' ||
  //         feature.FeatureType === 'interviewprepsalaryreview' ||
  //         feature.FeatureType === 'salaryreview'
  //       ) {
  //         if (feature.CurrentStep !== 'complete') {
  //           if ((feature.FeatureType === 'interviewprepsalaryreview' || feature.FeatureType === 'salaryreview') && feature.CurrentStep === 'assignAdmin') {
  //             nothingToDoID = feature.PurchasedItemKey
  //           } else {
  //             defaultID = defaultID === -1 ? feature.PurchasedItemKey : defaultID
  //           }
  //         } else {
  //           nothingToDoID = feature.PurchasedItemKey
  //         }
  //       }
  //     })
  //   })
  //   return defaultID !== -1 ? defaultID : nothingToDoID
}

const doTheyGetChat = prod => {
  switch (prod) {
    case 'subscription':
    case 'UOMCoaching':
    case 'JJCoaching':
      return true
    default:
      return false
  }
}

const getPushStepPayload = (item, feature) => {
  return {
    PurchaseBundleCode: item.PurchaseBundleCode,
    PurchasedItemKey: feature.PurchasedItemKey,
    Submit: feature.ProductFeatureCode,
    CurrentStep: feature.CurrentStep,
    Action: feature.Action ? feature.Action : feature.ProductFeatureCode,
  }
}

const getPaycheckLockoutMessage = (prod, currentStep) => {
  switch (prod.ProductCode) {
    case 'standard':
    case 'InterviewPrep':
    case 'ResumeArchitect':
    case 'WestPointCareerManagement':
      switch (currentStep) {
        case 'resume':
        case 'assessment':
        case 'assignAdmin':
          return getMsgObject(
            'custom',
            false,
            true,
            'NotYet',
            'FirstBeforeWestpointPaycheck',
            '/coaching',
            'Coaching'
          )
        case 'assignSalaryCoach':
          return getMsgObject(
            'custom',
            false,
            true,
            'HangTight',
            'PaycheckAssign', //"Your Pay Coach is being assigned to you. We'll email you when your questionnaire becomes available.",
            '/coaching',
            'Coaching'
          )
        default:
          break
      }
      break
    default:
      break
  }
  return {
    title: '',
    msg: ''
  }
}

const getAvailableServices = (whatTheyGot, attributes) => {
  let theReturn = {}
  let availableServices = []
  let membershipLevel = 'Pages.Services.LevelFree'
  let doBoth = false
  let hidefreeAccount = false
  const hasMS = false;
  let msValue = '';

  if (attributes && attributes.userAttributes && attributes.userAttributes.mS) {
    msValue = attributes.userAttributes.mS.actualValue;
  }
  switch (true) {
    case whatTheyGot.Subscription:
      membershipLevel = 'Pages.Services.LevelSubscription'
      break
    case whatTheyGot.ResumeArchitect && whatTheyGot.Tcm:
    case whatTheyGot.CareerAnswers && whatTheyGot.Tcm:
      //doBoth = true
      membershipLevel = 'Pages.Services.LevelTCM'
      //availableServices = getAddonsOnly()
      break
    case whatTheyGot.CareerAnswers && whatTheyGot.InterviewPrep:
    case whatTheyGot.ResumeArchitect && whatTheyGot.InterviewPrep:
      //doBoth = true
      membershipLevel = 'Pages.Services.IntvPrep'
      //availableServices = getAddonsOnly()
      break
    case whatTheyGot.CareerAnswers && whatTheyGot.ResumeArchitect:
      //doBoth = true
      membershipLevel = 'Pages.Services.ResArch'
      // availableServices.push('InterviewPrep')
      // availableServices.push('standard')
      // availableServices.push('SingleResumeCoaching')
      // availableServices.push('SingleInterviewCoaching')
      // availableServices.push('SingleCareerCoaching')
      break
    case whatTheyGot.Tcm:
      //doBoth = true
      membershipLevel = 'Pages.Services.LevelTCM'
      //availableServices = getAddonsOnly()
      break
    case whatTheyGot.InterviewPrep:
      //doBoth = true
      membershipLevel = 'Pages.Services.IntvPrep'
      //availableServices = getAddonsOnly()
      break
    case whatTheyGot.ResumeArchitect:
      //doBoth = true
      membershipLevel = 'Pages.Services.ResArch'
      // availableServices.push('CareerAnswers')
      // availableServices.push('InterviewPrep')
      // availableServices.push('standard')
      // availableServices.push('SingleResumeCoaching')
      // availableServices.push('SingleInterviewCoaching')
      // availableServices.push('SingleCareerCoaching')
      break
    case whatTheyGot.CareerAnswers:
      //doBoth = true
      membershipLevel = 'Pages.Services.CareerAnswers'
      // availableServices.push('ResumeArchitect')
      // availableServices.push('InterviewPrep')
      // availableServices.push('standard')
      // availableServices.push('SingleResumeCoaching')
      // availableServices.push('SingleInterviewCoaching')
      // availableServices.push('SingleCareerCoaching')
      break
    case whatTheyGot.AbaTech:
      availableServices.push('abasubscription')
      hidefreeAccount = true
      break
    case whatTheyGot.MapiTech:
      availableServices.push('mapisubscription')
      break
    case whatTheyGot.freeCoaching:
      if (msValue === 'PlusValue')
        availableServices.push('ascm_subscription_plus')
      else
        availableServices.push('subscription')
      hidefreeAccount = true
      break
    case whatTheyGot.Freemium:
      doBoth = true
      break
      // availableServices.push('Freemium')
      // availableServices.push('CareerAnswers')
      // availableServices.push('ResumeArchitect')
      // availableServices.push('InterviewPrep')
      // availableServices.push('standard')
    default:
      break
  }
  if (doBoth) {
    availableServices.push('Freemium')
    availableServices.push('subscription')
    // and gifting
    availableServices.push('CareerCoachingGift-3M');
    availableServices.push('CareerCoachingGift-6M');
    availableServices.push('CareerCoachingGift-12M');
  }
  // console.log('AND AVAILBLE SERVIES IS ', availableServices)
  theReturn = {
    membershipLevel,
    availableServices,
    hidefreeAccount,
  }
  // console.log('GETTING SERVICES AND RETURNING ', theReturn, whatTheyGot)
  return theReturn
}
// const getAddonsOnly = () => {
//   return ['SingleResumeCoaching', 'SingleInterviewCoaching', 'SingleCareerCoaching']
// }
export const getSubscriptionProducts = () => {
  return ['subscription', 'abasubscription', 'mapisubscription', 'ascm_subscription_plus'];
}


export default getPurchaseState