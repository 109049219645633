import React from "react"
import styled from 'styled-components'
import { mobile } from '../../styles/media-queries'


const StyledImg = styled.img`
  height: 50px;
  margin: 15px;

  &.footer {
    margin: 0;
    height: 40px
  }
  &.header {
    margin: 0;
    height: 50px
  }

  @media  ${mobile} {
    height: 35px;

    &.footer {
      margin: 0;
      height: 35px
    }
    &.header {
      margin: 0;
      height: 35px
    }
}`


export default ({type = "", dark = false}) => (
  <StyledImg  className={type}
    src={`/static/images/logos/${dark ? 'SwissRe-logo-black' : 'SwissRe-logo-white'}.png`}
  />
)