// ActionTypes

import humps from 'humps'

import axios from 'axios'
import { api, updateObject, getHost } from '../../shared/utility'

export const actionTypes = {
  GET_USER_NOTES_SUCCESS: 'GET_USER_NOTES__SUCCESS',
  GET_USER_NOTES_START: 'GET_USER_NOTES_START',
  GET_USER_NOTES_FAIL: 'GET_USER_NOTES_FAIL',
  SAVE_USER_NOTES_SUCCESS: 'SAVE_USER_NOTES_SUCCESS',
  SAVE_USER_NOTES_FAIL: 'SAVE_USER_NOTES_FAIL',
  SAVE_USER_NOTES_START: 'SAVE_USER_NOTES_START',
  DELETE_USER_NOTES_SUCCESS: 'DELETE_USER_NOTES_SUCCESS',
  DELETE_USER_NOTES_FAIL: 'DELETE_USER_NOTES_FAIL',
  DELETE_USER_NOTES_START: 'DELETE_USER_NOTES_START',
}

// Action Creators

export const actions = {
  getUserNotesSuccess: data => ({ type: actionTypes.GET_USER_NOTES_SUCCESS, data }),
  getUserNotesFail: error => ({ type: actionTypes.GET_USER_NOTES_FAIL, error: error }),
  getUserNotesStart: () => ({ type: actionTypes.GET_USER_NOTES_START }),

  saveUserNotesSuccess: data => ({ type: actionTypes.SAVE_USER_NOTES_SUCCESS, data }),
  saveUserNotesFail: error => ({ type: actionTypes.SAVE_USER_NOTES_FAIL, error: error }),
  saveUserNotesStart: () => ({ type: actionTypes.SAVE_USER_NOTES_START }),

  deleteUserNotesSuccess: data => ({ type: actionTypes.DELETE_USER_NOTES_SUCCESS, data }),
  deleteUserNotesFail: error => ({ type: actionTypes.DELETE_USER_NOTES_FAIL, error: error }),
  deleteUserNotesStart: () => ({ type: actionTypes.DELETE_USER_NOTES_START }),

  getUserNotes: userKey => {
    return dispatch => {
      dispatch(actions.getUserNotesStart())
      let path = `/coach/notes/${userKey}`
      return api
        .get(path, true)
        .then(response => {
          return humps.camelizeKeys(response.data)
        })
        .then(data => {
          dispatch(actions.getUserNotesSuccess(data))
        })
        .catch(error => {
          console.error(error)
          dispatch(actions.getUserNotesFail(error.response))
        })
    }
  },

  saveUserNotes: (userKey, postData) => {
    return dispatch => {
      dispatch(actions.saveUserNotesStart())
      let path = `${getHost().apiBaseUrl}/coach/notes`
      return axios(path, {
        method: 'post',
        data: postData,
        withCredentials: true,
      })
        .then(response => {
          return humps.camelizeKeys(response.data)
        })
        .then(data => {
          dispatch(actions.saveUserNotesSuccess(data))
        })
        .catch(error => {
          console.error(error)
          dispatch(actions.saveUserNotesFail(error.response))
        })
    }
  },

  deleteUserNotes: (userKey, notekey) => {
    return dispatch => {
      dispatch(actions.deleteUserNotesStart())
      let path = `/coach/notes/delete/${userKey}/${notekey}`
      return api
        .delete(path, true)
        .then(response => {
          return humps.camelizeKeys(response.data)
        })
        .then(data => {
          dispatch(actions.deleteUserNotesSuccess(data))
        })
        .catch(error => {
          console.error(error)
          dispatch(actions.deleteUserNotesFail(error.response))
        })
    }
  },
}

// Reducer
const defaultState = {
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
  data: null,
  error: null,
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_USER_NOTES_START:
      return updateObject(state, { loading: true })
    case actionTypes.GET_USER_NOTES_SUCCESS:
      return updateObject(state, {
        loading: false,
        loaded: true,
        data: action.data,
      })
    case actionTypes.GET_USER_NOTES_FAIL:
      return updateObject(state, {
        loading: false,
        error: action.error,
      })
    case actionTypes.saveUserNotesStart:
      return updateObject(state, { saving: true })
    case actionTypes.saveUserNotesFail:
      return updateObject(state, {
        saving: false,
        error: action.error,
      })
    case actionTypes.SAVE_USER_NOTES_SUCCESS:
      return updateObject(state, {
        saving: false,
        saved: true,
        data: action.data,
      })
    case actionTypes.DELETE_USER_NOTES_START:
      return updateObject(state, { loading: true })
    case actionTypes.DELETE_USER_NOTES_FAIL:
      return updateObject(state, {
        loading: false,
        error: action.error,
      })
    case actionTypes.DELETE_USER_NOTES_SUCCESS:
      return updateObject(state, {
        loading: false,
        loaded: true,
        data: action.data,
      })
    default:
      return state
  }
}
