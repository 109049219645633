import mqtt from 'mqtt'
import humps from 'humps'
import { api, getHost, guid } from './shared/utility'
export default class Chatter{

    constructor() {
        this.client = null
      }

     connect(onConnect, onNewMessage, onEnd, onClose, onReconnect, onError) {
        let self = this
        let path = `${getHost().apiBaseUrl}/iot/subscribe`

        api
          .get(path)
          .then(response => {
            const endPoint = response.data.url
            console.log(endPoint)
            self.client = mqtt.connect(endPoint, {
                clientId:guid(),
                port: 443,
                timeout: 3,
                mqttVersion: 4,
                keepalive: 0
            })
            self.client.on('connect', function () {
                if (onConnect) {
                    onConnect()
                }

            })
            //client.on('message', messageReceived)
            self.client.on('message', function (topic, msg) {
                var message = JSON.parse(msg)
                //console.log("Message Recieved:", message)
                const messageReceived = humps.camelizeKeys(message)
                if (message && onNewMessage) {
                    onNewMessage(message.t, message)
                }

            })
            self.client.on('end', function(){
                console.log("IOT ended")
                if( onEnd){
                    onEnd()
                }
            })
            self.client.on('close', function () {
                console.log("IOT close")
                if( onClose){
                    onClose()
                }
            })
            self.client.on('reconnect', function(){
                console.log("IOT reconnect")
                if(onReconnect){
                    onReconnect()
                }
            })
            self.client.on('error', function(){
                console.log("IOT Error")
                if(onError){
                    onError()
                }
            })
          })



    }
    end(){
        console.log("IOT END Fired")
        let self = this
        if( self.client){
            self.client.end()
            //self.client = undefined
        }
    }
    unsubscribe(channel) {
        let self = this
        if (channel) {
            try {
                if (self.client) {
                    console.log("unsubscribe from:" + channel)
                    self.client.unsubscribe(channel)
                }
            }
            finally{

            }
        }
    }
     subscribe(channel) {
       let self = this
        if (channel) {
            try {
                if (self.client) {
                    //console.log("subscribed to:" + channel)
                    self.client.subscribe(channel)
                }
            }
            finally{

            }
        }
    }
}
