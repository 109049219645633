import React from 'react';
import {connect} from 'react-redux';

class WithAccess extends React.Component {
  isAllowed = (path, options) => {
    const {type, resource} = options;
    const {access, loaded} = this.props;
    if (!loaded) return false;

    if (type === 'route') {
      if (!path) return true;
      
      if (access[path] === 'allowed') return true;
      return false;
    }

    if (type === 'component' && resource && access[resource] === 'allowed') {
      return true;
    }

    return false;
  }

  render() {
    const {access, loaded, component: Comp, options = {}, props} = this.props;
    const {type = 'route', resource} = options;
    
    if (!loaded) return null;

    if (type === 'component' && resource && resource.startsWith('component://') && this.isAllowed(null, options)) {
      return <Comp {...props} />
    }

    if (type === 'route') {
      const path = props.match.path;
      if (this.isAllowed(path, {type, resource})) return <Comp {...props} />

      return (
        <div style={{margin: '20px'}} >
          <h1>No Access Allowed!</h1>
          <hr />
        </div>
      )
    }

    return null;
  }
}


const mapStateToProps = state => ({
  access: state.access.access,
  loaded: state.access.loaded,
})

export default connect(mapStateToProps)(WithAccess);

