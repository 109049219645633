import humps from 'humps'
import moment from 'moment-timezone'
import { map } from 'ramda'
import { serialize, updateObject, api, getHost, DATE_FORMAT, handleError } from '../../shared/utility'

const formatResponse = client => {
  client.startDate = moment(client.startDate).format(DATE_FORMAT)
  return client
}

// Constants

export const actionTypes = {
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_START: 'GET_USERS_START',
  GET_USERS_FAIL: 'GET_USERS_FAIL',
}

// Action Creators

export const actions = {
  getUsersSuccess: (filters, response) => ({
    type: actionTypes.GET_USERS_SUCCESS,
    filters,
    response,
  }),

  getUsersFail: error => ({
    type: actionTypes.GET_USERS_FAIL,
    error,
  }),

  getUsersStart: () => ({
    type: actionTypes.GET_USERS_START,
  }),

  getUsers: queryParams => {
    return dispatch => {
      if (!queryParams) throw Error('queryParams are required')
      dispatch(actions.getUsersStart())
      let path = `${getHost().apiBaseUrl}/search/${queryParams.type}/${'?' + serialize(queryParams)}`

      return api
        .get(path)
        .then(response => {
          return humps.camelizeKeys(response.data)
        })
        .then(response => {
          dispatch(
            actions.getUsersSuccess(queryParams, {
              ...response,
              data: map(formatResponse, response.data),
            })
          )
        })
        .catch(error => handleError(dispatch, actions.getUsersFail, error))
    }
  },
}

// Reducer

const defaultState = {
  loading: false,
  filters: null,
  response: null,
  error: null,
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_USERS_START:
      return updateObject(state, { loading: true, error: null })
    case actionTypes.GET_USERS_SUCCESS:
      return updateObject(state, {
        error: null,
        loading: false,
        filters: action.filters,
        response: action.response,
      })
    case actionTypes.GET_USERS_FAIL:
      return updateObject(state, { loading: false, error: action.error, response: null })
    default:
      return state
  }
}
