import React, { useEffect } from 'react'

import { withRouter } from 'react-router-dom'
//import { injectGlobal, ThemeProvider } from 'styled-components';  // ^v3.3.2
import {ThemeProvider} from 'styled-components'; // ^v4.4.0
import { StyledMain } from '../../styles/global'
import Header from '../../components/Header/Header';
import PageSettings from '../../components/PageSettings';
import SkipNavigation from '../../components/SkipNavigation';
import Toaster from '../../containers/Toaster/Toaster'
import ConsentModal from '../../containers/Modals/ConsentModal'
import PostSurveyModal from '../../containers/Modals/PostSurveyModal'
import SelectPartnerModal from '../../containers/Modals/SelectPartnerModal'
import AccountStatusMessageModal from '../../containers/Modals/AccountStatusMessageModal'

import { getPartnerTheme } from '../../shared/get-partner-theme'
import Analytics from '../Analytics/Analytics'


const Layout = props => {
  const { assets, children, history, isAuthenticated, userKey, name, role, user, partnerKey, products, purchState, content, c , isInterviewApp} = props
  const theme = getPartnerTheme(partnerKey)
  const childProps = { history, isAuthenticated, userKey, name, role, user, assets, products, purchState, content, c }
  //console.log('GETTING LAYOUT AND THE CONTENT IS ', content)

  useEffect(() => {
    const fontFile = theme().fontFile;
    if(fontFile) {
        const cssId = 'font-file-' +  partnerKey; 
        if (!document.getElementById(cssId))
        {
            let head  = document.getElementsByTagName('head')[0];
            var link  = document.createElement('link');
            link.id   = cssId;
            link.rel  = 'stylesheet';
            link.type = 'text/css';
            link.href = fontFile;
            link.media = 'all';
            head.appendChild(link);
        }
    }
   }, [partnerKey])


  return (
    <ThemeProvider theme={theme}>
        <StyledMain {...childProps}>
          <PageSettings {...childProps} />
          <SkipNavigation />
          {!isInterviewApp && <Header {...childProps} />}
          <ConsentModal />
          <AccountStatusMessageModal />
          <PostSurveyModal c={c}/>
          <SelectPartnerModal />
          <Toaster />
          {children}
          <Analytics />
        </StyledMain>
    </ThemeProvider>
  )
}

export default withRouter(Layout)
