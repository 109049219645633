import { actionTypes } from "./toaster";

export const defaultState = {
  Header: {
    hideHeader: false,
  },
  TopTools: {
    currentTool: null,
    toolbarNavLinks: [],
    hideMenu: false,
  },
  Coaching: {
    rescheduling: false,
  }
}

export const actions = {
  setItem: (group, key, value) => ({type: 'FrontEnd_setItem', payload: {group, key, value}}),
  TopTools: {
    clearSelected: () => ({type: 'FrontEnd_TopTools_clearSelected'}),
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'FrontEnd_setItem':
      const {group, key, value} = action.payload;
      return {...state, [group]: {...state[group], [key]: value}};

    case 'FrontEnd_TopTools_clearSelected':
      let links = state.TopTools.toolbarNavLinks.map(link => ({...link, selected: false}));
      return {...state, TopTools: {...state.TopTools, toolbarNavLinks: links}};

    default:
      return state;
  }
}