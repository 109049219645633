// Constants

export const actionTypes = {
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
}

// Action Creators

export const actions = {
  getSettingsSuccess: response => ({
    type: actionTypes.GET_SETTINGS_SUCCESS,
    response,
  }),
}

// Reducer

const defaultState = {
  loading: false,
  coaches: {},
  error: null,
  loaded: false,
}

const checkClientReportEnabled = settings => {
  const talentHubSettingRaw = settings?.Assessments?.TalentHub
  
  if (!talentHubSettingRaw) {
    return false;
  }

  const setting = JSON.parse(talentHubSettingRaw);
  const clientReports = setting?.clientReports;

  if (!clientReports || !Array.isArray(clientReports) || clientReports.length === 0) {
    return false;
  }

  return true;
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_SETTINGS_SUCCESS:
      const theData = action.response // && action.response.data ? action.response.data: undefined
      theData.isClientReportEnabled = checkClientReportEnabled(action.response)
      return { ...theData, loading: false, loaded:true }
    default:
      return state
  }
}
