import { commonMobile } from './Mobile.Common'
import BgImgHome from '../../assets/images/home/Intel-home-hero.jpg'
import BgImgHomeMobile from '../../assets/images/home/Intel-home-hero-mobile.jpg'
import BgImgAssessment from '../../assets/images/asssessment/Intel-assessment-hero.jpg'
import BgImgAssessmentMobile from '../../assets/images/asssessment/Intel-assessment-hero-mobile.jpg'
import BgImgResume from '../../assets/images/resume/google-resume-hero.png'
import BgImgChat from '../../assets/images/chat/acme_talent_chat_hero_desktop.jpg'
import BgImgFQA from '../../assets/images/faq/Intel-FAQ-hero.jpg'
import BgImgFQAMobile from '../../assets/images/faq/Intel-FAQ-hero.jpg'
import BgImgAbout from '../../assets/images/about/TDLearn-about-hero.png'
import ImgAnalysis from '../../assets/images/about/analysis.svg'
import ImgExperts from '../../assets/images/about/experts.svg'
import ImgAboutLaunch from '../../assets/images/about/launch.svg'
import ImgWallet from '../../assets/images/about/wallet.svg'
import BgImgCoaching from '../../assets/images/coaching/Intel-coaching-hero.jpg'
import BgImgCoachingMobile from '../../assets/images/coaching/Intel-coaching-hero-mobile.jpg'
import imgCoaching from '../../assets/images/prods/sofiCoaching.png'
import BgImgInterview from '../../assets/images/interview/acme_talent_interview_hero_desktop.jpg'
import BgImgBestPractices from '../../assets/images/interview/foundationHero.jpg'
import BgImgProfile from '../../assets/images/profile/TDLearn-profile-hero.png'
import BgImgProfileMobile from '../../assets/images/profile/TDLearn-profile-hero-mobile.png'
import BgImgPurchase from '../../assets/images/purchase/hero.jpg'
import BgImgJobs from '../../assets/images/jobs/google-Jobs-hero.png'
import BgImgPaycheck from '../../assets/images/paycheck/hero.jpg'
import BgImgServices from '../../assets/images/services/acme_talent_services_hero_desktop.jpg'
import BgImgPhilosophyHero from '../../assets/images/resume/philosophy_hero.jpg'
import BgImgTipsHero from '../../assets/images/resume/tips_hero.jpg'
import BgImgDosHero from '../../assets/images/resume/dos_hero.jpg'
import accountPrefHero from '../../assets/images/account/TDLearn-acctpref-hero.png'
import accountPrefHeroMobile from '../../assets/images/account/acme_university_account_pref_mobile.jpg'
//import InterviewAppImage from '../../assets/images/interapp/interview_app.jpg'
//import InterviewApp2Image from '../../assets/images/interapp/interview_app2.jpg'
//import InterviewAppImage from '../../assets/images/interapp/InterviewPrep_Ad_blue.png'
import InterviewAppImage from '../../assets/images/interapp/InterviewPrep_Ad.png'
import ButtonAppStore from '../../assets/images/interapp/Button_AppStore_L.png'
import ButtonGooglePlay from '../../assets/images/interapp/Button_GooglePlay_L.png'
import VideoSplashImage from '../../assets/images/video_splash.jpg'
import WhatIsKFA from '../../assets/images/what_is_kfa.jpg'
// import ButtonBack from '../../assets/slant_button_jj.png'
// import ButtonBackContrast from '../../assets/slant_button_contrast.png'
// import ButtonBackWhite from '../../assets/slant_button_white.png'
import { HomeIcon, InsightsIcon, ResumeIcon, AssessmentsIcon } from '../../assets/icons/ToolbarIcons'
import { InterviewIcon, CoachingIcon, PaycheckIcon, JobsIcon, ChatIcon } from '../../assets/icons/ToolbarIcons'
import imgPhilosophy from '../../assets/images/resume/philosophy.jpg'
import imgTips from '../../assets/images/resume/tips.jpg'
import imgDosDonts from '../../assets/images/resume/dosdonts.jpg'
import imgBestPractice from '../../assets/images/interview/best_practices.jpg'
import GirlCoach from '../../assets/images/avatars/sampleCoachWoman.jpg'
import GirlCoach2 from '../../assets/images/avatars/sampleCoachWoman2.jpg'
import GirlCoach3 from '../../assets/images/avatars/sampleCoachWoman3.jpg'
import BoyCoach from '../../assets/images/avatars/sampleCoachGuy.jpg'
import KFALogo from '../../components/Logos/IntelLogo'
import mastHeadDesktopImage from '../../assets/images/e-learning/TDLearn-learning-hero.png'
import elearningCircleImage from '../../assets/images/e-learning/elearningCircle.png'
import mastHeadMobileImage from '../../assets/images/e-learning/TDLearn-learning-hero-mobile.png'
import adImage from '../../assets/images/e-learning/ad.png'
import { getDefaultNavbar } from '../../shared/utility'

const lightColor = '#57c5f8' //'#4285F4' //#57c5f8 is the intel color, but it's a bit light
const panelColor = lightColor // '#0D6E62' //'#61A0B8' //'#00A3B2'
const lighterColor = '#c7dcfc' // '#59c3cc'
const lightColorDisabled = '#59c3cc'
const darkColor = '#202124' //lightColor //'#3B819C' //'#008B98' //'#000' //'#1e3857'
const whiteColor = '#ffffff'
const blackColor = '#202124' //'#000000'
const secondaryColor = lightColor //'#4f4f4f'
const contrastColor = lightColor //'#D39883' //'#F8CA57' //'#04465F' //'#EFEFEF' //'#741e1a' //'#8bc330'
const contrastLightColor = lighterColor // '#FFDB81' //'#b19795' // '#f1f7e7' //'#e7f4d2' //
const contrastColor2 = '#bdcf02'
const lightGrayColor = '#f3f3f3' //'#f4f4f4'
const grayTextColor = '#6a6a77'
const mobile = { ...commonMobile }

export const Assets = history => {
  const theIcons = {
    HomeIcon, InsightsIcon, ResumeIcon, AssessmentsIcon,
    InterviewIcon, CoachingIcon, PaycheckIcon, JobsIcon, ChatIcon
  }
  const toolbarNavLinks = getDefaultNavbar(history, theIcons)

  const theReturn = {
    mobile,
    toolbar: 'littleBar',
    mastHead: 'large',
    externalJobsBoard: false,
    externalAssessment: false,
    scheduleRange: 24,
    hasGoals: true,
    hasCareerWins: true,
    hasNavigator: false,
    autoAssignScheduling: true,
    toolbarNavLinks: toolbarNavLinks(history),
    iconColor: lightColor,
    iconLightColor: '#b5d9dc',
    iconColorSelected: whiteColor,
    lightColor,
    menuColor: whiteColor,
    menuTextColor: whiteColor,
    lightColorDisabled,
    iconContrastColor2: contrastColor2,
    contrastColor,
    bgHome: BgImgHome,
    bgHomeMobile: BgImgHomeMobile,
    bgAssessment: BgImgAssessment,
    bgImgAssessmentMobile: BgImgAssessmentMobile,
    bgImgCoaching: BgImgCoaching,
    bgImgCoachingMobile: BgImgCoachingMobile,
    bgImgResume: BgImgResume,
    bgImgChat: BgImgChat,
    bgImgFQA: BgImgFQA,
    bgImgFQAMobile: BgImgFQAMobile,
    bgImgInterview: BgImgInterview,
    bgImgBestPractices: BgImgBestPractices,
    bgImgProfile: BgImgProfile,
    bgImgProfileMobile: BgImgProfileMobile,
    bgImgPurchase: BgImgPurchase,
    bgImgJobs: BgImgJobs,
    bgImgPaycheck: BgImgPaycheck,
    bgImgServices: BgImgServices,
    bgImgPhilosophyHero: BgImgPhilosophyHero,
    bgImgTipsHero: BgImgTipsHero,
    bgImgDosHero: BgImgDosHero,
    bgAccountPref: accountPrefHero,
    bgAccountPrefMobile : accountPrefHeroMobile,
    interviewAppImage: InterviewAppImage,
    buttonAppStore: ButtonAppStore,
    buttonGooglePlay: ButtonGooglePlay,
    //interviewApp2Image: InterviewApp2Image,
    videoSplashImage: VideoSplashImage,
    whatIsKFA: WhatIsKFA,
    // buttonBack: ButtonBack,
    // buttonBackContrast: ButtonBackContrast,
    LogoComponent: KFALogo,
    FooterLinks: '© Korn Ferry, All rights reserved ',
    FooterCareerQuestions:
      'Questions? Concerns? Contact us <a href="mailto:Advance@KornFerry.com">Advance@KornFerry.com</a> | <a href="tel:+1.888.291.7818">+1.888.291.7818</a>',
    FooterLoanQuestions: '',
    TermsLink: '',
    WelcomeMovie: 'https://video.kfadvance.com/Welcome/mp4-Welcome-1080.mp4',
    WhatIsKFA: 'https://video.kfadvance.com/Korn+Ferry+Advance/Why+Korn+Ferry+Advance.mp4',
    bgImgAbout: BgImgAbout,
    imgAnalysis: ImgAnalysis,
    imgExperts: ImgExperts,
    imgAboutLaunch: ImgAboutLaunch,
    imgWallet: ImgWallet,
    imgPhilosophy,
    imgTips,
    imgDosDonts,
    imgBestPractice,
    imgCoaching,
    boyCoach: BoyCoach,
    girlCoach: GirlCoach,
    girlCoach2: GirlCoach2,
    girlCoach3: GirlCoach3,
    addOnBlurb: '',
    addOnButton: '',
    sampleCoach1: {
      bg: 'yellow',
      img: BoyCoach,
      name: 'David',
      specialty: 'Career Coach',
      quote:
        '“As a coach, I am committed to each client’s journey. Nothing brings me more satisfaction than seeing my client’s overcome obstacles to land a career and lead a life filled with fulfillment, meaning, purpose, and passion.”',
    },
    sampleCoach2: {
      bg: 'blue',
      img: GirlCoach,
      name: 'Nicole Wade',
      specialty: 'Financial Sector | Career Coach',
      quote:
        '"As a coach, I am committed to each client’s journey. Nothing brings me more satisfaction than seeing my client’s overcome obstacles to land a career and lead a life filled with fulfillment, meaning, purpose, and passion."',
    },
    hideGradientOverlay: true,
    pages: {
      home: {
        showKFAd: false,
        showKFSubscriptionAd: false,
        showKFService: false,
        showSofiServices: false,
        showProductPurchased: false,
        showPrysmianFlow: false,
        showRedundentPrysmian: false,
        showInsights: false,
        hasJourneys: false,
        suppressGoals: true,
        suppressWins: true,
        mainBlurb:
          'Korn Ferry Advance is about helping you build a successful career. We have the resources and experience to help you manage your career. We just need to know a little bit more about you. It all starts with the basics… a profile, resume review, and an assessment. We’ll take it from there.',
        showPODLearningPlan: false,

        /* Replace Journeys Tiles content. Triggers Journeys layout (with grey column
           on the right) even if hasJourneys is false. */
        showAlternateContentBlock: true,
        suppressRightHandSideBar: true,
        suppressJourneysGreetingBlock: true
      },
      resume: {
        showKFAd: true,
        showAssessmentBlurb: true,
      },
      assessment: {
        showAssessmentBlurb: false,
        showResumeParsing: false,
        showAssessmentContent: false,
        showAssessmentStart: true,
        showAssessmentBlurb2: true,
        newInsights: true,
        waitingMastHeadOverlayBottom: '1rem'
      },
      profile: {
        showKFAd: false,
        showKFSubscriptionAd: false,
        showKFService: false,
        showSofiServices: false,
        showResumeParsing: false,
        showRedundentPrysmian: false,
        hideTopBorder: true,
      },
      faq: {
        showKFAd: false,
        showKFService: false,
        showPrysmianFaq: false,
      },
      coaching: {
        showKFAd: false,
        showCoachingAd1: false,
        showCoachingAd2: false,
        coachingGoals: false,
        showKFSubscriptionAd: false,
        showCoachPrepLink: true,
        showAddon: true,
      },
      interview: {
        showFAQ: true,
        mobileAppOnly: false,
        style: 'original',
      },
      eLearning: {
        mastHeadDesktopImage: mastHeadDesktopImage,
        mastHeadMobileImage: mastHeadMobileImage,
        adImage: adImage,
        useVendorsComponent: true,
        useWideVendorLayout: true,
        useWideLearningBlurb: true,
        elearningCircleImage: elearningCircleImage
      },
      chat: {
        showTiledBackground: true,
        bgTiledImgChat: "url('/static/images/misc/google-chat-bg.png')",
      }
    },
    menus: {
      main: 'group:main_menu',
      myAccount: 'group:my_account',
      topTools: 'group:top_tools',
    },
    getInsightsModifier: auth => auth && auth.purchState && auth.purchState.hasSubscription 
    ? 'insightvanitypathpostbuy' 
    : 'insightvanitypath',
  }
  //console.log('AND THE RETURN FOR DEFAULT IS ', theReturn);
  return theReturn
}

export const Theme = () => {
  const theTheme = {
    mobile,
    error: '#d54300',
    warning: '#FBBC04',
    good: 'green',
    fontOne: 'ProximaNova-Regular,Roboto, Helvetica Neue, Arial, sans-serif',
    fontTwo: 'ProximaNova-Bold, Roboto, Helvetica Neue, Arial, sans-serif',
    fontThree: 'ProximaNova-Extrabld, Roboto, Helvetica Neue, Arial, sans-serif',
    fontFour: 'ProximaNova-Semibold, Roboto, Helvetica Neue, Arial, sans-serif',
    darkColor: darkColor,
    lightColor: lightColor,
    panelColor,
    lightColorDisabled,
    lighterColor: lighterColor,
    headerColor: lightColor,
    whiteColor: whiteColor,
    blackColor: blackColor,
    contrastColor: contrastColor,
    contrastLightColor,
    lightGrayColor,


    buttonBackColor: lightColor, //'#D39883', //lightColor,
    buttonTextColor: whiteColor,
    buttonBorderColor: lightColor, //'#D39883', //lightColor,
    menuHoverColor: '#aaa',
    grayTextColor: grayTextColor,
    careerColor: lightColor,
    careerColorLight: lighterColor,
    paycheckColor: '#76e6d2',
    paycheckColorLight: '#ccfbf3',
    resumeColor: '#306259',
    resumeColorLight: '#c2ede5', //'#7abcb0',
    interviewColor: '#009396',
    interviewColorLight: '#b7f3f4',
    tbBackground: '#f4f4f4',
    pnlBackground: '#ebeae9',
    kfaBackground: '#E5F2F4', //'#BFE5D7', //'#f6f8db',
    kfa2Background: '#E0F2F1', //'#009396',
    secondary: secondaryColor, //'#4f4f4f', //'#424242',
    notification: '#ffc602',
    errorRequired: '#e99000',
    done: '#757575',
    toolbarTextColor: whiteColor,
    toolbar: 'littleBar',
    menuTextColor: whiteColor,
    footerTextColor: whiteColor,
    actionPanelBG1: '#f6f8db',
    actionPanelBG2: '#daeff0',
    // buttonBack: ButtonBack,
    // buttonBackContrast: ButtonBackContrast,
    // buttonBackWhite: ButtonBackWhite,
    contrastColor2: contrastColor2,
    bgQuoteCareerCoach: '#e8f9f6',
    bgQuotePaycheckCoach: '#e0f2f1',
    bgQuoteResumeCoach: '#e9efee',
    bgQuoteInterviewCoach: '#ddf0f1',
    colorCareerCoach: '#03c096',
    colorPaycheckCoach: '#61e3ba',
    colorResumeCoach: '#306259',
    colorInterviewCoach: '#009396',
    colorMastText: whiteColor,
    colorMastButtonText: whiteColor,
    navBackground: '#f6f6f6',
    selectedNavColor: 'transparent',
    selectedNavTextColor: lightColor,
    nonSelectedNavTextColor: grayTextColor,
    NavUnderlineColor: lightColor,
    maxContainerWidth: '920px',
    maxRuleWidth: '1008px',
    coachingMaxRuleWidth: '1008px',
    minHeroHeight: '350px',
    mastHeadTextMaxWidth: '429px',
    mastTitleMinHeight: '40px',
    mastTitleMaxWidth: '475px',
    mastTitleFontSizeMobile: '24px',
    mastTitleFontSizeDesk: '38px',
    mastBlurbFontSizeDesk: '22px',
    inputLabelFontSize: '16px',
    inputFontSize: '22px',
    inputFontFamily: 'ProximaNova-Semibold, Roboto, Helvetica Neue, Arial, sans-serif',
    inputLabelColor: '#000',
    homeHeroTextPaddingTopMobile: '50px',
    homeQuestionMargin: '50',
    homeSideBarHeight: '100%'
  }
  //console.log('AND THE THEME IS ', theTheme)
  return theTheme
}
