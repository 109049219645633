import humps from 'humps'
import axios from 'axios'
import { updateObject, getHost, api, getContent } from '../../shared/utility'

export const actionTypes = {
  OPEN_EXIT_SURVEY: 'OPEN_EXIT_SURVEY',
  CLOSE_EXIT_SURVEY: 'CLOSE_EXIT_SURVEY',
  GET_SURVEY_SUCCESS: 'GET_SURVEY_SUCCESS',
  GET_SURVEY_START: 'GET_SURVEY_START',
  GET_SURVEY_ERROR: 'GET_SURVEY_ERROR',
  SAVE_SURVEY_START: 'SAVE_SURVEY_START',
  SAVE_SURVEY_SUCCESS: 'SAVE_SURVEY_SUCCESS',
  SAVE_SURVEY_ERROR: 'SAVE_SURVEY_ERROR',
}

// Action Creators

export const actions = {
    openExitSurveynModal: () => ({
        type: actionTypes.OPEN_EXIT_SURVEY
      }),
      closeExitSurveyModal: () => {
        return {
          type: actionTypes.CLOSE_EXIT_SURVEY
        }
      },

  getSurveyStart: () => ({ type: actionTypes.GET_SURVEY_START }),

  getSurveySuccess: (data, surveyKey) => ({
    type: actionTypes.GET_SURVEY_SUCCESS,
    data,
    surveyKey,
  }),

  getSurveyFail: error => ({ type: actionTypes.GET_SURVEY_ERROR, error: error }),

  getSurvey: (surveyKey) => dispatch => {
    dispatch(actions.getSurveyStart())

    let path = `${getHost().apiBaseUrl}/survey/questions?survey=${surveyKey}`

    return api
      .get(path)
      .then(response => {
        dispatch(actions.getSurveySuccess(response, surveyKey))
      })
      .catch(error => {
        dispatch(actions.getSurveyFail(error))
      })
  },

  saveSurveyStart: () => ({ type: actionTypes.SAVE_SURVEY_START }),

  saveSurveyFail: () => ({ type: actionTypes.SAVE_SURVEY_ERROR }),
  saveSurveySuccess: (surveyKey) => ({ type: actionTypes.SAVE_SURVEY_SUCCESS, surveyKey }),

  saveSurvey: (surveyKey,postData) => {
    return dispatch => {
      dispatch(actions.saveSurveyStart())
      let path = `${getHost().apiBaseUrl}/survey/save`
      return axios(path, {
        method: 'post',
        data: postData,
        withCredentials: true,
      })
        .then(data => {
          dispatch(actions.saveSurveySuccess(surveyKey))
        })
        .catch(error => {
          console.error(error)
          dispatch(actions.saveSurveyFail(error.response))
        })
    }
  },

}

// Reducer
const defaultState = {
  isExitSurveyOpen: false,
  loading: false,
  surveyList: {},
  error: null,
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.OPEN_EXIT_SURVEY:
    return updateObject(state, {
      isExitSurveyOpen: true
    })

  case actionTypes.CLOSE_EXIT_SURVEY:
    return updateObject(state, {
      isExitSurveyOpen: false
    })

    case actionTypes.GET_SURVEY_START:
      return updateObject(state, { loading: true })

    case actionTypes.GET_SURVEY_SUCCESS:
      let _survey = state.surveyList || {}
      _survey[action.surveyKey] =  action.data.data
      return updateObject(state, {
        loading: false,
        surveyList: {...state.surveyList, ..._survey },
      })

    case actionTypes.GET_SURVEY_ERROR:
      return updateObject(state, { loading: false, error: action.error })
    
    case actionTypes.SAVE_SURVEY_START:
      return updateObject(state, { loading: true })
      
    case actionTypes.SAVE_SURVEY_SUCCESS:
      return updateObject(state, { loading: false })
    
    case actionTypes.SAVE_SURVEY_ERROR:
      return updateObject(state, { loading: false })
    default:
      return state
  }
}
