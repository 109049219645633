export const roles = {
  admin: 'admin',
  assessmentCoach: 'assessmentCoach',
  care: 'care',
  coach: 'coach',
  contentCreator: 'contentcreator',
  developer: 'developers',
  devPlanner: 'devPlanner',
  exec: 'exec',
  interviewPrepCoach: 'interviewPrepCoach',
  partnerCoach: 'partnerCoach',
  reportAccess: 'reportAccess',
  resumeCoach: 'resumeCoach',
  resumeRewriter: 'resumeRewriter',
  salaryCoach: 'salaryCoach',
  salaryReviewer: 'salaryReviewer',
  sgcoach: 'sgcoach',
  sysAdmin: 'sysadmin',
  system: 'system',
  mentor: 'mentor',
  talentAcquisitionCoach: 'talentAcquisitionCoach'
}

export const systemSupportedRoles = [
  roles.admin, 
  roles.coach, 
  roles.sysAdmin, 
  roles.developer, 
  roles.care, 
  roles.contentCreator, 
  roles.reportAccess,
  roles.mentor
]