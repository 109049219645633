import humps from 'humps'
import axios from 'axios'
import { logEvent, setUser2 } from '../../shared/analytics'
import { updateObject, api, getHost, handleError, querystring, mapLanguageCode, getContent } from '../../shared/utility'
import { actions as coachActions } from '../../redux/modules/coach-details'
import { actions as constantActions } from '../../redux/modules/constants'
import { actions as contentActions } from '../../redux/modules/content'
import { actions as settingsActions } from '../../redux/modules/settings'
import { actions as goalsActions } from '../../redux/modules/goals'
import { actions as actionToaster } from './toaster'
import getPurchaseState from './purchaseState'
import { actions as userProfileActions } from './user-profile'
import { actions as attributeActions } from '../../redux/modules/attributes'
import { actions as accessActions } from '../../redux/modules/access'
import { advanceInterviewPrepStep } from '../../redux/modules/auth.connect'
import { checkRole, initializeLoggedInUserRoles } from '../../shared/role.helper'
import { retryTimedFunc } from '../../shared/utility'
import { getCombined } from '../../shared/combine-get-api.helper';
import analyticsTypeConstants from '../../constants/analytics/analytics-type.constants';
import analyticsActionConstants from '../../constants/analytics/analytics-action.constants';

const R = require('ramda')
const isNotNil = R.complement(R.isNil)
const isNotEmpty = R.complement(R.isEmpty)
//const isDefined = R.both(isNotNil, isNotEmpty)
const hasFreemium = R.propEq('ProductCode', 'Freemium')

const checkForFeemium = purchasedProduct => {
  const list = R.filter(hasFreemium, purchasedProduct)
  return list && list.length > 0
}

const getProductCodes = products => {
  if (products && products.length > 0) {
    const list = products.map(p => p.ProductCode).join(',');
    return list;
  }
  return '';
}

const setUserAnalytics = (theUser, products, trackUserAnalytics, hasSubscription) => {

  setUser2(theUser.EmailAddress, theUser.UserKey
    , theUser.FirstName + ' ' + theUser.LastName
    , theUser.Created, theUser.PartnerKey
    , hasSubscription //checkForSubscription(products)
    , checkForFeemium(products)
    , theUser.ConsentForMarketing
    , trackUserAnalytics
    , getProductCodes(products))
}

export const actionTypes = {
  AUTH_START: 'AUTH_START',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  AUTH_FAIL: 'AUTH_FAIL',
  AUTH_LOGOUT: 'AUTH_LOGOUT',
  SESSION_EXPIRED: 'SESSION_EXPIRED',
  SET_AUTH_REDIRECT_PATH: 'SET_AUTH_REDIRECT_PATH',
  FETCHUSER_SUCCESS: 'FETCHUSER_SUCCESS',
  FETCHPRODS_SUCCESS: 'FETCHPRODS_SUCCESS',
  FETCHCONTENT_SUCCESS: 'FETCHCONTENT_SUCCESS',
  UPDATE_STEPS: 'UPDATE_STEPS',
  EMAIL_VERIFICATION_SUCCESS: 'EMAIL_VERIFICATION_SUCCESS',
  NAV_TO_APPOINTMENT_UPDATE_STATE: 'NAV_TO_APPOINTMENT_UPDATE_STATE',
  UPLOAD_START_STOP: 'UPLOAD_START_STOP',
  UPDATE_PURCHSTATE_RESUMEURL: 'UPDATE_PURCHSTATE_RESUMEURL',
  UPDATE_PURCHSTATE_PAYCHECK: 'UPDATE_PURCHSTATE_PAYCHECK',
  UPDATE_PURCHSTATE_RESUME: 'UPDATE_PURCHSTATE_RESUME',
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  REGISTER_START: 'REGISTER_START',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAIL: 'REGISTER_FAIL',
  RECOVER_START: 'RECOVER_START',
  RECOVER_SUCCESS: 'RECOVER_SUCCESS',
  RECOVER_FAIL: 'RECOVER_FAIL',
  GET_RECOVER_TOKEN_START: 'GET_RECOVER_TOKEN_START',
  GET_RECOVER_TOKEN_SUCCESS: 'GET_RECOVER_TOKEN_SUCCESS',
  GET_RECOVER_TOKEN_FAIL: 'GET_RECOVER_TOKEN_FAIL',
  RESETPASSWORD_START: 'RESETPASSWORD_START',
  RESETPASSWORD_SUCCESS: 'RESETPASSWORD_SUCCESS',
  RESETPASSWORD_FAIL: 'RESETPASSWORD_FAIL',
  UPDATE_PURCHSTATE_TIMEZONE: 'UPDATE_PURCHSTATE_TIMEZONE',
  UPDATE_NAVLINK_SELECTIONS: 'UPDATE_NAVLINK_SELECTIONS',
  CHECK_SSO_DOMAIN_START: 'CHECK_SSO_DOMAIN_START',
  CHECK_SSO_DOMAIN_SUCCESS: 'CHECK_SSO_DOMAIN_SUCCESS',
  CHECK_SSO_DOMAIN_FAIL: 'CHECK_SSO_DOMAIN_FAIL',
  CONSENT_OPEN: 'CONSENT_OPEN',
  CONSENT_CLOSE: 'CONSENT_CLOSE',
  CONSENT_UPDATE_START: 'CONSENT_UPDATE_START',
  CONSENT_UPDATE_ERROR: 'CONSENT_UPDATE_ERROR',
  CONSENT_UPDATE_SUCCESS: 'CONSENT_UPDATE_SUCCESS',
  TEMP_CONSENT_UPDATE: 'TEMP_CONSENT_UPDATE',
  CHECK_FOR_MOBILE_APP: 'CHECK_FOR_MOBILE_APP',
  CHECK_SSO_START: 'CHECK_SSO_START',
  CHECK_SSO_ERROR: 'CHECK_SSO_ERROR',
  CHECK_SSO_SUCCESS: 'CHECK_SSO_SUCCESS',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_TIMEZONE: 'UPDATE_USER_TIMEZONE',
  ROLE_CHANGE_START: 'ROLE_CHANGE_START',
  ROLE_CHANGE_SUCCESS: 'ROLE_CHANGE_SUCCESS',
  ROLE_CHANGE_FAIL: 'ROLE_CHANGE_FAIL',
  PARTNER_SELECT_OPEN: 'PARTNER_SELECT_OPEN',
  PARTNER_SELECT_CLOSE: 'PARTNER_SELECT_CLOSE',
  SET_LOADING: 'SET_LOADING',
  ACCOUNT_STATUS_MESSAGE_MODAL_OPEN: 'ACCOUNT_STATUS_MESSAGE_MODAL_OPEN'
}

const throw401 = () => {
  var error = new Error('Unauthenticated')
  error = {
    ...error,

    response: {
      data: '401 Unauthorized',
      status: 401,
      statusText: 'Unauthorized',
    },
  }
  throw error
}


// Action Creators

export const actions = {

  setLoading: () => {
    return { type: actionTypes.SET_LOADING }
  },


  roleChangeStart: () => {
    return { type: actionTypes.ROLE_CHANGE_START }
  },

  roleChangeError: () => {
    return { type: actionTypes.ROLE_CHANGE_FAIL }
  },

  roleChangeSuccess: (result) => {
    return { type: actionTypes.ROLE_CHANGE_SUCCESS, result }
  },

  roleChange: (role, entityKey) => {
    return dispatch => {
      let data = { role, entityKey }
      dispatch(actions.roleChangeStart())
      api
        .post('/account/changerole', data, true)
        .then(response => {
          dispatch(actions.roleChangeSuccess(response.data));
        })
        .catch(error => {
          console.error(error)
          dispatch(actions.roleChangeError(error))
        })
    }
  },

  updateUser: user => ({ type: actionTypes.UPDATE_USER, payload: { user } }),
  updateUserTimezone: timezone => ({ type: actionTypes.UPDATE_USER_TIMEZONE, payload: { timezone } }),

  checkSSOStart: () => {
    return { type: actionTypes.CHECK_SSO_START }
  },

  checkSSOError: () => {
    return { type: actionTypes.CHECK_SSO_ERROR }
  },

  checkSSOSuccess: (result, partnerKey) => {
    return { type: actionTypes.CHECK_SSO_SUCCESS, result, partnerKey }
  },

  checkSSO: email => {

    return (dispatch, getState) => {
      const { settings } = getState()
      let data = { email, partnerKey: settings.PartnerKey }
      dispatch(actions.checkSSOStart())

      api
        .post('/account/checksso', data, true)
        .then(response => {

          dispatch(actions.checkSSOSuccess(response.data, settings.PartnerKey));
        })
        .catch(error => {
          console.error(error)
          dispatch(actions.checkSSOError(error))
          //const { content } = getState()
          //dispatch(actionToaster.showError(getContent('Global.Message.Error', content)))
        })
    }
  },

  updateConsentStart: () => {
    return { type: actionTypes.CONSENT_UPDATE_START }
  },

  updateConsentError: () => {
    return { type: actionTypes.CONSENT_UPDATE_ERROR }
  },

  updateConsentSuccess: () => {
    return { type: actionTypes.CONSENT_UPDATE_SUCCESS }
  },


  updateConsent: data => {
    return (dispatch, getState) => {
      dispatch(actions.updateConsentStart())
      retryTimedFunc(3, 2000, () => api
        .post('/user/consent', data, true)
        .then(response => {
          dispatch(actions.updateConsentSuccess(response.data));
          dispatch(userProfileActions.getUserProfile())
        })
      ).catch(error => {
        console.error(error)
        dispatch(actions.updateConsentError(error))
        const { content } = getState()
        dispatch(actionToaster.showError(getContent('Global.Message.Error', content)))
      });
    }
  },

  updateTempConsent: data => {
    return { type: actionTypes.TEMP_CONSENT_UPDATE, data }
  },

  openConsent: () => {
    return { type: actionTypes.CONSENT_OPEN }
  },
  closeConsent: () => {
    return { type: actionTypes.CONSENT_CLOSE }
  },
  authStart: () => {
    return { type: actionTypes.AUTH_START }
  },
  checkForMobileApp: () => {
    console.log("* checkForMobileApp *")
    return { type: actionTypes.CHECK_FOR_MOBILE_APP }
  },
  authSuccess: (userKey, partnerKey, name, isTest = false, isAvailableInCountry = true, role, redirectUrl, entityContext) => {
    if (redirectUrl.indexOf('client') === -1) {
      window.location.replace(redirectUrl);
    }
    //console.log('auth success') constantActions
    return { type: actionTypes.AUTH_SUCCESS, userKey, partnerKey, name, isTest, isAvailableInCountry, role, entityContext }
  },

  openAccountStatusMessageModal: () => {
    return { type: actionTypes.ACCOUNT_STATUS_MESSAGE_MODAL_OPEN }
  },
  postEvent: payload => {
    //console.log('IN POST EVENT WITH PAYLOAD ', payload)
    //let path = `${getHost().postEvent}/${payload}`
    //console.log('AND tHE POST EVENt PAtH IS ', path)
    // return axios(path, {
    //   method: 'post',
    //   data: [],
    //   withCredentials: true,
    // })
    //   .then(response => {
    //     console.log('THE RESPONSE FROM POST EVENtS')
    //   })
    //   .catch(error => {
    //     console.error(error)
    //   })
  },
  fetchContentSuccess: (data, partnerKey) => ({ type: actionTypes.FETCHCONTENT_SUCCESS, data, partnerKey }),
  fetchUserSuccess: data => {
    initializeLoggedInUserRoles(data.roles);

    return { type: actionTypes.FETCHUSER_SUCCESS, data }
  },
  checkConsentOnUserFetchSuccess: (data, settings) => {
    return dispatch => {
      if (checkRole.canAccessClientPortal) {
        if (settings.showPrivacyConsent) {
          dispatch(!data.ConsentForPrivacy || (settings.showPartnerConsent && !data.ConsentForPartner) ? actions.openConsent() : actions.closeConsent())
        }
      }
    }
  },

  checkAccountStatusOnUserFetchSuccess: (data, settings) => {
    return dispatch => {
      if (checkRole.canAccessClientPortal && settings.showAccountStatusAlert && (data.isRevoked || data.isSuspended)) {
        dispatch(actions.openAccountStatusMessageModal())
      }
    }
  },

  checkAccessForClientPortal: data => {
    return dispatch => {
      if (!checkRole.canAccessClientPortal) {
        if (window.location.hostname.indexOf('client') !== -1) {
          const redirectUrl = window.location.protocol + '//' + window.location.hostname.replace('client', 'coach')
          window.location.replace(redirectUrl);
        } else {
          dispatch(actions.logout())
        }
      }
    }
  },

  checkForMultiplePartners: entityContext => {
    return dispatch => {
      if (entityContext && entityContext.length > 1) {
        dispatch(actions.openPartnerSelect())
      }
    }
  },

  updatePurchstateTimezone: data => {
    return { type: actionTypes.UPDATE_PURCHSTATE_TIMEZONE, data }
  },
  updatePurchaseStateResume: (item, data) => {
    return { type: actionTypes.UPDATE_PURCHSTATE_RESUME, item, data }
  },
  updateNavlinkSelections: data => {
    return { type: actionTypes.UPDATE_NAVLINK_SELECTIONS, data }
  },
  refreshProducts: coaching => {
    //console.log('REFRESHINNG PRODS WITH COACHING OF ', coaching)
    let navToID = coaching && coaching.currentSession && coaching.currentSession.currentStep !== 'complete' ? coaching.currentSession.purchasedItemKey : coaching ? coaching.defaultCoachingFeature : -1
    navToID = coaching && coaching.currentSession && coaching.currentSession.currentStep === 'coachingCallComplete' ? -1 : navToID
    return (dispatch, getState) => {
      const { settings, auth } = getState()

      if (settings.purchStateV2 !== true) {
        return api
          .get(getHost().fetchUserProducts)
          .then(response => {
            const prods = response.data.filter(p => p.UserKey === auth.user.UserKey)
            //console.log('AND FILTERED PRODS IS ', prods)
            dispatch(actions.processProductData(prods, settings))
            //if (navToID !== -1) {
            //console.log('NAVTOID IS ', navToID)
            dispatch(actions.navToAppointment(navToID))
            //}
          })
          .catch(error => handleError(dispatch, actions.authFail, error))
      }

      return dispatch(actions.refreshPurchStateV2(navToID))
    }
  },

  processProductData: (data, settings) => (dispatch, getState) => {
    const { auth, attributes } = getState();
    advanceInterviewPrepStep(data, auth.isInterviewApp);
    const purchaseState = getPurchaseState(data, auth.user, settings, auth.content, attributes)
    //console.log('UPDATED PURCH STATE WITH NEW PRODS ', purchaseState)
    dispatch(actions.fetchProdsSuccess(data, settings, purchaseState))
  },

  processProductDataV2: (data, settings, purchaseState) => (dispatch, getState) => {
    const { auth } = getState();
    advanceInterviewPrepStep(data, auth.isInterviewApp);
    dispatch(actions.fetchProdsSuccess(data, settings, purchaseState))
  },

  fetchProdsSuccess: (data, settings, purchaseState) => {
    return { type: actionTypes.FETCHPRODS_SUCCESS, data, settings, purchaseState }
  },

  updateSteps: data => {
    return (dispatch, getState) => {
      const state = getState();
      dispatch(actions.updateStepsStart(data, state.settings, state.attributes))
    }
  },

  updateStepsStart: (data, settings, attributes) => (dispatch, getState) => {
    if (settings.purchStateV2 !== true) {
      return { type: actionTypes.UPDATE_STEPS, data, settings, attributes }
    }

    dispatch(actions.refreshPurchStateV2())
  },

  refreshPurchStateV2: (itemKey = -1) => (dispatch, getState) => {
    const url = `${getHost().fetchPurchaseState}?itemKey=${itemKey}`

    return api
      .get(url)
      .then(response => {
        const { settings } = getState()
        const { products: prods, purchaseState } = response.data
        dispatch(actions.processProductDataV2(prods, settings, purchaseState))
      })
      .catch(error => handleError(dispatch, actions.authFail, error))
  },

  uploadStartStop: payload => ({
    type: actionTypes.UPLOAD_START_STOP,
    payload,
  }),

  updatePurchaseStatePaycheck: payload => ({
    type: actionTypes.UPDATE_PURCHSTATE_PAYCHECK,
    payload,
  }),
  emailVerificationSuccess: (payload, settings, attributes) => (dispatch, getState) => {
    if (settings.purchStateV2 === true) {
      dispatch(actions.refreshPurchStateV2())
    }

    dispatch({
      type: actionTypes.EMAIL_VERIFICATION_SUCCESS,
      payload,
      settings,
      attributes
    })
  },

  navToAppointment: (payload, button) => {
    return dispatch => {
      dispatch(coachActions.getCoachBioFail('RESET'))
      return dispatch(actions.naveToAppointmentUpdateState(payload, button))
    }
  },

  naveToAppointmentUpdateState: (payload, button) => (dispatch, getState) => {
    const { settings } = getState()

    if (settings.purchStateV2 !== true) {
      return ({
        type: actionTypes.NAV_TO_APPOINTMENT_UPDATE_STATE,
        payload,
        button,
      })
    }

    let url = `${getHost().fetchPurchaseState}?itemKey=${payload}`
    if (button) {
      url += `&button=${button}`
    }

    return api
      .get(url)
      .then(response => {
        const { products, purchaseState } = response.data
        dispatch(actions.processProductDataV2(products, settings, purchaseState))
      })
      .catch(error => handleError(dispatch, actions.authFail, error))
  },

  authFail: error => {
    return { type: actionTypes.AUTH_FAIL, error: error }
  },

  sessionExpired: error => {
    return { type: actionTypes.AUTH_FAIL, error: error }
  },

  logoutSuccess: () => {
    return { type: actionTypes.AUTH_LOGOUT }
  },

  setAuthRedirectPath: path => {
    return { type: actionTypes.SET_AUTH_REDIRECT_PATH, path: path }
  },

  logout: () => {
    /**
     * Check response
     */
    let url = getHost().logoutUrl
    return dispatch => {
      //
      api
        .get(url)
        .then(result => {
          if (result && result.data && result.data.LogOutUrl) {
            window.location.href = result.data.LogOutUrl
          } else {
            window.location.href = getHost().loginUrl
          }
        })
        .catch(() => (window.location.href = '/login'))
    }
  },

  getStaticContent: (language, partnerKey, entityKey) => async (dispatch) => {
    const routes = [
      `tables/${language}.json`,
      `ui/${partnerKey}/${language}.json`,
      `attributes/${partnerKey}`,
      `settings/${partnerKey}.json`,
      `applicationcontent`,
    ]

    let combineApiUrl = `${getHost().mainHost}/staticcontent/combine/${partnerKey}/${language}`
    if (entityKey) combineApiUrl += `?entity=${entityKey}`
    const combinedStaticData = (await axios.get(combineApiUrl)).data

    dispatch(constantActions.getConstantsSuccess(combinedStaticData.tables))
    dispatch(contentActions.getContentSuccess(combinedStaticData.ui, language))
    dispatch(attributeActions.gotPartnerAttributes(combinedStaticData.attributes))
    dispatch(settingsActions.getSettingsSuccess(combinedStaticData.settings))
    dispatch(actions.fetchContentSuccess(combinedStaticData.applicationContent, partnerKey))
  },

  getUser: (checkLoggedInResponse) => async (dispatch, getState) => {
    try {
      const { settings } = getState()
      const isPurchStateV2 = settings.purchStateV2
      const goalsPath = `user/goals?userKey=${checkLoggedInResponse.userKey}`;

      const routes = [
        'accessrules/user?appkey=clientportal',
        goalsPath,
        'user',
        'user/attributes'
      ]

      const productRoute = !isPurchStateV2 ? 'product/work?details=true' : 'purchase-state'

      routes.push(productRoute)

      const combinedResponse = await getCombined(routes);
      // const combinedResponse = test;

      const [
        accessResponse,
        goalsResponse,
        userResponse,
        userAttributeResponse,
        productResponse
      ] = combinedResponse;

      const { access, resources, resourceGroups } = accessResponse.data;
      let productData
      let purchaseState

      if (!isPurchStateV2) {
        productData = productResponse.data.filter(p => p.UserKey === checkLoggedInResponse.userKey);
      } else {
        ({ products: productData, purchaseState } = productResponse.data)
      }
      dispatch(accessActions.apiSuccess());


      const goalsData = humps.camelizeKeys(goalsResponse.data);
      dispatch(goalsActions.getUserGoalsSuccess(goalsData))
      const entityKey = checkLoggedInResponse.entityContext[0];
      const userData = { ...userResponse.data, EntityKey: entityKey };
      const roles = userData && userData.roles ? userData.roles : []
      dispatch(accessActions.setAccess(access, resources, resourceGroups, roles, checkLoggedInResponse.role, entityKey, productData, userData.entities));
      dispatch(actions.fetchUserSuccess(userData));
      dispatch(actions.checkAccessForClientPortal(userData))
      dispatch(attributeActions.gotUserAttributes(userAttributeResponse.data))
      dispatch(actions.authSuccess(
        checkLoggedInResponse.userKey,
        checkLoggedInResponse.partnerKey,
        checkLoggedInResponse.userFullName,
        checkLoggedInResponse.isTest,
        checkLoggedInResponse.isAvailableInCountry,
        checkLoggedInResponse.role,
        checkLoggedInResponse.redirectUrl,
        checkLoggedInResponse.entityContext,
      ))

      dispatch(actions.checkConsentOnUserFetchSuccess(userData, settings))
      dispatch(actions.checkAccountStatusOnUserFetchSuccess(userData, settings))

      if (!isPurchStateV2) {
        dispatch(actions.processProductData(productData, settings))
        dispatch(actions.navToAppointment(-1))
      } else {
        dispatch(actions.processProductDataV2(productData, settings, purchaseState))
      }

    } catch (error) {
      console.error("authFail", error)
      dispatch(actions.authFail())
    }
  }
  ,

  authCheckState: () => {
    let url = getHost().apiCheckLoggedInUrl
    return (dispatch, getState) => {
      dispatch(actions.authStart())

      api
        .get(url)
        .then(response => {
          return humps.camelizeKeys(response.data)
        })
        .then(async response => {
          if (!response.userKey) {
            const qs = querystring()
            const partnerKeyOrPrefix = qs.pc || qs.partnerkey || qs.partnerKey || response.partnerKey;
            const language = qs.l ? mapLanguageCode(qs.l) : response.languageCode ? response.LanguageCode : 'en-US'
            const entityKey = qs.ec ? qs.ec : undefined
            await dispatch(actions.getStaticContent(language, partnerKeyOrPrefix, entityKey))
            return throw401()
          } else {
            const { partnerKey, languageCode, entityContext } = response
            await dispatch(actions.checkForMultiplePartners(entityContext))
            await dispatch(actions.getStaticContent(languageCode, partnerKey, entityContext[0]))
            await dispatch(actions.getUser(response));
          }
        })
        .catch(error => {
          console.log("AUTH ERROR", error)
          dispatch(actions.authFail())
          handleError(dispatch, actions.authFail, error)
        })
    }
  },

  loginStart: () => {
    return { type: actionTypes.LOGIN_START }
  },
  loginFail: payload => {
    return { type: actionTypes.LOGIN_FAIL, payload }
  },
  loginSuccess: payload => {
    return { type: actionTypes.LOGIN_SUCCESS, payload }
  },

  login: (email, password, partnerKey) => dispatch => {
    dispatch(actions.loginStart())

    const data = {
      UserName: email,
      Password: password,
      ConfirmPassword: password,
      ExistingEmail: true,
      RememberMe: true,
      Referer: '',
      Href: window.location.href,
      partnerkey: partnerKey,
    }
    api
      .post('/account/login', data, true)
      .then(response => {
        if (response.data && response.data.UserKey && response.data.RedirectUrl) {
          // window.location.replace(response.data.RedirectUrl);
          dispatch(actions.loginSuccess(response.data));
          return
        } else {
          console.log("LOGIN FAILED:", response.data)
          dispatch(actions.loginFail(response.data))
        }
      })
      .catch(error => {
        console.error(error)
        dispatch(actions.loginFail({ Code: '-99', Message: 'request' }))
      })
  },

  registerStart: () => {
    return { type: actionTypes.REGISTER_START }
  },
  registerFail: (payload, label) => {
    return { type: actionTypes.REGISTER_FAIL, payload, label }
  },
  registerSuccess: (payload, label) => {
    return { type: actionTypes.REGISTER_SUCCESS, payload, label }
  },

  register: obj => dispatch => {
    dispatch(actions.registerStart())
    const qs = querystring()
    const language = qs.l ? mapLanguageCode(qs.l) : 'en-US'
    const data = {
      UserName: obj.email,
      PartnerKey: obj.partnerKey,
      FirstName: obj.firstName,
      LastName: obj.lastName,
      ...(obj.nric !== '' ? { PartnerUserKey: obj.nric } : {}),
      Password: obj.password,
      ConfirmPassword: obj.confirmPassword,
      LanguageCode: language,
      marketing: obj.showMarketingConsent ? obj.marketing : null,
      privacy: obj.privacy,
      Referer: '',
      workflow: obj.workflow,
      source: obj.source,
      Href: window.location.href,
      ...(obj.code !== '' ? { UserInvitationCode: obj.code } : {}),
      isTest: obj.isTest,
      ...(obj.providerCode ? { registerProviderCode: obj.providerCode } : {}),
      ...(obj.redemptionCode ? { RedemptionCode: obj.redemptionCode } : {}),
      ...(obj.entityKey ? { entityKey: obj.entityKey } : {})

    }

    api
      .post('/account/register', data, true)
      .then(response => {
        if (response && response.data && response.data.Code) {
          dispatch(actions.registerFail(response.data, obj.label))
        } else {
          dispatch(actions.registerSuccess(response.data, obj.label))

          api
            .get(getHost().apiCheckLoggedInUrl)
            .then(response => {
              if (response.data.RedirectUrl) {

                // if(window.location.href.includes("@") || window.location.href.includes("redemption_code")) {
                //KFAD-1602  removing refere, so email or redemption code wont be sent to third party sites after redirect.
                let meta = document.createElement('meta');
                meta.name = "referrer";
                meta.content = "no-referrer";
                document.getElementsByTagName('head')[0].appendChild(meta);
                //}

                window.location.replace(response.data.RedirectUrl)
              }
            })
        }
      })
      .catch(error => {
        console.error(error)
        dispatch(actions.registerFail(error, obj.label))
      })
  },

  recoverStart: () => {
    return { type: actionTypes.RECOVER_START }
  },
  recoverFail: payload => {
    return { type: actionTypes.RECOVER_FAIL, payload }
  },
  recoverSuccess: payload => {
    return { type: actionTypes.RECOVER_SUCCESS, payload }
  },

  recover: (email, onSuccess) => dispatch => {
    dispatch(actions.recoverStart())
    const data = {
      UserName: email,
    }

    api
      .post('/account/forgotpassword', data, true)
      .then(response => {
        if (response && response.data && response.data.code && response.data.code === 1) {
          dispatch(actions.recoverSuccess(response.data))
          onSuccess()
        } else {
          dispatch(actions.recoverFail(response.data))
        }
      })
      .catch(error => {
        console.error(error)
        dispatch(actions.recoverFail(error))
      })
  },

  getRecoverTokenStart: () => {
    return { type: actionTypes.GET_RECOVER_TOKEN_START }
  },
  getRecoverTokenFail: payload => {
    return { type: actionTypes.GET_RECOVER_TOKEN_FAIL, payload }
  },
  getRecoverTokenSuccess: payload => {
    return { type: actionTypes.GET_RECOVER_TOKEN_SUCCESS, payload }
  },

  getRecoverToken: token => dispatch => {
    dispatch(actions.getRecoverTokenStart())
    api
      .get(`/account/changepassword?token=${token}`, true)
      .then(response => {
        dispatch(actions.getRecoverTokenSuccess({ ...response.data, token }))
      })
      .catch(error => {
        console.error(error)
        dispatch(actions.getRecoverTokenFail(error))
      })
  },

  resetPasswordStart: () => {
    return { type: actionTypes.RESETPASSWORD_START }
  },
  resetPasswordFail: payload => {
    return { type: actionTypes.RESETPASSWORD_FAIL, payload }
  },
  resetPasswordSuccess: payload => {
    return { type: actionTypes.RESETPASSWORD_SUCCESS, payload }
  },

  resetPassword: (token, password, mode) => dispatch => {
    dispatch(actions.resetPasswordStart())

    const data = {
      token: token,
      Password: password,
      ConfirmPassword: password,
    }

    const resetQuery = mode === 'isReset' ? '?isReset=1' : '';
    api
      .post(`/account/changepassword${resetQuery}`, data, true)
      .then(response => {
        dispatch(actions.resetPasswordSuccess(response.data))
      })
      .catch(error => {
        console.error(error)
        dispatch(actions.resetPasswordFail(error.response.data))
      })
  },

  checkSsoDomainStart: () => {
    return { type: actionTypes.CHECK_SSO_DOMAIN_START }
  },
  checkSsoDomainFail: payload => {
    return { type: actionTypes.CHECK_SSO_DOMAIN_FAIL, payload }
  },
  checkSsoDomainSuccess: payload => {
    return { type: actionTypes.CHECK_SSO_DOMAIN_SUCCESS, payload }
  },

  checkSsoDomain: (domain) => dispatch => {
    dispatch(actions.checkSsoDomainStart())

    const data = {
      domain
    }

    api
      .post(`/account/checkssodomain`, data, true)
      .then(response => {
        if (response.data && response.data.RedirectUrl) {
          dispatch(actions.checkSsoDomainSuccess(response.data))
        } else {
          dispatch(actions.checkSsoDomainFail(response.data))
        }
      })
      .catch(error => {
        dispatch(actions.checkSsoDomainFail({ Code: '-99', Message: 'request' }))
      })
  },

  openPartnerSelect: () => {
    return { type: actionTypes.PARTNER_SELECT_OPEN }
  },
  closePartnerSelect: () => {
    return { type: actionTypes.PARTNER_SELECT_CLOSE }
  },
}

// Reducer
const initialState = {
  isInterviewApp: false,
  userKey: null,
  partnerKeys: null,
  partnerKey: null,
  error: null,
  loading: false,
  authLoading: false,
  sessionExpired: false,
  userInitiated: false,
  isAuthenticated: false,
  authRedirectPath: '/',
  user: null,
  products: null,
  purchState: null,
  isTest: false,
  isAvailableInCountry: true,
  loginError: null,
  registerError: null,
  recoverError: null,
  recoverTokenData: null,
  resetPasswordData: null,
  loginData: null,
  isConsentOpen: false,
  checkSSORedirectUrl: null,
  checkSSOloading: false,
  checkSSOPartnerKey: null,
  checkSSOOriginalPartnerKey: null,
  isSelectPartnerOpen: false,
  isAccountStatusModalOpen: false
}

export let partnerProducts = {};
export let loggedInUser = {};


const filterProducts = (products, partnerKey) => {
  return products.filter(p => p.PartnerKey === partnerKey)
}

export default (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.SET_LOADING:
      return {
        ...state,
        authLoading: true
      }

    case actionTypes.ROLE_CHANGE_START:
      return {
        ...state,
        loading: true
      }
    case actionTypes.ROLE_CHANGE_FAIL:
      return {
        ...state,
        loading: false
      }
    case actionTypes.ROLE_CHANGE_SUCCESS:
      const redirectUrl = action.result.redirectUrl
      window.location.replace(redirectUrl);


      // if(redirectUrl.indexOf('client') === -1 ) {
      //   window.location.replace(redirectUrl);
      // }

      return {
        ...state,
        loading: false,
        name: action.result.role,
      }
    case actionTypes.CHECK_SSO_START:
      return {
        ...state,
        checkSSOloading: true,
        checkSSORedirectUrl: null,
      }
    case actionTypes.CHECK_SSO_ERROR:
      return {
        ...state,
        checkSSOloading: false,
        checkSSORedirectUrl: null,
      }
    case actionTypes.CHECK_SSO_SUCCESS:
      let checkSSORedirectUrl = action.result && action.result.IsSSO && action.result.RedirectUrl ? action.result.RedirectUrl : null
      let checkSSOPartnerKey = action.result && action.result.PartnerKey ? action.result.PartnerKey : state.checkSSOOriginalPartnerKey
      return {
        ...state,
        checkSSOloading: false,
        checkSSORedirectUrl,
        checkSSOPartnerKey: checkSSOPartnerKey,
        checkSSOOriginalPartnerKey: !state.checkSSOOriginalPartnerKey ? action.partnerKey : state.checkSSOOriginalPartnerKey
      }
    case actionTypes.CHECK_FOR_MOBILE_APP:
      console.log('**** Bug Fix update... ***')
      console.log('* CHECK_FOR_MOBILE_APP *, state: ', state)
      if (state.isInterviewApp) return { ...state }

      const query = querystring()
      console.log('* CHECK_FOR_MOBILE_APP *, query: ', query, query && query.redirect && query.redirect.includes('context=app'))

      return {
        ...state,
        isInterviewApp: query && query.context ? query.context === 'app' : query && query.redirect && query.redirect.includes('context=app') ? true : false,
      }
    case actionTypes.CONSENT_UPDATE_START:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.CONSENT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.CONSENT_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.CONSENT_OPEN:
      return {
        ...state,
        isConsentOpen: true,
      }
    case actionTypes.CONSENT_CLOSE:
      return {
        ...state,
        isConsentOpen: false,
      }
    case actionTypes.TEMP_CONSENT_UPDATE:
      return {
        ...state,
        user: {
          ...state.user,
          tempConsent: action.data
        }
      }
    case actionTypes.AUTH_START:
      return updateObject(state, {
        error: null,
        loading: true,
        authLoading: true,
        isAuthenticated: false,
      })
    case actionTypes.FETCHUSER_SUCCESS:
      //console.log('GOT USER ', action.data)
      loggedInUser = action.data
      return updateObject(state, {
        user: {
          ...action.data,
          goalsRoles: [],
          avatarPath: action.data.UserKey + '?v=j' + new Date(),
        },
      })
    case actionTypes.FETCHCONTENT_SUCCESS:
      const product = filterProducts(action.data.Products, action.partnerKey)
      partnerProducts = product
      return updateObject(state, {
        content: {
          ...action.data, Products: product
        },
      })
    case actionTypes.FETCHPRODS_SUCCESS:
      const prods = action.data
      const { user } = state
      const { purchaseState } = action
      setUserAnalytics(user, prods, action.settings.trackUserAnalytics, purchaseState.hasSubscription)
      return updateObject(state, {
        products: action.data,
        purchState: purchaseState,
        loading: false,
        authLoading: false,
      })
    case actionTypes.UPLOAD_START_STOP: {
      const uploading = action.payload
      const { purchState } = state
      const hasResume = uploading ? purchState.resume.hasResume : true
      return purchState
        ? {
          ...state,
          purchState: {
            ...purchState,
            hasResume,
            uploading,
          },
        }
        : state
    }
    case actionTypes.UPDATE_PURCHSTATE_PAYCHECK: {
      const purchState = state.purchState
      const paycheck = purchState.paycheck
      const data = action.payload
      return purchState
        ? {
          ...state,
          purchState: {
            ...purchState,
            paycheck: {
              ...paycheck,
              questionData: { ...data },
            },
          },
        }
        : state
    }

    case actionTypes.UPDATE_PURCHSTATE_TIMEZONE: {
      const purchState = state.purchState
      return purchState
        ? {
          ...state,
          purchState: {
            ...purchState,
            timezone: action.data,
          },
        }
        : state
    }

    case actionTypes.UPDATE_PURCHSTATE_RESUME: {
      const purchState = state.purchState
      let updatedResumes = purchState.resumes.map(r => {
        if (action.item === 'resumeUrl') {
          return {
            ...r,
            Url: action.data,
          }
        } else {
          return {
            ...r,
            Created: action.data,
          }
        }
      })
      updatedResumes = updatedResumes.length > 0 ? updatedResumes : [{
        Url: action.data
      }]
      return purchState
        ? {
          ...state,
          purchState: {
            ...purchState,
            resume: {
              ...purchState.resume,
              [action.item]: action.data,
            },
            resumes: updatedResumes
          },
        }
        : state
    }

    case actionTypes.UPDATE_NAVLINK_SELECTIONS: {
      return state
    }
    case actionTypes.UPDATE_STEPS:
      const theUser = state.user
      //console.log(' IN AUTH UPDATE STEPS WITH ACTION DATA OF ', action.data)
      let products = state.products
      //console.log('AND PRODUCTS ARE ', products)
      const ProductToUpdate = action.data && action.data.PurchaseBundleCode ? action.data.PurchaseBundleCode : action.data.purchaseBundleCode
      const StepsToUpdate = action.data && action.data.Steps ? action.data.Steps : action.data.steps
      const newTeam = action.data && action.data.team ? action.data.team : []
      if (products) {
        R.forEach(x => {
          if (x.PurchaseBundleCode === ProductToUpdate) {
            // console.log('FOUND THE ITEM TO UPDATE IN UPDATE STEPS', x)
            // console.log('AND THE STEPS TO BE UPDATED ARE ', StepsToUpdate)
            x.details.Steps = StepsToUpdate
            x.details.team = newTeam
          }
        }, products)
      }
      const thePurchaseState = getPurchaseState(products, theUser, action.settings, state.content, action.attributes)
      //console.log('JUST UPDATED PURCHSTATE AND NOW ITS ', thePurchaseState)
      return updateObject(state, {
        products: products,
        purchState: thePurchaseState,
      })
    case actionTypes.EMAIL_VERIFICATION_SUCCESS: {
      const { isVerified } = action.payload
      //console.log('lai - state', state)
      let products2 = state.products
      const user = state.user ? state.user : undefined
      const newUser = { ...user, isVerified }

      const result = user
        ? {
          ...state,
          user: { ...user, isVerified },
        }
        : state

      if (action.settings.purchStateV2 !== true) {
        const thePurchaseState = getPurchaseState(products2, newUser, action.settings, state.content, action.attributes)
        result.purchState = thePurchaseState
      }

      return result
    }
    // case actionTypes.NAV_TO_APPOINTMENT_UPDATE_STATE: {
    //   let itemKey = action.payload
    //   //let button = action.button
    //   const showPreBuy = itemKey === 'prebuy'
    //   let thisOneSelected = false
    //   let NoItemKey = itemKey === -1
    //   let { purchState } = state
    //   let newCoaching = purchState.coaching
    //   if (purchState.coaching.defaultCoachingFeature !== -1 && itemKey === -1) {
    //     itemKey = purchState.coaching.defaultCoachingFeature
    //     NoItemKey = false
    //   }
    //   newCoaching.currentSession = {}
    //   newCoaching.currentGroup = {}

    //   if (newCoaching && !showPreBuy) {

    //     newCoaching.career.appointments = R.map(item => {
    //       thisOneSelected = item.purchasedItemKey === itemKey ? true : thisOneSelected
    //       if (item.purchasedItemKey === itemKey || NoItemKey) {
    //         NoItemKey = false
    //         newCoaching.currentSession = item
    //         newCoaching.currentGroup = newCoaching.career
    //       }
    //       return {
    //         ...item,
    //         selected: item.purchasedItemKey === itemKey ? true : false,
    //       }
    //     }, purchState.coaching.career.appointments)

    //     newCoaching.career.selected = thisOneSelected
    //     newCoaching.career.navKey =
    //       purchState.coaching && purchState.coaching.career.appointments.length > 0 ? purchState.coaching.career.appointments[0].purchasedItemKey : 'prebuy'

    //     thisOneSelected = false
    //     newCoaching.paycheck.appointments = R.map(item => {
    //       thisOneSelected = item.purchasedItemKey === itemKey ? true : thisOneSelected
    //       if (item.purchasedItemKey === itemKey || NoItemKey) {
    //         NoItemKey = false
    //         newCoaching.currentSession = item
    //         newCoaching.currentGroup = newCoaching.paycheck
    //       }
    //       return {
    //         ...item,
    //         selected: item.purchasedItemKey === itemKey ? true : false,
    //       }
    //     }, purchState.coaching.paycheck.appointments)
    //     newCoaching.paycheck.selected = thisOneSelected
    //     newCoaching.paycheck.navKey =
    //       purchState.coaching && purchState.coaching.paycheck.appointments.length > 0 ? purchState.coaching.paycheck.appointments[0].purchasedItemKey : 'prebuy'


    //       thisOneSelected = false
    //       newCoaching.feedbackCoach.appointments = R.map(item => {
    //         thisOneSelected = item.purchasedItemKey === itemKey ? true : thisOneSelected
    //         if (item.purchasedItemKey === itemKey || NoItemKey) {
    //           NoItemKey = false
    //           newCoaching.currentSession = item
    //           newCoaching.currentGroup = newCoaching.feedbackCoach
    //         }
    //         return {
    //           ...item,
    //           selected: item.purchasedItemKey === itemKey ? true : false,
    //         }
    //       }, purchState.coaching.feedbackCoach.appointments)
    //       newCoaching.feedbackCoach.selected = thisOneSelected
    //       newCoaching.feedbackCoach.navKey =
    //         purchState.coaching && purchState.coaching.feedbackCoach.appointments.length > 0 ? purchState.coaching.feedbackCoach.appointments[0].purchasedItemKey : 'prebuy'




    //     thisOneSelected = false
    //     newCoaching.resume.appointments = R.map(item => {
    //       thisOneSelected = item.purchasedItemKey === itemKey ? true : thisOneSelected
    //       if (item.purchasedItemKey === itemKey || NoItemKey) {
    //         NoItemKey = false
    //         newCoaching.currentSession = item
    //         newCoaching.currentGroup = newCoaching.resume
    //       }
    //       return {
    //         ...item,
    //         selected: item.purchasedItemKey === itemKey ? true : false,
    //       }
    //     }, purchState.coaching.resume.appointments)
    //     newCoaching.resume.selected = thisOneSelected
    //     newCoaching.resume.navKey =
    //       purchState.coaching && purchState.coaching.resume.appointments.length > 0 ? purchState.coaching.resume.appointments[0].purchasedItemKey : 'prebuy'
    //     thisOneSelected = false
    //     newCoaching.interview.appointments = R.map(item => {
    //       thisOneSelected = item.purchasedItemKey === itemKey ? true : thisOneSelected
    //       if (item.purchasedItemKey === itemKey || NoItemKey) {
    //         NoItemKey = false
    //         newCoaching.currentSession = item
    //         newCoaching.currentGroup = newCoaching.interview
    //       }
    //       return {
    //         ...item,
    //         selected: item.purchasedItemKey === itemKey ? true : false,
    //       }
    //     }, purchState.coaching.interview.appointments)
    //     newCoaching.interview.selected = thisOneSelected
    //     newCoaching.interview.navKey =
    //       purchState.coaching && purchState.coaching.interview.appointments.length > 0 ? purchState.coaching.interview.appointments[0].purchasedItemKey : 'prebuy'
    //     thisOneSelected = false

    //     newCoaching.placement.appointments = R.map(item => {
    //       thisOneSelected = item.purchasedItemKey === itemKey ? true : thisOneSelected
    //       if (item.purchasedItemKey === itemKey || NoItemKey) {
    //         NoItemKey = false
    //         newCoaching.currentSession = item
    //         newCoaching.currentGroup = newCoaching.placement
    //       }
    //       return {
    //         ...item,
    //         selected: item.purchasedItemKey === itemKey ? true : false,
    //       }
    //     }, purchState.coaching.placement.appointments)


    //     newCoaching.placement.selected = thisOneSelected
    //     newCoaching.placement.navKey =
    //       purchState.coaching && purchState.coaching.placement.appointments.length > 0 ? purchState.coaching.placement.appointments[0].purchasedItemKey : 'prebuy'

    //     thisOneSelected = false

    //     // newCoaching.westpoint.appointments = R.map(item => {
    //     //   thisOneSelected = item.purchasedItemKey === itemKey ? true : thisOneSelected
    //     //   if (item.purchasedItemKey === itemKey || NoItemKey) {
    //     //     NoItemKey = false
    //     //     newCoaching.currentSession = item
    //     //     newCoaching.currentGroup = newCoaching.westpoint
    //     //   }
    //     //   return {
    //     //     ...item,
    //     //     selected: item.purchasedItemKey === itemKey ? true : false,
    //     //   }
    //     // }, purchState.coaching.westpoint.appointments)
    //     // newCoaching.westpoint.selected = thisOneSelected
    //     // newCoaching.westpoint.navKey =
    //     //   purchState.coaching && purchState.coaching.westpoint.appointments.length > 0 ? purchState.coaching.westpoint.appointments[0].purchasedItemKey : 'prebuy'


    //     newCoaching.partnerCoach.selected = thisOneSelected
    //     newCoaching.partnerCoach.navKey =
    //       purchState.coaching && purchState.coaching.partnerCoach.appointments.length > 0 ? purchState.coaching.partnerCoach.appointments[0].purchasedItemKey : 'prebuy'

    //       newCoaching.partnerCoach.appointments = R.map(item => {
    //         thisOneSelected = item.purchasedItemKey === itemKey ? true : thisOneSelected
    //         if (item.purchasedItemKey === itemKey || NoItemKey) {
    //           NoItemKey = false
    //           newCoaching.currentSession = item
    //           newCoaching.currentGroup = newCoaching.partnerCoach
    //         }
    //         return {
    //           ...item,
    //           selected: item.purchasedItemKey === itemKey ? true : false,
    //         }
    //       }, purchState.coaching.partnerCoach.appointments)


    //       newCoaching.partnerCoach.selected = thisOneSelected
    //       newCoaching.partnerCoach.navKey =
    //         purchState.coaching && purchState.coaching.partnerCoach.appointments.length > 0 ? purchState.coaching.partnerCoach.appointments[0].purchasedItemKey : 'prebuy'

    //   } else {
    //     newCoaching.interview.selected = false
    //     newCoaching.resume.selected = false
    //     newCoaching.paycheck.selected = false
    //     newCoaching.career.selected = false
    //     newCoaching.placement.selected = false
    //     //newCoaching.westpoint.selected = false
    //     newCoaching.partnerCoach.selected = false
    //     newCoaching.feedbackCoach.selected = false

    //   }

    //   if (
    //     !(
    //       newCoaching.interview.selected ||
    //       newCoaching.resume.selected ||
    //       newCoaching.paycheck.selected ||
    //       newCoaching.career.selected ||
    //       newCoaching.placement.selected ||
    //       //newCoaching.westpoint.selected ||
    //       newCoaching.partnerCoach.selected ||
    //       newCoaching.feedbackCoach.selected

    //     )
    //   ) {
    //     //console.log('AND THE NAV ACTION BUTTON IS ', action)
    //     switch (action.button) {

    //       case 'feedbackCoach':
    //         newCoaching.feedbackCoach.selected = true
    //         newCoaching.currentGroup = newCoaching.feedbackCoach
    //         break

    //       case 'career':
    //         newCoaching.career.selected = true
    //         newCoaching.currentGroup = newCoaching.career
    //         break
    //       case 'paycheck':
    //         console.log('SETTING CURRENT GROUP TO PAYCHECK', newCoaching.paycheck)
    //         newCoaching.paycheck.selected = true
    //         newCoaching.currentGroup = newCoaching.paycheck
    //         break
    //       case 'resume':
    //         newCoaching.resume.selected = true
    //         newCoaching.currentGroup = newCoaching.resume
    //         break
    //       case 'placement':
    //         newCoaching.placement.selected = true
    //         newCoaching.currentGroup = newCoaching.placement
    //         break
    //       // case 'westpoint':
    //       //   newCoaching.westpoint.selected = true
    //       //   newCoaching.currentGroup = newCoaching.westpoint
    //       //   break
    //       case 'partnerCoach':
    //         newCoaching.partnerCoach.selected = true
    //         newCoaching.currentGroup = newCoaching.partnerCoach
    //         break
    //       default:
    //         newCoaching.interview.selected = true
    //         newCoaching.currentGroup = newCoaching.interview
    //     }
    //   }
    //   return purchState
    //     ? {
    //         ...state,
    //         purchState: { ...purchState, coaching: newCoaching },
    //       }
    //     : state
    // }

    case actionTypes.AUTH_SUCCESS:
      const partnerKey = action.partnerKey || 'SVOoQJaeX0eLboUOU0wSoQtt';

      return updateObject(state, {
        userKey: action.userKey,
        partnerKeys: partnerKey, //don't use!!! backwards compatibility
        partnerKey: partnerKey,
        name: action.name,
        role: action.role,
        isAuthenticated: true,
        authRedirectPath: '/' + action.userKey,
        error: null,
        isTest: action.isTest ? action.isTest : state.isTest,
        isAvailableInCountry: action.isAvailableInCountry,
        entityContext: action.entityContext
      })

    case actionTypes.SESSION_EXPIRED:
    case actionTypes.AUTH_FAIL:
      return updateObject(state, {
        error: action.error,
        loading: false,
        authLoading: false,
        isAuthenticated: false,
        sessionExpired: true,
      })

    case actionTypes.AUTH_LOGOUT:
      return updateObject(state, {
        userKey: null,
        authLoading: false,
        userInitLogout: true,
        isAuthenticated: false,
      })

    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return updateObject(state, {
        authLoading: false,
        authRedirectPath: action.path
      })

    case actionTypes.LOGIN_START:
      return updateObject(state, {
        loading: true,
      })
    case actionTypes.LOGIN_SUCCESS:
      logEvent('event', analyticsTypeConstants.user, analyticsActionConstants.authLoginSuccess, {
        itemKey: action.payload.UserKey
      });

      return updateObject(state, {
        loginError: null,
        //loading: false, //To avoid login flicker
        loginData: action.payload,
      })
    case actionTypes.LOGIN_FAIL:
      return updateObject(state, {
        loginError: action.payload,
        loading: false,
      })

    case actionTypes.REGISTER_FAIL:
      logEvent('event', analyticsTypeConstants.user, analyticsActionConstants.authRegisterError, {
        label: action.label
      })
      return updateObject(state, {
        registerError: action.payload,
        loading: false,
      })
    case actionTypes.REGISTER_START:
      return updateObject(state, {
        loading: true,
      })
    case actionTypes.REGISTER_SUCCESS:
      logEvent('event', analyticsTypeConstants.user, analyticsActionConstants.authRegisterSurccess, {
        label: action.label,
        itemKey: action.payload.UserKey
      })
      return updateObject(state, {
        loginError: null,
        // loading: false, //To avoid register flicker
      })

    case actionTypes.RECOVER_FAIL:
      logEvent('event', analyticsTypeConstants.user, analyticsActionConstants.authRecoverError)
      return updateObject(state, {
        recoverError: action.payload,
        loading: false,
      })
    case actionTypes.RECOVER_START:
      return updateObject(state, {
        loading: true,
      })
    case actionTypes.RECOVER_SUCCESS:
      logEvent('event', analyticsTypeConstants.user, analyticsActionConstants.authRecoverSuccess)
      return updateObject(state, {
        recoverError: null,
        loading: false,
      })

    case actionTypes.GET_RECOVER_TOKEN_FAIL:
      return updateObject(state, {
        recoverTokenData: action.payload,
        loading: false,
      })
    case actionTypes.GET_RECOVER_TOKEN_START:
      return updateObject(state, {
        loading: true,
      })
    case actionTypes.GET_RECOVER_TOKEN_SUCCESS:
      return updateObject(state, {
        recoverTokenData: action.payload,
        loading: false,
      })
    case actionTypes.RESETPASSWORD_START:
      return updateObject(state, {
        resetPasswordData: null,
        loading: true,
      })
    case actionTypes.RESETPASSWORD_SUCCESS:
      return updateObject(state, {
        resetPasswordData: action.payload,
        loading: false,
      })
    case actionTypes.RESETPASSWORD_FAIL:
      return updateObject(state, {
        resetPasswordData: action.payload,
        loading: false,
      })
    case actionTypes.UPDATE_USER:
      return { ...state, user: { ...state.user, ...action.payload.user } }

    case actionTypes.UPDATE_USER_TIMEZONE:
      return { ...state, user: { ...state.user, Timezone: action.payload.timezone } }

    case actionTypes.CHECK_SSO_DOMAIN_START:
      return updateObject(state, {
        loading: true,
      })

    case actionTypes.CHECK_SSO_DOMAIN_FAIL:
      return updateObject(state, {
        ssoCheckError: action.payload,
        loading: false,
      })

    case actionTypes.CHECK_SSO_DOMAIN_SUCCESS:
      return updateObject(state, {
        ssoDomainData: action.payload,
        loading: false,
      })

    case actionTypes.PARTNER_SELECT_OPEN:
      return {
        ...state,
        isSelectPartnerOpen: true,
      }
    case actionTypes.PARTNER_SELECT_CLOSE:
      return {
        ...state,
        isSelectPartnerOpen: false,
      }
    case actionTypes.ACCOUNT_STATUS_MESSAGE_MODAL_OPEN:
      return {
        ...state,
        isAccountStatusModalOpen: true,
      }

    default:
      return state
  }
}
