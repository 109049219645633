import axios from 'axios';
import humps from 'humps';

import { api, getHost } from '../../shared/utility'
import { actions as goalActions } from './goals'
export const actionTypes = {
  GOT_USER_ATTRIBUTES: 'GOT_USER_ATTRIBUTES',
  GOT_PARTNER_ATTRIBUTES: 'GOT_PARTNER_ATTRIBUTES',
}

// Action Creators



export const actions = {
  getUserAttributes: ForceProfileComplete => (dispatch, getState) => {
    const { auth } = getState()

    const profileComplete = ForceProfileComplete ? true : auth && auth.purchState ? auth.purchState.profile.isComplete : false
    let path = `/user/attributes`
    return api
      .get(path, true)
      .then(response => {
        return humps.camelizeKeys(response.data)
      })
      .then(data => {
        dispatch(actions.gotUserAttributes(data, profileComplete))
      })
      .catch(error => {})
  },
  gotUserAttributes: (rawPayload, forceProfileComplete) => (dispatch, getState) => {
    const payload = humps.camelizeKeys(rawPayload);
    const {auth} = getState()
    const profileComplete = forceProfileComplete ? true : auth && auth.purchState ? auth.purchState.profile.isComplete: false

    if (payload.cN && payload.cN.values) {
      dispatch(goalActions.gotUserNavRoles(payload.cN.values, profileComplete))
    }

    dispatch({ type: actionTypes.GOT_USER_ATTRIBUTES, payload })
  },
  gotPartnerAttributes: data => dispatch => {
    const payload = humps.camelizeKeys(data);
    dispatch(goalActions.gotNavigatorRoles(payload.cN))
    //todo add these values into the /user GET call for perf
    if (payload.gC && payload.gC.values) {
      dispatch(dispatch(goalActions.goalCategoriesLoaded(payload.gC.values)))
    }
    dispatch({ type: actionTypes.GOT_PARTNER_ATTRIBUTES, payload })
  },
  saveUserAttributes: payload => (dispatch, getState) => {
    let path = `${getHost().apiBaseUrl}/user/attributes`
    let thePayload = []
    payload.forEach(attribute => {
      thePayload.push({
        ADefKey: attribute.aDefKey,
        AVKey: attribute.aVKey,
        PartnerKey: attribute.partnerKey,
        Value: attribute.value,
      })
    })
    const newPayload = { attributes: thePayload }
    return axios(path, {
      method: 'post',
      data: newPayload,
      withCredentials: true,
    })
      .then(data => {})
      .catch(error => {
        console.error(error)
      })
  },
}

// Reducer

const defaultState = {
  partnerAttributes: {},
  userAttributes: {},
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GOT_PARTNER_ATTRIBUTES:
      return {
        ...state,
        partnerAttributes: action.payload,
      }
    case actionTypes.GOT_USER_ATTRIBUTES:
      return {
        ...state,
        userAttributes: action.payload,
      }
    default:
      return state
  }
}
