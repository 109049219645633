import axios from 'axios'
import cookie from 'cookie'
import { getHost } from '../shared/utility';

function getToken() {
  const cookies = cookie.parse(document.cookie)
  const csrfToken = cookies.kf_csrf;
  return csrfToken;
}

export async function csrfRequestInterceptor(config) {
  // explicitly and quickly bypass csrf check
  if (config.method === 'get') {
    return config;
  }

  let csrfToken = getToken();

  if (!csrfToken) {
    await axios(getHost().apiCheckLoggedInUrl, { withCredentials: true })
    // await (new Promise((resolve) => setTimeout(resolve, 5000)))
    csrfToken = getToken();
  }
  
  if (config.method === 'post') {
    config.headers.post['x-csrf-token'] = csrfToken;
  } else if (config.method === 'put') {
    config.headers.put['x-csrf-token'] = csrfToken;
  } else if (config.method === 'delete') {
    config.headers.delete['x-csrf-token'] = csrfToken;
  }

  return config
}