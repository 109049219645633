import React from "react"
import styled from 'styled-components'
import { mobile } from '../../styles/media-queries'


const StyledImg = styled.img`
  height: 100px;
  margin: 10px;

  &.footer {
    margin: 0 0 0 -10px;
    height: 100px!important;
  }
  &.header {
    margin: 0;
    height: 63px
  }

  @media  ${mobile} {
    height: 35px;

    &.footer {
      margin: 0;
      height: 100px!important;
    }
    &.header {
      margin: 0;
      height: 50px
    }
}`


export default ({type = "", dark = false}) => (
  <StyledImg  className={type}
    src={`/static/images/logos/${dark ? 'JJLogo-black' : 'JJLogo-black'}.svg`}
  />
)