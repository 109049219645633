const roles = {
  // widget roles
  button: 'button',
  checkbox: 'checkbox',
  gridcell: 'gridcell',
  link: 'link',
  menuitem: 'menuitem',
  menuitemcheckbox: 'menuitemcheckbox',
  menuitemradio: 'menuitemradio',
  option: 'option',
  progressbar: 'progressbar',
  radio: 'radio',
  scrollbar: 'scrollbar',
  searchbox: 'searchbox',
  separator: 'separator',
  slider: 'slider',
  spinbutton: 'spinbutton',
  switch: 'switch',
  tab: 'tab',
  tabpanel: 'tabpanel',
  textbox: 'textbox',
  treeitem: 'treeitem',

  // composite roles
  combobox: 'combobox',
  grid: 'grid',
  listbox: 'listbox',
  menu: 'menu',
  menubar: 'menubar',
  radiogroup: 'radiogroup',
  tablist: 'tablist',
  tree: 'tree',
  treegrid: 'treegrid',

  // document structure roles
  application: 'application',
  article: 'article',
  cell: 'cell',
  columnheader: 'columnheader',
  definition: 'definition',
  directory: 'directory',
  document: 'document',
  feed: 'feed',
  figure: 'figure',
  group: 'group',
  heading: 'heading',
  img: 'img',
  list: 'list',
  listitem: 'listitem',
  math: 'math',
  none: 'none',
  note: 'note',
  presentation: 'presentation',
  row: 'row',
  rowgroup: 'rowgroup',
  rowheader: 'rowheader',
  table: 'table',
  term: 'term',
  toolbar: 'toolbar',
  tooltip: 'tooltip',

  // landmark roles
  banner: 'banner',
  complementary: 'complementary',
  contentinfo: 'contentinfo',
  form: 'form',
  main: 'main',
  navigation: 'navigation',
  region: 'region',
  search: 'search',

  // live region roles
  alert: 'alert',
  log: 'log',
  marquee: 'marquee',
  status: 'status',
  timer: 'timer',

  // window roles
  alertdialog: 'alertdialog',
  dialog: 'dialog'
}

const labels = {
  mainNavigation: {key: 'Global.Aria.Label.MainNavigation', label: 'main navigation'},
  mainMenu: {key: 'Global.Aria.Label.MainMenu', label: 'main menu'},
  linkToInsightsPage: {key: 'Global.Aria.Label.LinkToInsightsPage', label: 'Insights page'},
  googlePlay: {key: 'Global.Aria.Label.GooglePlay', label: 'Google Play'},
  appleAppStore: {key: 'Global.Aria.Label.AppleAppStore', label: 'Apple App Store'},
  interviewPrep: {key: 'Global.Aria.Label.InterviewApp', label: 'Interview Prep. Practice makes perfect. Turn your phone into a powerful interview feedback tool. Practice in private, and see how your words and tone might be interpreted by an interviewer.'},
  clickToPlayVideo: {key: 'Global.Aria.Label.CickToPlayVideo', label: 'Click to play video'},
  avatar: {key: 'Global.Aria.Label.Avatar', label: 'Avatar'}
}

const ids = {
  mainContent: 'KfaMainContentId',
}

export const aria = {
  roles,
  labels,
  ids,
}
