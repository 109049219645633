import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { querystring, isValidRedirectUrl } from '../../shared/utility'

const UnauthenticatedRoute = ({ component: C, props: cProps, ...rest }) => {
  //console.log('UnauthenticatedRoute: ', cProps)
  let redirect = querystring('redirect')
  if (cProps.isAuthenticated && redirect && redirect.indexOf('http') !== -1) {
    if (isValidRedirectUrl(redirect)) {
      window.location.replace(redirect)
      redirect = null
      return null;
    }
  }

  return (
    <Route
      {...rest}
      render={props => {
        const { location, match, history } = props;
        if (!window.kfadvance) window.kfadvance = { location, match, history };

        if (cProps.isAuthenticated) {
          return <Redirect to={redirect === '' || redirect === null ? '/' : redirect} />
        }

        if (cProps.content.loaded || !cProps.content.loading) {
          return <C {...props} {...cProps} />
        }

        return null;
      }}
    />
  )
}

export default UnauthenticatedRoute;