import { commonMobile } from './Mobile.Common'

import BgImgHome from '../../assets/images/home/heroSofi.jpg'
import BgImgAssessment from '../../assets/images/asssessment/hero.jpg'
import BgImgResume from '../../assets/images/resume/hero.jpg'
import BgImgChat from '../../assets/images/chat/hero.jpg'
import BgImgFQA from '../../assets/images/faq/hero.jpg'
import BgImgAbout from '../../assets/images/about/hero.jpg'
import ImgAnalysis from '../../assets/images/about/analysis.svg'
import ImgExperts from '../../assets/images/about/experts.svg'
import ImgAboutLaunch from '../../assets/images/about/launch.svg'
import ImgWallet from '../../assets/images/about/wallet.svg'
import BgImgCoaching from '../../assets/images/coaching/hero.jpg'
import BgImgInterview from '../../assets/images/interview/hero.jpg'
import BgImgBestPractices from '../../assets/images/interview/foundationHero.jpg'
import BgImgProfile from '../../assets/images/profile/hero.jpg'
import BgImgPurchase from '../../assets/images/purchase/hero.jpg'
import BgImgJobs from '../../assets/images/jobs/hero.jpg'
import BgImgServices from '../../assets/images/services/hero.jpg'
import BgImgPhilosophyHero from '../../assets/images/resume/philosophy_heroSofi.jpg'
import BgImgTipsHero from '../../assets/images/resume/tips_heroSofi.jpg'
import BgImgDosHero from '../../assets/images/resume/dos_heroSofi.jpg'
import accountPrefHero from '../../assets/images/account/accountPref_hero.jpg'
//import InterviewAppImage from '../../assets/images/interapp/interview_app.jpg'
//import InterviewApp2Image from '../../assets/images/interapp/interview_app2.jpg'
//import InterviewAppImage from '../../assets/images/interapp/InterviewPrep_Ad_blue.png'
import InterviewAppImage from '../../assets/images/interapp/InterviewPrep_Ad.png'
import ButtonAppStore from '../../assets/images/interapp/Button_AppStore_L.png'
import ButtonGooglePlay from '../../assets/images/interapp/Button_GooglePlay_L.png'
import VideoSplashImage from '../../assets/images/video_splash.jpg'
import WhatIsKFA from '../../assets/images/what_is_kfa.jpg'
// import ButtonBack from '../../assets/slant_button.png'
// import ButtonBackContrast from '../../assets/slant_button_contrast.png'
// import ButtonBackWhite from '../../assets/slant_button_white.png'
import { HomeIcon, InsightsIcon, ResumeIcon, AssessmentsIcon } from '../../assets/icons/ToolbarIcons'
import { InterviewIcon, CoachingIcon, PaycheckIcon, JobsIcon, ChatIcon } from '../../assets/icons/ToolbarIcons'
import imgPhilosophy from '../../assets/images/resume/philosophySofi.jpg'
import imgTips from '../../assets/images/resume/tipsSofi.jpg'
import imgDosDonts from '../../assets/images/resume/dosdontsSofi.jpg'
import imgBestPractice from '../../assets/images/interview/best_practices.jpg'
import imgAssessment from '../../assets/images/prods/sofiAssessment.png'
import imgResume from '../../assets/images/prods/sofiResume.png'
import imgCoaching from '../../assets/images/prods/sofiCoaching.png'
import BoyCoach from '../../assets/images/avatars/sampleCoachGuy.jpg'
import BoyCoach2 from '../../assets/images/avatars/sampleCoachGuy.jpg'
import SofiLogo from "../../components/Logos/SofiLogo"
import { getDefaultNavbar } from '../../shared/utility'

const lightColor = '#00A3B2' //'#5ca2f6'
const panelColor = lightColor
const lighterColor = '#e2f5f8' // '#59c3cc'
const lightColorDisabled = '#59c3cc'
const darkColor = '#000' //'#1e3857'
const whiteColor = '#ffffff'
const blackColor = '#000000'
const secondaryColor = '#4f4f4f'
const contrastColor = '#8bc330'
const contrastLightColor = '#f1f7e7' //'#e7f4d2' //
const lightGrayColor = '#f4f4f4'

const mobile = {
  ...commonMobile,
  //JUST AN EXAMPLE TO SHOW HOW TO OVERRIDE DEFAULT
  bgCover: '#d9eef0',
  bgIcon: '#d9eef0',
}

export const Assets = history => {
  const theIcons = {
    HomeIcon, InsightsIcon, ResumeIcon, AssessmentsIcon,
    InterviewIcon, CoachingIcon, PaycheckIcon, JobsIcon, ChatIcon
  }
  const toolbarNavLinks = getDefaultNavbar(history, theIcons)

  const theReturn = {
    mobile,
    toolbar: 'bigBar',
    mastHead: 'oldStyle',
    externalJobsBoard: false,
    externalAssessment: false,
    scheduleRange: 24,
    hasGoals: true,
    hasNavigator: false,
    LogoComponent: SofiLogo,
    autoAssignScheduling: true,
    toolbarNavLinks: toolbarNavLinks(history),
    iconColor: lightColor,
    iconLightColor: '#b5d9dc',
    iconColorSelected: whiteColor,
    lightColor,
    menuColor: whiteColor,
    lightColorDisabled,
    contrastColor,
    bgHome: BgImgHome,
    bgAssessment: BgImgAssessment,
    bgImgCoaching: BgImgCoaching,
    bgImgResume: BgImgResume,
    bgImgChat: BgImgChat,
    bgImgFQA: BgImgFQA,
    bgImgInterview: BgImgInterview,
    bgImgBestPractices: BgImgBestPractices,
    bgImgProfile: BgImgProfile,
    bgImgPurchase: BgImgPurchase,
    bgImgJobs: BgImgJobs,
    bgImgServices: BgImgServices,
    bgImgPhilosophyHero: BgImgPhilosophyHero,
    bgImgTipsHero: BgImgTipsHero,
    bgImgDosHero: BgImgDosHero,
    bgAccountPref: accountPrefHero,
    interviewAppImage: InterviewAppImage,
    buttonAppStore: ButtonAppStore,
    buttonGooglePlay: ButtonGooglePlay,
    //interviewApp2Image: InterviewApp2Image,
    videoSplashImage: VideoSplashImage,
    whatIsKFA: WhatIsKFA,
    FooterLinks: '© Korn Ferry, All rights reserved ',
    FooterCareerQuestions:
      'Questions? Concerns? Contact us <a href="mailto:careeradvisory@kornferry.com">careeradvisory@kornferry.com</a> | <a href="tel:+1.888.291.7818">+1.888.291.7818</a>',
    FooterLoanQuestions: '', //Loan Questions? Contact SoFi <a href="mailto:CustomerService@SoFi.com">CustomerService@SoFi.com</a> | <a href="tel:+1.855.456.7634">+1.855.456.7634</a>',
    TermsLink: '',
    WelcomeMovie: 'https://video.kfadvance.com/Welcome/mp4-Welcome-1080.mp4',
    bgImgAbout: BgImgAbout,
    imgAnalysis: ImgAnalysis,
    imgExperts: ImgExperts,
    imgAboutLaunch: ImgAboutLaunch,
    imgWallet: ImgWallet,
    imgPhilosophy,
    imgTips,
    imgDosDonts,
    imgBestPractice,
    imgAssessment,
    imgResume,
    imgCoaching,
    addOnBlurb: 'Sometimes one session isn’t enough. Need more coaching? We got your back!',
    addOnButton: 'Add Another Session',
    sampleCoach1: {
      img: BoyCoach,
      name: 'Christopher Wade',
      specialty: 'Financial Sector | Career Coach',
      quote: '"As a coach my goals are to help my clients equip themselves with the traits and skills to be in touch with their best selves."',
    },
    sampleCoach2: {
      img: BoyCoach2,
      name: 'David',
      specialty: 'Career Coach',
      quote:
        '"As a coach, I am committed to each client’s journey. Nothing brings me more satisfaction than seeing my client’s overcome obstacles to land a career and lead a life filled with fulfillment, meaning, purpose, and passion."',
    },
    hideGradientOverlay: false,
    pages: {
      home: {
        showKFAd: false,
        showKFSubscriptionAd: false,
        showKFService: false,
        showSofiServices: true,
        showProductPurchased: true,
        showPrysmianFlow: false,
        showRedundentPrysmian: false,
        showInsights: false,
        mainBlurb:
          'Meet our new Korn Ferry Advance platform! We have and will continue updating our website in the coming months – but don’t worry if you were in the middle of any step, you’ll still be able to schedule and complete outstanding coaching sessions, as well as use our career tools.',
        showPODLearningPlan: false,
        showWebinarPanel: true,
        hasJourneys: false
      },
      resume: {
        showKFAd: false,
        showAssessmentBlurb: false,
      },
      assessment: {
        showAssessmentBlurb: false,
        showResumeParsing: false,
        showAssessmentContent: false,
        showAssessmentStart: true, showAssessmentBlurb2: false,
      },
      profile: {
        showKFAd: false,
        showKFService: false,
        showSofiServices: true,
        showResumeParsing: false,
        showRedundentPrysmian: false,
      },
      faq: {
        showKFAd: false,
        showKFService: false,
        showPrysmianFaq: false,
      },
      coaching: {
        showKFAd: false,
        showCoachingAd1: false,
        showCoachingAd2: false,
        coachingGoals: true,
        showKFSubscriptionAd: false,
        availableWarning: true,
      },
      interview: {
        showFAQ: true,
        mobileAppOnly: true,
      },
    },
    menus: {
      main: 'group:main_menu',
      myAccount: 'group:my_account',
      topTools: 'group:top_tools',
    }
  }
  //console.log('AND THE RETURN FOR DEFAULT IS ', theReturn);
  return theReturn
}

export const Theme = () => {
  const theTheme = {
    mobile,
    error: '#d54300',
    warning: '#daa520',
    good: 'green',
    fontOne: 'ProximaNovaA-Regular,Roboto, Helvetica Neue, Arial, sans-serif',
    fontTwo: 'ProximaNovaA-Semibold, Roboto, Helvetica Neue, Arial, sans-serif',
    darkColor: darkColor,
    lightColor: lightColor,
    headerColor: lightColor,
    panelColor,
    lightColorDisabled,
    lighterColor: lighterColor,
    whiteColor: whiteColor,
    blackColor: blackColor,
    contrastColor: contrastColor,
    contrastLightColor,
    lightGrayColor,
    buttonBackColor: lightColor,
    buttonTextColor: whiteColor,
    buttonBorderColor: lightColor,
    menuHoverColor: lightColor,
    grayTextColor: '#6a6a77',
    careerColor: lightColor,
    careerColorLight: lighterColor,
    paycheckColor: '#76e6d2',
    paycheckColorLight: '#ccfbf3',
    resumeColor: '#306259',
    resumeColorLight: '#7abcb0',
    interviewColor: '#009396',
    interviewColorLight: '#b7f3f4',
    tbBackground: '#f4f4f4',
    pnlBackground: '#ebeae9',
    kfaBackground: '#f6f8db',
    kfa2Background: '#E0F2F1', //'#009396',
    secondary: secondaryColor, //'#4f4f4f', //'#424242',
    notification: '#ffc602',
    errorRequired: '#e99000',
    done: '#757575',
    toolbarTextColor: whiteColor,
    menuTextColor: whiteColor,
    footerTextColor: whiteColor,
    actionPanelBG1: '#f6f8db',
    actionPanelBG2: '#daeff0',
    // buttonBack: ButtonBack,
    // buttonBackContrast: ButtonBackContrast,
    // buttonBackWhite: ButtonBackWhite,
    colorCareerCoach: '#03c096',
    colorPaycheckCoach: '#61e3ba',
    colorResumeCoach: '#306259',
    colorInterviewCoach: '#009396',
    colorMastText: whiteColor,
    colorMastButtonText: whiteColor
  }
  //console.log('AND THE THEME IS ', theTheme)
  return theTheme
}
