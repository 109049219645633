import { Theme as DefaultTheme } from '../components/Layout/Default.js'
import { Theme as SofiTheme } from '../components/Layout/Sofi.js'
import { Theme as SingaporeTheme } from '../components/Layout/Singapore.js'
import { Theme as PrysmianTheme } from '../components/Layout/Prysmian.js'
import { Theme as WestpointTheme } from '../components/Layout/Westpoint.js'
import { Theme as AAAE } from '../components/Layout/AAAE.js'
import { Theme as ASCM } from '../components/Layout/ASCM'
import { Theme as JAndJ } from '../components/Layout/JAndJ'
import { Theme as UOM } from '../components/Layout/UOM'
import { Theme as AFP} from '../components/Layout/AFP'
import { Theme as ABA} from '../components/Layout/ABA'
import { Theme as ACME_Talent } from '../components/Layout/ACME_Talent'
import { Theme as ACME_University } from '../components/Layout/ACME_University'
import { Theme as ACME_Outplacement } from '../components/Layout/ACME_Outplacement'
import { Theme as ACME_Association } from '../components/Layout/ACME_Association'
import { Theme as NFL } from '../components/Layout/NFL'
import { Theme as PayPal } from '../components/Layout/PayPal'
import { Theme as ACS } from '../components/Layout/ACS'
import { Theme as KFMentor } from '../components/Layout/KFMentor'
import { Theme as ABB } from '../components/Layout/ABB'
import { Theme as Phoenix} from '../components/Layout/Phoenix'
import { Theme as CareerTransitions} from '../components/Layout/CareerTransitions'
import { Theme as Mapi } from '../components/Layout/Mapi'
import { Theme as Journey_Demo } from '../components/Layout/Journey_Demo'
import { Theme as Feedback } from '../components/Layout/Feedback'
import { Theme as PGA} from '../components/Layout/PGA'
import { Theme as Google} from '../components/Layout/Google'
import { Theme as University} from '../components/Layout/University'
import { Theme as TD} from '../components/Layout/TD'
import { Theme as TDLearn} from '../components/Layout/TDLearn'
import { Theme as KFListen} from '../components/Layout/KFListen'
import { Theme as Unilever} from '../components/Layout/Unilever'
import { Theme as Integration} from '../components/Layout/Integration'
import { Theme as DigitalSea} from '../components/Layout/DigitalSea'
import { Theme as SwissRe} from '../components/Layout/SwissRe'
import { Theme as Microsoft} from '../components/Layout/Microsoft'
import { Theme as InclusiveLeader} from '../components/Layout/Inclusive_Leader'
import { Theme as Intel} from '../components/Layout/Intel'
import { Theme as Geisinger} from '../components/Layout/Geisinger'

export const getPartnerTheme = partnerKey => {

  switch (partnerKey) {
    case 'SVOoQJaeX0eLboUOU0wSoQtt': //kfa
      return DefaultTheme
    case '5411724D63CE4E61ACCA0681D8DE6E25': //sofi
      return SofiTheme
    case '7511cece2f65fe688fa10888': //singapore
      return SingaporeTheme
    case 'b1dc5b760f0d44e4b44beeed1bdfa406': // Prysmian
      return PrysmianTheme
    case '505a4b6298b54271a35f637656260a17': // Westpoint
      return WestpointTheme
    case 'fa0bd618bc54eba307f5d50e765a34d7': //AAAE
      return AAAE
    case '2d728213cf47f6bf0d1d4f84b6340026': //APICS
      return ASCM
    case '001c46d02de148cd967d67ef5a413206': //JJ
      return JAndJ
    case 'bdd893475af043629927d54212aced97':
      return UOM
    case 'c3a322655ec64ba4b57e11b94f394a55':
      return AFP
    case '03488715f2e64ffc82fad0c789854fbf':
      return ABA
    case '6cc7f9b92ecd43b2bf13fe4cdf3c8d5e':
      return ACME_Talent
    case '7cbb4a33dc364eb382e066ad3dcf6bef':
      return ACME_Association
    case '0ecdf070b13048dea3218c93d40ea9b0':
      return ACME_University
    case '1ba8e419db1d4c63912abe69127856fd':
      return ACME_Outplacement
    case 'b95b3a6f081b4748baf62ded9aef5a4a':
      return NFL
    case '10b0cae1cf9f4a6e9daf6e125462057c':
      return PayPal
    case '8760acff3139474c9a09462e56c4d1e0':
      return ACS
    case 'aabfcdd1df104d61bf1d9a287f403106':
      return KFMentor
    case 'd85c57c692394398b01baf425f29a5f4':
      return ABB
    case '3327fa2033b9492cb777088efba37957':
      return Phoenix
    case 'd4efcf6ddbfe422791337ec84e84ba26':
      return CareerTransitions
    case '6cc65419d1284832929458739d7e3eba':
      return Mapi
    case '4633483a61cd46aeb171ac24ec84e6b8':
      return Journey_Demo
    case '8fb61cead7324843a071b36e50a4970f':
        return Feedback
    case 'bb18ecc961f9401589eeac31256f06e3':
        return PGA
    case 'fc1e5f5840584e63be647a4225d6eb5c':
        return Google
    case '80dd4ab2fd314cd69c4e865433bdcb55':
      return University
    case '25b5f1f8690a46e1b7a44d00ccd65631':
      return TD
    case 'e3d48b3250b8414ebdeec63959989198':
      return TDLearn
    case '583418dc05c148618acdc8722b6d6c35':
      return Unilever
    case '9852370106d843829ead53d62ed31094':
      return Integration
    case '56d8c902e45a4d8b9ad54296629fe5a0':
      return DigitalSea
    case '84cb62d1e806444e8dc23b10c5f26c1d':
      return SwissRe
    case '0f2e477da19443b0ade02f8135626c4d':
      return Microsoft
    case 'FIXME_FIXME_InclusiveLeader':
      console.log('GOT INCLUSIVE THEME')
      return InclusiveLeader
    case '288d8b84d1a64ece9b956441df57de59':
      return Intel
    case 'fe55ef0b7a2245acb1961e03aa7acc1d':
      return KFListen
    case '837f4075b3ea4242817969a56b93adb6':
      return Geisinger
    default:
      return DefaultTheme
  }
}
