import React,{Fragment} from 'react';
import {connect} from 'react-redux';
import {actions} from '../../../redux/modules/frontend';
import shortid from 'shortid'
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ClearIcon from '@material-ui/icons/Clear';
import Typography from '@material-ui/core/Typography';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {siteDrawerStyles as styles, StyledToolbar} from '../styles';
import { logEvent } from '../../../shared/analytics';
import { analyticsTypes, analyticsActions } from '../../../constants/analytics';
import RoleSelect from '../../RoleSelect/RoleSelect'


const defaultMenuTextColor = '#000000';

class SiteDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  toggleDrawer = open => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
  };

  redirect = url => {
    const {history, close} = this.props;
    if (history.location && history.location.pathname && history.location.pathname === url) {
      return false
    }
    history.push(url);
    close();
  }

  onClick = (item) => {
    const {clearSelectedTool, setItem} = this.props;
    clearSelectedTool();
    setItem('TopTools', 'currentTool', null);

    if (item.type === 'route') {
      this.redirect(item.resource);
      logEvent('event', analyticsTypes.menu, analyticsActions.navigate, {source: 'topLeftMenu:' + item.resource});
      return;
    }

    if (item.type === 'link') {
      window.open(item.resource, '_blank');
      return;
    }

    if (item.type === 'action') {
      item.onClick();
      return;
    }
  }

  getLabel = item => {
    const {c, content} = this.props;
    if (item.props && item.props.contentDef) return c(item.props.contentDef);
    if (content && content.Global) return content['Global']['Menu'][item.name];
    return '-'
  }

  isZeroOrOneChild = menu => {
    const {access} = this.props;
    if (!menu || !menu.children || menu.children.filter(obj => access[obj.resource] === 'allowed').length > 1) return false;
    return true;
  }

  genSubMenu = (menu, level) => {
    const {classes, c, history, assets, access, resources} = this.props;
    if (!menu || !menu.children || access[menu.resource] !== 'allowed') return null;

    if (this.isZeroOrOneChild(menu)) {
      const allowedChildren = menu.children.filter(obj => access[obj.resource] === 'allowed');
      if (allowedChildren.length === 0) return null;

      let obj = allowedChildren[0];
      let item = resources[obj.resource];
      item['access'] = access[obj.resource];

      return (
        <div key={shortid.generate()}>
          <MenuItem className="menu-group" style={{marginLeft: 20*(level-1)}} onClick={() => this.onClick(item)}>
            <ListItemIcon className={classes.icon}><Icon>{item.props ? item.props.icon : null}</Icon></ListItemIcon>
            <ListItemText className={classes.menuItem} disableTypography
                primary={<Typography variant="BUTTON TEXT" style={{color: assets.menuTextColor || defaultMenuTextColor, 'textTransform': 'uppercase', fontSize: '14px' }}>{this.getLabel(item)}</Typography>}
                />
          </MenuItem>
          <Divider />
        </div>
      )
    }

    return (
      <div key={shortid.generate()}>
        <ListItem className={"menu-group " + classes.menuItem} button key={menu.resource} onClick={() => this.setState({[menu.resource]: !this.state[menu.resource]})}>
          <ListItemIcon className={classes.icon}><Icon>{menu.props ? menu.props.icon : null}</Icon></ListItemIcon>
          <ListItemText className={classes.menuItem} disableTypography
              primary={<Typography variant="BUTTON TEXT" style={{color: assets.menuTextColor || defaultMenuTextColor, 'textTransform': 'uppercase', fontSize: '14px'}}>{this.getLabel(menu)}</Typography>}
              />
          {this.state[menu.resource] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Divider />
        <Collapse in={this.state[menu.resource]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {menu.children.filter(obj => access[obj.resource] === 'allowed').map(obj => {
              let item = resources[obj.resource];
              item['access'] = access[obj.resource];
              if (obj.childen) item['children'] = obj.children;

              return ['route', 'link'].includes(item.type) ? (
                  <div key={shortid.generate()}>
                    <ListItem className="menu-group"  button key={item.name} style={{marginLeft: level*20}} onClick={() => this.onClick(item)}>
                      <ListItemIcon className={classes.icon}><Icon>{item.props ? item.props.icon : null}</Icon></ListItemIcon>
                      <ListItemText className={classes.menuItem} disableTypography
                          primary={<Typography variant="BUTTON TEXT" style={{color: assets.menuTextColor || defaultMenuTextColor, 'textTransform': 'uppercase', fontSize: '14px'}}>{this.getLabel(item)}</Typography>}
                        />
                    </ListItem>
                    <Divider />
                  </div>
                ) : this.genSubMenu(item, level+1);
            })}
          </List>
        </Collapse>

      </div>
    )
  }

  genMenu = (menu) => {
    if (!menu || !menu.children) return null;

    const {classes, assets, access, resources} = this.props;

    return (
      <div className={classes.list}
            role="presentation"
            onClick={this.toggleDrawer(false)}
            onKeyDown={this.toggleDrawer(false)}
          >
        <List style={{backgroundColor: assets.lightColor, overflow: 'hidden'}}>
          {menu.children.filter(obj => obj.type === 'divider' || obj.type === 'action' || access[obj.resource] === 'allowed').map(obj => {
            let item = obj;

            if ( obj.resource) {
                item = resources[obj.resource];
                item.access = access[obj.resource];
                if (obj.children) item['children'] = obj.children;
            }

            return ['route', 'link', 'action', 'divider'].includes(item.type) ? (
                <Fragment key={shortid.generate()}>
                  <ListItem className="menu-group" button key={item.name} onClick={() => this.onClick(item)} disabled={item.selected} >
                    <ListItemIcon className={classes.icon}><Icon>{item.props ? item.props.icon : null}</Icon></ListItemIcon>
                    <ListItemText className={classes.menuItem} disableTypography
                        primary={<Typography variant="BUTTON TEXT" style={{color: assets.menuTextColor || defaultMenuTextColor, 'textTransform': 'uppercase'}}>{this.getLabel(item) || item.name}</Typography>}
                      />
                  </ListItem>
                  <Divider />
                </Fragment>
              ) : this.genSubMenu(item, 1);
          })}
        </List>
      </div>
    )

  }

  render() {
    const {classes, isOpen, close, assets, getMainMenu, type, rolesMenu = []} = this.props;
    const LogoComponent = assets.LogoComponent;

    return (
      <Drawer anchor='top' open={isOpen} onClose={close} classes={{paper: classes.drawerPaper}}>
        <StyledToolbar className={classes.topnav} tabIndex="-1">
            <div className={classes.barContainer} >
              <IconButton onClick={close} className={classes.barButton} color="inherit" aria-label="Menu">
                <ClearIcon  />
              </IconButton>
              <Link tabIndex="-1" to="/" className={classes.barLogo} >
                <LogoComponent type="header" />
              </Link>
            </div>
        </StyledToolbar>
        {type === "menu" && this.genMenu(getMainMenu(assets)) }

        {type === "role-menu" && <RoleSelect rolesMenu={rolesMenu} assets={assets} /> }

        

      </Drawer>
    )
  }
}

const mapStateToProps = state => ({
  access: state.access.access,
  resources: state.access.resources,
  getMainMenu: assets => assets.menus && assets.menus.main
        ? state.access.resourceGroups[assets.menus.main]
        : state.access.resourceGroups['group:main_menu'],
})

const mapDispatchToProps = dispatch => ({
  clearSelectedTool: () => dispatch(actions.TopTools.clearSelected()),
  setItem: (group, key, value) => dispatch(actions.setItem(group, key, value)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SiteDrawer));
