export default {
  page: 'page',
  button: 'button',
  icon: 'icon',
  menu: 'menu',
  link: 'link',
  video: 'asset',
  article: 'asset',
  calendar: 'calendar',
  coach: 'coach',

  asset: 'asset',
  user: 'user',
  interview: 'interview',
  goals: 'goals',
  purchase: 'purchase',
  schedule: 'schedule',
  scheduling: 'scheduling',
  rescheduling: 'rescheduling',
  subscription: 'subscription',
  upload: 'upload',
  navigation: 'navigation',
  assessment: 'assessment',
}