import React from 'react'


export default class extends React.Component {
    render() {
     const { Color } = this.props
     const color = Color ? Color : "#3b3b3b";

      return (
        <svg width="40px" height="39px" viewBox="0 0 45 45" version="1.1" xmlns="http://www.w3.org/2000/svg" >
        <title>Group 3</title>
        <g id="201217_NewVersion" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g id="Client_Portal" transform="translate(-907.000000, -143.000000)" stroke={color} strokeWidth="1.75">
                <g id="Group-3" transform="translate(907.000000, 143.000000)">
                    <g id="Regular" transform="translate(4.000000, 7.000000)">
                        <polyline id="Shape" points="22.1052632 27.8618421 16.3486842 27.8618421 16.3486842 33.6184211"></polyline>
                        <path d="M33.8747807,28.9870614 C32.6211449,32.6506546 29.062704,35.0151161 25.1995445,34.7514572 C21.3363849,34.4877984 18.1321911,31.6617903 17.3879386,27.8618421" id="Shape"></path>
                        <polyline id="Shape" points="29.0131579 23.2565789 34.7697368 23.2565789 34.7697368 17.5"></polyline>
                        <path d="M17.2421053,22.1313596 C18.495741,18.4677664 22.0541819,16.103305 25.9173415,16.3669638 C29.7805011,16.6306227 32.9846948,19.4566308 33.7289474,23.2565789" id="Shape"></path>
                        <path d="M15.6287281,15.2173246 C13.9208225,13.7102801 11.718508,12.8836609 9.44078947,12.8947368 C4.77162782,12.8581324 0.813782819,16.3212467 0.230263158,20.9539474" id="Shape"></path>
                        <circle id="Oval" cx="9.44078947" cy="5.41118421" r="5.18092105"></circle>
                        <circle id="Oval" cx="24.4078947" cy="4.25986842" r="4.02960526"></circle>
                        <path d="M30.3947368,12.8947368 C29.279587,10.965265 27.3020919,9.69229115 25.0840572,9.47609062 C22.8660225,9.25989009 20.6799228,10.127022 19.2131579,11.8048246" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
      )
    }
  }