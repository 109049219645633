import React from 'react'
import styled from 'styled-components'
import { mobile } from '../../styles/media-queries'

const StyledDiv = styled.div`
    margin: 20px;
    background-color: #006B36;

    img {
      width: 350px;
      padding: 10px;
    }

    &.header {
      margin: 0px;
      background-color: transparent;
      
    }

    img.header {
      width: 250px;
      padding: 0px;
    }


    &.footer {
      margin: 0px;
      background-color: transparent;
    }

    img.footer {
      width: 200px;
      padding: 0px;
    }


  @media  ${mobile} {
    img {
      width: 250px;
      padding: 10px;
    }
  }`

export default ({ type = "",dark = false }) => (
  <StyledDiv className={type}>
    <img
      className={type}
      //src={`/static/images/logos/${dark ? 'ASCM_KornFerry_Lockup-W' : 'ASCM_KornFerry_Lockup-W'}.png`}
      src="/static/images/logos/Ascm_kfa_cobranded.png"
      alt="ASCM Logo"
    />
  </StyledDiv>
)
