import styled from 'styled-components'
import Mutton from '@material-ui/core/Button'

export const Panel = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    padding: 10px;
    margin: 10px;
  }
`

export const Clearfix = styled.div`
  && {
    overflow: auto;
    &:before,
    &:after {
      display: table;
      line-height: 0;
      content: '';
      height: 0;
    }
  }
`

export const Button = styled(Mutton)`
  && {
    text-transform: capitalize;
    background: linear-gradient(to top, #f4f4f4, #ffffff 82%, #f9f9f9);
    border: solid 1px #c6c6c6; /* extract colors to theme */
    border-radius: 4px;
    color: #222222;
    padding: 8px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 4px 2px;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    cursor: pointer;
    &:hover {
      background: #fff;
    }
  }
`

export const H1 = styled.h1`
  && {
    font-size: 1.5rem;
    font-weight: 500;

    line-height: 1.35417em;
  }
`

export const H2 = styled.h2`
  && {
    font-size: 0.9rem;
    font-weight: bold
    color: #4b6e7f
    line-height: 1.35417em;
  }
`

export const H3 = styled.h3`
  && {
    font-size: 0.9rem;
    font-weight: 500
    color: #4b6e7f
    line-height: 1.35417em;
    margin: 0;
  }
`

export const Caption = styled.span`
  && {
    font-size: 0.75rem;
    font-weight: 400;

    line-height: 1.375em;
  }
`

export const Divider = styled.div`
  border: solid 0.5px #979797;
  margin: 1rem 0;
`

export const FlexCenterHV = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Message = styled.div`
  margin: 2rem 0;
  padding: 1rem;
  background-color: rgb(211, 211, 211);
`
